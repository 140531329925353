import { AfterViewInit, Component, Inject, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import { AssignOrganizationModel, IOrganizationModel, SwaggerException, UserModel } from '@app/api';
import { ApiService, AppRoutingData, LogService, pathFragmentsToAdmin } from '@app/core';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { DialogBase } from '../../dialogs/dialog-base';
import { OrganizationInputComponent } from '../organization-input/organization-input.component';

enum UploadError {
  Add = 'add',
  AddCraft = 'addCraft',
  Assign = 'assign',
  Edit = 'edit',
}

export interface IFlatOrganizationModel extends IOrganizationModel {
  street?: string;
  city?: string;
  zipCode?: string;
  country?: string;
}

@Component({
  selector: 'app-organization-dialog',
  templateUrl: './organization-dialog.component.html',
  styleUrls: ['./organization-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class OrganizationDialogComponent extends DialogBase<OrganizationDialogComponent> implements OnInit {
  data: any;
  users: UserModel[];
  userLoadingScope: Busy = { isBusy: false };
  form: UntypedFormGroup;

  constructor(
    public dialogRef: MatDialogRef<OrganizationDialogComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private apiService: ApiService,
    private logger: LogService,
    private router: Router,
    private userNotification: UserNotificationService
  ) {
    super(dialogRef, data);
  }

  async ngOnInit() {
    this.form = new UntypedFormGroup({
      organization: new UntypedFormControl(this.data?.organization),
    });
    if (this.showUser) await this.loadUser();
  }

  get f() {
    return this.form.controls;
  }

  get showUser(): boolean {
    return this.data.showUser && this.data?.organization?.id;
  }

  get existingOrganizations() {
    return this.data?.existingOrganizations ?? [];
  }

  get assignedOrganizations() {
    return this.data?.assignedOrganizations ?? [];
  }

  get existingCrafts() {
    return this.data?.existingCrafts ?? [];
  }

  protected readData(data: any) {
    super.readData(data);
    this.data = data;
  }

  goToUser(user: UserModel) {
    this.router.navigate(pathFragmentsToAdmin(AppRoutingData.users.path), { queryParams: { edit: true, id: user.id } });
    this.cancel();
  }

  async confirm() {
    let organization = this.f.organization.value;
    const isNew = !organization.id;
    let error = isNew ? UploadError.Add : UploadError.Edit;

    await using(new BusyScope(this), async () => {
      try {
        organization.id = await this.apiService.saveOrganization(organization);

        error = UploadError.AddCraft;

        if (!this.data.isAddOrEdit) {
          const craftIds: string[] = [];
          if (this.data.canEditCrafts) {
            for (let i = 0; i < organization.crafts?.length ?? 0; ++i) {
              const craft = organization.crafts[i];

              if (!craft.id) {
                craft.id = await this.apiService.saveCraft(craft);
              }

              craftIds.push(craft.id);
            }
          }

          error = UploadError.Assign;

          const model = new AssignOrganizationModel({
            organizationId: organization.id,
            craftIds,
          });

          await this.apiService.assignOrganization(model);
        }

        this.userNotification.notify('organizations.success.save');
        this.dialogRef.close(organization);
      } catch (e) {
        if (SwaggerException.isSwaggerException(e) && e.status === 409) {
          this.userNotification.notify('organizations.error.exists');
        } else {
          this.userNotification.notify('organizations.error.' + error);
        }
      }
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  private async loadUser() {
    const organizationId = this.data?.organization?.id;
    if (organizationId && !this.userLoadingScope.isBusy && this.users == null) {
      await using(new BusyScope(this.userLoadingScope), async busy => {
        this.users = await this.apiService.getUserForOrganization(organizationId);
      }).catch(error => {
        this.logger.error('Loading users failed', error);
      });
    }
  }
}
