import { Component, ElementRef, OnInit, TemplateRef, ViewChild, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ProblemDetails, ProblemDetailsErrorType, RmFileContent, RoomTemplateModel, TransferFileFormat } from '@app/api';
import {
  ApiService,
  AppRoutingData,
  AuthenticationService,
  BaseSubscriptionComponent,
  DataHolder,
  GlobalsService,
  ProjectService,
} from '@app/core';
import { FileType } from '@app/core/enumerations';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { TranslateService } from '@ngx-translate/core';
import { SortMeta } from 'primeng/api';
import { RoomTemplateEditDialogComponent } from './room-template-edit-dialog/room-template-edit-dialog.component';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { OkDialogComponent } from '../../dialogs/ok-dialog/ok-dialog.component';
import { C4GridDef, C4GridFilterType, C4GridMatchMode } from '../../grid';
import { RoomToTemplateDialogComponent } from '../../dialogs/room-to-template-dialog/room-to-template-dialog.component';
import { MultiSelectDialogComponent } from '../../dialogs/multi-select-dialog/multi-select-dialog.component';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import * as saveAs from 'file-saver';

class ExtendedRoomTemplate extends RoomTemplateModel {
  numberOfAttributes?: number;
}

@Component({
  selector: 'app-room-templates',
  templateUrl: './roomTemplates.component.html',
  styleUrls: ['./roomTemplates.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class RoomTemplatesComponent extends BaseSubscriptionComponent implements OnInit, Busy {
  @ViewChild('generalTranslate', { static: true }) generalTranslateTemplate: TemplateRef<any>;
  @ViewChild('actions', { static: true }) actionsTemplate: TemplateRef<any>;
  @ViewChild('fileInput', { static: true }) fileInput: ElementRef<HTMLInputElement>;

  icon = AppRoutingData.roomTemplates.icon;
  isBusy: boolean;
  isInProject: boolean;
  gridDef: C4GridDef;
  roomTemplatesHolder: DataHolder<ExtendedRoomTemplate[]>;

  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private dialog: MatDialog,
    private globals: GlobalsService,
    private projectService: ProjectService,
    private translate: TranslateService,
    private userNotification: UserNotificationService
  ) {
    super();
  }

  async ngOnInit() {
    this.roomTemplatesHolder = new DataHolder(this.initData());

    const initialSorting: SortMeta[] = [{ field: 'name', order: 1 }];

    this.gridDef = {
      initialSorting: initialSorting,
      grid: {
        filterRow: true,
        lazyInit: false,
        responsive: true,
        rowExpand: true,
        paging: true,
        scrollable: true,
        rows: 10,
        rowsOptions: [5, 10, 20, 50, 100],
        lazy: false,
      },
      row: {
        link: false,
      },
      cols: [
        {
          field: 'name',
          header: 'roomTemplates.name',
          width: '2*',
          minWidth: '10em',
          priority: 1,
          sortable: true,
          filterType: C4GridFilterType.text,
          filterMatchMode: C4GridMatchMode.contains,
        },
        {
          field: 'isDefault',
          header: 'roomTemplates.defaultTemplate',
          width: '1*',
          minWidth: '4em',
          priority: 1,
          sortable: true,
          filterType: C4GridFilterType.select,
          options: [
            {
              label: this.translate.instant('general.true'),
              value: true,
            },
            {
              label: this.translate.instant('general.false'),
              value: false,
            },
          ],
          template: this.generalTranslateTemplate,
        },
        {
          field: 'numberOfAttributes',
          header: 'roomTemplates.numberOfAttributes',
          width: '1*',
          minWidth: '4em',
          priority: 1,
          sortable: true,
          filterType: C4GridFilterType.text,
          filterMatchMode: C4GridMatchMode.contains,
        },
        {
          field: '',
          header: 'schemata.actions',
          width: '8em',
          minWidth: '8em',
          cssClass: 'action-flex-grid',
          priority: 1,
          sortable: false,
          template: this.actionsTemplate,
        },
      ],
    };

    this.subscribe(this.projectService.projectId$, async projectId => {
      this.isInProject = projectId != null;
      await this.updateData();
    });
  }

  async addOrEditTemplate(roomTemplateId: string = null) {
    document.documentElement.classList.add('dialog-focus');
    const result = await this.dialog
      .open(RoomTemplateEditDialogComponent, {
        disableClose: true,
        data: {
          roomTemplateId,
          isInProject: this.isInProject,
        },
        panelClass: this.globals.isMobile ? ['tasklike-dialog', 'fullscreen'] : 'tasklike-dialog',
      })
      .afterClosed()
      .toPromise();
    document.documentElement.classList.remove('dialog-focus');

    if (result) await this.updateData();
  }

  async deleteRoomTemplate(roomTemplate: RoomTemplateModel) {
    if (roomTemplate.numberOfLinkedRooms > 0) {
      await this.dialog
        .open(OkDialogComponent, {
          data: {
            title: 'general.errorCaption.delete',
            description: 'roomTemplates.error.deleteLinked',
            params: {
              roomCount: roomTemplate.numberOfLinkedRooms,
            },
          },
        })
        .afterClosed()
        .toPromise();
    } else {
      const dialogRef = this.dialog.open(ConfirmDialogComponent, {
        data: {
          title: 'roomTemplates.deleteConfirmCaption',
          description: 'roomTemplates.deleteConfirmDescription',
        },
      });
      const dialogResult = await dialogRef.afterClosed().toPromise();
      if (dialogResult) {
        await using(new BusyScope(this), async busy => {
          await this.apiService.removeRoomTemplate(roomTemplate.id);
          this.updateData(); // do not await to remove busy overlay (grid has its own)
        }).catch(error => {
          if (error.status == 409) {
            this.userNotification.notify('roomTemplates.error.isAssigned');
          } else {
            this.userNotification.notify('roomTemplates.error.remove');
          }
        });
      }
    }
  }

  async export(format: TransferFileFormat = TransferFileFormat.CSV) {
    const fileResponse = await this.apiService.exportRoomBookFromProject(RmFileContent.RoomTemplates, format);

    if (CapacitorUtils.isApp()) {
      await CapacitorUtils.blobFileHandler(fileResponse.data, fileResponse.fileName, true);
    } else {
      saveAs(fileResponse.data, decodeURIComponent(fileResponse.fileName));
    }
  }

  async addFromGlobal() {
    let globalTemplates: RoomTemplateModel[];
    await using(new BusyScope(this), async _ => {
      globalTemplates = await this.apiService.getGlobalRoomTemplates();
    }).catch(error => {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.roomTemplates', error);
    });

    if (!globalTemplates) return;

    const data = {
      items: globalTemplates.map(t => {
        var selectableTemplate = t as Selectable;
        selectableTemplate.displayName = t.name;
        selectableTemplate.isSelected = false;
        return selectableTemplate;
      }),
      mustSelect: true,
      canCancel: true,
      title: 'roomBook.selectGlobal.templateTitle',
      description: 'roomBook.selectGlobal.templateDescription',
    };

    const templates: RoomTemplateModel[] = await this.dialog
      .open(MultiSelectDialogComponent, {
        data: data,
        disableClose: true,
      })
      .afterClosed()
      .toPromise();

    if (templates?.length) {
      await using(new BusyScope(this), async _ => {
        await this.apiService.transferRoomTemplatesToProject(templates.map(t => t.id));
        this.userNotification.notify('general.successMsg.transfer');
        this.updateData(); // do not await to remove busy overlay (grid has its own)
      }).catch(_ => {
        this.userNotification.notify('general.errorMsg.transfer');
      });
    }
  }

  async createFromRoom() {
    var roomId = await this.dialog.open(RoomToTemplateDialogComponent).afterClosed().toPromise();
    if (roomId) {
      this.updateData();
    }
  }

  async import(file: File) {
    if (file && (file.type === FileType.CSV_EXCEL || file.type == FileType.CSV_TEXT)) {
      let success = false;
      await using(new BusyScope(this), async _ => {
        const data = await file.arrayBuffer();
        const blob = new Blob([data], { type: 'text/csv' });

        await this.apiService.importRoomBookToProject(RmFileContent.RoomTemplates, TransferFileFormat.CSV, {
          data: blob,
          fileName: file.name,
        });

        success = true;
        this.userNotification.notify('general.successMsg.import');
      }).catch(e => {
        if (e instanceof ProblemDetails) {
          const { EntityKeys, EntityType } = e.extensions;

          switch (e.type) {
            case ProblemDetailsErrorType.MissingEntity:
              this.userNotification.notify('roomBook.error.missingEntity', {
                entityType: EntityType,
                entityKeys: EntityKeys.map(x => `'${x}'`).join(', '),
              });
              break;
            case ProblemDetailsErrorType.DuplicatedData:
              this.userNotification.notify('roomBook.error.duplicatedEntity', {
                entityType: EntityType,
                entityKeys: EntityKeys.map(x => `'${x}'`).join(', '),
              });
              break;
            default:
              this.userNotification.notify('general.errorMsg.import');
              break;
          }
        }
      });

      if (success) await this.updateData();
    } else {
      this.userNotification.notify('general.errorFileType');
    }
  }

  private initData() {
    const fakeTemplates: ExtendedRoomTemplate[] = [];
    for (let idx = 0; idx < 3; idx++) {
      const extended = new ExtendedRoomTemplate({
        name: 'fakeData',
        isDefault: false,
      });
      extended.numberOfAttributes = 0;
      fakeTemplates.push(extended);
    }
    return fakeTemplates;
  }

  private async updateData() {
    await this.roomTemplatesHolder
      ?.updateData(async () => {
        const templates = await this.apiService.getRoomTemplates();
        const result = templates.map(t => {
          const extended = new ExtendedRoomTemplate(t);
          extended.numberOfAttributes = t.attributes?.length ?? 0;
          return extended;
        });
        this.gridDef.grid.rowCount = result.length;
        return result;
      }, true)
      .catch(e => {
        this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.roomTemplates', e);
      });
  }
}
