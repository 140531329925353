import { Injectable } from '@angular/core';
import { ApiService, AppConfigService, rgbToHex } from '@app/core';
import { MaterialCssVarsService } from 'angular-material-css-vars-legacy';

type HexColor = string;

interface Theme {
  primary: HexColor;
  accent: HexColor;
  warn: HexColor;
}

@Injectable({
  providedIn: 'root',
})
export class CustomizationService {
  private theme: Theme;

  constructor(private apiService: ApiService, private materialCssVarsService: MaterialCssVarsService) {}

  get primaryHexColor() {
    return this.theme.primary;
  }

  get accentHexColor() {
    return this.theme.accent;
  }

  get warnHexColor() {
    return this.theme.warn;
  }

  setTheme() {
    this.theme = {
      // Settings Styles will be updated from server with tenant customizations on load in app-config.service.ts
      primary: this.getHexColor(AppConfigService.settings.style.main),
      accent: this.getHexColor(AppConfigService.settings.style.accent),
      warn: this.getHexColor(AppConfigService.settings.style.warning),
    };

    this.updateTheme(this.theme);
  }

  updateTheme(theme: Partial<Theme>) {
    if (theme.primary) this.materialCssVarsService.setPrimaryColor(`#${theme.primary}`);
    if (theme.accent) this.materialCssVarsService.setAccentColor(`#${theme.accent}`);
    if (theme.warn) this.materialCssVarsService.setWarnColor(`#${theme.warn}`);
  }

  async resetStyles(fromServer: boolean = false, overrideSettings: boolean = false) {
    let theme: Theme = this.theme;

    if (fromServer) {
      const customization = await this.apiService.getCustomization();

      theme = {
        primary: this.getHexColor(customization.mainColor),
        accent: this.getHexColor(customization.accentColor),
        warn: this.getHexColor(AppConfigService.settings.style.warning),
      };

      if (overrideSettings) {
        this.theme = theme;
      }
    }

    this.updateTheme(theme);
  }

  private getHexColor(rgb: string) {
    const [r, g, b] = rgb.split(',').map(value => Number.parseInt(value.trim()));
    return rgbToHex(r, g, b);
  }
}
