import { Component, TemplateRef, ViewChild } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { OrganizationModel, ProjectState, TeamConfigMetadata } from '@app/api';
import { AppRoutingData, FormUtils } from '@app/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { FileUtils } from '@app/core/utils/file-utils';
import { CropImageDialogComponent } from '../../dialogs/crop-image-dialog/crop-image-dialog.component';
import { FileGroup } from '@app/core/enumerations';
import { PcfGeneral, ProjectConfigService } from '@app/shared/services/project-config';
import { ConfirmDialogComponent } from '../../dialogs';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { Router } from '@angular/router';
import { UserNotificationService } from '@app/shared/services';

const LOGO_SMALL_SIZE = 512;
const LOGO_WIDTH = 768;
const LOGO_HEIGHT = 256;

@Component({
  selector: 'app-general-config',
  templateUrl: './general-config.component.html',
  styleUrls: ['./general-config.component.scss'],
})
export class GeneralConfigComponent implements Busy {
  @ViewChild('projectLogoFallback') projectLogoFallbackTemplate: TemplateRef<any>;

  appRoutingData = AppRoutingData;
  validTypes: string[] = [FileGroup.image];
  isBusy: boolean = false;

  constructor(
    private configService: ProjectConfigService,
    private dialog: MatDialog,
    private router: Router,
    private notificiationService: UserNotificationService
  ) {}

  get form() {
    return this.configService.generalForm;
  }

  get f() {
    return this.form.controls;
  }

  get isNew(): boolean {
    return this.configService.isNew;
  }

  get isTemplate(): boolean {
    return this.configService.isTemplate;
  }

  get computedName(): string {
    return this.configService.computedName;
  }

  get teamTemplates(): TeamConfigMetadata[] {
    return this.configService.teamTemplates;
  }

  get organizations(): OrganizationModel[] {
    return this.configService.organizations;
  }

  getError(control: AbstractControl) {
    return Object.keys(control.errors)[0];
  }

  async editLogo() {
    const dialogResult = await this.dialog
      .open(CropImageDialogComponent, {
        data: {
          title: 'projectConfig.general.uploadLogo',
          description: 'projectConfig.general.uploadLogoDescription',
          image: FormUtils.getFormValue<PcfGeneral>(this.form, 'logo'),
          imageWidth: LOGO_WIDTH,
          imageHeight: LOGO_HEIGHT,
          previewImageWidth: 48,
          isColumnLayout: true,
        },
      })
      .afterClosed()
      .toPromise();

    if (dialogResult?.confirmed) {
      FormUtils.getFormControl<PcfGeneral>(this.form, 'logo').setValue(dialogResult.image);
      this.form.markAsDirty();
    }
  }

  async editLogoSmall() {
    const dialogResult = await this.dialog
      .open(CropImageDialogComponent, {
        data: {
          title: 'projectConfig.general.uploadLogoSmall',
          description: 'projectConfig.general.uploadLogoSmallDescription',
          image: FormUtils.getFormValue<PcfGeneral>(this.form, 'logoSmall'),
          fallbackTemplate: this.projectLogoFallbackTemplate,
          imageWidth: LOGO_SMALL_SIZE,
          imageHeight: LOGO_SMALL_SIZE,
        },
      })
      .afterClosed()
      .toPromise();

    if (dialogResult?.confirmed) {
      FormUtils.getFormControl<PcfGeneral>(this.form, 'logoSmall').setValue(dialogResult.image);
      this.form.markAsDirty();
    }
  }

  async setLogo(file: File) {
    const encodedImage = await FileUtils.getEncodedImage(file, {
      imageHeight: LOGO_HEIGHT,
      imageWidth: LOGO_WIDTH,
      shouldImageCover: true,
    });

    FormUtils.getFormControl<PcfGeneral>(this.form, 'logo').setValue(encodedImage);
    this.form.markAsDirty();
  }

  async setLogoSmall(file: File) {
    const encodedImage = await FileUtils.getEncodedImage(file, {
      imageHeight: LOGO_SMALL_SIZE,
      imageWidth: LOGO_SMALL_SIZE,
      shouldImageCover: true,
    });

    FormUtils.getFormControl<PcfGeneral>(this.form, 'logoSmall').setValue(encodedImage);
    this.form.markAsDirty();
  }

  async archiveProject() {
    const dialogResult = await this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'projectConfig.general.archive.title',
          description: 'projectConfig.general.archive.confirmationText',
          params: { projectName: this.computedName },
          acceptButtonLabel: 'general.yes',
          cancelButtonLabel: 'general.no',
        },
      })
      .afterClosed()
      .toPromise();

    if (dialogResult === true) {
      using(new BusyScope(this), async () => {
        await this.configService.setState(ProjectState.Archived);
      })
        .then(() => {
          this.router.navigate([AppRoutingData.projects]);
        })
        .catch(() => {
          this.notificiationService.notify('projectConfig.general.archive.error');
        });
    }
  }
}
