import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { COMMA, ENTER, SEMICOLON } from '@angular/cdk/keycodes';
import { MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent } from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent } from '@angular/material/legacy-chips';
import { ProjectUserModel, SetProjectUserRolesModel, TeamRole } from '@app/api';
import { ApiService, AppConfigService } from '@app/core';
import { UserNotificationService } from '@app/shared/services';
import { Input } from '@angular/core';

class ExtendedProjectUser extends ProjectUserModel {
  active?: boolean;
}

@Component({
  selector: 'app-team-role-assign',
  templateUrl: './team-role-assign.component.html',
  styleUrls: ['./team-role-assign.component.scss'],
})
export class TeamRoleAssignComponent implements OnInit {
  separatorKeysCodes: number[] = [ENTER, COMMA];
  roles: TeamRole[];
  autoRoles: TeamRole[] = [];
  isLoaded: boolean = false;
  userCtrl = new UntypedFormControl();
  @Input() forceApiCall: boolean = false;
  @Input() userData: ExtendedProjectUser;
  @Output() rolesChanged = new EventEmitter<ExtendedProjectUser>();
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;

  constructor(protected apiService: ApiService, private userNotification: UserNotificationService) {}

  async ngOnInit() {
    if (!this.userData) {
      this.userData = new ExtendedProjectUser();
      this.userData.roles = [];
    }
    try {
      const everyoneId = AppConfigService.settings.defaultRoleIds.everyone.toLowerCase();
      const roles = await this.apiService.getRoles(false, false);
      const rolesEveryoneIndex = roles.findIndex(x => everyoneId == x.id.toLowerCase());
      if (rolesEveryoneIndex >= 0) {
        roles.splice(rolesEveryoneIndex, 1);
      }

      this.roles = roles.filter(r => !r.isReadOnly);
      this.autoRoles = this.roles.slice();
    } catch {
      //nothing - msg on focus only
    }
    this.isLoaded = true;
  }

  rolefilter(value: string, item: ExtendedProjectUser) {
    if (!this.roles) {
      this.autoRoles = [];
      return;
    }
    if (!value) {
      this.autoRoles = this.reduceRoles(this.roles.slice(), item);
      return;
    }
    const filterValue = value.toLowerCase();
    const filtered = this.roles.filter(role => {
      return role.name.toLowerCase().indexOf(filterValue) === 0;
    });
    this.autoRoles = this.reduceRoles(filtered, item);
  }

  async acFocus(item: ExtendedProjectUser) {
    if (!this.roles || this.roles.length < 1) {
      await this.userNotification.notify('general.errorFailedToLoadDataKeys.roles');
      this.autoRoles = [];
    }
    this.autoRoles = this.reduceRoles(this.roles.slice(), item);
  }

  reduceRoles(filtered: TeamRole[], item: ExtendedProjectUser) {
    if (item && item.roles && item.roles.length > 0) {
      item.roles.forEach(ir => {
        for (let i = 0; i < filtered.length; i++) {
          if (ir.id === filtered[i].id) {
            filtered.splice(i, 1);
            break;
          }
        }
      });
    }
    return filtered.slice();
  }

  async selected(user: ExtendedProjectUser, event: MatAutocompleteSelectedEvent) {
    const selected = event.option.value;
    this.userInput.nativeElement.value = '';

    if (user.roles.indexOf(selected) >= 0) return;

    user.roles.push(selected);
    if (this.forceApiCall) {
      try {
        user.active = true;
        await this.updateUserRole(user);
      } catch {
        user.roles.pop();
        this.userNotification.notify('general.roleUpdateError');
      }
    }

    user.active = false;
  }

  async addRole(user: ExtendedProjectUser, event: MatChipInputEvent) {
    const input = event.chipInput.inputElement;
    const value = event.value;

    if (input) input.value = '';

    if ((value || '').trim()) {
      const val = value.trim().toLowerCase();
      if (val.length > 0) {
        const result = this.roles.filter(r => r.name.toLowerCase().indexOf(val) === 0);
        if (result.length === 1 && user.roles.indexOf(result[0]) === -1) {
          user.roles.push(result[0]);
          if (this.forceApiCall) {
            try {
              user.active = true;
              await this.updateUserRole(user);
            } catch {
              user.roles.pop();
              this.userNotification.notify('general.roleUpdateError');
            }
          }
        }
      }
    }

    this.autoRoles = this.reduceRoles(this.roles.slice(), user);

    user.active = false;
  }

  async removeRole(user: ExtendedProjectUser, index: number) {
    const delRole = user.roles[index];
    user.roles.splice(index, 1);
    if (this.forceApiCall) {
      try {
        user.active = true;
        await this.updateUserRole(user);
      } catch {
        user.roles.splice(index, 0, delRole);
        this.userNotification.notify('general.roleUpdateError');
      }
      user.active = false;
    }
  }

  async updateUserRole(user: ExtendedProjectUser) {
    const userroles = new SetProjectUserRolesModel();
    userroles.init(user);
    userroles.projectUserId = user.id;
    userroles.roles = userroles.roles.filter((value, index, self) => {
      return self.indexOf(value) === index;
    });
    await this.apiService.updateUserRoles(userroles);
    this.rolesChanged.emit(user);
  }
}
