<div class="c4-dialog-wrapper">
  <h1 mat-dialog-title>{{ 'dialogs.changeCompany.caption' | translate }}</h1>
  <mat-dialog-content>
    <div>{{ 'dialogs.changeCompany.description' | translate }}</div>
    <mat-form-field class="filter">
      <mat-label>{{ 'general.actions.filter' | translate }}</mat-label>
      <input matInput [disabled]="!companies.dataReady" (input)="onFilterChange($event.target.value)">
    </mat-form-field>
    <div *ngIf="companies" class="app-company-list" [ngClass]="companies.busyClass">
      <div *ngFor="let company of companies.data" class="app-company-list-entry c4-busy-content"
           [matTooltip]="companies.dataReady ? ('dialogs.changeCompany.tooltip' | translate:{company:company.name}) : undefined"
           (click)="selectProject(company)">
        <div *ngIf="company.id === currentCompanyId" class="current">{{company.name + ' ' +
          ('dialogs.changeCompany.currentCompany'|translate) }}</div>
        <div *ngIf="company.id !== currentCompanyId">{{company.name}}</div>
        <span class="mdi mdi-chevron-right"></span>
      </div>
    </div>
    <div>{{ 'dialogs.changeCompany.subdescription' | translate }}</div>
  </mat-dialog-content>
  <mat-dialog-actions>
    <button *ngIf="currentCompanyId" mat-flat-button color="primary" (click)="close()" type="button">{{ 'general.cancel'
      | translate }}</button>
  </mat-dialog-actions>
</div>