import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { AppRoutingData, OfflineGuard } from './core';
import {
  SetupGuard,
  NotAuthenticatedGuard,
  AuthenticationGuard,
  PrivilegesGuard,
  CapacitorAppGuard,
  TenantGuard,
  ProjectAssignmentGuard,
  AdminGuard,
  ProjectGuard,
} from './core/guards';
import { OAuthFlowGuard } from './core/guards/oauth-flow.guard';
import { BaseLayoutComponent, PlainLayoutComponent } from './shared/components';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  // Portal Manager area
  {
    path: 'admin',
    data: { isInAdminArea: true },
    component: BaseLayoutComponent,
    canActivate: [AuthenticationGuard, AdminGuard],
    children: [
      { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
      {
        path: AppRoutingData.dashboard.adminPath ?? AppRoutingData.dashboard.path,
        loadChildren: () => import('app/C4CustomerPortal/admin/dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.dashboard,
      },
      {
        path: AppRoutingData.companies.adminPath ?? AppRoutingData.companies.path,
        loadChildren: () => import('app/C4CustomerPortal/admin/companies/companies.module').then(m => m.CompaniesModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.companies,
      },
      {
        path: AppRoutingData.templates.adminPath ?? AppRoutingData.templates.path,
        loadChildren: () =>
          import('app/C4CustomerPortal/admin/templates-cfg/templates-cfg.module').then(m => m.TemplatesCfgModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.templates,
      },
      {
        path: AppRoutingData.users.adminPath ?? AppRoutingData.users.path,
        loadChildren: () => import('app/C4CustomerPortal/admin/users/users.module').then(m => m.UsersModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.users,
      },
      {
        path: AppRoutingData.schemata.adminPath ?? AppRoutingData.schemata.path,
        loadChildren: () => import('app/C4CustomerPortal/admin/schemata/schemata.module').then(m => m.SchemataModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.schemata,
      },
      {
        path: AppRoutingData.setup.adminPath ?? AppRoutingData.setup.path,
        loadChildren: () => import('app/C4CustomerPortal/admin/setup/setup.module').then(m => m.SetupModule),
        data: AppRoutingData.setup,
      },
      {
        path: AppRoutingData.organizations.adminPath ?? AppRoutingData.organizations.path,
        loadChildren: () =>
          import('app/C4CustomerPortal/admin/organizations/organizations.module').then(m => m.OrganizationsModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.organizations,
      },
      {
        path: AppRoutingData.crafts.adminPath ?? AppRoutingData.crafts.path,
        loadChildren: () => import('app/C4CustomerPortal/admin/crafts/crafts.module').then(m => m.CraftsModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.crafts,
      },
      {
        path: AppRoutingData.defectReasons.adminPath ?? AppRoutingData.defectReasons.path,
        loadChildren: () =>
          import('app/C4CustomerPortal/admin/defectReasons/defectReasons.module').then(m => m.DefectReasonsModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.defectReasons,
      },
      {
        path: AppRoutingData.defectTypes.adminPath ?? AppRoutingData.defectTypes.path,
        loadChildren: () => import('app/C4CustomerPortal/admin/defectTypes/defectTypes.module').then(m => m.DefectTypesModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.defectTypes,
      },
      {
        path: AppRoutingData.standardTexts.adminPath ?? AppRoutingData.standardTexts.path,
        loadChildren: () =>
          import('app/C4CustomerPortal/admin/standardTexts/standardTexts.module').then(m => m.StandardTextsModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.standardTexts,
      },
      {
        path: AppRoutingData.issueLabels.adminPath ?? AppRoutingData.issueLabels.path,
        loadChildren: () => import('app/C4CustomerPortal/admin/issueLabels/issueLabels.module').then(m => m.IssueLabelsModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.issueLabels,
      },
      {
        path: AppRoutingData.issueStages.adminPath ?? AppRoutingData.issueStages.path,
        loadChildren: () => import('app/C4CustomerPortal/admin/issueStages/issueStages.module').then(m => m.IssueStagesModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.issueStages,
      },
      {
        path: AppRoutingData.issueTypes.adminPath ?? AppRoutingData.issueTypes.path,
        loadChildren: () => import('app/C4CustomerPortal/admin/issueTypes/issueTypes.module').then(m => m.IssueTypesModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.issueTypes,
      },
      {
        path: AppRoutingData.roomTemplates.adminPath ?? AppRoutingData.roomTemplates.path,
        loadChildren: () =>
          import('app/C4CustomerPortal/admin/roomTemplates/roomTemplates.module').then(m => m.RoomTemplatesModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.roomTemplates,
      },
      {
        path: AppRoutingData.attributes.adminPath ?? AppRoutingData.attributes.path,
        loadChildren: () => import('app/C4CustomerPortal/admin/attributes/attributes.module').then(m => m.AttributesModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.attributes,
      },
      {
        path: AppRoutingData.categories.adminPath ?? AppRoutingData.categories.path,
        loadChildren: () => import('app/C4CustomerPortal/admin/categories/categories.module').then(m => m.CategoriesModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.categories,
      },
      {
        path: AppRoutingData.phases.adminPath ?? AppRoutingData.phases.path,
        loadChildren: () => import('app/C4CustomerPortal/admin/leanPhases/leanPhases.module').then(m => m.LeanPhasesModule),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.phases,
      },
      {
        path: AppRoutingData.workpackageTemplates.adminPath ?? AppRoutingData.workpackageTemplates.path,
        loadChildren: () =>
          import('app/C4CustomerPortal/admin/leanWorkpackageTemplates/leanWorkpackageTemplates.module').then(
            m => m.LeanWorkpackageTemplatesModule
          ),
        canActivateChild: [SetupGuard],
        data: AppRoutingData.workpackageTemplates,
      },
      {
        path: AppRoutingData.workpackageSequences.adminPath ?? AppRoutingData.workpackageSequences.path,
        loadChildren: () =>
          import('app/C4CustomerPortal/admin/leanWorkpackageSequences/leanWorkpackageSequences.module').then(
            m => m.LeanWorkpackageSequencesModule
          ),
        data: AppRoutingData.workpackageSequences,
      },
    ],
  },
  // Portal area
  {
    path: AppRoutingData.projects.path,
    component: BaseLayoutComponent,
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('app/C4CustomerPortal/projects/projects.module').then(m => m.ProjectsModule),
        data: AppRoutingData.projects,
      },
      {
        path: ':projectId',
        canActivate: [ProjectGuard],
        canActivateChild: [PrivilegesGuard],
        children: [
          {
            path: AppRoutingData.attributes.path,
            loadChildren: () => import('app/C4CustomerPortal/attributes/attributes.module').then(m => m.AttributesModule),
            data: AppRoutingData.attributes,
          },
          {
            path: AppRoutingData.bim.path,
            loadChildren: () => import('app/C4CustomerPortal/bim/bim.module').then(m => m.BimModule),
            data: AppRoutingData.bim,
          },
          {
            path: AppRoutingData.categories.path,
            loadChildren: () => import('app/C4CustomerPortal/categories/categories.module').then(m => m.CategoriesModule),
            data: AppRoutingData.categories,
          },
          {
            path: AppRoutingData.dashboard.path,
            loadChildren: () => import('app/C4CustomerPortal/dashboard/dashboard.module').then(m => m.DashboardModule),
            data: AppRoutingData.dashboard,
          },
          {
            path: AppRoutingData.documents.path,
            loadChildren: () => import('app/C4CustomerPortal/documents/documents.module').then(m => m.DocumentsModule),
            canActivateChild: [OfflineGuard],
            data: AppRoutingData.documents,
          },
          {
            path: AppRoutingData.gallery.path,
            loadChildren: () => import('app/C4CustomerPortal/gallery/gallery.module').then(m => m.GalleryModule),
            canActivateChild: [OfflineGuard],
            data: AppRoutingData.gallery,
          },
          {
            path: AppRoutingData.planning.path,
            loadChildren: () => import('app/C4CustomerPortal/planning/planning.module').then(m => m.PlanningModule),
            canActivateChild: [OfflineGuard],
            data: AppRoutingData.planning,
          },
          {
            path: AppRoutingData.ifc.path,
            loadChildren: () => import('app/C4CustomerPortal/ifc/ifc.module').then(m => m.IfcModule),
            canActivateChild: [OfflineGuard],
            data: AppRoutingData.ifc,
          },
          {
            path: AppRoutingData.defects.path,
            loadChildren: () => import('app/C4CustomerPortal/defects/defects.module').then(m => m.DefectsModule),
            data: AppRoutingData.defects,
          },
          {
            path: AppRoutingData.diary.path,
            loadChildren: () => import('app/C4CustomerPortal/diary/diary.module').then(m => m.DiaryModule),
            canActivateChild: [OfflineGuard],
            data: AppRoutingData.diary,
          },
          {
            path: AppRoutingData.team.path,
            loadChildren: () => import('app/C4CustomerPortal/team/team.module').then(m => m.TeamModule),
            data: AppRoutingData.team,
          },
          {
            path: AppRoutingData.notifications.path,
            loadChildren: () =>
              import('app/C4CustomerPortal/notifications/notifications.module').then(m => m.NotificationsModule),
            canActivateChild: [OfflineGuard],
            data: AppRoutingData.notifications,
          },
          {
            path: AppRoutingData.projectSettings.path,
            loadChildren: () =>
              import('app/C4CustomerPortal/project-settings/project-settings.module').then(m => m.ProjectSettingsModule),
            canActivateChild: [OfflineGuard],
            data: AppRoutingData.projectSettings,
          },
          {
            path: AppRoutingData.rooms.path,
            loadChildren: () => import('app/C4CustomerPortal/room-book/room-book.module').then(m => m.RoomBookModule),
            data: AppRoutingData.rooms,
          },
          {
            path: AppRoutingData.roomTemplates.path,
            loadChildren: () =>
              import('app/C4CustomerPortal/roomTemplates/roomTemplates.module').then(m => m.RoomTemplatesModule),
            data: AppRoutingData.roomTemplates,
          },
          {
            path: AppRoutingData.scheduler.path,
            loadChildren: () =>
              import('app/C4CustomerPortal/lean-scheduler/lean-scheduler.module').then(m => m.LeanSchedulerModule),
            data: AppRoutingData.scheduler,
          },
          {
            path: AppRoutingData.workpackages.path,
            loadChildren: () =>
              import('app/C4CustomerPortal/lean-workpackages/lean-workpackages.module').then(m => m.LeanWorkpackagesModule),
            data: AppRoutingData.workpackages,
          },
          {
            path: AppRoutingData.workpackageTemplates.path,
            loadChildren: () =>
              import('app/C4CustomerPortal/lean-workpackage-templates/lean-workpackage-templates.module').then(
                m => m.LeanWorkpackageTemplatesModule
              ),
            data: AppRoutingData.workpackageTemplates,
          },
          {
            path: AppRoutingData.workpackageSequences.path,
            loadChildren: () =>
              import('app/C4CustomerPortal/lean-workpackage-sequences/lean-workpackage-sequences.module').then(
                m => m.LeanWorkpackageSequencesModule
              ),
            data: AppRoutingData.workpackageSequences,
          },
          {
            path: AppRoutingData.specialDates.path,
            loadChildren: () =>
              import('app/C4CustomerPortal/lean-special-dates/lean-special-dates.module').then(m => m.LeanSpecialDatesModule),
            data: AppRoutingData.specialDates,
          },
          { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
        ],
      },
    ],
  },
  {
    path: '',
    component: PlainLayoutComponent,
    children: [
      {
        path: AppRoutingData.login.path,
        loadChildren: () => import('app/C4CustomerPortal/login/login.module').then(m => m.LoginModule),
        canActivateChild: [NotAuthenticatedGuard],
      },
      {
        path: AppRoutingData.logout.path,
        loadChildren: () => import('app/C4CustomerPortal/login/login.module').then(m => m.LoginModule),
        canActivateChild: [NotAuthenticatedGuard],
        data: { logout: true },
      },
      {
        path: AppRoutingData.resetPassword.path,
        loadChildren: () => import('app/C4CustomerPortal/resetPassword/resetPassword.module').then(m => m.ResetPasswordModule),
        canActivateChild: [NotAuthenticatedGuard],
      },
      {
        path: AppRoutingData.download.path,
        loadChildren: () => import('app/C4CustomerPortal/download/download.module').then(m => m.DownloadModule),
        data: AppRoutingData.download,
      },
      {
        path: AppRoutingData.consent.path,
        loadChildren: () => import('app/C4CustomerPortal/consent/consent.module').then(m => m.ConsentModule),
        data: AppRoutingData.consent,
        canActivateChild: [AuthenticationGuard, OAuthFlowGuard],
      },
      {
        path: 'tenant',
        loadChildren: () => import('app/C4CustomerPortal/tenant/tenant.module').then(m => m.TenantModule),
        canActivateChild: [NotAuthenticatedGuard, TenantGuard],
      },
      {
        path: 'errorPage',
        loadChildren: () => import('app/C4CustomerPortal/errorPage/error-page.module').then(m => m.ErrorPageModule),
      },
    ],
  },
  // Tab Configuration in Teams
  {
    path: AppRoutingData.msteams.path,
    component: PlainLayoutComponent,
    children: [
      {
        path: 'config',
        loadChildren: () => import('app/C4CustomerPortal/msteams/msteams.module').then(m => m.MSTeamsModule),
        data: AppRoutingData.msteams,
      },
    ],
  },
  // route handling without project id
  { path: '**', component: PlainLayoutComponent, canActivate: [ProjectAssignmentGuard] },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      scrollPositionRestoration: 'top',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
