import { Component, OnInit } from '@angular/core';
import {
  ApiService,
  DataHolder,
  ProjectService,
  GlobalFormStateTrackerService,
  BaseSubscriptionComponent,
  pathTo,
  AppRoutingData,
} from '@app/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserNotificationService } from '@app/shared/services/user-notification/user-notification.service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Router } from '@angular/router';
import { ProjectWithUserSettingsModel } from '@app/api';

@Component({
  selector: 'app-change-company',
  templateUrl: './change-company.component.html',
  styleUrls: ['./change-company.component.scss'],
})
export class ChangeCompanyComponent extends BaseSubscriptionComponent implements OnInit {
  currentCompanyId: string;
  companies: DataHolder<ProjectWithUserSettingsModel[]>;
  private filterChanged: Subject<string> = new Subject<string>();
  private cachedCompanies: ProjectWithUserSettingsModel[];

  constructor(
    private apiService: ApiService,
    private projectService: ProjectService,
    private router: Router,
    private globalFormStateTracker: GlobalFormStateTrackerService,
    private userNotification: UserNotificationService,
    public dialogRef: MatDialogRef<ChangeCompanyComponent>
  ) {
    super();
    this.companies = new DataHolder<ProjectWithUserSettingsModel[]>(this.fakeCompanies());
  }

  async ngOnInit() {
    this.companies
      .updateData(async () => {
        this.currentCompanyId = this.projectService.projectId$.getValue();
        this.cachedCompanies = await this.apiService.getAvailableProjects();
        return this.cachedCompanies.orderBy(company => company.name);
      }, true)
      .catch(e => {
        this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.companyData', e);
        this.dialogRef.close();
      });

    this.subscribe(this.filterChanged.pipe(debounceTime(300)), filter => this.filterCompanies(filter));
  }

  async selectProject(project: ProjectWithUserSettingsModel) {
    if (this.companies.dataReady) {
      if (project.id !== this.currentCompanyId) {
        try {
          if (await this.globalFormStateTracker.canLeave()) {
            const split = this.router.url.split('?');
            let url = split[0].split('/').skip(3).join('/');
            const params = split.length > 1 ? `?${split[1]}` : '';

            if (!url || url == '') {
              url = AppRoutingData.dashboard.path;
            }

            await this.router.navigateByUrl(pathTo(project.id, url + params));
          }

          this.dialogRef.close(project);
        } catch (e) {
          this.userNotification.notify('companies.errorSwitchCompany');
        }
      } else {
        this.dialogRef.close(project);
      }
    }
  }

  filterCompanies(filter: string) {
    this.companies.updateData(async () => {
      return this.cachedCompanies?.filter(company =>
        filter?.length ? company.name.toLowerCase().includes(filter.toLowerCase()) : true
      );
    }) ?? [];
  }

  onFilterChange(filter: string) {
    this.filterChanged.next(filter);
  }

  close() {
    this.dialogRef.close();
  }

  private fakeCompanies(): ProjectWithUserSettingsModel[] {
    const result: ProjectWithUserSettingsModel[] = [];
    for (let i = 0; i < 2; i++) {
      result.push(
        new ProjectWithUserSettingsModel({
          id: '?',
          name: '##########',
        })
      );
    }
    return result;
  }
}
