<div class="add-defect-mobile" *ngIf="canCreateDefect && globals.isMobileMenu">
  <button mat-flat-button [matTooltip]="'defects.header.newDefect' | translate" (click)="createItem(null)">
    <span class="mdi mdi-plus-box"></span>
    <span class="add-defect-label">{{'general.add' | translate}}</span>
  </button>
</div>
<div class="c4-grid-container defect" [class.c4-busy]="!defectsHolder?.dataReady || isBusy">
  <c4-grid *ngIf="gridDef" [gridDef]="gridDef" [source]="defectsHolder.data" [noDataText]="'grid.defects.noDataText'"
           [selectionMode]="selectionMode" [(selection)]="selection" (filter)="gridFilterChanged($event)">
  </c4-grid>
</div>

<ng-template #state let-row='row'>
  <div class="state-container">
    <app-state-circle [hexColor]="row.currentState.color"></app-state-circle>
    <div class="title">{{ row.currentState.title }}</div>
  </div>
</ng-template>

<ng-template #actions let-col='col' let-row='row'>
  <div class="c4-no-focus">
    <button type="button" mat-icon-button class="c4-grid-btn" aria-label="Edit" (click)="editItem(row.id)"
            [matTooltip]="'general.edit' | translate">
      <span class="mdi mdi-square-edit-outline"></span>
    </button>
  </div>
</ng-template>

<ng-template #selectAll let-col='col'>
  <div class="c4-no-focus offset-left">
    <button mat-icon-button class="c4-grid-btn" (click)="allRowsSelect($event)">
      <span class="mdi mdi-check-all"></span>
    </button>
  </div>
</ng-template>

<ng-template #selectRow let-col='col' let-row='row'>
  <div class="c4-no-focus offset-left">
    <button mat-icon-button class="c4-grid-btn" (click)="rowSelect(row)">
      <span class="mdi mdi-check"></span>
    </button>
  </div>
</ng-template>

<ng-template #mailSent let-col='col' let-row='row'>
  <div class="defect-list-ico">
    <span *ngIf="row.hasNotifications" [class.expired]="row.deadlineExpiredMailCreatedOn" class="mdi mdi-mail"></span>
    <span *ngIf="!row.hasNotifications" class="mdi mdi-minus"></span>
  </div>
</ng-template>

<ng-template #nameCol let-col='col' let-row='row'>
  <div class="c4-name-column" (click)="editItem(row)">
    <div class="header">{{row.title}}</div>
    <div class="sub-header" [matTooltip]='row.description' matTooltipClass="multi-line">
      {{ stripHtml(row.description) }}
    </div>
  </div>
</ng-template>

<ng-template #overTime let-col='col' let-row='row'>
  <div *ngIf="row[col.field]" class="c4-topline" [ngClass]="getColorClass(row[col.field],row)">
    {{ row[col.field] | localizedDate }}
  </div>
</ng-template>

<ng-template #contextMenu let-col='col' let-row='row'>
  <div class="c4-no-focus">
    <button mat-icon-button class="c4-grid-btn" [matMenuTriggerFor]="rowContext" [matMenuTriggerData]="{menudata:row}"
            [matTooltip]="'general.actions.title' | translate">
      <span class="mdi mdi-dots-horizontal"></span>
    </button>
  </div>
</ng-template>
<mat-menu #rowContext="matMenu">
  <ng-template matMenuContent let-menudata='menudata'>
    <button mat-menu-item class="c4-context-menu-item" (click)="editItem(menudata)"
            [matTooltip]="'general.edit' | translate">
      <span class="mdi mdi-square-edit-outline"></span>
      <span>{{'defects.editLabel' |translate}}</span>
    </button>
    <button *ngIf="canCreateDefect" mat-menu-item class="c4-context-menu-item" (click)="createCopyItem(menudata.id)"
            [matTooltip]="'general.copy' | translate">
      <span class="mdi mdi-content-copy"></span>
      <span>{{'defects.copy' | translate}}</span>
    </button>
    <button mat-menu-item class="c4-context-menu-item" (click)="shareItem(menudata.id)"
            [matToolTip]="'planning.header.tool_share' | translate">
      <span class="mdi mdi-share-variant"></span>
      <span>{{'general.share' |translate}}</span>
    </button>
    <button mat-menu-item class="c4-context-menu-item" (click)="reportOfItem(menudata.id)"
            [matToolTip]="'defects.header.tool_report' | translate">
      <span class="mdi mdi-file-pdf-box"></span>
      <span>{{'defects.header.report' |translate}}</span>
    </button>
  </ng-template>
</mat-menu>