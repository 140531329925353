import { Injectable } from '@angular/core';
import { GenerateReportModel, LRTaskState, ReportRoomBookOptions } from '@app/api';
import { ApiService, LogService, Utils } from '@app/core';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { LongRunningTaskService, UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { IReportOptions } from './room-book.interfaces';
import * as saveAs from 'file-saver';

@Injectable({
  providedIn: 'root',
})
export class RoomBookReportService {
  private busy: Busy;

  constructor(
    private userNotification: UserNotificationService,
    private lrtService: LongRunningTaskService,
    private apiService: ApiService,
    private log: LogService
  ) {}

  setBusy(busy: Busy) {
    this.busy = busy;
  }

  async createReport(options: IReportOptions) {
    const roomIds = options.roomsIds;

    await using(new BusyScope(this.busy), async _ => {
      const createdStatus = await this.apiService.reportForDynamicRoom(
        new GenerateReportModel({
          entityIds: roomIds,
          roomBookOptions: new ReportRoomBookOptions({
            categoryIds: Utils.oneOrMoreOrFallback(options.categoryIds, null),
          }),
        })
      );

      this.log.info('LRTask start: ', createdStatus.id);

      await new Promise((resolve, reject) => {
        this.lrtService.getObservableForTask(createdStatus.id).subscribe(
          async updatedStatus => {
            if (updatedStatus.state == LRTaskState.Failure) {
              this.log.error('LRTask error: ', updatedStatus.problemDetails);

              reject(updatedStatus.problemDetails);
            }

            if (updatedStatus.state == LRTaskState.Success) {
              const report = await this.apiService.downloadFile(createdStatus.id);

              if (CapacitorUtils.isApp()) {
                await CapacitorUtils.blobFileHandler(report.data, report.fileName, true);
              } else {
                saveAs(report.data, decodeURIComponent(report.fileName));
              }

              this.userNotification.notify('roomBook.reportSuccess');
              resolve(true);
            }
          },
          e => {
            reject(e);
          }
        );
      });
    }).catch(e => {
      this.log.error('Room report failed', e);
      this.userNotification.notify('roomBook.error.reportDownload');
    });
  }
}
