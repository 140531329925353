import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { StepperSelectionEvent, CdkStep } from '@angular/cdk/stepper';
import { ATrustSignComponent } from '../../atrust-sign/atrust-sign.component';
import { DrawToSignComponent } from '../../draw-to-sign/draw-to-sign.component';

@Component({
  selector: 'app-sign-selection-dialog',
  templateUrl: './sign-selection-dialog.component.html',
  styleUrls: ['./sign-selection-dialog.component.scss'],
})
export class SignSelectionDialogComponent implements OnInit {
  SignType = SignType;
  @ViewChild('stepSign') stepSign: CdkStep;
  @ViewChild('stepper') stepper: any;
  @ViewChild('drawToSign') drawToSign: DrawToSignComponent;
  @ViewChild('aTrustSign') aTrustSign: ATrustSignComponent;

  initArgs: SignSelectionInitArgs;
  selectedSignType: SignType;
  activeSignType: SignType = SignType.None;

  get isLastStep() {
    return this.stepper?.selectedIndex == this.stepper?.steps.length - 1;
  }

  get isOkButtonEnabled() {
    return this.drawToSign?.isValid ?? true;
  }

  get showOkButton() {
    return !(this.isLastStep && this.activeSignType == SignType.ATrustSign);
  }

  constructor(public confirmRef: MatDialogRef<SignSelectionDialogComponent>, @Inject(MAT_DIALOG_DATA) data) {
    this.selectedSignType = SignType.DrawToSign;
    this.initArgs = data.initArgs;
  }

  ngOnInit() {}

  onStepperSelectionChange($event: StepperSelectionEvent) {
    if ($event.selectedStep == this.stepSign) {
      this.activeSignType = this.selectedSignType;
    } else this.activeSignType = SignType.None;
  }

  onCancel() {
    this.confirmRef.close();
  }

  async onClickNext() {
    if (this.stepper.selectedIndex == this.stepper.steps.length - 1) {
      let result = null;

      switch (this.activeSignType) {
        case SignType.ATrustSign:
          //Feedback logic ?
          break;

        case SignType.DrawToSign:
          result = await this.drawToSign.getSignResult();
          break;
      }

      this.confirmRef.close(result);
    } else {
      this.stepper.next();
    }
  }
}

enum SignType {
  None,
  DrawToSign,
  ATrustSign,
}

export class SignSelectionInitArgs {
  documentId: string;
  resource: string;
}
