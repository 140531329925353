<div #root class="split-root-container" [class.vertical]="!isHorizontal">
	<div #first class="first-container" [style.flex]="sizes[0]">
		<ng-content></ng-content>
	</div>

	<div class="grabber-container">
		<div class="grabber-hover" [class.dragable]="!isAnyCollapsed" [class.with-button]="isDrag || isAnyCollapsed">
			<div #grabber class="grabber">
				<div class="grab-button">
					<div *ngIf="!isFirstCollapsed" class="icon-container first" (mousedown)="collapseFirst($event)">
						<span class="mdi mdi-chevron-left"></span>
					</div>
					<div *ngIf="!isSecondCollapsed" class="icon-container second" (mousedown)="collapseSecond($event)">
						<span class="mdi mdi-chevron-right"></span>
					</div>
				</div>
			</div>
		</div>
	</div>

	<div #second class="second-container" [style.flex]="sizes[1]">
		<ng-content select="[split]"></ng-content>
	</div>
</div>