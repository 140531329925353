import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { CommentModel } from '@app/api';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { ICommentService, ICommentWithViewpoint, ViewpointWithThumbnail } from '@app/shared/services';
import { AdditionalDataLoader, AuthenticationService } from '@app/core';
import { SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-comment-bubble',
  templateUrl: './comment-bubble.component.html',
  styleUrls: ['./comment-bubble.component.scss'],
})
export class CommentBubbleComponent implements Busy, OnInit {
  @Input() service: ICommentService;
  @Input() viewpointCount: number = 0;
  @Input() createNewViewpoint: () => Promise<ViewpointWithThumbnail>;
  @Input() viewpointsLoader: AdditionalDataLoader<ViewpointWithThumbnail[]>;
  @Input() comment: ICommentWithViewpoint;
  @Output() isEditChange = new EventEmitter<boolean>();

  isBusy: boolean;
  thumbnail: SafeResourceUrl;

  userId: string;

  private oldText: string = '';
  private oldViewpoint: ViewpointWithThumbnail = null;
  private _isEdit: boolean;

  constructor(authenticationService: AuthenticationService, private dialog: MatDialog) {
    this.userId = authenticationService.getUserId();
  }

  get isEdit(): boolean {
    return this._isEdit;
  }

  set isEdit(isEdit: boolean) {
    this._isEdit = isEdit;
    this.isEditChange.emit(isEdit);
  }

  get canAddViewpoint() {
    return this.createNewViewpoint != undefined;
  }

  ngOnInit() {}

  editComment() {
    if (this.isEdit) return;

    if (this.comment.editorId != this.userId) throw 'Edit of other comments is not allowed!';

    this.oldText = this.comment.text;
    this.oldViewpoint = this.comment.viewpoint;
    this.isEdit = true;
  }

  cancelEditComment() {
    if (!this.isEdit) return;

    this.thumbnail = null;
    this.comment.text = this.oldText;
    this.comment.viewpoint = this.oldViewpoint;
    this.isEdit = false;
  }

  async updateComment() {
    if (!this.isEdit) return;

    await using(new BusyScope(this), async _ => {
      if (this.comment.text != this.oldText || this.comment.viewpoint != this.oldViewpoint) {
        const success = await this.service.update(this.comment);
        if (!success) return;

        this.comment.modifiedOn = new Date();
      }

      this.isEdit = false;
    });
  }

  async deleteComment() {
    if (this.comment.editorId != this.userId) throw 'Delete of other comments is not allowed!';

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'general.confirmation.commentDeleteCaption',
        description: 'general.confirmation.commentDeleteDescription',
      },
      disableClose: true,
    });

    const isConfirmed = await dialogRef.afterClosed().toPromise();
    if (!isConfirmed) return;

    await using(new BusyScope(this), async _ => {
      await this.service.delete(this.comment.id);
    });
  }

  onEnter(event: KeyboardEvent) {
    if (this.isEdit && event.ctrlKey) this.updateComment();
  }

  async addViewpoint() {
    this.comment.viewpoint = await this.createNewViewpoint();
  }

  viewpointClicked(viewpoint: ViewpointWithThumbnail) {
    if (viewpoint == this.comment.viewpoint) {
      this.service.selectViewpoint(viewpoint);
    } else {
      this.comment.viewpoint = viewpoint;
      this.viewpointsLoader.load();
    }
  }

  removeViewpoint() {
    this.comment.viewpoint = null;
    this.viewpointsLoader.load();
  }
}
