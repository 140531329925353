import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { AttributeModel, AttributeValueType, CategoryModel, RmFileContent, TransferFileFormat } from '@app/api';
import {
  ApiService,
  AppRoutingData,
  BaseSubscriptionComponent,
  DataHolder,
  GlobalsService,
  ProjectService,
  StructureType,
} from '@app/core';
import { FileType } from '@app/core/enumerations';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { AttributeDialogComponent } from './attribute-dialog/attribute-dialog.component';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { TranslateService } from '@ngx-translate/core';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import * as saveAs from 'file-saver';
import { SelectionMode, TreeSelectDialogComponent } from '../dialogs/tree-select-dialog/tree-select-dialog.component';
import { TreeNode } from 'primeng/api';

export class ExtendedAttributeModel extends AttributeModel {
  nodeType?: AttributeTreeNodeType;
  castedDefaultValue?: number | string | boolean;
}

export interface MixedCategory extends Omit<CategoryModel, 'children'> {
  nodeType?: AttributeTreeNodeType;
  children?: AttributeTreeNode[];
}

export interface AttributeTreeNode extends TreeNode<MixedCategory | ExtendedAttributeModel> {
  id?: string;
  name?: string;
}

export enum AttributeTreeNodeType {
  attribute = 'attribute',
  category = 'category',
}

export function buildAttributesTreeRec(categories: CategoryModel[], attributes: AttributeModel[]) {
  const tree: AttributeTreeNode[] = [];

  for (const c of categories) {
    const categoryAttributes: AttributeTreeNode[] = attributes
      .filter(a => a.category.id == c.id)
      .map(a => {
        const extendedAttribute = a as ExtendedAttributeModel;

        extendedAttribute.nodeType = AttributeTreeNodeType.attribute;

        return {
          id: a.id,
          key: a.id,
          name: a.name,
          data: extendedAttribute,
        };
      });
    const childCategories = buildAttributesTreeRec(c.children ?? [], attributes);

    if (categoryAttributes.length > 0 || childCategories.length > 0) {
      const mixedCategory = c as MixedCategory;

      mixedCategory.nodeType = AttributeTreeNodeType.category;

      tree.push({
        id: c.id,
        key: c.id,
        name: c.name,
        data: mixedCategory,
        children: categoryAttributes.concat(childCategories),
      });
    }
  }

  return tree;
}

@Component({
  selector: 'app-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.scss'],
  host: { class: 'c4-prevent-flex-height-overflow' },
  encapsulation: ViewEncapsulation.None,
})
export class AttributesComponent extends BaseSubscriptionComponent implements OnInit, Busy {
  icon = AppRoutingData.attributes.icon;
  isBusy: boolean;
  isInProject: boolean = false;

  attributesHolder: DataHolder<AttributeTreeNode[]>;
  attributeValueType = AttributeValueType;
  attributeTreeNodeType = AttributeTreeNodeType;

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private projectService: ProjectService,
    private translateService: TranslateService,
    private userNotification: UserNotificationService,
    public globals: GlobalsService
  ) {
    super();
  }

  async ngOnInit() {
    this.attributesHolder = new DataHolder(this.initData());

    this.subscribe(this.projectService.projectId$, async projectId => {
      this.isInProject = projectId != null;
      await this.updateData();
    });
  }

  async addOrEditAttribute(data: any = null) {
    const result = await this.dialog
      .open(AttributeDialogComponent, {
        disableClose: true,
        data,
      })
      .afterClosed()
      .toPromise();
    if (result) await this.updateData();
  }

  async deleteAttribute(attributeId: string) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'attributes.deleteConfirmCaption',
        description: 'attributes.deleteConfirmDescription',
      },
    });
    const dialogResult = await dialogRef.afterClosed().toPromise();
    if (dialogResult) {
      await using(new BusyScope(this), async _ => {
        await this.apiService.deleteAttribute(attributeId);
        this.userNotification.notify('attributes.success.delete');
        this.updateData(); // do not await to remove busy overlay (grid has its own)
      }).catch(error => {
        if (error.status == 409) {
          this.userNotification.notify('attributes.error.isAssigned');
        } else {
          this.userNotification.notify('attributes.error.remove');
        }
      });
    }
  }

  async export(format: TransferFileFormat = TransferFileFormat.CSV) {
    const fileResponse = await this.apiService.exportRoomBookFromProject(RmFileContent.Attributes, format);

    if (CapacitorUtils.isApp()) {
      await CapacitorUtils.blobFileHandler(fileResponse.data, fileResponse.fileName, true);
    } else {
      saveAs(fileResponse.data, decodeURIComponent(fileResponse.fileName));
    }
  }

  async import(file: File) {
    if (file && (file.type === FileType.CSV_EXCEL || file.type == FileType.CSV_TEXT)) {
      let success = false;
      await using(new BusyScope(this), async _ => {
        const data = await file.arrayBuffer();
        const blob = new Blob([data], { type: 'text/csv' });

        await this.apiService.importRoomBookToProject(RmFileContent.Attributes, TransferFileFormat.CSV, {
          data: blob,
          fileName: file.name,
        });

        success = true;
        this.userNotification.notify('general.successMsg.import');
      }).catch(e => {
        console.error(e);
        this.userNotification.notify('general.errorMsg.import');
      });

      if (success) await this.updateData();
    } else {
      this.userNotification.notify('general.errorFileType');
    }
  }

  async addFromGlobal() {
    let globalAttributes: AttributeModel[], globalCategories: CategoryModel[];
    await using(new BusyScope(this), async _ => {
      globalCategories = await this.apiService.getGlobalCategories(StructureType.tree);
      globalAttributes = await this.apiService.getGlobalAttributes();
    }).catch(error => {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.attributes', error);
    });

    if (!globalAttributes) return;

    const tree = buildAttributesTreeRec(globalCategories, globalAttributes);

    const data = {
      title: 'roomBook.selectGlobal.attributeTitle',
      description: 'roomBook.selectGlobal.attributeDescription',
      selectionMode: SelectionMode.checkbox,
      canCancel: true,
      items: tree,
    };

    const dialogResult: AttributeTreeNode[] = await this.dialog
      .open(TreeSelectDialogComponent, {
        data: data,
        disableClose: true,
      })
      .afterClosed()
      .toPromise();

    const selectedAttributeIds = dialogResult
      .filter(selected => selected.data.nodeType == AttributeTreeNodeType.attribute)
      .map(a => a.data.id);

    if (dialogResult.length > 0) {
      await using(new BusyScope(this), async _ => {
        await this.apiService.transferAttributesToProject(selectedAttributeIds);
        this.userNotification.notify('general.successMsg.transfer');
        this.updateData(); // do not await to remove busy overlay (grid has its own)
      }).catch(_ => {
        this.userNotification.notify('general.errorMsg.transfer');
      });
    }
  }

  private initData() {
    const fakeCategories: AttributeTreeNode[] = [];
    for (let idx = 0; idx < 3; idx++) {
      fakeCategories.push({
        key: idx.toString(),
        data: new CategoryModel({
          name: 'fakeData',
        }),
      });
    }

    return fakeCategories;
  }

  private async updateData() {
    await this.attributesHolder
      ?.updateData(async () => {
        const categories = await this.apiService.getCategories(StructureType.tree);
        const attributes = await this.apiService.getAttributes();
        const extendedAttributes = attributes.map(a => {
          const extended = new ExtendedAttributeModel(a);
          extended.castedDefaultValue = this.getCastedDefaultValue(a);
          return extended;
        });

        const tree = buildAttributesTreeRec(categories, extendedAttributes);

        return tree;
      }, true)
      .catch(e => {
        this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.attributes', e);
      });
  }

  private getCastedDefaultValue(attribute: AttributeModel) {
    if (!attribute.value) return null;

    switch (attribute.valueType) {
      case AttributeValueType.Checkbox:
        return attribute.value == 'true';
      case AttributeValueType.Dropdown:
      case AttributeValueType.Autocomplete:
        return attribute.value;
      case AttributeValueType.Integer:
      case AttributeValueType.Double:
        return Number.parseFloat(attribute.value);
      default:
        return attribute.value;
    }
  }
}
