import { Injectable } from '@angular/core';
import { MatLegacySnackBar as MatSnackBar } from '@angular/material/legacy-snack-bar';
import { TranslateService } from '@ngx-translate/core';
import { AppConfigService, LogService } from '@app/core/services';
import { ApiCallAbortedException } from '@app/core';

@Injectable({
  providedIn: 'root',
})
export class UserNotificationService {
  private lastNotificationToken: number;

  constructor(private snackBar: MatSnackBar, private translateService: TranslateService, private log: LogService) {}

  async notifyFailedToLoadDataAndLog(dataNameKey: string, error: Error): Promise<number> {
    if (ApiCallAbortedException.isApiCallAbortedException(error)) {
      this.log.debug(`Aborted loading data with key ${dataNameKey}`, error);
      return;
    }
    this.log.error(`Failed to load data with key ${dataNameKey}`, error);
    const dataName = await this.translateService.get(dataNameKey).toPromise();
    return await this.notify('general.errorFailedToLoadData', {
      dataName,
    });
  }

  async notify(messageKey: string, params?: any, actionKey = 'general.ok'): Promise<number> {
    const notificationToken = new Date().valueOf();
    const keys: string[] = [messageKey, actionKey];
    const texts: any = await this.translateService.get(keys, params).toPromise();
    this.snackBar.open(texts[keys[0]], texts[keys[1]], {
      duration: AppConfigService.settings.snackbar.dismissAfterMilliseconds,
    });
    this.lastNotificationToken = notificationToken;
    return notificationToken;
  }

  dismiss() {
    this.snackBar.dismiss();
  }

  dismissIftokenMatches(notificationToken: number) {
    if (this.lastNotificationToken === notificationToken) {
      this.dismiss();
    }
  }
}
