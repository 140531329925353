import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import { ChangeDetectorRef, Directive, TemplateRef, ViewContainerRef } from '@angular/core';
import { IPosition } from '@app/core';
import { Subscription, filter, fromEvent, merge, take } from 'rxjs';

@Directive({
  selector: '[appContextMenu]',
  exportAs: 'appContextMenu',
})
export class ContextMenuDirective<T = unknown> {
  private closeSubscription: Subscription;
  private overlayRef: OverlayRef;

  constructor(
    private overlay: Overlay,
    private templateRef: TemplateRef<unknown>,
    private viewContainerRef: ViewContainerRef,
    private changeDetectorRef: ChangeDetectorRef
  ) {}

  context: T;

  open(position: IPosition, context?: T) {
    this.changeDetectorRef.detectChanges();
    const positionStrategy = this.overlay
      .position()
      .flexibleConnectedTo(position)
      .withPositions([
        {
          originX: 'start',
          originY: 'top',
          overlayX: 'start',
          overlayY: 'top',
        },
      ]);

    this.context = context;
    this.overlayRef = this.overlay.create({
      positionStrategy,
      scrollStrategy: this.overlay.scrollStrategies.close(),
    });

    this.closeSubscription = merge(fromEvent<MouseEvent>(document, 'mousedown'))
      .pipe(
        filter(event => {
          const clickTarget = event.target as HTMLElement;
          return !!this.overlayRef && !this.overlayRef.overlayElement.contains(clickTarget);
        }),
        take(1)
      )
      .subscribe(this.close.bind(this));

    const portal = new TemplatePortal(this.templateRef, this.viewContainerRef);
    this.overlayRef.attach(portal);

    const overlay = this.overlayRef.overlayElement;
    overlay.onclick = this.close.bind(this);
    overlay.onkeyup = (event: KeyboardEvent) => {
      if (event.key === 'Enter') this.close();
    };

    const menu = overlay.firstChild as HTMLElement;
    menu?.focus();
  }

  close() {
    this.context = null;

    if (this.closeSubscription) {
      this.closeSubscription.unsubscribe();
      this.closeSubscription = null;
    }

    if (this.overlayRef) {
      this.overlayRef.dispose();
      this.overlayRef = null;
    }
  }
}
