import { Injectable } from '@angular/core';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FilePreviewSpec } from '@app/api';
import { Utils } from '@app/core/utils';
import { CreateTableMigrationStep, ensureMigrations, IMigration } from '@app/core/utils/indexed-db-extensions';
import { TranslateService } from '@ngx-translate/core';
import { from, Observable } from 'rxjs';
import { ApiService } from '../api/api.service';
import { ProjectService } from '../globals';
import { OfflineService } from '../offline';

const names = {
  fileCache: 'file-cache',
  filePreview: 'file-preview',
};

const migrations: IMigration[] = [
  {
    start(db: IDBDatabase, transaction: IDBTransaction) {
      return Promise.resolve(true);
    },
    after(db: IDBDatabase, transaction: IDBTransaction) {
      return Promise.resolve(true);
    },
    steps: [
      new CreateTableMigrationStep(names.fileCache),
      new CreateTableMigrationStep(names.filePreview, ['id', 'spec', 'resource']),
    ],
  },
];

const DEF = {
  db: 'file-preview-cache',
  migrations: migrations,
};

export interface ImagePreviewOptions {
  unsafe?: boolean;
  bright?: boolean;
  onlyImage?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class FilePreviewCacheService {
  private task: Promise<IDBDatabase>;
  private objectURLCache: { [key: string]: string } = {};

  constructor(
    private readonly api: ApiService,
    private readonly sanitizer: DomSanitizer,
    private readonly projectService: ProjectService,
    private readonly offlineService: OfflineService,
    private readonly translationService: TranslateService
  ) {
    this.task = new Promise(async (res, rej) => {
      try {
        const migrations = DEF.migrations;
        const request = indexedDB.open(DEF.db, migrations.length + 1);

        ensureMigrations(request, migrations);

        await request.wait();

        res(request.result);
      } catch ($err) {
        rej($err);
      }
    });
  }

  static async reset() {
    await indexedDB.deleteDatabase(DEF.db).wait();
  }

  getPreview(
    id: string,
    spec: FilePreviewSpec,
    resource: string,
    modifiedOn?: Date,
    asBackgroundUrl?: boolean
  ): Observable<SafeUrl> {
    const promise = new Promise<SafeUrl>(async (res, rej) => {
      try {
        const obj: IObjectURLEntry = {
          id,
          spec,
          resource,

          modifiedOn: modifiedOn,
        };

        if (!this.offlineService.isProjectOffline()) {
          const { projectId } = this.projectService;

          if (modifiedOn) {
            const dateModifiedOn: Date = new Date(modifiedOn);
            res(
              `/api/projects/${projectId}/items/${id}/preview/${spec}?Resource=${resource}&modifiedOn=${dateModifiedOn.valueOf()}`
            );
          } else {
            res(`/api/projects/${projectId}/items/${id}/preview/${spec}?Resource=${resource}`);
          }

          return;
        }

        if (typeof obj.modifiedOn == 'string') {
          obj.modifiedOn = new Date(obj.modifiedOn);
        }

        await this.findOrMakeKey(obj);

        const objectURL = await this.ensureObjectURL(obj.key);

        // if (asBackgroundUrl) {
        //   objectURL = `url('${objectURL}')`;
        // }

        // const objectURLSafe = this.sanitizer.bypassSecurityTrustUrl(objectURL);

        // res(objectURLSafe);

        res(asBackgroundUrl ? objectURL : this.sanitizer.bypassSecurityTrustUrl(objectURL));
      } catch ($err) {
        rej($err);
      }
    });

    return from(promise);
  }

  public getErrorImageUrl(options?: ImagePreviewOptions) {
    const parts = ['unable-to-load-image'];

    if (!options.onlyImage) {
      const tuple = this.translationService.currentLang;
      const [single] = tuple.match('[a-z]{2}');

      parts.push(single);
    }

    if (options.bright) {
      parts.push('black');
    } else {
      parts.push('white');
    }

    const path = `/assets/file-preview/${parts.join('-')}.png`;

    return path;
  }

  private async ensureObjectURL(url: string) {
    if (url in this.objectURLCache) {
      return this.objectURLCache[url];
    } else {
      const objectStore = await this.transaction(names.fileCache);

      const request = objectStore.get(url);

      await request.wait();

      const arrayBuffer: ArrayBuffer = request.result;

      return (this.objectURLCache[url] = URL.createObjectURL(arrayBuffer as any));
    }
  }

  private async findOrMakeKey(obj: IObjectURLEntry) {
    if (await this.findKey(obj)) {
      return true;
    }

    obj.key = Utils.createUUID();

    const download = await this.api.getPreview(obj.id, obj.spec, obj.resource);

    const db = await this.task;
    const transaction = db.transaction([names.filePreview, names.fileCache], 'readwrite');

    const fileCache = transaction.objectStore(names.fileCache);
    const filePreview = transaction.objectStore(names.filePreview);

    const fileCacheRequest = fileCache.put(download.data, obj.key);

    await fileCacheRequest.wait();

    const filePreviewRequest = filePreview.put(obj);

    await filePreviewRequest.wait();

    this.objectURLCache[obj.key] = URL.createObjectURL(download.data);

    return false;
  }

  private async findKey(obj: IObjectURLEntry): Promise<boolean> {
    const objectStore = await this.transaction(names.filePreview, 'readonly');

    const request = objectStore.get([obj.id, obj.spec, obj.resource]);

    await request.wait();

    const entry: IObjectURLEntry = request.result;

    if (entry) {
      if (obj.modifiedOn && extractTime(entry.modifiedOn) != extractTime(obj.modifiedOn)) {
        return false;
      }

      obj.key = entry.key;

      return true;
    }

    return false;
  }

  async transaction(table: string, mode: 'readonly' | 'readwrite' = 'readonly') {
    const db = await this.task;

    const transaction = db.transaction(table, mode);

    return transaction.objectStore(table);
  }
}

interface IObjectURLEntry {
  id: string;
  spec: FilePreviewSpec;
  resource: string;

  key?: string;
  modifiedOn?: Date;
}

function extractTime(modifiedOn: Date | string) {
  if (typeof modifiedOn == 'string') {
    modifiedOn = new Date(modifiedOn);
  }

  if (modifiedOn instanceof Date) {
    return modifiedOn.getTime();
  }

  return 0;
}
