import { capSQLiteSet, SQLiteDBConnection } from '@capacitor-community/sqlite';
import { IEntity } from './definitions';
import { SQLiteTables } from './sqlite/utils';

export declare type DBTypes = string | number | boolean | Date | Uint8Array | null;
export declare type DBFilter = { [k: string]: DBTypes | DBTypes[] };

export class SQLStatements {
  private tables = new Array<string>();
  private statements = new Array<capSQLiteSet>();

  constructor(private connection: SQLiteDBConnection) {}

  discard() {
    this.statements.splice(0);
  }

  enqueue(set: capSQLiteSet) {
    this.statements.push(set);
  }

  remove(table: SQLiteTables, filter: DBFilter) {
    const args = new Array<DBTypes>();
    const wheres = Builders.Filter(filter, args);

    this.enqueue({
      values: args,
      statement: `DELETE FROM ${table} WHERE ${wheres}`,
    });
  }

  update(table: SQLiteTables, update: IEntity, filter: DBFilter) {
    const args = new Array<DBTypes>();
    const sets = Builders.Update(update, args);
    const wheres = Builders.Filter(filter, args);

    this.enqueue({
      values: args,
      statement: `UPDATE ${table} SET ${sets} WHERE ${wheres}`,
    });
  }

  insertOrReplace<T = IEntity>(table: SQLiteTables, obj: T) {
    const keys = [];
    const values = [];
    const parameters = [];

    const tables = this.tables;
    const statements = this.statements;

    for (const k of Object.keys(obj)) {
      keys.push('[' + k + ']');

      const v = obj[k];

      if (typeof v != 'undefined' && v != null) {
        values.push(v);
        parameters.push('?');
      } else {
        parameters.push('null');
      }
    }

    const statement = `INSERT OR REPLACE INTO ${table} (${keys.join(', ')}) VALUES (${parameters.join(', ')})`;

    if (tables.indexOf(table) == -1) {
      tables.push(table);
    }

    statements.push({
      statement: statement,
      values: values,
    });
  }

  async execute(transaction: boolean = false) {
    const tables = this.tables.splice(0);
    const connection = this.connection;
    const statements = this.statements.splice(0);

    if (statements.length == 0) {
      console.info('Executing', statements.length, 'statements');
      return;
    } else {
      console.info('Executing', statements.length, 'statements on', tables.join(', '));
    }

    try {
      // connection already opened on ctor
      // await connection.open();

      const result = await connection.executeSet(statements, transaction);
      if (result.changes) {
        console.info(result);
      } else {
        console.warn('changes is empty for', statements);
      }

      return result;
    } finally {
      // await connection.close();
    }
  }
}

export const Builders = {
  Update(obj: IEntity, args: DBTypes[]) {
    const parts = [];

    for (const key of Object.keys(obj)) {
      const value = obj[key];

      if (typeof value == 'object') {
        if (value === null) {
          parts.push(`${key} = NULL`);
          continue;
        }

        if (value instanceof Date) {
          args.push(value);

          parts.push(`${key} = ?`);
          continue;
        }

        if (value instanceof Uint8Array) {
          args.push(value);

          parts.push(`${key} = ?`);
          continue;
        }

        throw new Error('Object is not supported');
      }

      if (typeof value == 'string') {
        args.push(value);

        parts.push(`${key} = ?`);
        continue;
      }

      if (typeof value == 'number') {
        args.push(value);

        parts.push(`${key} = ?`);
        continue;
      }

      if (typeof value == 'boolean') {
        args.push(value);

        parts.push(`${key} = ?`);
        continue;
      }

      throw new Error('Literal is not supported');
    }

    return parts.join(', ');
  },
  Filter(f: DBFilter, args: DBTypes[]) {
    const parts = [];

    for (const key of Object.keys(f)) {
      const value = f[key];

      if (typeof value == 'object') {
        if (value === null) {
          parts.push(`${key} IS NULL`);
          continue;
        }

        if (value instanceof Date) {
          args.push(value);

          parts.push(`${key} = ?`);
          continue;
        }

        if (value instanceof Uint8Array) {
          args.push(value);

          parts.push(`${key} = ?`);
          continue;
        }

        if (value instanceof Array) {
          const options = value.map(a => {
            args.push(a);

            return '?';
          });

          parts.push(`${key} IN (${options.join(', ')})`);
          continue;
        }

        throw new Error('Object is not supported');
      }

      if (typeof value == 'string') {
        args.push(value);

        parts.push(`${key} LIKE ?`);
        continue;
      }

      if (typeof value == 'number') {
        args.push(value);

        parts.push(`${key} = ?`);
        continue;
      }

      if (typeof value == 'boolean') {
        args.push(value);

        parts.push(`${key} = ?`);
        continue;
      }

      throw new Error('Literal is not supported');
    }

    return parts.join(' AND ');
  },
};
