import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { Router } from '@angular/router';
import {
  ConstructionDiaryModel,
  DriveItemTagsUpdateMode,
  DriveItemTagsUpdateModel,
  GalleryDriveItemModel,
  ModuleType,
  PropertyModel,
} from '@app/api';
import { ApiService, BaseSubscriptionComponent, LogService, nameof, ProjectService } from '@app/core';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { LocalizedDatePipe } from '@app/shared/pipes';
import { DocumentDownloadService, UserNotificationService } from '@app/shared/services';
import { FilePreviewOverlayService } from '@app/shared/services/file-preview/file-preview-overlay-config.service';
import { TranslateService } from '@ngx-translate/core';
import { IImageTag, ImageTags, ImageTagService } from '../image-tag.service';

@Component({
  selector: 'app-image-details',
  templateUrl: './image-details.component.html',
  styleUrls: ['./image-details.component.scss'],
})
export class ImageDetailsComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() set image(image: GalleryDriveItemModel) {
    this._image = image;
    this.imageChanged();
  }
  @Output() close = new EventEmitter<void>();
  @Output() delete = new EventEmitter<GalleryDriveItemModel>();
  @Output() move = new EventEmitter<GalleryDriveItemModel>();
  @Output() share = new EventEmitter<GalleryDriveItemModel>();
  @Output() preview = new EventEmitter<GalleryDriveItemModel>();

  locale: string;
  moduleType = ModuleType;
  fieldTranslationPrefix: string = '';
  additionalProperties: PropertyModel[] = [];

  @Input()
  canShare: boolean;

  private projectId: string;
  private _image: GalleryDriveItemModel;

  constructor(
    private log: LogService,
    private apiService: ApiService,
    private datePipe: LocalizedDatePipe,
    private dialog: MatDialog,
    private documentDownload: DocumentDownloadService,
    private filePreviewService: FilePreviewOverlayService,
    private projectService: ProjectService,
    private router: Router,
    private translate: TranslateService,
    private userNotification: UserNotificationService,
    private svcImageTagService: ImageTagService
  ) {
    super();
    this.locale = translate.currentLang;
  }

  get image(): GalleryDriveItemModel {
    return this._image;
  }

  ngOnInit() {
    this.subscribe(this.translate.onLangChange, langChangeEvent => {
      this.locale = langChangeEvent.lang;
    });
    this.subscribe(this.projectService.projectId$, projectId => {
      this.projectId = projectId;
    });
  }

  async moveImage() {
    this.move.emit(this.image);
  }

  async shareImage() {
    this.share.emit(this.image);
  }

  async downloadImage() {
    await this.documentDownload.downloadDocument({
      fileName: this.image.name,
      id: this.image.id,
      resource: this.image.resourceIdentifier.key.name,
    });
  }

  async fireTagChange(tags: ImageTags) {
    if (!tags) {
      return;
    }

    const createdTags = tags.map(x => {
      return x.text;
    });

    const model = new DriveItemTagsUpdateModel({
      mode: DriveItemTagsUpdateMode.Replace,
      createdTags: createdTags,
      ids: [this.image.metatdataId],
    });

    try {
      const cachedImage = this.image;

      const response = await this.apiService.updateTags(model);
      const responseTags = response.tagsForItems[cachedImage.metatdataId];

      await this.svcImageTagService.createWithProject();

      cachedImage.tags = responseTags.tags;

      this.userNotification.notify('dialogs.image-tag-dialog.actions.success');
    } catch ($err) {
      this.log.error($err);

      this.userNotification.notify('dialogs.image-tag-dialog.actions.failure');
    }
  }

  navigateToImage() {
    let url = this.router.createUrlTree(['projects', this.projectId, 'documents'], { queryParams: { path: this.image.path } });

    switch (this.image.resourceIdentifier.moduleType) {
      case ModuleType.Defect:
        url = this.router.createUrlTree(['projects', this.projectId, 'defects'], {
          queryParams: { id: this.image.relatedEntityId, edit: true, tab: 'files' },
        });
        break;
      case ModuleType.ConstructionDiary:
        url = this.router.createUrlTree(['projects', this.projectId, 'diary'], {
          queryParams: { id: this.image.relatedEntityId, edit: true, tab: 'files' },
        });
        break;
      case ModuleType.Bim:
        url = this.router.createUrlTree(['projects', this.projectId, 'bim'], {
          queryParams: { id: this.image.relatedEntityId, edit: true, tab: 'files' },
        });
        break;
      case ModuleType.RoomBook:
        url = this.router.createUrlTree(['projects', this.projectId, 'rooms'], {
          queryParams: { id: this.image.relatedEntityId, tab: 'files' },
        });
        break;
    }

    if (CapacitorUtils.isApp()) {
      this.router.navigateByUrl(url);
    } else {
      window.open(url.toString(), '_blank');
    }
  }

  deleteItem() {
    this.delete.emit(this.image);
  }

  closeItem() {
    this.close.emit();
  }

  previewImage() {
    this.preview.emit(this.image);
  }

  imageChanged() {
    this.additionalProperties = this.image.additionalProperties.filter(p => !p.isFilterProperty);

    if (this.image.resourceIdentifier.moduleType == ModuleType.ConstructionDiary) {
      this.additionalProperties
        .filter(p => p.fieldName.toLowerCase() == nameof<ConstructionDiaryModel>('assigned'))
        .forEach(p => {
          p.value = this.datePipe.transform(p.value);
        });
    }

    this.setTranslationPrefix();
  }
  private setTranslationPrefix() {
    switch (this.image.resourceIdentifier.moduleType) {
      case ModuleType.Defect:
        this.fieldTranslationPrefix = 'grid.defects.';
        break;
      case ModuleType.ConstructionDiary:
        this.fieldTranslationPrefix = 'grid.constructionDiary.';
        break;
      case ModuleType.Bim:
        this.fieldTranslationPrefix = 'grid.issues.';
        break;
      case ModuleType.RoomBook:
        this.fieldTranslationPrefix = 'grid.rooms.';
        break;
    }
  }
}
