import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { LeanWorkpackageTemplateModel } from '@app/api';
import { ApiService, AppRoutingData, BaseSubscriptionComponent, DataHolder } from '@app/core';
import { UserNotificationService } from '@app/shared/services/user-notification/user-notification.service';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmDialogComponent } from '../../dialogs/confirm-dialog/confirm-dialog.component';
import { WorkpackageEditDialogComponent } from './workpackage-edit-dialog/workpackage-edit-dialog.component';

interface ExtendedLeanWorkpackageTemplateModel extends LeanWorkpackageTemplateModel {
  strippedDescription?: string;
}

export class WorkpackageTemplatesBase extends BaseSubscriptionComponent implements Busy {
  isBusy: boolean;

  icon: string = AppRoutingData.workpackageTemplates.icon;

  workpackageTemplatesHolder: DataHolder<ExtendedLeanWorkpackageTemplateModel[]>;
  rowCount: number = 0;

  constructor(
    protected apiService: ApiService,
    protected dialog: MatDialog,
    protected translateService: TranslateService,
    protected userNotification: UserNotificationService
  ) {
    super();

    this.workpackageTemplatesHolder = new DataHolder(this.initData());
  }

  async editWorkpackageTemplate(workpackageTemplate: LeanWorkpackageTemplateModel = null) {
    const updatedIds = await this.openEditDialog(workpackageTemplate);

    if (updatedIds.length > 0) await this.updateData();
  }

  async deleteWorkpackageTemplate(workpackageTemplate: LeanWorkpackageTemplateModel) {
    const isDeleteConfirmed = await this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'general.confirmation.deleteCaption',
          description: 'general.confirmation.deleteDescription',
          params: {
            element: await this.translateService.get('workpackageTemplates.element').toPromise(),
            name: workpackageTemplate.name,
          },
        },
      })
      .afterClosed()
      .toPromise();

    if (isDeleteConfirmed) {
      await using(new BusyScope(this), async _ => {
        await this.apiService.deleteWorkpackageTemplate(workpackageTemplate.id);

        await this.updateData();
      }).catch(e => {
        this.userNotification.notify('general.errorMsg.delete');
      });
    }
  }

  protected async openEditDialog(workpackageTemplate: LeanWorkpackageTemplateModel = null) {
    return await this.dialog
      .open(WorkpackageEditDialogComponent, {
        data: {
          id: workpackageTemplate?.id,
          isTemplate: true,
        },
        disableClose: true,
      })
      .afterClosed()
      .toPromise();
  }

  protected async updateData() {
    await this.workpackageTemplatesHolder
      ?.updateData(async () => {
        const result = await this.apiService.getWorkpackageTemplates();

        for (const workpackageTemplate of result as ExtendedLeanWorkpackageTemplateModel[]) {
          workpackageTemplate.strippedDescription = this.stripHtml(workpackageTemplate.description);
        }

        this.rowCount = result.length;

        return result;
      }, true)
      .catch(e => {
        this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.workpackageTemplates', e);
      });
  }

  private initData() {
    const fakeWorkpackages: LeanWorkpackageTemplateModel[] = [];

    for (let idx = 0; idx < 3; idx++) {
      const workpackageTemplate: ExtendedLeanWorkpackageTemplateModel = new LeanWorkpackageTemplateModel({
        name: 'fakeData',
      });

      workpackageTemplate.strippedDescription = 'fakeData fakeData fakeData';

      fakeWorkpackages.push();
    }

    return fakeWorkpackages;
  }

  private stripHtml(input: string) {
    return input?.replace(/(<([^>]+)>)/gi, '');
  }
}
