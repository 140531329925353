export interface IProjectStatusMap {
  [id: string]: IProjectStatusInfo;
}

export interface IProjectStatusInfo {
  offline: boolean;
}

export interface IProjectItemSyncStatusMap {
  [id: string]: IProjectItemSyncStatusInfo;
}

export interface IProjectItemSyncStatusInfo {
  id: string;
  text: string;
  type: string;
  action: string;
  status: OfflineSyncStatus;
}

export enum OfflineSyncStatus {
  Waiting = 'Waiting',
  Success = 'Success',
  Failure = 'Failure',
}

// --------------------------------------------------
// Entities
// --------------------------------------------------

export type IEntityWithContent =
  | IPSArea
  | IPSProjectOrganizationCraft
  | IPSProjectOrganization
  | IPSProjectUser
  | IPSCraft
  | IPSDefectType
  | IPSDefect
  | IPSDriveItem
  | IPSFloor
  | IPSOrganization
  | IPSReason
  | IPSRegion
  | IPSRoom
  | IPSSchema
  | IPSUser
  | IPSResource;

export type IEntity = IEntityWithContent | IPSComment | IPSDefectComment | IPSMetadata | IPSProject | IPSUpload | IPSSyncError;

export interface IPSSyncError {
  id: string;
  project: string;
  createdOn: Date;
  parameters: string;
}

export interface IPSArea {
  id: string;
  project: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSComment {
  id: string;
  project: string;
  text: string;
  editorId: string;
  replyToCommentId?: string;
  mode: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSProjectOrganizationCraft {
  id: string;
  project: string;
  projectOrganizationId: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSProjectOrganization {
  id: string;
  project: string;
  organizationId: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSProjectUser {
  id: string;
  project: string;
  userId: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSCraft {
  id: string;
  project: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSDefectComment {
  id: string;
  project: string;
  defectId: string;
  commentId: string;
  mode: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSDefectType {
  id: string;
  project: string;
  code: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSDefect {
  id: string;
  project: string;
  json: string;
  mode: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSDriveItem {
  id: string;
  project: string;
  relatedEntityId?: string;
  type: string;
  path: string;
  name: string;
  mimeType: string;
  resource: string;
  json: string;
  mode: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSFloor {
  id: string;
  project: string;
  areaId: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSMetadata {
  name: string;
  data: string;
}

export interface IPSResource {
  id: string;
  project: string;
  json: string;
}

export interface IPSOrganization {
  id: string;
  project: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSProject {
  id: string;
  json: string;
  offline: boolean;
  modifiedOn: Date;
  sync_checksum: string;
  sync_datetime: Date;
}

export interface IPSProjectPrivilege {
  id: string;
  project: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSReason {
  id: string;
  project: string;
  code: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSRegion {
  id: string;
  project: string;
  areaId: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSRoom {
  id: string;
  project: string;
  floorId: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSZoneGroup {
  id: string;
  project: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSZone {
  id: string;
  project: string;
  zoneGroupId: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSSchema {
  id: string;
  project: string;
  type: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

export interface IPSUpload {
  id: string;
  type: string;
  result: string;
}

export interface IPSUser {
  id: string;
  project: string;
  json: string;
  deleted: boolean;
  modifiedOn: Date;
}

// --------------------------------------------------
// Joined Entities
// --------------------------------------------------

export interface IPSUserWithProjectUserModel extends IPSUser {
  projectUserModel: string;
}

export interface IPSCommentWithUser extends IPSComment {
  jsonUser: string;
}

export interface IPSCommentWithUserAndDefectId extends IPSCommentWithUser {
  defectId: string;
}
