import { Injectable } from '@angular/core';
import { AuthenticationService } from './authentication.service';
import {
  AuthUserClient,
  LoginModel,
  ExternalLoginModel,
  SwaggerException,
  RefreshTokenModel,
  RequestSecretTokenModel,
  LoginResultModel,
  RequestPasswordResetModel,
  PasswordResetTokenDataModel,
  RedeemPasswordResetTokenModel,
  SessionClient,
  ProjectModel,
} from '@app/api';
import { LoginError } from './login-error';
import { LogService } from '../log/log.service';
import { LoginData, RequestOtpData } from '.';
import { MsalService } from '@azure/msal-angular';
import { ProjectService } from '../globals';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class ApiAuthenticationService {
  private isSwitchingCompany = false;

  constructor(
    private authenticationClient: AuthUserClient,
    private authenticationService: AuthenticationService,
    private log: LogService,
    private msalAuthService: MsalService,
    private projectService: ProjectService,
    private sessionClient: SessionClient
  ) {}

  async login(loginData: LoginData, projectId: string): Promise<LoginResultModel> {
    let loginResultModel: LoginResultModel;
    try {
      //possibilites:
      //-) only primary secret (password)
      //-) only primary secret (one time token)
      //-) primary + secondary (password + one time token)
      loginResultModel = await this.authenticationClient
        .login(
          new LoginModel({
            userName: loginData.userName,
            primarySecret: loginData.primarySecret,
            secondarySecret: loginData.secondarySecret,
            projectId: projectId,
          })
        )
        .toPromise();
    } catch (e) {
      if (SwaggerException.isSwaggerException(e)) {
        e = e as SwaggerException;
        this.log.error(`Login failed: ${e.status}: ${e.message}`, e);
        switch (e.status) {
          case 401:
            throw LoginError.WrongUserOrPassword;
          case 423:
            throw LoginError.TenantConsent;
        }
      } else {
        this.log.error('Login failed', e);
      }
      throw LoginError.Generic;
    }

    this.authenticationService.loginWithToken(
      loginResultModel.accessToken,
      loginResultModel.refreshToken,
      loginResultModel.enforcePasswordChange,
      loginResultModel.availableProjects,
      loginResultModel.enforceUserDataChange
    );

    return loginResultModel;
  }

  async loginWithAccessToken(accessToken: string, tenantId: string, groupId: string, projectId: string) {
    let loginResultModel: LoginResultModel;
    try {
      loginResultModel = await this.authenticationClient
        .loginWithAccessToken(
          new ExternalLoginModel({
            accessToken,
            groupId,
            projectId,
          })
        )
        .toPromise();
    } catch (e) {
      if (SwaggerException.isSwaggerException(e)) {
        e = e as SwaggerException;
        this.log.error(`Login failed: ${e.status}: ${e.message}`, e);
        if (e.status === 423) {
          throw LoginError.TenantConsent;
        }
        if (e.status === 401) {
          throw LoginError.Generic;
        }
      } else {
        this.log.error('Login failed', e);
      }
      throw LoginError.Generic;
    }

    this.authenticationService.loginWithToken(
      loginResultModel.accessToken,
      loginResultModel.refreshToken,
      loginResultModel.enforcePasswordChange,
      loginResultModel.availableProjects,
      loginResultModel.enforceUserDataChange
    );

    return loginResultModel;
  }

  async requestLoginOtp(requestOtpData: RequestOtpData) {
    try {
      await this.authenticationClient
        .requestSecretToken(
          new RequestSecretTokenModel({
            userName: requestOtpData.userName,
            primarySecret: requestOtpData.primarySecret,
          })
        )
        .toPromise();
    } catch (e) {
      if (SwaggerException.isSwaggerException(e)) {
        e = e as SwaggerException;
        this.log.error(`Request OTP failed: ${e.status}: ${e.message}`, e);
        if (e.status === 401) {
          throw LoginError.WrongUserOrPassword;
        }
      } else {
        this.log.error('Request OTP failed', e);
      }
      throw LoginError.GenericOtp;
    }
  }

  async requestPasswordResetToken(userName: string) {
    await this.authenticationClient
      .requestPasswordResetToken(
        new RequestPasswordResetModel({
          userName,
        })
      )
      .toPromise();
  }

  async getPasswordResetTokenData(passwordResetToken: string): Promise<PasswordResetTokenDataModel> {
    return this.authenticationClient.getPasswordResetTokenData(passwordResetToken).toPromise();
  }

  async redeemPasswordResetToken(passwordResetToken: string, password: string) {
    return this.authenticationClient
      .redeemPasswordResetToken(
        new RedeemPasswordResetTokenModel({
          password,
          passwordResetToken,
        })
      )
      .toPromise();
  }

  async logout(refreshToken: string) {
    await this.sessionClient.logout(refreshToken).toPromise();

    this.msalAuthService.initialize().subscribe(() => {
      () => {
        if (!!this.msalAuthService.instance.getActiveAccount()) {
          this.msalAuthService.logout(); //logged in via MS-Account
        }
      };
    });
  }

  async refreshToken(oldToken: string, refreshToken: string) {
    try {
      const newTokenResult = await this.authenticationClient
        .refresh(
          new RefreshTokenModel({
            accessToken: oldToken,
            refreshToken: refreshToken,
          })
        )
        .toPromise();
      return newTokenResult;
    } catch (e) {
      this.log.error('Token refresh failed');
      return null;
    }
  }
}
