<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
<app-progress-bar [progress]="uploadProgress" type="determinate"></app-progress-bar>
<div class="c4-planlist-container" [ngClass]="[schemaPendingClass, uploadAllowed?dragDropClass:'']"
     (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragenter)="onDragEnter($event)"
     (dragleave)="onDragLeave($event)" (dragend)="onDragEnd($event)">
  <div class="c4-grid-container"
       [ngClass]="[(planData)?planData.busyClass:'',moduleType === domModuleType.Bim?'bim-ifc':'']">
    <c4-grid *ngIf="gridDef && planData && (planData.data || gridDef.grid.lazy)" [gridDef]="gridDef"
             [source]="planData.data" [noDataText]="'documents.noDataText'"
             (filter)="gridFilterChanged($event)"></c4-grid>
  </div>
</div>


<ng-template #fileName let-col='col' let-row='row'>
  <span class="c4-topline c4-planning-filename" (click)="filenameAction(row)">{{row[col.field] }}</span>
</ng-template>
<ng-template #uploadDate let-col='col' let-row='row'>
  <span class="c4-topline" [matTooltip]="row[col.field] | localizedDate:'short'">
    {{ row[col.field] | localizedDate }}
  </span>
</ng-template>


<ng-template #fileType let-col='col' let-row='row'>
  <span class="plan-ico filtype mdi mdi-file" [ngClass]="'planning.icon.'+row.Filetype | translate"></span>
</ng-template>

<ng-template #contextMenu let-col='col' let-row='row'>
  <div class="c4-no-focus">
    <button mat-icon-button class="c4-grid-btn" [matMenuTriggerFor]="rowContext" [matMenuTriggerData]="{menudata:row}"
            [matTooltip]="'general.actions.title' | translate">
      <span class="mdi mdi-dots-horizontal"></span>
    </button>
  </div>
</ng-template>

<ng-template #conversionState let-col='col' let-row='row'>
  <ng-container *ngIf="!row.canBeConverted; else currentConversionState">
    <span class="filtype plan-ico mdi mdi-cloud-off-outline"
          [matTooltip]="'planning.conversionState.NotSupported' | translate"></span>
  </ng-container>
  <ng-template #currentConversionState>
    <span class="filtype plan-ico mdi"
          [matTooltip]="'planning.conversionState.' + (row.conversion?.currentState ? row.conversion.currentState : 'Unknown') | translate"
          [class]="getConversionStateIcon(row)"></span>
  </ng-template>
</ng-template>


<mat-menu #rowContext="matMenu">
  <ng-template matMenuContent let-menudata='menudata'>
    <button *ngIf="menudata.privilege.modify && menudata[stateField] !==approval.ApprovedLabel" mat-menu-item
            class="c4-context-menu-item" (click)="approve(menudata,true)">
      <span class="mdi mdi-bookmark-check"></span>
      <span>{{'planning.grid.approve' |translate}}</span>
    </button>
    <button *ngIf="menudata.privilege.modify && menudata[stateField]===approval.ApprovedLabel" mat-menu-item
            class="c4-context-menu-item" (click)="approve(menudata,false)">
      <span class="mdi mdi-sync"></span>
      <span>{{'planning.grid.reject' |translate}}</span>
    </button>
    <button *ngIf="canWriteRestricted" mat-menu-item class="c4-context-menu-item"
            (click)="downloadDoc(menudata.name,menudata.id,false)">
      <span class="mdi mdi-download"></span>
      <span>{{'documents.download' |translate}}</span>
    </button>
    <button *ngIf="menudata.privilege.readContent && canSharePrivilege" mat-menu-item class="c4-context-menu-item"
            (click)="shareDoc(menudata.id, menudata.name)">
      <span class="mdi mdi-share-variant"></span>
      <span>{{'general.share' |translate}}</span>
    </button>
    <button *ngIf="canWriteRestricted && canSharePrivilege" mat-menu-item class="c4-context-menu-item"
            (click)="generateZipDownloadUrl([menudata.id])">
      <span class="mdi mdi-link"></span>
      <span>{{'planning.header.generateUrl' |translate}}</span>
    </button>
    <button *ngIf="moduleType === domModuleType.Bim && menudata.canBeConverted && canWriteRestricted" mat-menu-item
            class="c4-context-menu-item" (click)="generateModel(menudata)">
      <span class="mdi mdi-vector-polygon"></span>
      <span>{{'Modell neu generieren' |translate}}</span>
    </button>
    <button *ngIf="menudata.privilege.delete" mat-menu-item class="c4-context-menu-item delete"
            (click)="deletePlan(menudata)">
      <span class="mdi mdi-trash-can-outline"></span>
      <span>{{'documents.delete' |translate}}</span>
    </button>
    <button *ngIf="canReadLog" mat-menu-item class="c4-context-menu-item" (click)="getLog(menudata)">
      <span class="mdi mdi-history"></span>
      <span>{{'documents.getLog' |translate}}</span>
    </button>
    <button *ngIf="canReadPrivilege" mat-menu-item class="c4-context-menu-item" (click)="getPrivileges(menudata)">
      <span class="mdi mdi-table"></span>
      <span>{{'documents.getPrivileges' |translate}}</span>
    </button>
  </ng-template>
</mat-menu>


<ng-template #selectRow let-col='col' let-row='row'>
  <div class="c4-no-focus offset-left">
    <button mat-icon-button class="c4-grid-btn" (click)="rowSelect(row)">
      <span class="mdi mdi-check"></span>
    </button>
  </div>
</ng-template>

<ng-template #selectAll let-col='col'>
  <div class="c4-no-focus offset-left">
    <button mat-icon-button class="c4-grid-btn" (click)="allRowsSelect($event)">
      <span class="mdi mdi-check-all"></span>
    </button>
  </div>
</ng-template>

<ng-template #stateBubble let-row="row">
  <div class="plan-state-container" [class]="row.stateCode">
    <app-state-circle></app-state-circle>
    <span>{{row.Status}}</span>
  </div>
</ng-template>