import { Component, OnInit, Inject } from '@angular/core';
import { DialogBase } from '../dialog-base';
import {
  UserModel,
  UpdateProjectUserAssignmentType,
  ProjectModel,
  UpdateProjectUserAssignmentsModel,
  UpdateProjectUserInvitation,
} from '@app/api';
import { ApiService, Utils } from '@app/core';
import { UserNotificationService } from '@app/shared/services';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { Busy, using, BusyScope } from '@app/shared/utils/busy';
import { MatLegacySlideToggleChange as MatSlideToggleChange } from '@angular/material/legacy-slide-toggle';

@Component({
  selector: 'app-company-user-assign-dialog',
  templateUrl: './company-user-assign-dialog.component.html',
  styleUrls: ['./company-user-assign-dialog.component.scss'],
})
export class CompanyUserAssignDialogComponent extends DialogBase<CompanyUserAssignDialogComponent> implements OnInit {
  private _selectedItem: any;
  public projectFilter: ProjectModel;
  public userFilter: UserModel;
  public assignedItems: IItemModel[];
  public availableItems: IItemModel[];
  public assignUsers: boolean;
  public assignCompanies: boolean;
  public busyAssignments: Busy = <Busy>{ isBusy: false };
  public hasChanged: boolean = false;
  public movedItems: any[] = [];

  public get selectedItem(): any {
    return this._selectedItem;
  }
  public set selectedItem(v: any) {
    this._selectedItem = v;
  }

  constructor(
    public dialogRef: MatDialogRef<CompanyUserAssignDialogComponent>,
    private userNotification: UserNotificationService,
    @Inject(MAT_DIALOG_DATA) data,
    private apiService: ApiService
  ) {
    super(dialogRef, data);
  }

  protected readData(data: any) {
    super.readData(data);
    this.projectFilter = data.company;
    if (Utils.isNullOrUndefined(this.projectFilter)) {
      this.userFilter = data.user;
      this.assignCompanies = true;
    } else {
      this.assignCompanies = false;
    }
    this.assignUsers = !this.assignCompanies;
  }

  async ngOnInit() {
    this.selectedItem = null;
    this.movedItems = [];

    await using(new BusyScope(this.busyAssignments), async busy => {
      this.busyAssignments.isBusy = true;
      this.assignedItems = [];
      for (let idx = 0; idx < 1; idx++) {
        this.assignedItems.push({
          entityId: '',
          entityType: 'project',
          entityText: 'Fake-Company-name [Fake-External]',
          canInvite: false,
          tryInvite: false,
        });
      }

      let allCompanies: ProjectModel[] = await this.apiService.getProjects();

      if (this.assignCompanies) {
        // user => companies
        const allAssignedCompanies = await this.apiService.getAssignedProjectsForUser(this.userFilter.id);

        this.assignedItems = [];
        this.availableItems = [];

        for (const company of allCompanies) {
          let available = true;
          for (const assignedCompany of allAssignedCompanies) {
            if (assignedCompany.id === company.id) {
              available = false;
              break;
            }
          }

          let displayValue = company.name;
          if (!Utils.isNullOrWhitespace(company.externalSystemName)) {
            displayValue += ` [${company.externalSystemName}]`;
          }
          if (!Utils.isNullOrWhitespace(company.externalId)) {
            displayValue += ` [${company.externalId}]`;
          }

          if (available) {
            this.availableItems.push({
              entityId: company.id,
              entityText: displayValue,
              entityType: 'project',
              canInvite: this.userFilter.isOnBehalfLogin,
              tryInvite: this.userFilter.isOnBehalfLogin,
            });
          } else {
            this.assignedItems.push({
              entityId: company.id,
              entityText: displayValue,
              entityType: 'project',
              canInvite: false,
              tryInvite: false,
            });
          }
        }
      } else {
        // company => users
        const allUsers = await this.apiService.getUsers();
        const allAssignedUsers = await this.apiService.getAssignedUsersForProject(this.projectFilter.id);

        this.assignedItems = [];
        this.availableItems = [];

        for (const user of allUsers) {
          let available = true;

          for (const assignedUser of allAssignedUsers) {
            if (assignedUser.id === user.id) {
              available = false;
              break;
            }
          }

          if (available) {
            this.availableItems.push({
              entityId: user.id,
              entityText: user.username,
              entityType: 'user',
              canInvite: user.isOnBehalfLogin,
              tryInvite: user.isOnBehalfLogin,
            });
          } else {
            this.assignedItems.push({
              entityId: user.id,
              entityText: user.username,
              entityType: 'user',
              canInvite: false,
              tryInvite: false,
            });
          }
        }
      }
    });
  }

  public getDisplayValue(model: IItemModel): string {
    return model.entityText;
  }

  public fireToggleInvite(item: IItemModel) {
    if (item.canInvite) {
      item.tryInvite = item.tryInvite == false;
    }
  }

  public fireToggle(ev: MatSlideToggleChange, item: IItemModel) {
    item.tryInvite = ev.checked;
  }

  public move(item: IItemModel) {
    const assigned = this.assignedItems.indexOf(item) >= 0;
    const moved = this.movedItems.indexOf(item) >= 0;
    //check
    if (moved) {
      this.movedItems.remove(item);
    } else {
      this.movedItems.push(item);
    }
    //assign
    if (assigned) {
      this.assignedItems.remove(item);
      this.availableItems.push(item);
    } else {
      this.availableItems.remove(item);
      this.assignedItems.push(item);
    }
    this.hasChanged = this.movedItems.length > 0;
  }

  public addSelectedItem(v: any) {
    if (Utils.isNullOrUndefined(v)) return;
    this.hasChanged = true;
    this.move(v);
    this.selectedItem = null;
  }

  public async confirm() {
    try {
      await using(new BusyScope(this), async busy => {
        const model = new UpdateProjectUserAssignmentsModel();

        if (this.assignCompanies) {
          model.updateProjectUserAssignmentType = UpdateProjectUserAssignmentType.UserProjectIds;
          model.userIds = [new UpdateProjectUserInvitation({ entityId: this.userFilter.id })];
          model.projectIds = [];
          for (const company of this.assignedItems) {
            model.projectIds.push(
              new UpdateProjectUserInvitation({
                entityId: company.entityId,
                inviteWithTeams: company.canInvite && company.tryInvite,
              })
            );
          }
        } else {
          model.updateProjectUserAssignmentType = UpdateProjectUserAssignmentType.ProjectUserIds;
          model.projectIds = [new UpdateProjectUserInvitation({ entityId: this.projectFilter.id })];
          model.userIds = [];
          for (const user of this.assignedItems) {
            model.userIds.push(
              new UpdateProjectUserInvitation({
                entityId: user.entityId,
                inviteWithTeams: user.canInvite && user.tryInvite,
              })
            );
          }
        }

        await this.apiService.updateProjectUserAssignments(model);
      });
      await this.userNotification.notify('dialogs.assignCompanyUser.success');
      this.dialogRef.close(this.assignedItems);
    } catch (error) {
      await this.userNotification.notify('dialogs.assignCompanyUser.error.assignFailed');
    }
  }

  public cancel() {
    this.dialogRef.close();
  }
}

interface IItemModel {
  entityId: string;
  entityText: string;
  entityType: 'user' | 'project';

  canInvite: boolean;
  tryInvite: boolean;
}
