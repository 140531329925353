import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import * as moment from 'moment';
import { Moment } from 'moment';
import { Utils } from './utils';

const invalidFolderNames = [
  '.LOCK',
  'CON',
  'PRN',
  'AUX',
  'NUL',
  'COM0',
  'COM1',
  'COM2',
  'COM3',
  'COM4',
  'COM5',
  'COM6',
  'COM7',
  'COM8',
  'COM9',
  'LPT0',
  'LPT1',
  'LPT2',
  'LPT3',
  'LPT4',
  'LPT5',
  'LPT6',
  'LPT7',
  'LPT8',
  'LPT9',
  '_VTI_',
  'DESKTOP.INI',
];

export class CustomValidators {
  static unique(existing: string[]): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const exists = existing.contains(control.value);

      return exists ? { unique: true } : null;
    };
  }

  static uniqueItems(): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      const items: string[] = control.value.map(x => x.name);

      let oldItem = '';
      for (const item of items.sort()) {
        if (oldItem == item) {
          return { unique: true };
        }

        oldItem = item;
      }

      return null;
    };
  }

  static uniqueDatepickerDate(existing: Moment[]): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (!control.value) return null;

      const moment: Moment = control.value as Moment;
      const exists = existing.find(e => e.isSame(moment, 'D'));

      return exists ? { unique: true } : null;
    };
  }

  static uniqueFlatControls(arrayNames: string[], keyControlName: string): ValidatorFn {
    return (group: UntypedFormGroup): ValidationErrors | null => {
      let areControlsUnique = this.areUnique(
        arrayNames.flatMap(arrayName => {
          const array = group.controls[arrayName] as UntypedFormArray;
          return array.controls.map((group: UntypedFormGroup) => group.controls[keyControlName].value);
        })
      );

      return areControlsUnique ? null : { uniqueFlatControls: true };
    };
  }

  static uniqueControls(keyControlName: string): ValidatorFn {
    return (array: UntypedFormArray): ValidationErrors | null => {
      let areControlsUnique = this.areUnique(
        array.controls.map((group: UntypedFormGroup) => group.controls[keyControlName].value)
      );

      return areControlsUnique ? null : { uniqueControls: true };
    };
  }

  static date(control: UntypedFormControl): ValidationErrors | null {
    return control.value && !control.value.match(/^\d{1,2}\.\d{1,2}\.\d{4}$/) ? { date: true } : null;
  }

  static afterTime(other: AbstractControl): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (!other?.value || !control?.value) return null;

      const timeFormat = 'HH:mm';
      const moment1: Moment = moment(other.value.format(timeFormat), timeFormat);
      const moment2: Moment = moment(control.value.format(timeFormat), timeFormat);

      return moment2.isAfter(moment1) ? null : { afterTime: true };
    };
  }

  static afterDate(other: AbstractControl, canBeSame: boolean = false): ValidatorFn {
    return (control: UntypedFormControl): ValidationErrors | null => {
      if (!other?.value || !control?.value) return null;

      const isValid = canBeSame
        ? moment(control.value).isSameOrAfter(moment(other.value))
        : moment(control.value).isAfter(moment(other.value));

      return isValid ? null : { afterDate: true };
    };
  }

  static decimal(control: UntypedFormControl): ValidationErrors | null {
    return control.value && !control.value.toString().match(/^-?\d+((\.|,)\d+)?$/) ? { decimal: true } : null;
  }

  static email(control: UntypedFormControl): ValidationErrors | null {
    return control.value && !Utils.isEmail(control.value) ? { email: true } : null;
  }

  static url(control: UntypedFormControl): ValidationErrors | null {
    return control.value && !control.value.match(/^(ftp|http|https):\/[^ "]+$/) ? { url: true } : null;
  }

  static equals(otherControl: AbstractControl): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value = control.value;
      const otherValue = otherControl.value;

      if (value !== otherValue) {
        return { equals: true };
      }

      return null;
    };
  }

  static valuesRequired(control: UntypedFormControl): ValidationErrors | null {
    return control.value && Object.values(control.value).every(v => !v) ? { valuesRequired: true } : null;
  }

  static folder(control: UntypedFormControl): ValidationErrors | null {
    const isValid: boolean = /^[ÄÖÜA-Z0-9\-\_\s\(\)]+$/i.test(control.value as string);
    return isValid ? null : { folder: true };
  }

  static driveItemName(control: UntypedFormControl): ValidationErrors | null {
    const driveItemName = control.value as string;

    if (/["\*:<>\?\/\\\|%]/i.test(driveItemName)) return { oneDriveItemName: true };

    if (driveItemName.startsWith('~$')) return { oneDriveItemNameStart: true };

    if (driveItemName.endsWith('.')) return { oneDriveItemNameEnd: true };

    if (invalidFolderNames.includes(driveItemName.toUpperCase())) return { reserved: true };

    return null;
  }

  static sharepointChannel(control: UntypedFormControl): ValidationErrors | null {
    const channelName = control.value as string;

    switch (true) {
      case /(^_)|(^\.)/.test(channelName):
        return { channelStart: true };
      case /(\.$)/.test(channelName):
        return { channelEnd: true };
      case /[~#%&\*\{\}\/\\\:\<\>\?\+\|\'"]|(\.\.+)/.test(channelName):
        return { channel: true };
      case /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/.test(channelName):
        return { channelLink: true };
      default:
        return null;
    }
  }

  private static areUnique(array: string[]) {
    for (let i = 0; i < array.length; i++) {
      const value = array[i];

      if (!value) continue;

      for (let j = 0; j < array.length; j++) {
        if (i == j) continue;

        const otherValue = array[j];

        if (!otherValue) continue;

        if (value.toLowerCase() === otherValue.toLowerCase()) return false;
      }
    }

    return true;
  }
}
