import { Component, HostListener, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { DriveItemModel, DriveItemMoveOperation, DriveItemType, ModuleType } from '@app/api';
import { BaseSubscriptionComponent, GlobalsService, OfflineService } from '@app/core';
import { FileSize } from '@app/shared/pipes';
import { DocumentsService } from '@app/shared/services';
import { SortMeta } from 'primeng/api';
import { GridComponent, GridSelectionMode } from '../../grid';
import { C4GridColumn, C4GridDef } from '../../grid/grid.interfaces';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-document-list',
  templateUrl: './document-list.component.html',
  styleUrls: ['./document-list.component.scss'],
})
export class DocumentListComponent extends BaseSubscriptionComponent implements OnInit, OnDestroy {
  @ViewChild('selectRow', { static: true }) selectRow: TemplateRef<any>;
  @ViewChild('selectAll', { static: true }) selectAll: TemplateRef<any>;
  @ViewChild('fileType', { static: true }) fileType: TemplateRef<any>;
  @ViewChild('fileName', { static: true }) fileName: TemplateRef<any>;
  @ViewChild('contextMenuButton', { static: true }) contextMenuButton: TemplateRef<any>;
  @ViewChild('docEditor', { static: true }) docEditor: TemplateRef<any>;
  @ViewChild('docEditorMobile', { static: true }) docEditorMobile: TemplateRef<any>;
  @ViewChild('size', { static: true }) size: TemplateRef<any>;
  @ViewChild('download', { static: true }) download: TemplateRef<any>;
  @ViewChild('fileThumbnail', { static: true }) fileThumbnail: TemplateRef<any>;
  @ViewChild('grid') grid: GridComponent;

  @Input() isModificationEnabled: boolean = true;
  @Input() enablePreview: boolean;
  @Input() selectionMode: GridSelectionMode = GridSelectionMode.multiple;
  @Input() fileNameTemplate?: TemplateRef<any>;

  gridDef: C4GridDef;
  isOffline: boolean = false;
  isDragDrop: boolean = false;
  supportedMoveModuleTypes = [ModuleType.Document];
  DriveItemMoveOperations = DriveItemMoveOperation;

  constructor(
    private fileSize: FileSize,
    private globals: GlobalsService,
    private offlineService: OfflineService,
    public documentsService: DocumentsService,
    private dialog: MatDialog
  ) {
    super();
  }

  get dragDropClass(): string {
    if (this.documentsService.canAddFile) {
      if (this.isDragDrop) return 'app-docExDragDrop';
      if (this.globals.isDragDrop) return 'app-dragDrop';
    }

    return '';
  }

  public get canReadLog(): boolean {
    return this.documentsService.canReadLog;
  }

  public get canReadPrivilege(): boolean {
    return this.documentsService.canReadPrivilege;
  }

  public get canSharePrivilege(): boolean {
    return this.documentsService.canSharePrivilege;
  }

  async ngOnInit() {
    // this.disabled = false;
    this.globals.registerDragDropItem(this);
    const initialSorting: SortMeta[] = [];
    initialSorting.push({ field: 'type', order: -1 });
    initialSorting.push({ field: 'name', order: 1 });
    const cols: C4GridColumn[] = [
      {
        field: 'type',
        header: 'teams.type',
        width: '5em',
        minWidth: '5m',
        priority: 3,
        cssClass: 'c4-center-icon',
        sortable: false,
        template: this.fileType,
      },
      {
        field: 'name',
        header: 'teams.name',
        width: '4*',
        minWidth: '8em',
        priority: 1,
        sortable: true,
        template: this.fileName,
      },
      {
        field: 'contextmenu',
        header: 'teams.none',
        width: '4em',
        minWidth: '4em',
        priority: 1,
        sortable: false,
        template: this.contextMenuButton,
      },
      {
        field: 'lastModifiedDateTime',
        header: 'teams.changed',
        width: '3*',
        minWidth: '10em',
        priority: 3,
        sortable: true,
        template: this.docEditor,
        mobileTemplate: this.docEditorMobile,
      },
      {
        field: 'size',
        header: 'teams.size',
        width: '1*',
        minWidth: '6em',
        priority: 2,
        sortable: true,
        pipe: this.fileSize,
        template: this.size,
      },
      {
        field: 'download',
        header: 'teams.download',
        width: '8em',
        minWidth: '8em',
        priority: this.enablePreview ? 3 : 1,
        sortable: false,
        template: this.download,
        cssClass: 'c4-center-label',
      },
    ];

    if (this.selectionMode == GridSelectionMode.multiple) {
      cols.unshift({
        field: 'select',
        header: 'teams.none',
        width: '4em',
        minWidth: '4m',
        priority: 4,
        cssClass: 'c4-center-icon',
        sortable: false,
        template: this.selectRow,
        headerTemplate: this.selectAll,
      });
    }

    if (this.enablePreview) {
      cols.splice(
        cols.findIndex(x => x.field == 'name'),
        0,
        {
          field: 'c4_grid_thumbnail',
          header: 'teams.thumbnail',
          width: '8em',
          minWidth: '8em',
          priority: 1,
          sortable: false,
          template: this.fileThumbnail,
          cssClass: 'c4-thumbnail c4-center-cell',
        }
      );
    }

    this.gridDef = {
      grid: {
        filterRow: false,
        globalFilter: false,
        responsive: true,
        rowExpand: true,
        paging: true,
        rows: 50,
        rowsOptions: [5, 10, 25, 50, 100],
        lazy: false,
        lazyInit: false,
      },
      row: {
        link: false,
        path: '',
        param: '',
      },
      cols: cols,
      initialSorting,
    };

    this.subscribe(this.documentsService.driveItems$, driveItems => {
      this.gridDef.grid.rowCount = driveItems?.length ?? 0;
    });

    this.subscribe(this.documentsService.path$, _ => {
      if (this.grid) this.grid.goToFirstPage();
    });

    this.subscribe(this.offlineService.projectStatusChanged, _ => {
      this.isOffline = this.offlineService.isProjectOffline();
    });
  }

  //!temporary workaround!
  //Todo check if primeng update solves issue (https://github.com/primefaces/primeng/issues/9893)
  @HostListener('keydown.space', ['$event'])
  onSpaceKeyDown(event: KeyboardEvent) {
    event.stopPropagation();
    event.preventDefault();
    if (event.target) {
      event.target['value'] = event.target['value'] + ' ';
      (event.target as HTMLElement).focus();
    }
  }

  ngOnDestroy() {
    // this.disabled = true;
    this.globals.unregisterDragDropItem(this);
    // this._rootPath = '';
    // this.resource = null;
    return super.ngOnDestroy();
  }

  selectDriveItems(e: Event) {
    e.stopImmediatePropagation();
    this.documentsService.selectDriveItems(this.grid.dataTable.first, this.grid.dataTable.first + this.grid.dataTable.rows);
  }

  toogleDriveItemSelection(item: DriveItemModel) {
    this.documentsService.toggleDriveItemSelection(item);
  }

  previewDriveItem(driveItem: DriveItemModel) {
    this.documentsService.previewDriveItem(driveItem);
  }

  canMove(driveItem: DriveItemModel) {
    return this.supportedMoveModuleTypes.includes(driveItem.resourceIdentifier.moduleType);
  }

  isFolder(driveItem: DriveItemModel) {
    return driveItem.type === DriveItemType.Folder;
  }

  navigateToFolder(driveItem: DriveItemModel) {
    this.documentsService.navigateToDriveItem(driveItem);
  }

  onDragLeave(event) {
    this.enableDragDrop(event, false);
  }

  onDragEnd(event) {
    this.enableDragDrop(event, false);
  }

  onDragOver(event) {
    this.enableDragDrop(event, true);
  }

  onDragEnter(event) {
    this.enableDragDrop(event, true);
  }

  private enableDragDrop(event, isDragDrop: boolean) {
    if (!this.documentsService.canAddFile || !this.documentsService.dataReady) return;

    this.isDragDrop = isDragDrop;
    if (isDragDrop && event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';
      event.dataTransfer.effectAllowed = 'copy';
    }
    event.stopPropagation();
    event.preventDefault();
  }

  async onDrop(event: DragEvent) {
    this.enableDragDrop(event, false);
    this.documentsService.dropFiles(event);
  }

  async downloadDriveItem(driveItem: DriveItemModel) {
    await this.documentsService.downloadDriveItem(driveItem);
  }

  async shareDriveItem(driveItem: DriveItemModel) {
    await this.documentsService.shareDriveItem(driveItem);
  }

  async generateZipDownloadUrl(driveItem: DriveItemModel) {
    await this.documentsService.generateZipDownloadUrl([driveItem]);
  }

  async deleteDriveItem(driveItem: DriveItemModel) {
    await this.documentsService.deleteDriveItem(driveItem);
  }

  async markDriveItemAsRead(driveItem: DriveItemModel) {
    await this.documentsService.markDriveItemAsRead(driveItem);
  }

  async signDriveItem(driveItem: DriveItemModel) {
    await this.documentsService.signDriveItem(driveItem);
  }

  async getLog(driveItem: DriveItemModel) {
    await this.documentsService.getLog(driveItem);
  }

  async getPrivileges(driveItem: DriveItemModel) {
    await this.documentsService.getPrivileges(driveItem);
  }

  async renameDriveItem(driveItem: DriveItemModel) {
    await this.documentsService.renameDriveItemName(driveItem);
  }
}
