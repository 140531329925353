import { Component, Input, EventEmitter, Output } from '@angular/core';
import { ApiService, TranslationManagementService } from '@app/core';
import { NotificationCardData } from './notification-card-data';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import {
  DriveItemIdentifierModel,
  NotificationAttachmentModel,
  NotificationSendType,
  NotificationState,
  NotificationStateModel,
  NotificationType,
  ShareDriveModel,
  TextFormatType,
} from '@app/api';
import { DocumentDownloadService, UserNotificationService } from '@app/shared/services';
import { MailNotificationDialogComponent } from '../dialogs/mail-notification-dialog/mail-notification-dialog.component';
import { ApiNotificationCall } from '../dialogs/mail-notification-dialog/mail-notification-dialog.interfaces';

export const NotificationStateIcon = {
  [NotificationState.Created]: 'mdi mdi-send-clock',
  [NotificationState.Sent]: 'mdi mdi-send-clock sent',
  [NotificationState.Successful]: 'mdi mdi-send-check success',
  [NotificationState.Error]: 'mdi mdi-send error',
};

@Component({
  selector: 'app-notification-card',
  templateUrl: './notification-card.component.html',
  styleUrls: ['./notification-card.component.scss'],
})
export class NotificationCardComponent {
  @Input() hideReceiver: boolean;
  @Input() data: NotificationCardData;
  @Input() rawHtmlBody: string;
  @Input() notificationType: NotificationSendType = NotificationSendType.Input;
  @Input() showActionButtons: boolean = true;
  @Output() deleteEvent = new EventEmitter<string>();
  @Output() reloadEvent = new EventEmitter<boolean>();

  NotificationSendType = NotificationSendType;
  NotificationState = NotificationState;

  constructor(
    public translate: TranslationManagementService,
    private dialog: MatDialog,
    protected apiService: ApiService,
    private userNotification: UserNotificationService,
    private downloadService: DocumentDownloadService
  ) {}

  get highlightClass(): string {
    let classString =
      this.data && this.data.highlight && this.notificationType && this.notificationType == NotificationSendType.Input
        ? 'app-highlight-notification'
        : '';
    if (
      this.notificationType == NotificationSendType.Output &&
      this.data.notificationStateList &&
      this.data.notificationStateList.find(x => x.notificationState != NotificationState.Successful)
    )
      classString += ' warning';

    return classString;
  }

  get busyClass(): string {
    return !this.data || this.data.isSkeleton ? 'c4-busy' : '';
  }

  get busyContentClass(): string {
    return !this.data || this.data.isSkeleton ? 'c4-busy-content' : '';
  }

  get showForwardButton(): boolean {
    let result = false;
    this.notificationType == NotificationSendType.Output &&
    this.data.notificationType == NotificationType.ExternalDriveChangeNotification
      ? (result = true)
      : (result = false);

    return result;
  }

  get showResendButton(): boolean {
    let result = false;

    this.notificationType == NotificationSendType.Output &&
    this.data.notificationType == NotificationType.ExternalDriveChangeNotification &&
    this.data.notificationState != NotificationState.Successful
      ? (result = true)
      : (result = false);

    return result;
  }

  getNotificationIcon(model: NotificationStateModel): string {
    return NotificationStateIcon[model.notificationState];
  }

  async delete(e: Event) {
    e.stopPropagation();

    const dialogResult = await this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'general.notifications.deleteConfirmCaption',
          description: 'general.notifications.deleteConfirmDescription',
        },
      })
      .afterClosed()
      .toPromise();

    if (dialogResult) {
      this.deleteEvent.emit(this.data.id);
    }
  }

  async resend(e: Event) {
    e.stopPropagation();

    try {
      this.apiService.resendEmail(this.data.id);
      this.userNotification.notify('general.notifications.notificationSent');
    } catch (e) {
      this.userNotification.notify('general.errorMsg.share');
    }
  }

  async forward(e: Event) {
    e.stopPropagation();
    const changeSet = await this.apiService.getChangeSet(this.data.id, this.data.sendType);
    if (changeSet && changeSet.length > 0) {
      const dialogResult = await this.dialog
        .open(MailNotificationDialogComponent, {
          data: {
            itemNames: changeSet.map(c => c.driveItemName),
            userEvent: true,
            message: this.rawHtmlBody,
            subject: this.data.subject,
            isCustomMailAddressSupported: true,
            sendNotification: this.getNotificationCall(
              changeSet.map(
                c =>
                  new DriveItemIdentifierModel({
                    driveItemId: c.driveItemId,
                    resource: c.resourceKey.name,
                  })
              )
            ),
          },
          disableClose: true,
        })
        .afterClosed()
        .toPromise();

      if (dialogResult) {
        this.reloadEvent.emit();
      }
    }
  }

  async downloadFile(file: NotificationAttachmentModel) {
    var id = file.attachmentId;
    var fileName = file.fileName;

    await this.downloadService.downloadAttachment({
      id,
      fileName,
    });
  }

  private getNotificationCall(items: DriveItemIdentifierModel[]): ApiNotificationCall {
    return async model => {
      return await this.apiService.shareFileNotifaction(
        new ShareDriveModel({
          to: model.to,
          message: model.message ?? '',
          subject: model.subject ?? '',
          format: TextFormatType.Text,
          items: items,
        })
      );
    };
  }
}
