import { ErrorHandlerOptions, SentryErrorHandler } from '@sentry/angular-ivy';

export class GlobalErrorHandler extends SentryErrorHandler {
  private reloadPending = false;

  constructor(options?: ErrorHandlerOptions) {
    super(options);
  }

  notifyReloadPending() {
    this.reloadPending = true;
  }

  handleError(error: any) {
    if (error?.message && /ChunkLoadError/i.test(error.message)) {
      // hide error in console, beautification only
      if (this.reloadPending) {
        return;
      }

      window.location.reload();
      return;
    }

    // Comment this in if you need to debug Offline-Service
    // fetch('/api/debugging/dump', {
    //   body: JSON.stringify(
    //     new DumpModel({
    //       texts: [error.message],
    //     })
    //   ),
    //   method: 'POST',
    //   headers: {
    //     "content-type": "application/json"
    //   }
    // });

    super.handleError(error);
  }
}

export function createGlobalErrorHandler(options?: ErrorHandlerOptions) {
  return new GlobalErrorHandler(options);
}
