import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { PrivilegeEnum } from '@app/api';
import { ApiService } from '..';
import { ProjectService } from '../services';

@Injectable({
  providedIn: 'root',
})
export class ProjectAssignmentGuard {
  constructor(private apiService: ApiService, private router: Router, private projectService: ProjectService) {}

  async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const userSession = await this.apiService.getUserSession();

    if (userSession == null) {
      return this.router.createUrlTree(['login']);
    }

    let privileges = await this.apiService.getUserPrivileges();

    let projectId = this.projectService.projectId$.getValue();
    if (projectId) {
      const queryParams = JSON.parse(JSON.stringify(route.queryParams));
      const queryProjectId = queryParams.companyId;
      if (queryProjectId) {
        queryParams.companyId = null;
        projectId = queryProjectId;
      }

      return this.router.createUrlTree(['projects', projectId, ...route.url.map(segment => segment.path)], {
        queryParams,
      });
    }

    return this.router.createUrlTree(['projects']);
  }
}
