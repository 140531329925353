import { Component, forwardRef, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {
  ConstructionDiaryModel,
  DefectModel,
  DriveItemModel,
  IFileUploadDestination,
  IssueModel,
  ModuleType,
  PrivilegeEnum,
  RoomModel,
} from '@app/api';
import { ApiService, AppRoutingData, GlobalsService, LogService } from '@app/core';
import { DocumentsService, UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';

export interface DriveItemActionTarget {
  path?: string;
  entityId?: string;
  resource?: string;
}

export interface DriveItemActionDialogResultModel<T> {
  status: DialogResultModelStatus;
  actionResult?: T;
}

export enum DialogResultModelStatus {
  success = 'success',
  cancel = 'cancel',
  skip = 'skip',
}

export type DriveItemAction<T> = (target: DriveItemActionTarget) => Promise<T>;

const SUPPORTED_MODULES = [
  ModuleType.Document,
  ModuleType.Bim,
  ModuleType.RoomBook,
  ModuleType.ConstructionDiary,
  ModuleType.Defect,
];

interface ModuleItem {
  icon: string;
  resource: string;
}

interface ModuleData {
  iconUrl: string;
  resource?: string;
  items?: ModuleItem[];
}

@Component({
  selector: 'app-drive-item-action-dialog',
  templateUrl: './drive-item-action-dialog.component.html',
  styleUrls: ['./drive-item-action-dialog.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [forwardRef(() => DocumentsService)],
})
export class DriveItemActionDialogComponent<T> implements OnInit, OnDestroy, Busy {
  ModuleType = ModuleType;

  isBusy: boolean;
  title: string;
  icon: string;
  confirmLabel: string;
  cancelLabel: string;
  skipLabel: string;

  moduleData: Partial<Record<ModuleType, ModuleData>> = {};
  singleItemModules: ModuleType[] = [];
  groupItemModules: ModuleType[] = [];
  selectedModule: ModuleType;

  galleryIcon = AppRoutingData.gallery.icon;
  defects: DefectModel[];
  diaries: ConstructionDiaryModel[];
  issues: IssueModel[];
  rooms: RoomModel[];
  selectedEntity: Entity;

  private forbiddenDriveItems?: DriveItemModel[];
  private initialLocation: IFileUploadDestination;
  private privileges: PrivilegeEnum[] = [];
  private availableModules: ModuleType[];
  private selectedResource: string;
  private action: DriveItemAction<T>;
  private onError: (error?: any) => void;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private apiService: ApiService,
    private dialogRef: MatDialogRef<DriveItemActionDialogComponent<T>>,
    private globals: GlobalsService,
    private logService: LogService,
    private userNotification: UserNotificationService,
    public documentsService: DocumentsService
  ) {
    this.title = this.confirmLabel = data?.title ?? 'general.select';
    if (data?.confirmLabel) this.confirmLabel = data.confirmLabel;
    this.cancelLabel = data?.cancelLabel ?? 'general.cancel';
    this.skipLabel = data?.skipLabel;
    this.icon = data?.icon ?? 'mdi-check';
    this.availableModules = data?.modules?.distinct() ?? [];
    this.action = data?.action ?? this.doNothing;
    this.onError = data?.onError ?? this.doNothing;
    this.forbiddenDriveItems = data?.forbiddenDriveItems ?? [];
    this.initialLocation = data?.location?.resource
      ? {
          resource: data.location.resource,
          path: data.location.path ?? '',
        }
      : null;
  }

  get isExpanded() {
    return !this.globals.isPhone;
  }

  async ngOnInit() {
    this.privileges = await this.apiService.getUserPrivileges();
    // no need to await response (Promise.all(response);) to load data in background
    await this.loadNecessaryEntities();
    await this.initSideMenu(this.initialLocation);
  }

  ngOnDestroy() {
    this.documentsService.dispose();
  }

  async confirm() {
    await using(new BusyScope(this), async _ => {
      const actionResult = await this.action({
        path: this.selectedModule == ModuleType.Document ? this.documentsService.currentPath : undefined,
        entityId: this.selectedEntity?.id,
        resource: this.selectedResource,
      });

      const result: DriveItemActionDialogResultModel<T> = {
        actionResult,
        status: DialogResultModelStatus.success,
      };

      this.dialogRef.close(result);
    }).catch(e => this.onError(e));
  }

  close() {
    const result: DriveItemActionDialogResultModel<T> = {
      status: DialogResultModelStatus.cancel,
    };

    this.dialogRef.close(result);
  }

  skip() {
    const result: DriveItemActionDialogResultModel<T> = {
      status: DialogResultModelStatus.skip,
    };

    this.dialogRef.close(result);
  }

  selectModule(module: ModuleType, resource: string, path: string = '') {
    this.selectedEntity = null;
    this.selectedModule = module;
    this.selectedResource = resource;

    if (module == ModuleType.Document) {
      this.documentsService.navigateToPath(path, resource);
    }
  }

  private hasPrivileges(privileges: PrivilegeEnum[]) {
    return privileges.every(p => this.privileges.contains(p));
  }

  private getIconUrlForModule(module: ModuleType) {
    switch (module) {
      case ModuleType.Document:
        return AppRoutingData.documents.iconUrl;
      case ModuleType.Defect:
        return AppRoutingData.defects.iconUrl;
      case ModuleType.ConstructionDiary:
        return AppRoutingData.diary.iconUrl;
      case ModuleType.Bim:
        return AppRoutingData.bim.iconUrl;
      case ModuleType.RoomBook:
        return AppRoutingData.rooms.iconUrl;
    }
  }

  private async initSideMenu(initialLocation: IFileUploadDestination = null) {
    const resourceIdentifiers = await this.apiService.getResourceIdentifiers();

    for (const module of SUPPORTED_MODULES) {
      if (module == ModuleType.Document) {
        if (!this.availableModules.includes(ModuleType.Document)) continue;

        const portalResources = resourceIdentifiers.filter(i => i.moduleType === ModuleType.Document).map(i => i.key.name);
        const isSingleResource = portalResources.length < 2;
        this.moduleData[module] = {
          iconUrl: this.getIconUrlForModule(module),
          resource: isSingleResource ? portalResources[0] : null,
          items: isSingleResource
            ? null
            : portalResources.map(resource => ({
                icon: 'mdi-folder-outline',
                resource,
              })),
        };

        if (isSingleResource) this.singleItemModules.push(ModuleType.Document);
        else this.groupItemModules.push(ModuleType.Document);
      } else {
        if (!this.availableModules.includes(module)) continue;

        this.moduleData[module] = {
          iconUrl: this.getIconUrlForModule(module),
          resource: resourceIdentifiers.find(i => i.moduleType === module).key.name,
        };

        this.singleItemModules.push(module);
      }
    }

    if (initialLocation && this.availableModules.includes(ModuleType.Document)) {
      this.selectModule(ModuleType.Document, initialLocation.resource, initialLocation.path);
    } else if (this.singleItemModules.length > 0) {
      this.selectModule(this.singleItemModules[0], this.moduleData[this.singleItemModules[0]].resource);
    } else if (this.groupItemModules.length > 0) {
      this.selectModule(this.groupItemModules[0], this.moduleData[this.groupItemModules[0]].items[0].resource);
    } else {
      this.userNotification.notify('dialogs.driveItemAction.missingPrivileges');
      this.dialogRef.close({ status: DialogResultModelStatus.cancel });
    }
  }

  private async loadNecessaryEntities(): Promise<Promise<void>[]> {
    const entitiesToLoad: Promise<void>[] = [];

    for (const module of SUPPORTED_MODULES) {
      if (this.availableModules.includes(module)) {
        switch (module) {
          case ModuleType.Document:
            this.documentsService.driveItemIterator = driveItem => {
              if (this.forbiddenDriveItems.some(x => x.id == driveItem.id)) {
                driveItem.rowClass = 'awesome-class';
              }
            };
            await this.documentsService.initialize(ModuleType.Document);
            break;
          case ModuleType.Defect:
            entitiesToLoad.push(this.loadDefects());
            break;
          case ModuleType.ConstructionDiary:
            entitiesToLoad.push(this.loadDiaries());
            break;
          case ModuleType.Bim:
            entitiesToLoad.push(this.loadIssues());
            break;
          case ModuleType.RoomBook:
            entitiesToLoad.push(this.loadRooms());
            break;
        }
      }
    }

    return entitiesToLoad;
  }

  private async loadDefects() {
    try {
      const canReadDefects = this.hasPrivileges([PrivilegeEnum.ReadDefects]);
      this.defects = canReadDefects ? await this.apiService.getDefectsForProject() : [];
    } catch (e) {
      this.logService.error('Failed to load defects', e);
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.defects', e);
    }
  }

  private async loadDiaries() {
    try {
      const canReadDiaries = this.hasPrivileges([PrivilegeEnum.ReadConstructionDiary]);
      this.diaries = canReadDiaries ? await this.apiService.getDiariesForProject() : [];
    } catch (e) {
      this.logService.error('Failed to load diaries', e);
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.diary', e);
    }
  }

  private async loadIssues() {
    try {
      const canReadIssues = this.hasPrivileges([PrivilegeEnum.ReadIssues]);
      this.issues = canReadIssues ? await this.apiService.getIssuesForProject() : [];
    } catch (e) {
      this.logService.error('Failed to load issues', e);
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.issues', e);
    }
  }

  private async loadRooms() {
    try {
      const canReadRoomBook = this.hasPrivileges([PrivilegeEnum.ReadRoomBook]);
      this.rooms = canReadRoomBook ? await this.apiService.getRoomsForProject() : [];
    } catch (e) {
      this.logService.error('Failed to load rooms', e);
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.rooms', e);
    }
  }

  private doNothing() {}
}
