import { Component, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatSidenav } from '@angular/material/sidenav';
import { ActivatedRoute, Router } from '@angular/router';
import { ModuleType, PrivilegeEnum, TenantInfoModel } from '@app/api';
import {
  ApiService,
  AppConfigService,
  AppRoutingData,
  AuthenticationService,
  BaseSubscriptionComponent,
  GlobalsService,
  Link,
  OfflineService,
  pathTo,
  pathToAdmin,
  ProjectService,
  RoutingData,
} from '@app/core';
import { SideBarService } from '@app/core/services/globals/side-bar.service';
import { ChangeCompanyComponent } from '../../change-company/change-company.component';
import { SidebarNavigationItemGroupComponent } from '../../sidebar-navigation-item-group/sidebar-navigation-item-group.component';

interface MenuItem extends MenuGroupHeader {
  link: string;
}

interface MenuGroupHeader {
  image?: string;
  icon?: string;
  label: string;
}

interface MenuGroupItem {
  link: string;
  icon: string;
  label: string;
}

interface MenuGroupConfiguration {
  header: MenuGroupHeader;
  items: MenuGroupItem[];
}

enum PageType {
  overview = 'overview',
  project = 'project',
  admin = 'admin',
}

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent extends BaseSubscriptionComponent implements OnInit {
  @ViewChild('sidenav', { static: true }) sidenav: MatSidenav;
  @ViewChildren(SidebarNavigationItemGroupComponent) itemGroups: QueryList<SidebarNavigationItemGroupComponent>;

  PageType = PageType;

  isMinified = true;
  isOffline = false;
  importantLinks: Link[] = [];
  currentPage: PageType = PageType.overview;
  isAdmin = false;
  pathToAdmin = pathToAdmin();
  isProjectAdmin = false;
  pathToProjectSettings: string;

  adminDashboardItem = AppRoutingData.dashboard;
  adminProjectsMenuGroup: MenuGroupConfiguration = this.getAdminProjectsMenuGroup();
  adminAccessMenuGroup: MenuGroupConfiguration = this.getAdminAccessMenuGroup();
  adminBimMenuGroup: MenuGroupConfiguration = this.getAdminBimMenuGroup();
  adminRoomBookMenuGroup: MenuGroupConfiguration = this.getAdminRoomBookMenuGroup();
  adminLeanMenuGroup: MenuGroupConfiguration = this.getAdminLeanMenuGroup();
  adminDefectsMenuGroup: MenuGroupConfiguration = this.getAdminDefectsMenuGroup();
  adminSettingsMenuGroup: MenuGroupConfiguration = this.getAdminSettingsMenuGroup();

  items: Record<string, MenuItem> = {};
  documentMenuGroup: MenuGroupConfiguration;
  bimMenuGroup: MenuGroupConfiguration;
  roomMenuGroup: MenuGroupConfiguration;
  leanMenuGroup: MenuGroupConfiguration;
  teamMenuGroup: MenuGroupConfiguration;

  projectId: string;
  projectName: string;
  tenantName: string;
  otherTenants: TenantInfoModel[] = [];

  constructor(
    private apiService: ApiService,
    private authService: AuthenticationService,
    private dialog: MatDialog,
    private offlineService: OfflineService,
    private projectService: ProjectService,
    private route: ActivatedRoute,
    private router: Router,
    private sideBar: SideBarService,
    public globals: GlobalsService
  ) {
    super();
  }

  ngOnInit() {
    this.subscribe(this.projectService.tenant$, async tenant => {
      this.tenantName = tenant.name;
      this.otherTenants = await this.apiService.getOtherTenants();
    });

    this.subscribe(this.projectService.projectId$, async projectId => {
      this.items = {};
      this.projectId = projectId;
      this.isOffline = this.offlineService.isProjectOffline();
      this.currentPage = PageType.admin;
      if (!this.route.snapshot.data?.isInAdminArea) this.currentPage = !projectId ? PageType.overview : PageType.project;

      const privileges = await this.apiService.getUserPrivileges();
      this.isAdmin = privileges.includes(PrivilegeEnum.Admin);
      this.isProjectAdmin = privileges.includes(PrivilegeEnum.ReadWriteProjectSettings);

      if (projectId) {
        this.pathToProjectSettings = pathTo(projectId, AppRoutingData.projectSettings.path);

        const project = await this.apiService.getProject(projectId);
        this.projectName = project.name;

        this.items = {
          dashboard: this.getMenuItem(AppRoutingData.dashboard, projectId, privileges),
          gallery: this.getMenuItem(AppRoutingData.gallery, projectId, privileges),
          planning: this.getMenuItem(AppRoutingData.planning, projectId, privileges),
          diary: this.getMenuItem(AppRoutingData.diary, projectId, privileges),
          defects: this.getMenuItem(AppRoutingData.defects, projectId, privileges),
          notifications: this.getMenuItem(AppRoutingData.notifications, projectId, privileges),
        };

        await this.updateMenuGroups(projectId, privileges);
      }
    });

    this.subscribe(this.authService.projectUpdated, async projectId => {
      const project = await this.apiService.getProject(projectId);
      this.projectName = project.name;
    });

    this.subscribe(this.offlineService.projectStatusChanged, async _ => {
      this.isOffline = this.offlineService.isProjectOffline();
    });

    this.importantLinks = AppConfigService.settings.importantLinks ?? [];

    if (this.globals.isMobileMenu) this.sideBar.close();
    this.subscribe(this.sideBar.sideBarOpenState$, isOpen => {
      if (isOpen) this.sidenav.open();
      else this.sidenav.close();
    });

    this.subscribe(this.sideBar.sideBarMinifiedState$, isMinified => {
      this.isMinified = isMinified;
    });
  }

  changeTenant(item: TenantInfoModel) {
    window.open(!/^https?:\/\//i.test(item.publicUrl) ? `https://${item.publicUrl}` : item.publicUrl, '_blank');
  }

  async changeProject() {
    await this.dialog.open(ChangeCompanyComponent).afterClosed().toPromise();
  }

  sidenavOpened() {
    this.sideBar.open();
  }

  sidenavClosed() {
    this.sideBar.close();
  }

  backdropClick() {
    this.sideBar.close();
  }

  toggleNavSize() {
    if (this.globals.isMobileMenu) {
      this.sideBar.close();
    } else {
      this.sideBar.toggleMinifiedState(true);
    }
  }

  closeSidebarInMobileView(c4Sidenav: MatSidenav) {
    if (this.globals.isMobileMenu) {
      c4Sidenav.close();
      (document.activeElement as HTMLElement).blur();
    }
  }

  closeExcept(item: SidebarNavigationItemGroupComponent) {
    for (const group of this.itemGroups) {
      if (group != item) group.isOpen = false;
    }
  }

  private async updateMenuGroups(projectId: string, privileges: PrivilegeEnum[]) {
    this.documentMenuGroup = await this.getDocumentMenuGroup(projectId, privileges);
    this.bimMenuGroup = this.getBimMenuGroup(projectId, privileges);
    this.roomMenuGroup = this.getRoomBookMenuGroup(projectId, privileges);
    this.leanMenuGroup = this.getLeanMenuGroup(projectId, privileges);
    this.teamMenuGroup = this.getTeamMenuGroup(projectId, privileges);
  }

  private getMenuItem(routingData: RoutingData, projectId: string, privileges: PrivilegeEnum[]): MenuItem {
    if (routingData.privileges?.length && routingData.privileges.some(privilege => !privileges.includes(privilege)))
      return null;

    return {
      image: routingData.iconUrl,
      label: `${routingData.path.replace('/', '.')}.title`,
      link: pathTo(projectId, routingData.path),
    };
  }

  private getMenuGroupItem(route: RoutingData, projectId: string = null) {
    const prefix = projectId ? '' : 'admin.';
    const path = projectId ? route.path : route.adminPath ?? route.path;

    return {
      link: projectId ? pathTo(projectId, path) : pathToAdmin(path),
      icon: route.icon,
      label: `${prefix}${path.replace('/', '.')}.title`,
    };
  }

  private getMenuGroupItems(routes: RoutingData[], projectId: string, privileges: PrivilegeEnum[]): MenuGroupItem[] {
    return routes
      .filter(route => !route.privileges || route.privileges.every(privilege => privileges.includes(privilege)))
      .map(allowedRoute => this.getMenuGroupItem(allowedRoute, projectId));
  }

  private getAdminProjectsMenuGroup(): MenuGroupConfiguration {
    return {
      header: {
        icon: 'mdi-warehouse',
        label: 'admin.menu.projects.title',
      },
      items: [AppRoutingData.projects, AppRoutingData.templates, AppRoutingData.schemata].map(route =>
        this.getMenuGroupItem(route)
      ),
    };
  }

  private getAdminAccessMenuGroup(): MenuGroupConfiguration {
    return {
      header: {
        icon: 'mdi-account-supervisor-circle',
        label: 'admin.menu.access.title',
      },
      items: [AppRoutingData.users, AppRoutingData.organizations].map(route => this.getMenuGroupItem(route)),
    };
  }

  private getAdminBimMenuGroup(): MenuGroupConfiguration {
    return {
      header: {
        image: AppRoutingData.bim.iconUrl,
        label: 'ifc.group.title',
      },
      items: [AppRoutingData.issueLabels, AppRoutingData.issueStages, AppRoutingData.issueTypes].map(route =>
        this.getMenuGroupItem(route)
      ),
    };
  }

  private getAdminRoomBookMenuGroup(): MenuGroupConfiguration {
    return {
      header: {
        image: AppRoutingData.rooms.iconUrl,
        label: 'roomBook.group.title',
      },
      items: [AppRoutingData.roomTemplates, AppRoutingData.attributes, AppRoutingData.categories].map(route =>
        this.getMenuGroupItem(route)
      ),
    };
  }

  private getAdminLeanMenuGroup(): MenuGroupConfiguration {
    return {
      header: {
        image: AppRoutingData.lean.iconUrl,
        label: 'lean.title',
      },
      items: [AppRoutingData.phases, AppRoutingData.workpackageTemplates, AppRoutingData.workpackageSequences].map(route =>
        this.getMenuGroupItem(route)
      ),
    };
  }

  private getAdminDefectsMenuGroup(): MenuGroupConfiguration {
    return {
      header: {
        image: AppRoutingData.defects.iconUrl,
        label: `${AppRoutingData.defects.path}.title`,
      },
      items: [AppRoutingData.defectReasons, AppRoutingData.defectTypes].map(route => this.getMenuGroupItem(route)),
    };
  }

  private getAdminSettingsMenuGroup(): MenuGroupConfiguration {
    return {
      header: {
        icon: 'mdi-cogs',
        label: 'admin.menu.general.title',
      },
      items: [AppRoutingData.setup, AppRoutingData.crafts, AppRoutingData.standardTexts].map(route =>
        this.getMenuGroupItem(route)
      ),
    };
  }

  private async getDocumentMenuGroup(projectId: string, privileges: PrivilegeEnum[]): Promise<MenuGroupConfiguration> {
    if (
      AppRoutingData.documents.privileges?.length &&
      AppRoutingData.documents.privileges.some(privilege => !privileges.includes(privilege))
    ) {
      return null;
    }

    const portalResources = await this.getPortalResources();

    return {
      header: {
        image: AppRoutingData.documents.iconUrl,
        label: `${AppRoutingData.documents.path}.title`,
      },
      items: portalResources.map(resource => ({
        link: pathTo(projectId, `${AppRoutingData.documents.path}/${resource}`),
        icon: AppRoutingData.documents.icon,
        label: resource,
      })),
    };
  }

  private getBimMenuGroup(projectId: string, privileges: PrivilegeEnum[]): MenuGroupConfiguration {
    const items: MenuGroupItem[] = this.getMenuGroupItems([AppRoutingData.ifc, AppRoutingData.bim], projectId, privileges);

    return !items?.length
      ? null
      : {
          header: {
            image: AppRoutingData.bim.iconUrl,
            label: 'ifc.group.title',
          },
          items,
        };
  }

  private getRoomBookMenuGroup(projectId: string, privileges: PrivilegeEnum[]): MenuGroupConfiguration {
    const items: MenuGroupItem[] = this.getMenuGroupItems(
      [AppRoutingData.rooms, AppRoutingData.roomTemplates, AppRoutingData.attributes, AppRoutingData.categories],
      projectId,
      privileges
    );

    return !items?.length
      ? null
      : {
          header: {
            image: 'assets/icons/build365-raumbuch.svg',
            label: 'roomBook.group.title',
          },
          items,
        };
  }

  private getLeanMenuGroup(projectId: string, privileges: PrivilegeEnum[]): MenuGroupConfiguration {
    const items: MenuGroupItem[] = this.getMenuGroupItems(
      [
        AppRoutingData.scheduler,
        AppRoutingData.workpackages,
        AppRoutingData.workpackageTemplates,
        AppRoutingData.workpackageSequences,
        AppRoutingData.specialDates,
      ],
      projectId,
      privileges
    );

    return !items?.length
      ? null
      : {
          header: {
            image: 'assets/icons/build365-lean-prozesse.svg',
            label: 'lean.title',
          },
          items,
        };
  }

  private getTeamMenuGroup(projectId: string, privileges: PrivilegeEnum[]): MenuGroupConfiguration {
    const items: MenuGroupItem[] = this.getMenuGroupItems(
      [AppRoutingData.team, AppRoutingData.organizations],
      projectId,
      privileges
    );

    return !items?.length
      ? null
      : {
          header: {
            image: 'assets/icons/no-poverty.svg',
            label: 'team.title',
          },
          items,
        };
  }

  private async getPortalResources() {
    const resourceIdentifiers = this.projectId ? await this.apiService.getResourceIdentifiers() : [];

    return resourceIdentifiers
      .filter(i => i.moduleType === ModuleType.Document)
      .map(i => i.key.name)
      .sort();
  }
}
