<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: false }"></ngx-loading>
<!-- <div class="add-issue-mobile" *ngIf="!smallList && globals.isMobileMenu">
  <div class="add-issue-btn" [matTooltip]="'issues.header.newissue' | translate" (click)="createItem(null)">
    <span class="mdi mdi-plus-box"></span>
    <span class="add-issue-label">{{'issues.editTitle' | translate}}</span>
  </div>
</div> -->
<div class="c4-grid-container issue" [class.c4-busy]="!issuesHolder?.dataReady || isBusy">
	<c4-grid *ngIf="gridDef" [gridDef]="gridDef" [source]="issuesHolder.data" [noDataText]="'grid.issues.noDataText'"
					 [selectionMode]="selectionMode" [(selection)]="selection">
	</c4-grid>
</div>

<ng-template #state let-row='row'>
	<div class="state-container">
		<app-state-circle [hexColor]="row.currentState.color"></app-state-circle>
		<div class="title">{{ row.currentState.title }}</div>
	</div>
</ng-template>

<ng-template #actions let-col='col' let-row='row'>
	<div class="c4-no-focus">
		<button type="button" mat-icon-button class="c4-grid-btn" aria-label="Edit" (click)="editItem(row.id)"
						[matTooltip]="'general.edit' | translate">
			<span class="mdi mdi-square-edit-outline"></span>
		</button>
	</div>
</ng-template>

<!-- <ng-template #selectAll let-col='col'>
	<div class="c4-no-focus offset-left">
		<button mat-icon-button class="c4-grid-btn" (click)="allRowsSelect($event)">
			<span class="mdi mdi-check-all"></span>
		</button>
	</div>
</ng-template> -->

<!-- <ng-template #selectRow let-col='col' let-row='row'>
	<div class="c4-no-focus offset-left">
		<button mat-icon-button class="c4-grid-btn" (click)="rowSelect(row)">
			<span class="mdi mdi-check"></span>
		</button>
	</div>
</ng-template> -->

<ng-template #nameCol let-col='col' let-row='row'>
	<div class="c4-name-column" (click)="editItem(row.id)">
		<div class="header">{{row.title}}</div>
		<div class="sub-header" [matTooltip]='row.description' matTooltipClass="multi-line">{{ stripHtml(row.description) }}
		</div>
	</div>
</ng-template>

<ng-template #overTime let-col='col' let-row='row'>
	<div class="c4-topline" [ngClass]="getColorClass(row[col.field], row)">
		{{ row[col.field] | localizedDate }}
	</div>
</ng-template>

<ng-template #contextMenu let-col='col' let-row='row'>
	<div class="c4-no-focus">
		<button mat-icon-button class="c4-grid-btn" [matMenuTriggerFor]="rowContext" [matMenuTriggerData]="{menudata:row}"
						[matTooltip]="'general.actions.title' | translate">
			<span class="mdi mdi-dots-horizontal"></span>
		</button>
	</div>
</ng-template>
<mat-menu #rowContext="matMenu">
	<ng-template matMenuContent let-menudata='menudata'>
		<button mat-menu-item class="c4-context-menu-item" (click)="editItem(menudata.id)"
						[matTooltip]="'general.edit' | translate">
			<span class="mdi mdi-square-edit-outline"></span>
			<span>{{'issues.editLabel' |translate}}</span>
		</button>
		<button mat-menu-item class="c4-context-menu-item" (click)="createCopyItem(menudata.id)"
						[matTooltip]="'general.copy' | translate">
			<span class="mdi mdi-content-copy"></span>
			<span>{{'issues.copy' | translate}}</span>
		</button>
		<button mat-menu-item class="c4-context-menu-item" (click)="shareItem(menudata.id)"
						[matToolTip]="'planning.header.tool_share' | translate">
			<span class="mdi mdi-share-variant"></span>
			<span>{{'general.share' |translate}}</span>
		</button>
		<button mat-menu-item class="c4-context-menu-item" (click)="reportOfItem(menudata.id)"
						[matToolTip]="'issues.header.tool_report' | translate">
			<span class="mdi mdi-file-pdf-box"></span>
			<span>{{'issues.header.report' |translate}}</span>
		</button>
		<button mat-menu-item class="c4-context-menu-item" (click)="previewImage(menudata)" [disabled]="true"
						[matTooltip]="'general.preview' | translate">
			<ng-container *ngIf="false; else cancel">
				<span class="mdi mdi-file-image"></span>
				<span>{{'issues.imagePreview' |translate}}</span>
			</ng-container>
			<ng-template #cancel>
				<span class="mdi mdi-file-image as-disabled"></span>
				<span class="as-disabled">{{'issues.imagePreview' |translate}}</span>
			</ng-template>
		</button>
	</ng-template>
</mat-menu>