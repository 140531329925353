import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { NotificationStateModel } from '@app/api';
import { ApiService, BaseSubscriptionComponent } from '@app/core';
import { NotificationStateIcon } from '@app/shared/components/notification-card/notification-card.component';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';

@Component({
  selector: 'app-view-log-receivers-dialog',
  templateUrl: './view-log-receivers-dialog.component.html',
  styleUrls: ['./view-log-receivers-dialog.component.scss'],
})
export class ViewLogReceiversDialogComponent extends BaseSubscriptionComponent implements OnInit, Busy {
  isBusy: boolean;
  logId: string;
  resource: string;
  receivers: NotificationStateModel[];

  constructor(
    @Inject(MAT_DIALOG_DATA) data: any,
    private apiService: ApiService,
    private userNotification: UserNotificationService,
    public dialogRef: MatDialogRef<ViewLogReceiversDialogComponent>
  ) {
    super();

    this.logId = data.logId;
    this.resource = data.resource;
  }

  async ngOnInit() {
    await using(new BusyScope(this), async busy => {
      try {
        this.receivers = await this.apiService.getReceiversForShareLog(this.logId, this.resource);
      } catch (e) {
        this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.receivers', e);
      }
    });
  }

  getNotificationIcon(receiver: NotificationStateModel): string {
    return NotificationStateIcon[receiver.notificationState];
  }

  close() {
    this.dialogRef.close();
  }
}
