import { Component } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ApiService, BaseSubscriptionComponent, OfflineService, ProjectService } from '@app/core';
import { ProgressDialogComponent, ProgressDialogResult } from '../../dialogs/progress-dialog/progress-dialog.component';
import { ActivityStepState, ActivityStepWithProgress } from '../../../../core/services/offline/stepWithProgress';
import { SyncErrorAction, SyncErrorDialogComponent } from '../sync-error-dialog/sync-error-dialog.component';
import { CopyInfoDialogComponent } from '../../dialogs/copy-info-dialog/copy-info-dialog.component';
import { KeepAwake } from '@capacitor-community/keep-awake';

@Component({
  selector: 'app-offline-state-button',
  templateUrl: './offline-state-button.component.html',
  styleUrls: ['./offline-state-button.component.scss'],
})
export class OfflineStateButtonComponent extends BaseSubscriptionComponent {
  isOffline: boolean = false;
  errorCount: number = 0;
  projectName: string;
  isBusy = false;

  constructor(
    private apiService: ApiService,
    private offlineService: OfflineService,
    private dialog: MatDialog,
    private projectService: ProjectService
  ) {
    super();
  }

  async ngOnInit() {
    this.projectService.projectId$.subscribe(async projectId => {
      const project = projectId ? await this.apiService.getProject(projectId) : null;

      this.projectName = project ? project.name : null;

      await this.update();
    });

    this.offlineService.projectStatusChanged.subscribe(async x => {
      await this.update();
    });
  }

  async goOnline() {
    await KeepAwake.keepAwake();
    do {
      const steps = this.offlineService.goOnline();

      const proceed = await this.dialog
        .open(ProgressDialogComponent, {
          data: {
            title: 'offline.dialogs.online.caption',
            subTitle: this.projectName,
            description: 'offline.dialogs.online.description',
            steps,
          },
          disableClose: true,
        })
        .afterClosed()
        .toPromise();

      await this.update();
      this.apiService.removeCachedDriveItems(this.projectService.projectId$.value);

      if (proceed) {
        const action = await this.showErrorsIfAny(steps.items);

        if (action == SyncErrorAction.rerun) {
          continue;
        }

        if (action == SyncErrorAction.deleteStorage) {
          await this.startRecovery();
        }
      }

      await KeepAwake.allowSleep();
      return;
    } while (true);
  }

  async goOffline() {
    await KeepAwake.keepAwake();
    do {
      const steps = this.offlineService.goOffline();
      const result = await this.dialog
        .open(ProgressDialogComponent, {
          data: {
            title: 'offline.dialogs.offline.caption',
            subTitle: this.projectName,
            description: 'offline.dialogs.offline.description',
            steps,
          },
          disableClose: true,
        })
        .afterClosed()
        .toPromise();

      await this.update();

      if (result === ProgressDialogResult.showErrors) {
        const action = await this.showErrorsIfAny(steps.items);

        if (action == SyncErrorAction.rerun) {
          continue;
        }

        if (action == SyncErrorAction.deleteStorage) {
          await this.offlineService.recovery();
        }
      }

      await KeepAwake.allowSleep();
      return;
    } while (true);
  }

  async sync() {
    await KeepAwake.keepAwake();
    do {
      const steps = this.offlineService.sync();
      const result = await this.dialog
        .open(ProgressDialogComponent, {
          data: {
            title: 'offline.dialogs.sync.caption',
            subTitle: this.projectName,
            description: 'offline.dialogs.sync.description',
            steps,
          },
        })
        .afterClosed()
        .toPromise();

      await this.update();

      if (result === ProgressDialogResult.showErrors) {
        const action = await this.showErrorsIfAny(steps.items);

        if (action == SyncErrorAction.rerun) {
          continue;
        }

        if (action == SyncErrorAction.deleteStorage) {
          await this.offlineService.recovery();
        }
      }

      await KeepAwake.allowSleep();
      return;
    } while (true);
  }

  async showSyncError() {
    const errors = await this.offlineService.getSyncErrors();

    const action = await this.dialog
      .open(SyncErrorDialogComponent, {
        data: {
          companyName: this.projectName,
          errors,
          single: true,
        },
      })
      .afterClosed()
      .toPromise();

    if (action == SyncErrorAction.deleteStorage) {
      await this.startRecovery();
    }
  }

  private async update() {
    const errors = await this.offlineService.getSyncErrors();

    const isOffline = this.offlineService.isProjectOffline();

    this.isOffline = isOffline;
    this.errorCount = errors.length;
  }

  private async startRecovery() {
    this.isBusy = true;

    const data = await this.offlineService.recovery();

    const proceed = await this.dialog
      .open(CopyInfoDialogComponent, {
        data: {
          title: 'offline.dialogs.recovery.caption',
          description: 'offline.dialogs.recovery.description',
          copyableString: data,
        },
        disableClose: true,
      })
      .afterClosed()
      .toPromise();

    this.isBusy = false;
  }

  private async showErrorsIfAny(steps: ActivityStepWithProgress[]) {
    const errors = steps
      .filter(s => s.currentState == ActivityStepState.failure)
      // flat map
      .reduce((previous, step) => [...previous, ...step.errors], []);

    const warnings = steps
      .filter(s => s.currentState == ActivityStepState.completedWithWarnings)
      // flat map
      .reduce((previous, step) => [...previous, ...step.warnings], []);

    if (errors.length > 0 || warnings.length > 0) {
      return await this.dialog
        .open(SyncErrorDialogComponent, {
          data: {
            companyName: this.projectName,
            errors,
            warnings,
          },
        })
        .afterClosed()
        .toPromise();
    }

    return null;
  }
}
