import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService, AppConfigService, Utils } from '..';

@Injectable()
export class TenantGuard {
  constructor(private apiService: ApiService, private router: Router) {}

  async canActivateChild() {
    let tenantUrl = await this.apiService.getTenantUrl();

    if (!tenantUrl) return true;

    const redirectUrl = new URL(window.location.origin);
    redirectUrl.hostname = tenantUrl;
    window.location.replace(redirectUrl.toString());
    return false;
  }
}
