import { Component, Input, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ProjectModel } from '@app/api';
import { ApiService, AppConfigService, AuthenticationService, BaseSubscriptionComponent } from '@app/core';

@Component({
  selector: 'app-project-image',
  templateUrl: './project-image.component.html',
  styleUrls: ['./project-image.component.scss'],
})
export class ProjectImageComponent extends BaseSubscriptionComponent implements OnInit {
  @Input() set projectId(id: string) {
    this.id = id;
    if (id != null) {
      this.refreshProject();
    }
  }
  @Input() sizeRem: number = 2.4;

  projectName: string;
  hasProjectImage: boolean;
  projectImageUrl: string;

  private id: string;

  constructor(private apiService: ApiService, private authService: AuthenticationService) {
    super();
  }

  ngOnInit() {
    this.subscribe(this.authService.projectUpdated, async projectId => {
      if (projectId == this.id) this.refreshProject();
    });
  }

  refreshProject() {
    this.apiService.getProject(this.id).then(project => {
      this.projectName = project.name;
      this.hasProjectImage = project.hasLogoSmall;
      this.projectImageUrl = `${AppConfigService.settings.api.url}/project/${
        this.id
      }/logoSmall?ts=${project.lastModificationOfGeneralSettings.getTime()}`;
    });
  }
}
