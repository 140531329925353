import { AfterViewInit, Component, Input, ElementRef, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { WorkpackageState, WorkpackageTemplate } from '@app/api';
import { AppConfigService, BaseSubscriptionComponent, LogService, getStatesFromModel, nameof } from '@app/core';
import { ContrastColorPipe } from '@app/shared/pipes/contrast-color.pipe';
import {
  CustomizationService,
  ProjectSchedulerService,
  SchedulerFilter,
  SchedulerService,
  UserNotificationService,
  Week,
} from '@app/shared/services';
import {
  AjaxStore,
  AssignmentModel,
  AssignmentStore,
  DependencyModel,
  DependencyStore,
  DomHelper,
  DragHelper,
  EventModel,
  EventStore,
  MenuItemConfig,
  Model,
  ResourceModel,
  ResourceStore,
  SchedulerEventModel,
  SchedulerPro,
  SchedulerProConfig,
  TaskEditorBase,
  Tooltip,
  ViewPreset,
} from '@bryntum/schedulerpro';
import { BryntumSchedulerProComponent } from '@bryntum/schedulerpro-angular';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { Subject, combineLatest } from 'rxjs';
import { concatMap, debounceTime } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import {
  WorkpackageAssignmentModel,
  TimeRangeColorModel,
  WorkpackageModel,
  BryntumProjectChanges,
  overlappingEventSorter,
} from './bryntum-scheduler.models';
import { ApiProjectModel, LaneModel, StoreType } from './bryntum-scheduler.models';
import { environment } from '@env/environment';
import { BusyScope, using } from '@app/shared/utils/busy';
import { FileExtension } from '@app/core/enumerations';

const DRAG_SEQUENCE_TEMPLATE_THRESHOLD = 3;
const SEQUENCE_DRAG_OFFSET = 100; //10rem

enum FiguresType {
  completed = 'completed',
  capacity = 'capacity',
  cost = 'cost',
}

export enum TemplateType {
  workpackage = 'workpackage',
  sequence = 'sequence',
}

@Component({
  selector: 'app-bryntum-scheduler',
  templateUrl: './bryntum-scheduler.component.html',
  styleUrls: ['./bryntum-scheduler.component.scss'],
})
export class BryntumSchedulerComponent extends BaseSubscriptionComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild(BryntumSchedulerProComponent) schedulerComponent: BryntumSchedulerProComponent;
  @ViewChild('dragTemplate', { static: true }) dragTemplate: TemplateRef<any>;

  @Input() showGridOnly: boolean;
  @Input() showAddButton: boolean;

  figuresType = FiguresType;
  selectedFiguresType: FiguresType = FiguresType.completed;

  resourceStore = new ResourceStore({
    id: StoreType.resources,
    modelClass: LaneModel,
    tree: true,
    writeAllFields: true,
  });

  timeRangeStore = new AjaxStore({
    id: StoreType.timeRanges,
    modelClass: TimeRangeColorModel,
  });
  eventStore = new EventStore({
    id: StoreType.events,
    modelClass: WorkpackageModel,
  });
  assignmentStore = new AssignmentStore({
    id: StoreType.assignments,
    modelClass: WorkpackageAssignmentModel,
  });
  dependencyStore = new DependencyStore({
    id: StoreType.dependencies,
  });

  private isExport: boolean = false;
  private isProjectLoaded: boolean = false;
  apiProject = new ApiProjectModel(this.schedulerService, {
    writeAllFields: true,
    supportShortSyncResponse: true,

    resourceStore: this.resourceStore,
    timeRangeStore: this.timeRangeStore,
    eventStore: this.eventStore,
    assignmentStore: this.assignmentStore,
    dependencyStore: this.dependencyStore,
    adjustDurationToDST: true,

    listeners: {
      load: () => {
        this.isProjectLoaded = true;
      },
      syncFail: ({ source }) => {
        const crudManager = source as ApiProjectModel;
        crudManager.revertChanges();
      },
    },
  });

  private contextMenu: Record<string, Partial<MenuItemConfig>> = {
    removeRow: {
      text: 'Remove',
      icon: 'mdi mdi-delete',
      weight: 100, // Add the item to the bottom
      onItem: () => {
        const selectedItems = this.scheduler.selectedRecords as LaneModel[];
        if (selectedItems?.length) {
          const phases = selectedItems.filter(lane => !lane.parentId);
          const phaseCount = phases.length;

          let swimlaneCount = selectedItems.filter(
            lane => !!lane.parentId && phases.all(phase => phase.id != lane.parentId)
          ).length;
          if (phaseCount > 0) for (const phase of phases) swimlaneCount += phase.allChildren.length;

          const dialogPromise = this.dialog
            .open(ConfirmDialogComponent, {
              data: {
                title: 'general.confirmation.deleteSelectedCaption',
                description:
                  phaseCount > 0
                    ? 'scheduler.confirmation.deletePhaseDescription'
                    : 'general.confirmation.deleteSelectedDescription',
                params: {
                  phaseCount,
                  swimlaneCount,
                  count: selectedItems.length,
                },
              },
            })
            .afterClosed()
            .toPromise();

          dialogPromise.then(isConfirmed => {
            if (isConfirmed) {
              const eventsToDelete = selectedItems
                .flatMap(resource => [resource].concat(resource.allChildren as LaneModel[]))
                .flatMap(resource => this.assignmentStore.getAssignmentsForResource(resource) as WorkpackageAssignmentModel[])
                .filter(assignment => assignment.isMain)
                .map(assignment => assignment.eventId as string);

              this.syncManually(async () => {
                this.eventStore.remove(eventsToDelete);
                this.resourceStore.remove(selectedItems);
              });
            }
          });
        }
      },
    },
    duplicate: {
      text: 'Duplicate',
      // cls: 'b-separator', // Add a visual line above the item
      icon: 'mdi mdi-content-duplicate',
      weight: 100, // Add the item to the bottom
      onItem: ({ record }) => this.duplicate(record as LaneModel),
    },
    addPhase: {
      text: 'Add Phase',
      icon: 'mdi mdi-chart-gantt',
      weight: 0,
      onItem: this.addPhase.bind(this),
    },
    addLane: {
      text: 'Add Lane',
      icon: 'mdi mdi-chart-gantt',
      weight: 0,
      onItem: ({ record }) => this.addSwimlane((record.parentId ? record.parent : record) as LaneModel),
    },
    renameCell: {
      text: 'Rename Cell',
      icon: 'mdi mdi-square-edit-outline',
      weight: 100,
      onItem: ({ record, column }) => this.scheduler.startEditing({ id: record.id, columnId: column.id }),
    },
  };

  private ignoreSelectedWorkpackagesAction: boolean;
  private isFiltering: boolean = false;
  private visibleStartDate: Date;
  private visibleEndDate: Date;
  private viewportChanged = new Subject<void>();

  schedulerProConfig: Partial<SchedulerProConfig> = {
    // styling
    rowHeight: 40,
    barMargin: 0,
    resourceMargin: 5,

    // config
    createEventOnDblClick: false,
    multiEventSelect: true,
    zoomKeepsOriginalTimespan: true,
    onAssignmentSelectionChange: ({ deselected, selected }) => {
      // prevent cycles when (de)selecting => !canLeave
      if (this.ignoreSelectedWorkpackagesAction) return;

      this.ignoreSelectedWorkpackagesAction = true;

      this.schedulerService
        .canLeave()
        .then(canLeave => {
          const deselectedAssignments = deselected as AssignmentModel[];
          const selectedAssignments = selected as AssignmentModel[];

          if (!canLeave) {
            this.scheduler.deselectAssignments(selectedAssignments);
            this.scheduler.selectAssignments(deselectedAssignments);
            this.ignoreSelectedWorkpackagesAction = false;
          } else {
            const selectedEventIds = this.scheduler.selectedEvents.map(event => event.id.toString()) ?? [];
            this.schedulerService.selectWorkpackages(selectedEventIds).finally(() => {
              this.ignoreSelectedWorkpackagesAction = false;
            });
          }
        })
        .catch(_ => {
          this.ignoreSelectedWorkpackagesAction = false;
        });
    },
    onVisibleDateRangeChange: ({ new: { startDate, endDate } }) => {
      this.visibleStartDate = startDate;
      this.visibleEndDate = endDate;
      this.viewportChanged.next();
    },
    onBeforeEventEditShow: ({ eventRecord }) => {
      if (!moment(eventRecord.endDate).isSame(eventRecord.startDate)) {
        eventRecord.endDate = moment(eventRecord.endDate).add(-1, 'ms').toDate();
      }
    },
    onBeforeEventSave: async ({ eventRecord }) => {
      if (!moment(eventRecord.endDate).isSame(eventRecord.startDate)) {
        eventRecord.endDate = moment(eventRecord.endDate).startOf('day').add(1, 'day').toDate();
      }
    },
    onTimeAxisHeaderClick: ({ startDate }) => {
      this.schedulerService.setSelectedWeek(moment(startDate));
    },

    features: {
      eventFilter: false,
      cellMenu: {
        items: this.contextMenu,
        processItems: ({ items, record, column }) => {
          if (!this.canEditCell(record, column.field)) items.renameCell = false;
        },
      },
      timeAxisHeaderMenu: {
        items: {
          dateRange: false,
        },
      },
      dependencies: {
        clickWidth: 6,
      },
      // dependencyEdit: false,
      dependencyEdit: {
        showLagField: true,
      },
      eventEdit: false,
      // eventEdit: {
      //   items: {
      //     // Remove the time fields
      //     startTimeField: false,
      //     endTimeField: false,
      //   },
      // },
      eventDrag: {
        validatorFn: ({ newResource }) => {
          // restrict phase assignment
          const valid = newResource.parentId != null;
          return {
            valid,
            message: valid ? '' : this.translate.instant('scheduler.error.addEventToPhase'),
          };
        },
        copyKey: 'CTRL',
        copyMode: 'assignment',
      },
      eventDragCreate: false,
      eventDragSelect: true,
      eventMenu: {
        items: {
          unassignEvent: false,
          deleteEvent: {
            text: 'Remove Event/Assignment',
            // cls: 'b-separator', // Add a visual line above the item
            icon: 'mdi mdi-delete',
            weight: 500, // Add the item to the bottom
            onItem: () => {
              this.deleteAssignments(this.scheduler.selectedAssignments as WorkpackageAssignmentModel[]);
            },
          },
          workpackageEditAdvanced: {
            text: 'Open Edit Detail',
            // cls: 'b-separator', // Add a visual line above the item
            icon: 'mdi mdi-square-edit-outline',
            weight: 500, // Add the item to the bottom
            onItem: menuItem => {
              // Bryntum has wrong typescript definition therefore cast to any
              const id = (menuItem as any)?.eventRecord?.data?.id;
              this.workpackageEditAdvanced(id);
            },
          },
        },
        processItems: ({ items, eventRecord, resourceRecord, assignmentRecord }) => {
          this.scheduler.selectAssignment(assignmentRecord);
          // disable split event
          items.splitEvent = false;

          // set translation for custom items
          const deleteEventItem = items.deleteEvent as Partial<MenuItemConfig>;
          deleteEventItem.text = this.translate.instant('scheduler.actions.deleteEvent');

          // restrict cut on multiselect - cut + paste with multiple assignments results in deletion of event instead of update
          if (this.scheduler.selectedAssignments.length > 1) {
            items.cutEvent = false;
          }

          // restrict edit on multiselect
          if (this.schedulerService.selectedWorkpackages$.value.length > 1) {
            items.workpackageEditAdvanced = false;
          }
          // set translation for custom items
          else if (items.workpackageEditAdvanced) {
            const workpackageEditAdvancedItem = items.workpackageEditAdvanced as Partial<MenuItemConfig>;
            workpackageEditAdvancedItem.text = this.translate.instant('scheduler.actions.workpackageEditAdvanced');
          }
        },
      },
      eventTooltip: {
        template: data => {
          const workpackage = data.eventRecord as WorkpackageModel;
          return `<div>
            <div style="font-weight: 500">${workpackage.name}</div>
            <div>${workpackage.get(nameof<WorkpackageModel>('description')) ? workpackage.description : ''}</div>
          </div>`;
        },
      },
      pdfExport: {
        scheduleRange: 'currentview',
        orientation: 'landscape',
        paperFormat: 'A3',
        rowsRange: 'visible',
        fileFormat: 'pdf',
        sendAsBinary: true,
        fetchOptions: { credentials: 'same-origin' },
        repeatHeader: true,
        alignRows: true,
        exporterType: 'multipagevertical',

        exportServer: AppConfigService.settings.bryntum.exportServer,
        clientURL: window.location.origin,
        translateURLsToAbsolute: window.location.origin,

        exportDialog: {
          items: {
            paperFormatField: { hidden: true },
            fileFormatField: { hidden: true },
          },
          header: this.translate.instant('scheduler.export.title'),
        },
      },
      rowReorder: true,
      scheduleTooltip: false,
      // scheduler context menu
      scheduleMenu: {
        processItems({ items, resourceRecord }) {
          // disable create
          items.addEvent = false;
          // allow paste only on swimlanes
          if (resourceRecord.parentId == null) items.pasteEvent = false;
        },
      },
      sort: false,
      // histogram summary
      summary: {
        renderer: ({ startDate, endDate, events }) => {
          const workpackages = events as WorkpackageModel[];
          if (!this.isExport) {
            const isOutsideOfViewport = endDate < this.visibleStartDate || this.visibleEndDate < startDate;
            if (!this.scheduler || !this.isProjectLoaded || this.isFiltering || isOutsideOfViewport) return '';
          }

          switch (this.selectedFiguresType) {
            case FiguresType.capacity:
              return this.getWorkpackageCapacityFiguresTemplate(workpackages);
            case FiguresType.cost:
              return this.getWorkpackageCostFiguresTemplate(workpackages);
            default:
              return this.getCompletedWorkpackageFiguresTemplate(workpackages);
          }
        },
      },
      // advanced event edit
      taskEdit: {
        confirmDelete: true,
        editorConfig: {
          bbar: {
            items: {
              cancelButton: false,
              workpackageEditAdvanced: {
                text: this.translate.instant('scheduler.actions.details'),
                icon: 'mdi mdi-square-edit-outline',
                onClick: ({
                  source: {
                    parent: { parent: taskDialogReference },
                  },
                }) => {
                  // dialog not closed on mobile (ipad)
                  taskDialogReference.close();

                  const id = taskDialogReference.loadedRecord?.data?.id;
                  this.workpackageEditAdvanced(id);
                },
              },
            },
          },
        },
        items: {
          generalTab: {
            items: {
              // issues with main phase assignment
              resourcesField: false,
              durationField: {
                step: 1,
                min: -1,
                allowNegative: true,
                onChange({ value, source }) {
                  const dayInMilliseconds = 86400000;
                  // value is initially set to day - 1ms to improve user experience
                  if (value) {
                    // prevent duration being negative
                    if (source.value < 0) {
                      // duration is set to 0
                      source.value = 0;

                      //going from duration = 0 to duration = 1
                    } else if (source.value.milliseconds == dayInMilliseconds) {
                      //reset to 1 day - 1ms
                      const newDurationInDay = (dayInMilliseconds - 1) / dayInMilliseconds;
                      source.value = newDurationInDay;
                    }
                  }
                },
              },
              // {
              //   type: 'combobox',
              //   listItemTpl: record => `${record.name} (${(this.resourceStore.getById(record.parentId) as LaneModel)?.name})`,
              //   chipView: {
              //     itemTpl: record => `${record.name} (${(this.resourceStore.getById(record.parentId) as LaneModel)?.name})`,
              //   },
              // },
              effortField: false,
              // needed if resource field should be custom (grouping + collapsed shown)
              // resourcesField: new Combo({
              //   label: 'Resources',
              //   displayField: 'name',
              //   store: {
              //     groupers: [
              //       { field: 'phaseId' }, //, renderer: ({record}) => record.name }
              //     ],
              //     data: this.resourceStore.allRecords
              //       .map((record: LaneModel) => ({
              //         id: record.id,
              //         name: record.name,
              //         phaseId: record.parentId,
              //       }))
              //       .filter(record => !!record.phaseId),
              //   },
              // }),
              // Remove the time fields
              startDateField: {
                type: 'date',
              },
              endDateField: {
                type: 'date',
                onChange({ value }) {
                  if (value) {
                    value.setTime(moment(value).add(1, 'day').toDate());
                    value.setTime(moment(value).add(-1, 'ms').toDate());
                  }
                },
              },
              // Remove completion in precent field
              percentDoneField: false,
              stateField: {
                type: 'combobox',
                label: this.translate.instant('workpackages.fieldName.state'),
                items: [],
                // Name of the field matches data field name, so value is loaded/saved automatically
                name: nameof<WorkpackageModel>('stateId'),
              },
            },
          },
          predecessorsTab: false,
          successorsTab: false,
          advancedTab: false,
          notesTab: {
            items: {
              noteField: {
                name: nameof<WorkpackageModel>('description'),
              },
            },
          },
        },
      },
      timeSpanHighlight: true,
      timeRanges: {
        showHeaderElements: false,
        bodyRenderer: ({ timeRange }) => {
          const extendedTimeRange = timeRange as TimeRangeColorModel;
          const isSingleDate = !extendedTimeRange.endDate;
          return isSingleDate
            ? `<div class="time-range-body" style="background-color: #${extendedTimeRange.hexColor}">
              <span class="mdi mdi-cards-diamond" style="color: #fff"></span>
            </div>`
            : '';
        },
      },
      tree: true,
    },

    listeners: {
      afterDependencyCreateDrop: ({ source, target, dependency }) => {
        if (!target) return;

        const d = dependency as DependencyModel;

        if (!d) return;

        const lag = moment(target.startDate).diff(moment(source.endDate), 'days');
        if (lag > 0) d.setLag(lag, 'd');
      },
      beforeAssignmentDelete: ({ assignmentRecords }) => {
        this.deleteAssignments(assignmentRecords);
        return false;
      },
      beforePaste: async ({ eventRecords, resourceRecord, date }) => {
        const copiedEvents = eventRecords as WorkpackageModel[];
        // when copying multiple assignments of same event or calling copyEvents([event]) on an event with multiple assignments eventRecords will contain duplicates
        const distinctEvents: WorkpackageModel[] = Object.values(
          copiedEvents.reduce((eventDictionary, event) => {
            eventDictionary[event.id] = event;
            return eventDictionary;
          }, {} as Record<string | number, WorkpackageModel>)
        );

        // if workpackage is selected, not empty cell, scheduler does not select anything for pasting. should be fixed on next bryntum version update
        if (!date) {
          let eventForPaste = this.scheduler.selectedEvents[0];

          // workaround for selectedEvents == copied events to past copied events on same position
          if (distinctEvents.length == this.scheduler.selectedEvents.length) {
            const copiedEventIds = distinctEvents.map(e => e.id);
            if (this.scheduler.selectedEvents.every(selected => copiedEventIds.contains(selected.id))) {
              eventForPaste = this.getTopLeftMostEvent(this.scheduler.selectedEvents as WorkpackageModel[]);
            }
          }

          this.scheduler.features.eventCopyPaste.pasteEvents(eventForPaste.startDate as Date, eventForPaste.resource);

          return false;
        }

        // disable paste on phase
        if (!resourceRecord.parentId) {
          this.userNotification.notify('scheduler.error.addEventToPhase');
          return false;
        }

        // paste events keeping their relative position to each other
        if (distinctEvents.length > 1) {
          const topLeftMostEvent = this.getTopLeftMostEvent(distinctEvents);
          const relativeResourceIndex = this.resourceStore.indexOf(resourceRecord);
          const topLeftMostEventResourceIndex = this.resourceStore.indexOf(topLeftMostEvent.mainAssignment.resource);
          const newEventResources: Record<string, LaneModel> = {};
          for (const event of distinctEvents) {
            const resourceIndex =
              relativeResourceIndex + this.resourceStore.indexOf(event.mainAssignment.resource) - topLeftMostEventResourceIndex;

            const newResource = this.resourceStore.records[resourceIndex] as LaneModel;

            if (!newResource) {
              this.userNotification.notify('scheduler.error.addEventOutsideScheduler');
              return false;
            }

            if (!newResource.parentId) {
              this.userNotification.notify('scheduler.error.addEventToPhase');
              return false;
            }

            newEventResources[event.id] = newResource;
          }

          for (const event of distinctEvents) {
            await this.scheduler.features.eventCopyPaste.copyEvents([event]);

            await this.scheduler.features.eventCopyPaste.pasteEvents(
              moment(date)
                .add(moment(event.startDate).diff(moment(topLeftMostEvent.startDate), 'days'), 'days')
                .toDate(),
              newEventResources[event.id]
            );
          }

          this.scheduler.features.eventCopyPaste.copyEvents(eventRecords);

          return false;
        }
      },
      // filter records for assignment in event edit dialog
      // beforeTaskEditShow: ({ taskRecord, editor }) => {
      //   const resourceStore: Store = editor.widgetMap.generalTab.widgetMap.resourcesField.store;
      //   resourceStore.filter({
      //     filters: (resource: LaneModel) => !!resource.parentId,
      //     replace: true,
      //   });
      // },
      // beforeTaskEditShow: ({ taskRecord, editor }) => {
      beforeTaskEditShow: ({ taskRecord, editor }: { taskRecord: WorkpackageModel; editor: TaskEditorBase }) => {
        const stateField = editor.widgetMap.stateField as any; // missing items in Combo interface ...
        // stateField.items = getStatesFromModel(taskRecord).map(state => ({
        //   value: state.key,
        //   text: `${state.code} ${state.title} ${state.key}`,
        // }));

        const hasConstraint = taskRecord.hasConstraint;

        const states = getStatesFromModel(taskRecord)
          .filter(state => !hasConstraint || state.stateType === WorkpackageState.Open)
          .map(state => ({
            value: state.key,
            text: `${state.code} ${state.title}`,
          }));

        stateField.items = states;
        stateField.value = states.find(s => s.value == taskRecord.stateId);
      },
      beforePdfExport: ({ config }) => {
        this.isExport = true;
        config.fileName = this.schedulerService.getExportFileName(FileExtension.PDF);

        // Export ignores tenant color update through CustomizationService -> update it manually again
        const exportScheduler = config.client as SchedulerPro;
        exportScheduler.element.style.setProperty('--palette-primary-500-rgb', `#${this.customizationService.primaryHexColor}`);
      },
      pdfExport: () => {
        this.isExport = false;
        // Dependencies dont get drawn while pdf export is in progress - refresh after export
        this.scheduler.features.dependencies.refresh();
      },
      gridRowBeforeDragStart: ({ context }) => {
        // check if all lanes are either phases or swimlanes
        const lanes: LaneModel[] = context.records;
        if (lanes.length > 1) {
          const allShouldBePhase = lanes[0].parentId == null;
          if (lanes.some(r => (allShouldBePhase ? r.parentId != null : r.parentId == null))) return false;
        }
      },
      gridRowDrag: ({ context }) => {
        // forbid dropping swimlane on root (== same level as phase)
        if (context.parent.isRoot && context.records[0].parentId != null) context.valid = false;

        // forbid dropping phase as child in other phase
        if (!context.parent.isRoot && context.parent.parentId == null && context.records[0].parentId == null) {
          context.valid = false;
        }
      },
    },
    eventRenderer: ({ eventRecord, resourceRecord, assignmentRecord, renderData }) => {
      const event = eventRecord as WorkpackageModel;
      const resource = resourceRecord as LaneModel;
      const assignment = assignmentRecord as WorkpackageAssignmentModel;

      const isSingleDay = moment(event.startDate).isSame(moment(event.endDate), 'day');
      const color = this.contrastColorPipe.transform(event.eventColor, 'class');
      const cls = [];

      if (assignment?.isMain ?? true) {
        renderData.eventStyle = 'plain';
        cls.push(color);
      } else {
        renderData.eventStyle = 'hollow';
        cls.push('color-black');
        cls.push('hover-' + color);
        cls.push('selected-' + color);
      }

      return isSingleDay
        ? ''
        : `<div class="workpackage-label-container ${cls.join(' ')}">
          <span class="label">${event.name}</span>
          <span class="${WorkpackageModel.getIconClass(event.stateType)}"></span>
        </div>`;
    },
    overlappingEventSorter: overlappingEventSorter,
    columns: [
      {
        type: 'tree',
        text: 'Region',
        width: 240,
        field: nameof<LaneModel>('name'),
        cls: 'indent',
        cellCls: 'indent',
      },
      {
        text: 'Floor',
        field: nameof<LaneModel>('floor'),
        flex: 1,
        cls: 'horizontally-devided',
      },
      {
        text: 'Area',
        field: nameof<LaneModel>('area'),
        flex: 1,
        cls: 'horizontally-devided',
      },
    ],
  };

  // Timeline Zoom Levels
  presets: ViewPreset[] = this.getCalenderWeekViewPresets();

  private scheduler: SchedulerPro;
  private arePreviewWeeksHighlighted: boolean;
  private dragHelper: DragHelper;

  constructor(
    private dialog: MatDialog,
    private log: LogService,
    private schedulerService: SchedulerService,
    private translate: TranslateService,
    private contrastColorPipe: ContrastColorPipe,
    private userNotification: UserNotificationService,
    private customizationService: CustomizationService
  ) {
    super();
  }

  async ngOnInit() {
    if (this.showGridOnly) {
      this.schedulerProConfig.features.regionResize = false;
      this.schedulerProConfig.features.summary = false;
      this.schedulerProConfig.subGridConfigs = {
        normal: {
          collapsed: true,
        },
      };
    } else {
      this.schedulerProConfig.startDate = this.schedulerService.startOfScheduler;
      this.schedulerProConfig.endDate = moment(this.schedulerService.endOfScheduler).endOf('day').toDate();
    }

    this.translateCustomSchedulerComponents();
    this.subscribe(this.translate.onLangChange, async _ => await this.translateCustomSchedulerComponents());

    this.subscribe(this.schedulerService.selectedWorkpackages$, async selectedWorkpackages => {
      if (!this.scheduler || this.ignoreSelectedWorkpackagesAction) return;

      const selectedWorkpackageIds = selectedWorkpackages.map(workpackage => workpackage.id);

      const selectedAssignments = [];
      for (const assignment of this.assignmentStore.records as WorkpackageAssignmentModel[]) {
        if (assignment.isMain && selectedWorkpackageIds.includes(assignment.eventId.toString()))
          selectedAssignments.push(assignment);
      }

      try {
        this.ignoreSelectedWorkpackagesAction = true;

        // workaround since selectAssignments cannot override selected but only adds them
        this.scheduler.clearEventSelection();

        if (selectedAssignments.length > 0) {
          this.scheduler.selectAssignments(selectedAssignments);
        }
      } finally {
        this.ignoreSelectedWorkpackagesAction = false;
      }
    });

    this.subscribe(this.schedulerService.bryntumProjectChanges$, async changes => {
      this.applyChangeset(changes);
    });
  }

  async ngAfterViewInit() {
    // scheduler might be disposed (cleans up all properties and methods i.e. features/highlightTimeSpan() doesn't exist anymore)
    if (this.isDestroyed) return;

    this.scheduler = this.schedulerComponent.instance;
    this.scheduler.features.eventCopyPaste.generateNewName = record => record.name;
    this.subscribe(this.viewportChanged.pipe(debounceTime(100)), () => {
      this.scheduler.features.summary?.refresh();
    });

    await this.loadStores(Object.values(StoreType));

    this.subscribe(this.schedulerService.filter$, filter => {
      this.isFiltering = true;

      const textFilter = filter.text.toLowerCase();

      this.eventStore.filter({
        id: 'craftFilter',
        filterBy: (event: WorkpackageModel) => !filter.craftIds.length || filter.craftIds.contains(event.craftId),
      });

      this.eventStore.filter({
        id: 'stateFilter',
        filterBy: (event: WorkpackageModel) =>
          !filter.states.length || filter.states.some(state => event.currentState.key == state.key),
      });

      this.assignmentStore.filter({
        id: 'foreignFilter',
        filterBy: (assignment: WorkpackageAssignmentModel) => filter.areForeignPhaseWorkpackagesVisible || assignment.isMain,
      });

      this.eventStore.filter({
        id: 'textFilter',
        filterBy: (event: WorkpackageModel) =>
          !textFilter ||
          event.name.toLowerCase().includes(textFilter) ||
          event.resource.name.toLowerCase().includes(textFilter) ||
          ((event.resource as LaneModel).area && (event.resource as LaneModel).area.toLowerCase().includes(textFilter)) ||
          ((event.resource as LaneModel).floor && (event.resource as LaneModel).floor.toLowerCase().includes(textFilter)),
      });

      this.resourceStore.filter({
        id: 'phaseFilter',
        filterBy: (resource: LaneModel) =>
          !filter.phaseIds.length || filter.phaseIds.includes(resource.parentId?.toString() ?? resource.id.toString()),
      });

      this.resourceStore.filter({
        id: 'textFilter',
        filterBy: (resource: LaneModel) =>
          !textFilter ||
          resource.name.toLowerCase().includes(textFilter) ||
          (resource.area && resource.area.toLowerCase().includes(textFilter)) ||
          (resource.floor && resource.floor.toLowerCase().includes(textFilter)) ||
          resource.events.any((e: WorkpackageModel) => this.eventStore.isAvailable(e)),
      });

      this.scheduler.clearEventSelection();

      this.isFiltering = false;
      if (this.scheduler.features.summary?.refresh) this.scheduler.features.summary?.refresh();
    });

    this.subscribe(this.schedulerService.storesChanged$.pipe(concatMap(this.loadStores.bind(this))));

    let isInitialHighlightEvent = true;
    if (!this.showGridOnly) {
      setTimeout(() => {
        this.subscribe(
          combineLatest([this.schedulerService.selectedWeek$, this.schedulerService.arePreviewWeeksActive$]),
          ([week, arePreviewWeeksActive]) => {
            let isHighlightPreviewWeeksEvent = false;
            if (arePreviewWeeksActive) {
              isHighlightPreviewWeeksEvent = !this.arePreviewWeeksHighlighted;

              // workaround for bugged zoomToSpan
              this.scheduler.zoomInFull();

              this.scheduler.zoomToSpan({
                startDate: moment().startOf('week').add(-2, 'weeks').toDate(),
                endDate: moment().startOf('week').add(this.schedulerService.previewWeeks, 'weeks').toDate(),
              });
            } else if (this.arePreviewWeeksHighlighted) {
              // workaround for bugged zoomOutFull
              this.scheduler.zoomInFull();

              this.scheduler.zoomOutFull();
            }

            this.highlightWeek(week, arePreviewWeeksActive, !isHighlightPreviewWeeksEvent, isInitialHighlightEvent);

            isInitialHighlightEvent = false;
          }
        );
      }, 0);
    }

    this.initCustomTooltips();

    this.initDrag();
  }

  ngOnDestroy() {
    if (this.dragHelper != null) this.dragHelper.destroy();

    return super.ngOnDestroy();
  }

  onEditCell(event: any) {
    if (event.editorContext.record?.isTemporary) {
      event.returnValue = false;
      return;
    }

    const context = event.editorContext;
    event.returnValue = this.canEditCell(context.record, context.column.field);
  }

  figuresTypeChanged(figures: FiguresType) {
    this.selectedFiguresType = figures;
    this.scheduler.renderContents();
  }

  private applyChangeset(changes: BryntumProjectChanges) {
    this.apiProject.suspendAutoSync();
    this.apiProject.applyChangeset(changes);
    // temp fix until bryntum fixes updates in applyChangeset not rendered - causes sync to be triggered ...
    for (const updatedEvent of (changes?.events?.updated ?? []) as EventModel[]) {
      const event = this.eventStore.getById(updatedEvent.id) as EventModel;
      event.startDate = updatedEvent.startDate;
      event.endDate = updatedEvent.endDate;
    }
    this.apiProject.acceptChanges();
    this.apiProject.resumeAutoSync();
    // temp fix until bryntum fixes updates in applyChangeset not rendered
    this.scheduler.refreshRows();
    this.scheduler.features.summary?.refresh();
  }

  async export() {
    await this.scheduler.features.pdfExport.showExportDialog();
  }

  async addPhase(phase: LaneModel = null) {
    let newNode: ResourceModel;
    await this.syncManually(async () => {
      const phases = this.resourceStore.query(n => n.parentId == null) as LaneModel[];
      const phasesCount = phases.length;

      const newPhase = new LaneModel({
        expanded: false,
        sequence: phasesCount,
        parentId: null,
        children: [],
      });

      [newNode] = (await this.resourceStore.addAsync(newPhase)) as ResourceModel[];
      newNode.name = phase?.name ?? `Phase ${phasesCount + 1}`;
    });

    this.scheduler.startEditing(newNode);
  }

  private async addSwimlane(parent: LaneModel, swimlane: LaneModel = null) {
    if (parent != null) {
      let newNode: ResourceModel;
      await this.syncManually(async () => {
        const swimlanes = parent.allChildren as LaneModel[];
        const swimlaneCount = swimlanes.length;

        const newSwimlane = new LaneModel({
          name: swimlane?.name ?? `${parent.name} (${swimlaneCount + 1})`,
          area: swimlane?.area,
          floor: swimlane?.floor,
          parentId: parent.id,
          sequence: swimlaneCount,
        });

        newNode = parent.insertChild(newSwimlane, swimlane?.nextSibling) as ResourceModel;
      });

      this.scheduler.startEditing(newNode);
    }
  }

  private duplicate(node: LaneModel) {
    const isPhase = !node?.parentId;
    if (isPhase) this.addPhase(node);
    else this.addSwimlane(node.parent as LaneModel, node);
  }

  private workpackageEditAdvanced(id: string) {
    if (!id) return;

    this.schedulerService.editWorkpackage(id.toString());
  }

  private async translateCustomSchedulerComponents() {
    for (const key of Object.keys(this.contextMenu)) {
      this.contextMenu[key].text = await this.translate.get('scheduler.actions.' + key).toPromise();
    }
  }

  private highlightWeek(week: Week, showPreviewWeeks: boolean, scrollToWeek: boolean, centerWeek: boolean = false) {
    const today = moment();
    const startOfCurrentWeek = today.clone().startOf('week');
    const endOfCurrentWeek = today.clone().endOf('week');

    if (startOfCurrentWeek.isBefore(this.scheduler.endDate) && endOfCurrentWeek.isAfter(this.scheduler.startDate)) {
      this.scheduler.highlightTimeSpan({
        startDate: startOfCurrentWeek.toDate(),
        endDate: endOfCurrentWeek.toDate(),
        cls: 'calendar-week border-only',
        name: '',
      });
    } else {
      this.scheduler.unhighlightTimeSpans();
    }

    this.scheduler.highlightTimeSpan({
      startDate: week.startOf.toDate(),
      endDate: week.endOf.toDate(),
      cls: 'calendar-week body-only',
      clearExisting: false,
      name: '',
    });

    if (scrollToWeek) this.scheduler.scrollToDate(week.startOf.toDate(), { block: centerWeek ? 'center' : 'nearest' });

    if (showPreviewWeeks) {
      const m = moment();
      const startOfWeek = m.startOf('week').toDate();
      this.scheduler.highlightTimeSpan({
        startDate: startOfWeek,
        endDate: m
          .add(this.schedulerService.previewWeeks - 1, 'weeks')
          .endOf('week')
          .toDate(),
        name: '',
        // name: 'Preview Weeks',
        clearExisting: false,
        surround: true,
      });

      // scroll to previewWeeks on first highlight only to keep scroll for changed week
      // if (!this.arePreviewWeeksHighlighted) this.scheduler.scrollToDate(startOfWeek, { block: 'center' });

      this.arePreviewWeeksHighlighted = true;
    } else {
      this.arePreviewWeeksHighlighted = false;
    }
  }

  private formatCalenderWeek(date: Date) {
    return this.translate.instant('general.date.calenderWeekNr', { number: moment(date).week() });
  }

  private calendarWeekRenderer(startDate: Date, endDate: Date) {
    const today = moment();

    let classes = 'label-container';
    if (today.isBetween(startDate, endDate)) classes += ' current';

    return `<div class="${classes}">${this.formatCalenderWeek(startDate)}<div>`;
  }

  private getCalenderWeekViewPresets(tickWidth: number = 84): ViewPreset[] {
    return [
      new ViewPreset({
        id: 'calenderWeek', // Unique id value provided to recognize your view preset. Not required, but having it you can simply set new view preset by id: scheduler.viewPreset = 'myPreset'

        name: 'Kalenderwoche', // A human-readable name provided to be used in GUI, e.i. preset picker, etc.

        tickWidth, // Time column width in horizontal mode
        tickHeight: 50, // Time column height in vertical mode
        displayDateFormat: 'HH:mm', // Controls how dates will be displayed in tooltips etc

        shiftIncrement: 1, // Controls how much time to skip when calling shiftNext and shiftPrevious.
        shiftUnit: 'week', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
        mainUnit: 'week', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
        defaultSpan: 4, // By default, if no end date is supplied to a view it will show 12 hours

        timeResolution: {
          // Dates will be snapped to this resolution
          unit: 'week', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
          increment: 1,
        },

        headers: [
          // This defines the header rows from top to bottom
          // Properties for each row: 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
          {
            unit: 'month',
            dateFormat: 'MMM YY',
            headerCellCls: 'vertically-devided horizontally-devided',
          },
          {
            unit: 'week',
            headerCellCls: 'horizontally-devided calendar-week',
            renderer: this.calendarWeekRenderer.bind(this),
          },
        ],

        columnLinesFor: 1, // Defines header level column lines will be drawn for. Defaults to the last level.
      }),
      new ViewPreset({
        id: 'day', // Unique id value provided to recognize your view preset. Not required, but having it you can simply set new view preset by id: scheduler.viewPreset = 'myPreset'

        name: 'Tagesansicht', // A human-readable name provided to be used in GUI, e.i. preset picker, etc.

        tickWidth, // Time column width in horizontal mode
        tickHeight: 50, // Time column height in vertical mode
        displayDateFormat: 'HH:mm', // Controls how dates will be displayed in tooltips etc

        shiftIncrement: 1, // Controls how much time to skip when calling shiftNext and shiftPrevious.
        shiftUnit: 'day', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
        mainUnit: 'day', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
        defaultSpan: 4, // By default, if no end date is supplied to a view it will show 12 hours

        timeResolution: {
          // Dates will be snapped to this resolution
          unit: 'day', // Valid values are 'millisecond', 'second', 'minute', 'hour', 'day', 'week', 'month', 'quarter', 'year'.
          increment: 1,
        },

        headers: [
          // This defines the header rows from top to bottom
          // Properties for each row: 'unit', 'increment', 'dateFormat', 'renderer', 'align', and 'thisObj'
          {
            unit: 'week',
            headerCellCls: 'vertically-devided horizontally-devided calendar-week',
            renderer: this.calendarWeekRenderer.bind(this),
          },
          {
            unit: 'day',
            dateFormat: 'DD MMM',
            headerCellCls: 'horizontally-devided',
          },
        ],

        columnLinesFor: 1, // Defines header level column lines will be drawn for. Defaults to the last level.
      }),
    ];
  }

  private initCustomTooltips() {
    // time ranges
    new Tooltip({
      forSelector: '.b-sch-timerange',
      getHtml: ({ activeTarget }) => {
        const timeRange = this.timeRangeStore.getById(activeTarget.dataset.id) as TimeRangeColorModel;
        if (!timeRange) return;

        return timeRange.name;
      },
    });
  }

  private initDrag() {
    this.dragHelper = new DragHelper({
      cloneTarget: true,
      mode: 'translateXY',
      targetSelector: '.drag-item-for-scheduler',
      dropTargetSelector: '.b-timeline-subgrid .b-grid-row:not(.b-tree-parent-row),.b-sch-event,.workpackage-drop-target',
      autoSizeClonedTarget: false,

      createProxy: x => {
        if (x.tagName !== 'APP-WORKPACKAGE-SEQUENCE') {
          return x.cloneNode(true) as HTMLElement;
        }

        var items = Array.from(x.querySelectorAll('app-workpackage-template-tile'));
        var content = this.dragTemplate.createEmbeddedView({ $implicit: items.length > DRAG_SEQUENCE_TEMPLATE_THRESHOLD })
          .rootNodes[0] as HTMLElement;

        for (let i = 1; i <= DRAG_SEQUENCE_TEMPLATE_THRESHOLD; i++) {
          if (items.length >= i) {
            content.appendChild(items[i - 1].cloneNode(true));
          }
        }

        if (items.length > DRAG_SEQUENCE_TEMPLATE_THRESHOLD) {
          content.classList.add('drag-template-ellipsis');
        }

        return content;
      },
    });

    this.dragHelper.on({
      drag: ({ event, context }) => {
        if (!context.valid) {
          context.element.classList.add('invalid');
        } else {
          context.element.classList.remove('invalid');
        }
      },
      dragstart: ({ event, context }) => {
        this.schedulerService.startDrag();
      },
      drop: async ({ context }) => {
        try {
          this.schedulerService.endDrag();

          if (!context.valid) {
            return;
          }

          // safty check - happened but should not
          if (this.scheduler != null) {
            const target = this.findTarget(context.target);

            if (target && target.type == 'simulation') {
              await this.dropTargetInSimulation(context);
            } else {
              await this.dropTargetInScheduler(context);
            }

            context.finalize(true);
          }
        } catch (error) {
          context.finalize(false);
        }
      },
    });
  }

  private async dropTargetInScheduler(context: any) {
    let sequenceLength = 0;
    const data = context.grabbed.dataset;
    const rowRecord = this.scheduler.resolveRowRecord(context.target);

    let coordinate = DomHelper[`getTranslateX`](context.element);
    coordinate += SEQUENCE_DRAG_OFFSET;
    const startDate = moment(this.scheduler.getDateFromCoordinate(coordinate, 'round', false)).startOf('day');

    if (!startDate.isValid()) {
      this.userNotification.notify('scheduler.error.addEventOutsideScheduler');
      return;
    }

    await this.syncManually(async () => {
      if (data.type == TemplateType.workpackage) {
        const templates = this.schedulerService.templates;
        const template = templates.find(t => t.id == data.entityId);

        if (template != null) {
          const workpackage = WorkpackageModel.fromTemplate(template, startDate, this.schedulerService.mainColorHex);
          const added = await this.eventStore.addAsync(workpackage);

          await this.assignmentStore.addAsync(
            new WorkpackageAssignmentModel({ eventId: added[0].id, resourceId: rowRecord.id, isMain: true })
          );
        }
      } else {
        const sequences = this.schedulerService.sequences;
        const sequence = sequences.find(s => s.id == data.entityId);

        if (sequence != null) {
          const events: SchedulerEventModel[] = [];
          let templates = this.schedulerService.templates;
          let wasSuccess = true;

          for (const sequenceTemplate of sequence.templates) {
            const template = templates.find(t => t.id == sequenceTemplate.templateId);

            if (template == null) {
              wasSuccess = false;
              break;
            }

            const item = WorkpackageModel.fromSequenceTemplate(
              template,
              sequenceTemplate,
              startDate,
              this.customizationService.primaryHexColor
            );

            const addedEvents = await this.eventStore.addAsync(item);

            events.push(...addedEvents);
          }

          if (!wasSuccess) {
            this.apiProject.revertChanges();
          } else {
            const assignments: WorkpackageAssignmentModel[] = [];
            const dependencies: DependencyModel[] = [];
            for (let i = 0; i < events.length; i++) {
              const event = events[i];
              const nextEvent = events[i + 1];

              assignments.push(new WorkpackageAssignmentModel({ eventId: event.id, resourceId: rowRecord.id, isMain: true }));

              if (!!nextEvent) {
                dependencies.push(
                  new DependencyModel({
                    from: event.id,
                    fromEvent: event.id,
                    to: nextEvent.id,
                    toEvent: nextEvent.id,
                  })
                );
              }
            }

            await this.assignmentStore.addAsync(assignments);
          }
        }
      }
    });
  }

  private async dropTargetInSimulation(context: any) {
    let finalPhase: Model = null;
    let finalWorkpackage: SchedulerEventModel = null;

    const data = context.grabbed.dataset;

    if (data.type == TemplateType.workpackage) {
      const templates = this.schedulerService.templates;
      const template = templates.find(t => t.id == data.entityId);

      const startDate = this.schedulerService.selectedWeek$.value.startOf;

      if (template != null) {
        await this.syncManually(async () => {
          const workpackage = WorkpackageModel.fromTemplate(template!, moment(startDate), this.schedulerService.mainColorHex);

          var existingPhase = this.resourceStore.allRecords.find((x: LaneModel) => x.isTemporary && !x.parentId);
          if (!existingPhase) {
            const [addedPhase] = await this.resourceStore.addAsync(
              new LaneModel({
                name: this.translate.instant('scheduler.special-lanes.temporary.phase'),
                barMargin: 0,
                expanded: true,
                parentId: null,
                children: [],
                isTemporary: true,
              })
            );
            existingPhase = addedPhase;
          }

          finalPhase = existingPhase;

          var existingSwimlane = this.resourceStore.allRecords.find((x: LaneModel) => x.isTemporary && !!x.parentId);
          if (!existingSwimlane) {
            const firstLane = new LaneModel({
              name: this.translate.instant('scheduler.special-lanes.temporary.lane'),
              barMargin: 0,
              parentId: existingPhase.id,
              isTemporary: true,
            });

            existingPhase.insertChild(firstLane);

            const [addedSwimlane] = await this.resourceStore.addAsync(firstLane);

            existingSwimlane = addedSwimlane;
          }

          const [addedWorkpackage]: SchedulerEventModel[] = await this.eventStore.addAsync(workpackage);

          await this.assignmentStore.addAsync(
            new WorkpackageAssignmentModel({ eventId: addedWorkpackage.id, resourceId: existingSwimlane.id, isMain: true })
          );

          finalWorkpackage = addedWorkpackage;
        });

        if (typeof finalWorkpackage.id == 'string') {
          await this.schedulerService.selectWorkpackages([finalWorkpackage.id]);
        }

        if (typeof finalPhase.id == 'string') {
          const oldFilter: SchedulerFilter = this.schedulerService.filter$.value;
          if (oldFilter.phaseIds.length > 0 && !oldFilter.phaseIds.find(x => x == finalPhase.id)) {
            const projectSchedulerService = this.schedulerService as ProjectSchedulerService;
            projectSchedulerService.filterData({
              ...oldFilter,
              phaseIds: [...oldFilter.phaseIds, finalPhase.id],
            });
          }
        }
      }
    }
  }

  private findTarget(target: HTMLElement) {
    do {
      const { dropTarget } = target.dataset;
      if (dropTarget) {
        return { target: target, type: dropTarget };
      }
      target = target.parentElement;
    } while (target && target != document.body);

    return false;
  }

  private async syncManually(updateFunction: () => Promise<void>) {
    try {
      // wait for all changes and sync manually
      this.apiProject.suspendAutoSync();

      await updateFunction();

      await this.apiProject.sync();
    } finally {
      this.apiProject.resumeAutoSync();
    }
  }

  private async loadStore(storeType: StoreType) {
    return await this.loadStores([storeType]);
  }

  private async loadStores(storeTypes: StoreType[]) {
    try {
      await this.apiProject.load({ request: { stores: storeTypes } });
    } catch (error) {
      if (error?.cancelled) {
        this.log.debug('Load was cancelled', error);
        return;
      }

      throw error;
    }
  }

  private canEditCell(lane: Model, fieldName: string) {
    const isSwimlane = !!lane?.parentId;
    return isSwimlane || fieldName == nameof<LaneModel>('name');
  }

  private distinct<T>(values: T[], propertySelector: (value: T) => any = value => value.toString()): T[] {
    return Object.values(
      values.reduce(
        (valuesDictionary, value) => ({
          ...valuesDictionary,
          [propertySelector(value)]: value,
        }),
        {}
      )
    );
  }

  private getWorkpackageCapacityFiguresTemplate(events: WorkpackageModel[]) {
    // const maxTotaledCapacityPerTick = this.getMaxValueByTick(wp => wp.capacity);
    const currentCapacity = this.distinct(events ?? [], e => e.id).reduce(
      (sum: number, workpackage: WorkpackageModel) => (sum += workpackage.capacity ?? 0),
      0
    );
    // const currentCapacityInPercent = maxTotaledCapacityPerTick > 0 ? (100 * currentCapacity) / maxTotaledCapacityPerTick : 0;

    return `<span class="label" style="${this.getKeyFigureLabelStyle(currentCapacity)}">${currentCapacity}</span>`;

    // return `
    //   <div class="histogram-bar" style="height: ${currentCapacityInPercent}%"></div>
    //   <span class="label" style="${this.getKeyFigureLabelStyle(currentCapacityInPercent)}">${currentCapacity}</span>
    // `;
  }

  private getWorkpackageCostFiguresTemplate(events: WorkpackageModel[]) {
    // const maxTotaledCostsPerTick = this.getMaxValueByTick(wp => wp.cost);
    const currentCost = this.distinct(events ?? [], e => e.id).reduce(
      (sum: number, workpackage: WorkpackageModel) => (sum += workpackage.cost ?? 0),
      0
    );
    // const currentCostInPercent = maxTotaledCostsPerTick > 0 ? (100 * currentCost) / maxTotaledCostsPerTick : 0;

    return `<span class="label" style="${this.getKeyFigureLabelStyle(currentCost)}">${currentCost}€</span>`;

    // return `
    //   <div class="histogram-bar" style="height: ${currentCostInPercent}%"></div>
    //   <span class="label" style="${this.getKeyFigureLabelStyle(currentCostInPercent)}">${currentCost}€</span>
    // `;
  }

  private getCompletedWorkpackageFiguresTemplate(events: WorkpackageModel[]) {
    // const maxEventsPerTick = this.getMaxValueByTick(_ => 1);

    const distinctWorkpackagesAtCurrentTick = this.distinct(events ?? [], e => e.id);
    const totalWorkpackagesAtCurrentTick = distinctWorkpackagesAtCurrentTick.length;
    const doneWorkpackagesAtCurrentTick = distinctWorkpackagesAtCurrentTick.filter(
      (wp: WorkpackageModel) => wp.stateType == WorkpackageState.Done || wp.stateType == WorkpackageState.Checked
    ).length;
    // const totalWorkpackagesInPercent = maxEventsPerTick > 0 ? (100 * totalWorkpackagesAtCurrentTick) / maxEventsPerTick : 0;
    // const doneWorkpackagesInPercent = maxEventsPerTick > 0 ? (100 * doneWorkpackagesAtCurrentTick) / maxEventsPerTick : 0;

    return `<span class="label" style="${this.getKeyFigureLabelStyle(
      totalWorkpackagesAtCurrentTick
    )}">${doneWorkpackagesAtCurrentTick} / ${totalWorkpackagesAtCurrentTick}</span>`;

    // return `
    //   <div class="histogram-bar total" style="height: ${totalWorkpackagesInPercent}%"></div>
    //   <div class="histogram-bar done" style="height: ${doneWorkpackagesInPercent}%"></div>
    //   <span class="label" style="${this.getKeyFigureLabelStyle(
    //     totalWorkpackagesInPercent
    //   )}">${doneWorkpackagesAtCurrentTick} / ${totalWorkpackagesAtCurrentTick}</span>
    // `;
  }

  private getMaxValueByTick(valueSelector: (wp: WorkpackageModel) => number) {
    let maxValue = 0;

    if (this.scheduler != null) {
      const tickValues: Record<number, number> = {};
      const shiftUnit = this.scheduler.timeAxis.viewPreset.shiftUnit;
      const workpackages = this.eventStore.records as WorkpackageModel[];
      for (const workpackage of workpackages) {
        const currentMomentInTick = moment(workpackage.startDate);
        const end = moment(workpackage.endDate);
        while (end.isAfter(currentMomentInTick)) {
          const tick = Math.floor(this.scheduler.timeAxis.getTickFromDate(currentMomentInTick.toDate()));
          const tickValue = tickValues[tick] ?? 0;
          const summedTickValue = tickValue + valueSelector(workpackage);
          tickValues[tick] = summedTickValue;

          if (summedTickValue > maxValue) maxValue = summedTickValue;

          currentMomentInTick.add(1, shiftUnit as moment.unitOfTime.DurationConstructor);
        }
      }
    }

    return maxValue;
  }

  private getKeyFigureLabelStyle(barHeightInPercent: number) {
    if (barHeightInPercent == 0) return 'display: none';

    return;
    // const footerHeight = 48,
    //   fontSize = 14,
    //   margin = 1;

    // const barHeight = Math.ceil((footerHeight * barHeightInPercent) / 100),
    //   remainingSpace = footerHeight - barHeight,
    //   isLabelFittingInRemainingSpace = remainingSpace >= fontSize + margin * 2;

    // return isLabelFittingInRemainingSpace ? `bottom: ${barHeight + margin}px` : `top: ${remainingSpace + margin}px`;
  }

  private async deleteAssignments(selectedAssignments: WorkpackageAssignmentModel[]) {
    const isConfirmed = await this.dialog
      .open(ConfirmDialogComponent, {
        data: {
          title: 'scheduler.confirmation.deleteWorkpackageCaption',
          description: 'scheduler.confirmation.deleteWorkpackageDescription',
        },
      })
      .afterClosed()
      .toPromise();

    if (!isConfirmed) return;

    const groupedAssignments: Record<string, WorkpackageAssignmentModel[]> = selectedAssignments.reduce(
      (groupedAssignments, assignment) => {
        const eventId = assignment.eventId;

        let existingAssignmentsForEvent: WorkpackageAssignmentModel[] = groupedAssignments[eventId];
        if (!existingAssignmentsForEvent) {
          existingAssignmentsForEvent = [];
          groupedAssignments[eventId] = existingAssignmentsForEvent;
        }

        existingAssignmentsForEvent.push(assignment);

        return groupedAssignments;
      },
      {}
    );

    await this.syncManually(async () => {
      for (const eventId of Object.keys(groupedAssignments)) {
        const assignments = groupedAssignments[eventId];
        if (assignments.some(a => a.isMain)) {
          this.eventStore.remove(eventId);
        } else {
          this.assignmentStore.remove(assignments);
        }
      }
    });
  }

  private getTopLeftMostEvent(events: WorkpackageModel[]) {
    let topLeftMostEvent = events[0];

    for (let i = 1; i < events.length; i++) {
      const event = events[i];
      if (
        event.startDate < topLeftMostEvent.startDate ||
        (event.startDate == topLeftMostEvent.startDate &&
          this.resourceStore.indexOf(event.mainAssignment.resource) <
            this.resourceStore.indexOf(topLeftMostEvent.mainAssignment.resource))
      )
        topLeftMostEvent = event;
    }

    return topLeftMostEvent;
  }

  // #region Debug

  isDebugMode: boolean = environment.production == false;
  isStressTestActive: boolean = false;
  stressTestProgress: number = 0;

  async startStressTest() {
    try {
      this.isStressTestActive = true;
      this.stressTestProgress = 0;

      const projectSchedulerService = this.schedulerService as ProjectSchedulerService;
      if (projectSchedulerService.loadTemplatesAndSequences == undefined) {
        this.userNotification.notify('Stress test is for project only!');
        return;
      }

      if (this.resourceStore.allCount == 0) {
        this.userNotification.notify('Add at least a single phase before starting!');
        return;
      }

      if (this.resourceStore.find(x => x.name.startsWith('Stress Test'), true) != null) {
        this.userNotification.notify('Delete all existing stress test records before you start!');
        return;
      }

      await using(new BusyScope(this.schedulerService), async _ => {
        await projectSchedulerService.loadTemplatesAndSequences();
        const templates = this.schedulerService.templates;
        if (!templates.length) {
          this.userNotification.notify('No templates found for this project!');
          return;
        }

        let addedPhases: LaneModel[] = [];

        await this.syncManually(async () => {
          for (let i = 0; i < 4; i++) {
            const children = [];

            for (let j = 0; j < 50; j++) {
              children.push(
                new LaneModel({
                  name: `Stress Test Swimlane ${j + 1}`,
                  sequence: j,
                })
              );
            }

            const newPhase = new LaneModel({
              name: `Stress Test Phase ${i + 1}`,
              expanded: false,
              sequence: i,
              parentId: null,
              children,
            });

            addedPhases.push(newPhase);
          }

          addedPhases = (await this.resourceStore.addAsync(addedPhases)) as ResourceModel[];
        });

        const workpackagesPerPhase = 2500;
        const start = moment(this.scheduler.startDate);
        const end = moment(this.scheduler.endDate);
        const weeks = Math.min(end.diff(start, 'weeks'), 30);

        let completedPhases = 0;
        for (const phase of addedPhases) {
          let completedSwimlanes = 0;
          const swimlanes = phase.allChildren;
          const workpackagesPerSwimlaneAndWeek = Math.ceil(workpackagesPerPhase / weeks / swimlanes.length);

          await this.syncManually(async () => {
            for (const swimlane of swimlanes) {
              this.stressTestProgress =
                (100 * completedPhases) / addedPhases.length +
                ((100 / addedPhases.length) * completedSwimlanes) / swimlanes.length;

              for (let i = 0; i < weeks; i++) {
                const startDate = start.clone().add(i, 'weeks');
                for (let j = 0; j < workpackagesPerSwimlaneAndWeek; j++) {
                  const randomTemplate = templates[Math.floor(Math.random() * templates.length)];
                  const workpackage = WorkpackageModel.fromTemplate(
                    randomTemplate,
                    startDate,
                    this.schedulerService.mainColorHex
                  );
                  const added = await this.eventStore.addAsync(workpackage);
                  await this.assignmentStore.addAsync(
                    new WorkpackageAssignmentModel({ eventId: added[0].id, resourceId: swimlane.id, isMain: true })
                  );
                }
              }

              ++completedSwimlanes;

              if (completedSwimlanes % 10 == 0) await this.apiProject.sync();
            }
          });
          ++completedPhases;
        }
      });
    } finally {
      this.isStressTestActive = false;
    }
  }

  // #endregion Debug
}
