import { Component, OnInit, Inject, ViewChild, ElementRef, TemplateRef } from '@angular/core';
import {
  MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
} from '@angular/material/legacy-dialog';
import { DriveItemLogModel, DriveItemLogType, DriveItemType, NotificationState } from '@app/api';
import { ApiService, TranslationManagementService, Utils, nameof } from '@app/core';
import { LocalizedDateFormat, LocalizedDatePipe } from '@app/shared/pipes';
import { UserNotificationService } from '@app/shared/services/user-notification/user-notification.service';
import { C4GridDef } from '../../grid';
import { NotificationStateIcon } from '../../notification-card/notification-card.component';
import { ViewLogReceiversDialogComponent } from './view-log-receivers-dialog/view-log-receivers-dialog.component';

export interface viewLogDlgData {
  id: string;
  resource?: string;
}

export interface viewLogMultiDlgData {
  ids: string[];
  resource: string;
  sortBy?: keyof DriveItemLogModel;
  sortDirection?: 'ASC' | 'DESC';
}

@Component({
  selector: 'app-view-log',
  templateUrl: './view-log.component.html',
  styleUrls: ['./view-log.component.scss'],
})
export class ViewLogComponent implements OnInit {
  requestData: viewLogDlgData | viewLogMultiDlgData;
  itemLogs: DriveItemLogModel[] = [];
  isBusy: boolean = true;
  logTypes = DriveItemLogType;
  gridDef: C4GridDef;

  @ViewChild('itemType', { static: true }) itemType: TemplateRef<any>;
  @ViewChild('itemDescription', { static: true }) itemDescription: TemplateRef<any>;
  @ViewChild('itemAction', { static: true }) itemAction: TemplateRef<any>;
  @ViewChild('logType', { static: true }) logType: TemplateRef<any>;
  @ViewChild('mobileLogType', { static: true }) mobileLogType: TemplateRef<any>;
  @ViewChild('emptyTemplate', { static: true }) emptyTemplate: TemplateRef<any>;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: viewLogDlgData | viewLogMultiDlgData,
    protected apiService: ApiService,
    private dialog: MatDialog,
    private datePipe: LocalizedDatePipe,
    public translate: TranslationManagementService,
    private userNotification: UserNotificationService,
    public viewLogRef: MatDialogRef<ViewLogComponent>
  ) {
    this.requestData = data ? data : null;
    this.initData();
  }

  async ngOnInit() {
    this.isBusy = true;
    this.gridDef = this.getGridDef();
    try {
      if (this.requestData[nameof<viewLogDlgData>('id')]) {
        const data = this.requestData as viewLogDlgData;
        if (!this.requestData.resource) {
          this.itemLogs = await this.apiService.getUserLogs(data.id);
        } else {
          this.itemLogs = await this.apiService.getItemLogs(data.id, data.resource);
        }
      } else {
        const data = this.requestData as viewLogMultiDlgData;
        const fetchFunc = Utils.isNullOrWhitespace(this.requestData.resource)
          ? this.apiService.getUserLogs
          : (id?: string) => this.apiService.getItemLogs(id, data.resource);

        const promises = data.ids?.map(id => fetchFunc(id)) ?? [fetchFunc()];
        const results = await Promise.all(promises);
        const result: DriveItemLogModel[] = results.flat();

        if (data.sortBy) {
          result.sort((left, right) => {
            const sortValueLeft = left[data.sortBy];
            const sortValueRight = right[data.sortBy];

            if (sortValueLeft === sortValueRight) return 0;

            if (data.sortDirection === 'DESC') return sortValueLeft > sortValueRight ? -1 : 1;
            return sortValueLeft < sortValueRight ? -1 : 1;
          });
        }
        this.itemLogs = result;
      }

      //todo -> currently not loading templates right withouth loading griddef again
      this.gridDef = this.getGridDef();
    } catch {
      this.itemLogs = [];
      this.userNotification.notify('log.loadError');
    }
    this.isBusy = false;
  }

  getNotificationIcon(item: DriveItemLogModel): string {
    return NotificationStateIcon[item.notificationState];
  }

  async showReceivers(item: DriveItemLogModel) {
    if (item.driveItemLogType === this.logTypes.Share) {
      await this.dialog
        .open(ViewLogReceiversDialogComponent, {
          data: {
            logId: item.id,
            resource: item.resource,
          },
        })
        .afterClosed()
        .toPromise();
    }
  }

  cancel() {
    this.viewLogRef.close();
  }

  initData() {
    this.itemLogs = [];
    for (let i = 0; i < 3; i++) {
      const tmp = new DriveItemLogModel();
      tmp.name = '###############';
      tmp.driveItemModifiedOn = new Date();
      tmp.driveItemLogType = DriveItemLogType.None;
      tmp.driveItemModifiedBy = '### ### ###';
      tmp.driveItemType = DriveItemType.File;
      this.itemLogs.push(tmp);
    }
  }

  private getGridDef(): C4GridDef {
    const def: C4GridDef = {
      initialSorting: [
        {
          field: 'driveItemModifiedOn',
          order: 0,
        },
      ],
      grid: {
        filterRow: false,
        lazy: false,
        lazyInit: false,
        paging: true,
        scrollable: false,
        rowExpand: true,
        rows: 10,
        rowsOptions: [5, 10, 20, 50, 100],
      },
      row: {
        link: false,
      },
      cols: [
        {
          field: 'driveItemModifiedOn',
          header: 'log.createdOn',
          width: '10em',
          minWidth: '10em',
          sortable: true,
          pipe: this.datePipe,
          priority: 2,
          pipeArg: LocalizedDateFormat.relative,
        },
        {
          field: 'driveItemType',
          header: 'log.none',
          width: '4em',
          minWidth: '4em',
          sortable: false,
          priority: 2,
          template: this.itemType,
          mobileTemplate: this.emptyTemplate,
        },
        {
          field: 'name',
          header: 'log.name',
          width: '1*',
          minWidth: '8em',
          sortable: false,
          priority: 1,
          template: this.itemDescription,
        },
        {
          field: 'driveItemModifiedBy',
          header: 'log.createdBy',
          width: '10em',
          minWidth: '10em',
          priority: 2,
          sortable: true,
        },
        {
          field: 'driveItemLogType',
          header: 'log.logtype',
          width: '10em',
          minWidth: '10em',
          priority: 1,
          sortable: true,
          template: this.logType,
          mobileTemplate: this.mobileLogType,
        },
        {
          field: 'interaction',
          header: 'log.interaction',
          width: '7em',
          minWidth: '7em',
          priority: 2,
          sortable: false,
          template: this.itemAction,
          mobileTemplate: this.emptyTemplate,
        },
      ],
    };

    return def;
  }
}
