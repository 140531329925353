import { Component, ElementRef, EventEmitter, Input, NgZone, OnInit, Output, Renderer2, ViewChild } from '@angular/core';

export enum SplitDirection {
  horizontal = 'horizontal',
  vertical = 'vertical',
}

export interface SplitPosition {
  isFirstCollapsed?: boolean;
  isSecondCollapsed?: boolean;
  sizes?: number[];
}

type CancelSubscriptionFunction = () => void;

@Component({
  selector: 'app-split',
  templateUrl: './split.component.html',
  styleUrls: ['./split.component.scss'],
})
export class SplitComponent implements OnInit {
  @ViewChild('root', { static: true }) rootElement: ElementRef<HTMLElement>;
  // @ViewChild('first', { static: true }) firstElement: ElementRef<HTMLElement>;
  // @ViewChild('second', { static: true }) secondElement: ElementRef<HTMLElement>;
  @ViewChild('grabber', { static: true }) grabberElement: ElementRef<HTMLElement>;

  /** affects the steps size while dragging/resizing */
  @Input() resizeFactor: number = 1000;
  @Input() direction: SplitDirection = SplitDirection.horizontal;
  @Input() set position(position: SplitPosition) {
    this.isFirstCollapsed = false;
    this.isSecondCollapsed = false;
    this.sizes = this.sizesCache = position?.sizes ?? [1, 1];

    if (position?.isFirstCollapsed) {
      this.isFirstCollapsed = true;
      this.isSecondCollapsed = false;
      this.sizes = [0, 1];
    } else if (position?.isSecondCollapsed) {
      this.isFirstCollapsed = false;
      this.isSecondCollapsed = true;
      this.sizes = [1, 0];
    }
  }

  @Output() positionChange = new EventEmitter<SplitPosition>();

  sizes: number[] = [1, 1];
  isDrag: boolean;
  isFirstCollapsed: boolean = false;
  isSecondCollapsed: boolean = false;

  private sizesCache: number[] = [1, 1];
  private moveListeners: CancelSubscriptionFunction[] = [];
  private bounds: DOMRect;

  constructor(private ngZone: NgZone, private renderer: Renderer2) {}

  private get root(): HTMLElement {
    return this.rootElement.nativeElement;
  }

  // private get first(): HTMLElement {
  //   return this.firstElement.nativeElement;
  // }

  // private get second(): HTMLElement {
  //   return this.secondElement.nativeElement;
  // }

  private get grabber(): HTMLElement {
    return this.grabberElement.nativeElement;
  }

  get isHorizontal(): boolean {
    return this.direction == SplitDirection.horizontal;
  }

  get isAnyCollapsed(): boolean {
    return this.isFirstCollapsed || this.isSecondCollapsed;
  }

  ngOnInit() {
    this.renderer.listen('document', 'mouseup', this.dragEnd.bind(this));
    this.renderer.listen('document', 'touchend', this.dragEnd.bind(this));

    this.renderer.listen(this.grabber, 'mousedown', this.dragStart.bind(this));
    this.renderer.listen(this.grabber, 'touchstart', this.dragStart.bind(this));
  }

  collapseFirst(event: MouseEvent) {
    event?.stopPropagation();

    if (this.isSecondCollapsed) {
      this.isSecondCollapsed = false;
      this.sizes = this.sizesCache;
    } else if (!this.isFirstCollapsed) {
      this.isFirstCollapsed = true;
      this.sizesCache = this.sizes;
      this.sizes = [0, 1];
    }

    this.emitPositionChanged();
  }

  collapseSecond(event: MouseEvent) {
    event?.stopPropagation();

    if (this.isFirstCollapsed) {
      this.isFirstCollapsed = false;
      this.sizes = this.sizesCache;
    } else if (!this.isSecondCollapsed) {
      this.isSecondCollapsed = true;
      this.sizesCache = this.sizes;
      this.sizes = [1, 0];
    }

    this.emitPositionChanged();
  }

  private emitPositionChanged() {
    this.positionChange.emit({
      isFirstCollapsed: this.isFirstCollapsed,
      isSecondCollapsed: this.isSecondCollapsed,
      sizes: this.isAnyCollapsed ? this.sizesCache : this.sizes,
    });
  }

  private dragStart() {
    if (this.root && !this.isAnyCollapsed) {
      this.isDrag = true;
      this.bounds = this.root.getBoundingClientRect();

      this.ngZone.runOutsideAngular(() => {
        this.moveListeners.push(this.renderer.listen('document', 'mousemove', this.drag.bind(this)));
        this.moveListeners.push(this.renderer.listen('document', 'touchmove', this.drag.bind(this)));
      });

      document.body.style.cursor = this.isHorizontal ? 'col-resize' : 'row-resize';
    }
  }

  private drag(event: MouseEvent) {
    const resizeFactor = Math.max(this.resizeFactor, 2); // min resize factor
    const cursorPosition = this.isHorizontal ? event.clientX - this.bounds.left : event.clientY - this.bounds.top;
    const containerLength = this.isHorizontal ? this.bounds.width : this.bounds.height;

    const first = Math.max(Math.round((cursorPosition * resizeFactor) / containerLength), 0);
    const second = resizeFactor - first;
    this.sizes = [first, second];
  }

  private dragEnd() {
    let cancelSubscription: CancelSubscriptionFunction;
    while ((cancelSubscription = this.moveListeners.pop())) cancelSubscription();

    if (this.isDrag) {
      this.emitPositionChanged();

      document.body.style.cursor = 'default';
      this.isDrag = false;
    }
  }
}
