import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UserModel, UserSessionModel } from '@app/api';
import { AppConfigService } from '@app/core';

@Component({
  selector: 'app-user-image',
  templateUrl: './user-image.component.html',
  styleUrls: ['./user-image.component.scss'],
})
export class UserImageComponent {
  @Input() sizeRem: number;
  @Input() tooltip: string;

  @Input() set user(user: UserModel) {
    this.setImageUrl(user.id, user.modifiedOn);
  }

  @Input() set userSession(userSession: UserSessionModel) {
    this.setImageUrl(userSession.userId, userSession.userModifiedOn);
  }

  @Input() set userId(id: string) {
    this.setImageUrl(id);
  }

  @Output() imageClicked = new EventEmitter();

  userImageUrl: string;

  constructor() {}

  private setImageUrl(id: string, modifiedOn: Date = null) {
    let userImageUrl = !id ? null : `${AppConfigService.settings.api.url}/user/${id}/image`;

    if (userImageUrl != null && modifiedOn != null) userImageUrl += `?ts=${modifiedOn.getTime()}`;

    this.userImageUrl = userImageUrl;
  }
}
