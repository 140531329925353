import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, FormControl, UntypedFormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { CategoryModel, SwaggerException } from '@app/api';
import { ApiService, CustomValidators, intRegex, StructureType } from '@app/core';
import { FormComponent } from '@app/core/utils/form-component';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';

interface KnownCategory {
  name: string;
  number: number;
}

@Component({
  selector: 'app-category-dialog',
  templateUrl: './category-dialog.component.html',
  styleUrls: ['./category-dialog.component.scss'],
})
export class CategoryDialogComponent extends FormComponent implements OnInit, Busy {
  isBusy: boolean;
  title: string = '';
  category: CategoryModel;
  categories: CategoryModel[];
  disabledCategoryIds: string[];
  private categoryId: string;
  private parentId: string;
  private existingCategoryNames: KnownCategory[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private apiService: ApiService,
    private dialogRef: MatDialogRef<CategoryDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private userNotification: UserNotificationService
  ) {
    super();

    this.categoryId = data?.categoryId;
    this.parentId = data.parentId;
    this.title = 'categories.' + (this.isNew ? 'add' : 'edit');
    this.disabledCategoryIds = this.isNew ? [] : [this.categoryId];
  }

  private get isNew(): boolean {
    return !this.categoryId;
  }

  async ngOnInit() {
    this.initForm();

    let errorKey = 'userData';
    await using(new BusyScope(this), async _ => {
      errorKey = 'categories';
      let categories = await this.apiService.getCategories(StructureType.tree, 1);

      if (this.categoryId) {
        // forbid to select self or children (prevent cycle)
        this.removeCategoryById(this.categoryId, categories);
      }

      // set afterwards to make sure categories will be updated in UI
      this.categories = categories;

      const flatCategories = await this.apiService.getCategories(StructureType.flat);
      for (const category of flatCategories) {
        if (category.id != this.categoryId)
          this.existingCategoryNames.push({
            name: category.name,
            number: category.number,
          });
      }

      errorKey = 'category';
      if (this.categoryId) {
        this.category = await this.apiService.getCategory(this.categoryId);

        this.resetForm();
      }
    }).catch(e => {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.' + errorKey, e);
    });
  }

  async confirm() {
    if (this.form.dirty) {
      const category = this.parseForm();
      await using(new BusyScope(this), async () => {
        try {
          category.id = await this.apiService.saveCategory(category);
          this.userNotification.notify('categories.success.save');
          this.dialogRef.close(category);
        } catch (e) {
          if (SwaggerException.isSwaggerException(e) && e.status === 400) {
            this.userNotification.notify('categories.error.nestingTooDeep');
          } else {
            this.userNotification.notify('categories.error.' + (this.isNew ? 'add' : 'edit'));
          }
        }
      });
    } else {
      this.cancel();
    }
  }

  cancel() {
    this.dialogRef.close();
  }

  private initForm() {
    this.form = this.formBuilder.group(
      {
        name: [null, [Validators.required]],
        number: [null, [Validators.pattern(intRegex)]],
        sequence: [null, [Validators.pattern(intRegex)]],
        parentId: [this.parentId],
      },
      { validators: [this.categoryUniqueCheck.bind(this)] as ValidatorFn[] }
    );
  }

  private categoryUniqueCheck(control: UntypedFormGroup): ValidationErrors | null {
    if (!this.form) {
      return null;
    }

    const { name, number } = this.form.value || {};

    const exists = this.existingCategoryNames.some(x => x.name == name && (x.number || null) == number);

    return exists ? { unique: true } : null;
  }

  private resetForm() {
    this.f.name.setValue(this.category?.name);
    this.f.sequence.setValue(this.category?.sequence);
    this.f.number.setValue(this.category?.number);
    this.f.parentId.setValue(this.category?.parentId ?? this.parentId);

    this.form.markAsUntouched();
  }

  private parseForm() {
    return new CategoryModel({
      id: this.categoryId,
      name: this.f.name.value,
      sequence: this.parseIntNanAsNull(this.f.sequence.value),
      number: this.parseIntNanAsNull(this.f.number.value),
      parentId: this.f.parentId.value,
    });
  }

  private removeCategoryById(id: string, categories: CategoryModel[]) {
    for (let i = 0; i < categories.length; i++) {
      const category = categories[i];
      if (category.id == id) return categories.splice(i, 1);

      const removed = this.removeCategoryById(id, category.children);
      if (removed.length > 0) return removed; // already removed
    }

    return [];
  }
}
