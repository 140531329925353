import { Injectable } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  CallbackResponse,
  DriveActionMetadata,
  DriveItemChangeSetModel,
  DriveItemIdentifierModel,
  DriveItemModel,
  DriveItemMoveModel,
  DriveItemMoveOperation,
  DriveItemPrivilegeModel,
  DriveItemType,
  DriveUploadResultModel,
  IdentityModel,
  IdentitySetModel,
  LRTaskState,
  ModuleType,
  NotificationSendType,
  PrivilegeEnum,
  ProblemDetails,
  ProblemDetailsErrorType,
  ResourceIdentifier,
  ShareDriveModel,
  TextFormatType,
} from '@app/api';
import { ApiService, BehaviorObservable, CustomValidators, DataHolder, LogService, ProjectService } from '@app/core';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { FileNameFormat } from '@app/core/utils/file-name-format';
import { ConfirmDialogComponent } from '@app/shared/components/dialogs/confirm-dialog/confirm-dialog.component';
import { MailNotificationDialogComponent } from '@app/shared/components/dialogs/mail-notification-dialog/mail-notification-dialog.component';
import {
  SignSelectionDialogComponent,
  SignSelectionInitArgs,
} from '@app/shared/components/dialogs/sign-selection-dialog/sign-selection-dialog.component';
import { ViewLogComponent, viewLogDlgData } from '@app/shared/components/dialogs/view-log/view-log.component';
import { ZipDownloadUrlDialogComponent } from '@app/shared/components/dialogs/zip-download-url-dialog/zip-download-url-dialog.component';
import { DriveItemPrivilegesComponent } from '@app/shared/components/privileges/drive-item-privileges/drive-item-privileges.component';
import { BusyContext, BusyScope, using } from '@app/shared/utils/busy';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { DocumentDownloadData, DocumentDownloadService } from '../document-download';
import { DocumentUploadData } from '../document-upload/document-upload-interfaces';
import { DocumentUploadService } from '../document-upload/document-upload.service';
import { FilePreviewOverlayService } from '../file-preview/file-preview-overlay-config.service';
import { LongRunningTaskService } from '../long-running-tasks/long-running-task.service';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { saveAs } from 'file-saver';
import {
  DialogResultModelStatus,
  DriveItemAction,
  DriveItemActionDialogComponent,
  DriveItemActionDialogResultModel,
  TextInputDialogComponent,
  TextInputDialogParams,
} from '@app/shared/components';

export class ExtendedDriveItemModel extends DriveItemModel {
  author?: string;
  editor?: string;
  selected?: boolean;
  isSelectable?: boolean;
  rowClass?: string;
  changeSetImmediateState?: boolean;
  deletedItem?: boolean;
  // tslint:disable-next-line
  c4_grid_disable_expand?: boolean;
  c4_grid_preview?: string;
  c4_grid_thumbnail?: string;
}

export interface SimpleDriveItemModel {
  id?: string;
  name?: string;
  resourceIdentifier?: ResourceIdentifier;
}

@Injectable()
export class DocumentsService {
  private isInitialized: boolean;
  private module: ModuleType;
  private driveActionMetadata: DriveActionMetadata;
  private driveItemAction = new BusyContext();
  private driveItemsHolder: DataHolder<ExtendedDriveItemModel[]>;
  private isReadLogAllowed: boolean;
  private isReadPrivilegeAllowed: boolean;
  private isSharePrivilegeAllowed: boolean;
  private isAddFileOnCurrentPathAllowed: boolean;
  private isAddFolderOnCurrentPathAllowed: boolean;
  private isAddFileAllowed: boolean = true;
  private isAddFolderAllowed: boolean = true;
  private isGenerateReportSnapshotAllowed: boolean;

  private pathSubject = new BehaviorSubject<string[]>(null);
  private driveItemsSubject: BehaviorSubject<DriveItemModel[]>;

  private selectedDriveItemsSubject = new Subject<DriveItemModel[]>();
  private unreadDriveItemsSubject = new Subject<DriveItemModel[]>();
  private driveItemsUploadedSubject = new Subject<DriveUploadResultModel[]>();

  fileNameFormat: FileNameFormat = null;
  shouldRenameFile: FileValidationFunction = _ => false;

  public driveItemIterator = function (item: ExtendedDriveItemModel) {};

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
    private documentDownload: DocumentDownloadService,
    private documentUpload: DocumentUploadService,
    private filePreviewService: FilePreviewOverlayService,
    private logService: LogService,
    private projectService: ProjectService,
    private userNotification: UserNotificationService,
    private longRunningTaskService: LongRunningTaskService
  ) {
    this.driveItemsHolder = new DataHolder(this.initData());
    this.driveItemsSubject = new BehaviorSubject<DriveItemModel[]>(this.driveItemsHolder.data);
  }

  // #region Properties

  public get driveItems$(): BehaviorObservable<DriveItemModel[]> {
    return this.driveItemsSubject;
  }

  public get path$(): BehaviorObservable<string[]> {
    return this.pathSubject;
  }

  public get selectedDriveItems(): DriveItemModel[] {
    return this.driveItemsHolder.data.filter(item => item.selected);
  }

  public get selectedDriveItems$(): Observable<DriveItemModel[]> {
    return this.selectedDriveItemsSubject;
  }

  public get unreadDriveItems$(): Observable<DriveItemModel[]> {
    return this.unreadDriveItemsSubject;
  }

  public get driveItemsUploaded$(): Observable<DriveUploadResultModel[]> {
    return this.driveItemsUploadedSubject;
  }

  private _resource: string;
  public get resource(): string {
    return this._resource;
  }

  public get dataReady(): boolean {
    return this.driveItemsHolder.dataReady;
  }

  public get isBusy(): boolean {
    return !this.driveItemsHolder.dataReady || this.driveItemAction.isBusy;
  }

  private _uploadProgress: number;
  public get uploadProgress(): number {
    return this._uploadProgress;
  }

  private _currentPath: string = '';
  public get currentPath(): string {
    return this._currentPath;
  }

  private _filter: string = '';
  public get filter(): string {
    return this._filter;
  }

  public get canAddFile(): boolean {
    return this.isAddFileAllowed && this.isAddFileOnCurrentPathAllowed;
  }

  public get canAddFolder(): boolean {
    return this.isAddFolderAllowed && this.isAddFolderOnCurrentPathAllowed;
  }

  public get canReadLog(): boolean {
    return this.isReadLogAllowed;
  }

  public get canGenerateReportSnapshot(): boolean {
    return this.isGenerateReportSnapshotAllowed;
  }

  public get canReadPrivilege(): boolean {
    return this.isReadPrivilegeAllowed;
  }

  public get canSharePrivilege(): boolean {
    return this.isSharePrivilegeAllowed;
  }

  public get canMoveSelectedPrivilege(): boolean {
    return this.selectedDriveItems.every(x => x.privilege?.delete == true);
  }

  // #endregion

  static index = 0;
  private readonly index = 'DocumentsService #' + ('00' + ++DocumentsService.index).slice(-2);

  async initialize(
    module: ModuleType,
    isAddFileAllowed: boolean = true,
    isAddFolderAllowed: boolean = true,
    driveActionMetadata: DriveActionMetadata = null,
    fileNameFormat: FileNameFormat = null,
    renameCondition: FileValidationFunction = _ => false
  ) {
    this.module = module;
    this.isAddFileAllowed = isAddFileAllowed;
    this.isAddFolderAllowed = isAddFolderAllowed;
    this.driveActionMetadata = driveActionMetadata;
    this.fileNameFormat = fileNameFormat;
    this.shouldRenameFile = renameCondition;
    await this.checkPrivileges();
    this.isInitialized = true;
  }

  async report() {
    this.userNotification.notify('documents.reportCSVTrigger');

    try {
      const createdStatus = await this.apiService.reportForDriveFolder(this.currentPath, this.resource);

      await new Promise((resolve, reject) => {
        this.longRunningTaskService.getObservableForTask(createdStatus.id).subscribe(
          async updatedStatus => {
            if (updatedStatus.state == LRTaskState.Failure) {
              this.logService.error('LRTask error: ', updatedStatus.problemDetails);

              reject(updatedStatus.problemDetails);
            }

            if (updatedStatus.state == LRTaskState.Success) {
              const report = await this.apiService.downloadFile(createdStatus.id);

              if (CapacitorUtils.isApp()) {
                await CapacitorUtils.blobFileHandler(report.data, report.fileName, true);
              } else {
                saveAs(report.data, decodeURIComponent(report.fileName));
              }

              this.userNotification.notify('documents.reportSuccess');
              resolve(true);
            }
          },
          e => {
            reject(e);
          }
        );
      });
    } catch {
      this.userNotification.notify('documents.reportFailure');
    }
  }

  async moveSelectedDriveItems(modules: ModuleType[], operation: DriveItemMoveOperation) {
    await this.moveDriveItems(this.selectedDriveItems, modules, operation);
  }

  async moveDriveItems(driveItems: DriveItemModel[], modules: ModuleType[], operation: DriveItemMoveOperation) {
    const op = operation.toLowerCase();

    const moveDriveItem: DriveItemAction<DriveItemModel[]> = async target => {
      const moveModel = new DriveItemMoveModel({
        newPath: target.path,
        operation: operation,
        newResource: target.resource,

        metadata: new DriveActionMetadata({
          relatedEntityId: target.entityId,
        }),
      });

      const movedItems: DriveItemModel[] = [];
      const failed: DriveItemModel[] = [];
      let lastError: any;
      for (const image of driveItems) {
        try {
          const movedItem = await this.apiService.moveDriveItem(image.id, image.resourceIdentifier.key.name, moveModel);
          movedItems.push(movedItem);
        } catch (e) {
          lastError = e;
          failed.push(image);
        }
      }

      if (movedItems.length == 0) throw lastError;

      if (failed.length == 0) {
        this.userNotification.notify('general.successMsg.' + op);
      } else {
        const count = driveItems.length;
        this.userNotification.notify('general.errorMsg.' + op + (count > 1 ? 'Multiple' : ''), { count });
      }

      return movedItems;
    };

    const resource = driveItems.map(x => x.resourceIdentifier?.key?.name).distinct()[0];

    const result: DriveItemActionDialogResultModel<DriveItemModel[]> = await this.dialog
      .open(DriveItemActionDialogComponent<DriveItemModel[]>, {
        disableClose: true,
        panelClass: 'full-content',
        data: {
          title: 'general.' + op,
          icon: 'mdi-image-' + op,
          modules: modules,
          action: moveDriveItem,
          forbiddenDriveItems: driveItems.filter(x => x.type == DriveItemType.Folder),
          location: {
            resource: resource,
          },
          onError: e => {
            const count = driveItems.length;
            this.logService.error('Failed to move item', e);
            this.userNotification.notify('general.errorMsg.' + op + (count > 1 ? 'Multiple' : ''), { count });
          },
        },
      })
      .afterClosed()
      .toPromise();

    if (result.status == DialogResultModelStatus.success) {
      this.loadDriveItems();
    }
  }

  dispose() {
    this.isInitialized = false;
    this.driveItemsHolder.reset(this.initData());
    this.resetService();
  }

  async reset(useFakeData: boolean) {
    if (useFakeData) this.driveItemsHolder.reset(this.initData());
    else await this.driveItemsHolder.updateData(async () => []);
    this.resetService();
  }

  updateDriveActionMetadata(driveActionMetadata: DriveActionMetadata) {
    this.driveActionMetadata = driveActionMetadata;
  }

  async filterDriveItems(filter: string) {
    await this.loadDriveItems(this.currentPath, this.resource, filter);
  }

  previewDriveItem(driveItem: ExtendedDriveItemModel) {
    const fileId = driveItem.c4_grid_thumbnail;
    if (fileId) {
      this.filePreviewService.open({
        data: {
          fileId,
          fileResource: this.resource,
          fileMetadataId: driveItem.metadataId,
          events: {
            onsave: evt => {
              driveItem.hasGeoJson = !evt.deleted;
            },
          },
        },
      });
    }
  }

  toggleDriveItemSelection(driveItem: ExtendedDriveItemModel) {
    if (!driveItem.selected) this.selectDriveItem(driveItem);
    else this.deselectDriveItem(driveItem);
  }

  selectDriveItem(driveItem: ExtendedDriveItemModel) {
    const selectedItems = this.driveItemsHolder.data.filter(i => i.selected);
    selectedItems.push(driveItem);
    driveItem.selected = true; //this.canSelect(item) && !item.selected;
    this.selectedDriveItemsSubject.next(this.driveItemsHolder.data.filter(i => i.selected));
  }

  deselectDriveItem(driveItem: ExtendedDriveItemModel) {
    let selectedItems = this.driveItemsHolder.data.filter(i => i.selected);
    selectedItems = selectedItems.filter(i => i.id !== driveItem.id);
    driveItem.selected = false; //this.canSelect(row) && !row.selected;
    this.selectedDriveItemsSubject.next(this.driveItemsHolder.data.filter(i => i.selected));
  }

  selectDriveItems(skip: number = undefined, take: number = undefined) {
    const data = this.driveItemsHolder.data
      .filter(d => d.isSelectable)
      .slice(skip ?? 0, take ?? this.driveItemsHolder.data.length);
    const areAllSelected = data.every(r => r.selected);
    const select = !areAllSelected;
    data.forEach(r => (r.selected = select));
    this.selectedDriveItemsSubject.next(this.driveItemsHolder.data.filter(i => i.selected));
  }

  deselectDriveItems() {
    for (const driveItem of this.driveItemsHolder.data) {
      driveItem.selected = false;
    }

    this.selectedDriveItemsSubject.next([]);
  }

  async navigateToDriveItem(driveItem: ExtendedDriveItemModel) {
    if (driveItem.type != DriveItemType.Folder) return;
    await this.navigateToPath(driveItem.path, driveItem.resourceIdentifier.key.name, '');
  }

  async navigateToPath(path: string, resource: string = this.resource, filter: string = '') {
    await this.loadDriveItems(path, resource, filter);
  }

  async uploadFiles(files: File[]) {
    if (!this.canAddFile) return;

    const uploadItems = await this.documentUpload.decodeFiles(
      files,
      this._currentPath,
      this._resource,
      false,
      this.fileNameFormat,
      this.shouldRenameFile
    );

    await this.uploadData(uploadItems);
  }

  async dropFiles(event: DragEvent) {
    if (!this.canAddFile) return;

    const uploadItems = await this.documentUpload.decodeDropEvent(
      event,
      this._currentPath,
      this._resource,
      false,
      this.fileNameFormat,
      this.shouldRenameFile
    );

    await this.uploadData(uploadItems);
  }

  async openMailNotificationDialog(driveItems: SimpleDriveItemModel[], userEvent: boolean = true) {
    const selectedDriveItemNames: string[] = [];
    const selectedDriveItems: DriveItemIdentifierModel[] = [];
    for (const driveItem of driveItems) {
      selectedDriveItemNames.push(driveItem.name);
      selectedDriveItems.push(
        new DriveItemIdentifierModel({
          driveItemId: driveItem.id,
          resource: driveItem.resourceIdentifier.key.name,
        })
      );
    }

    if (selectedDriveItems?.length) {
      const sendNotificationCall = async model => {
        await this.apiService.shareFileNotifaction(
          new ShareDriveModel({
            to: model.to,
            message: model.message ?? '',
            subject: model.subject ?? '',
            format: TextFormatType.Text,
            items: selectedDriveItems,
          })
        );
      };

      await this.dialog
        .open(MailNotificationDialogComponent, {
          data: {
            itemNames: selectedDriveItemNames,
            userEvent,
            isCustomMailAddressSupported: true,
            sendNotification: sendNotificationCall,
          },
          disableClose: true,
        })
        .afterClosed()
        .toPromise();
    }
  }

  async downloadDriveItem(driveItem: ExtendedDriveItemModel, preview: boolean = false) {
    await this.documentDownload.downloadDocument(
      {
        fileName: driveItem.name,
        id: driveItem.id,
        resource: driveItem.resourceIdentifier?.key?.name,
      },
      preview
    );

    driveItem.unread = false;
  }

  async downloadSelectedDriveItems() {
    const downloadData: DocumentDownloadData[] = [];
    const driveItems: DriveItemModel[] = [];
    this.driveItemsHolder.data.forEach(driveItem => {
      if (driveItem.selected) {
        downloadData.push({
          fileName: driveItem.name,
          id: driveItem.id,
          resource: driveItem.resourceIdentifier?.key?.name,
        });

        driveItems.push(driveItem);
      }
    });

    await using(new BusyScope(this.driveItemAction), async _ => {
      await this.documentDownload.downloadDocuments(downloadData);

      driveItems.forEach(driveItem => {
        driveItem.unread = false;
      });
    });
  }

  async shareDriveItem(driveItemModel: DriveItemModel) {
    this.openMailNotificationDialog([driveItemModel]);
  }

  async shareSelectedDriveItems() {
    this.openMailNotificationDialog(this.driveItemsHolder.data.filter(driveItem => driveItem.selected));
  }

  async generateZipDownloadUrl(driveItems: DriveItemModel[]) {
    const resource = this._resource;
    const documentIds = driveItems.filter(item => item.resourceIdentifier?.key?.name == resource).map(item => item.id);
    const dialogRef = this.dialog.open(ZipDownloadUrlDialogComponent, {
      data: {
        documentIds,
        resource,
      },
      autoFocus: false,
    });

    return await dialogRef.afterClosed().toPromise();
  }

  async generateZipDownloadUrlForSelectedDriveItems() {
    await this.generateZipDownloadUrl(this.driveItemsHolder.data.filter(driveItem => driveItem.selected));
  }

  async deleteDriveItem(driveItem: DriveItemModel) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'documents.deleteConfirmCaption',
        description: 'documents.deleteConfirmDescription',
        params: {
          name: driveItem.name,
        },
      },
    });

    const dialogResult = await dialogRef.afterClosed().toPromise();

    if (dialogResult) {
      this.deselectDriveItems();

      let success = false;
      await using(new BusyScope(this.driveItemAction), async _ => {
        await this.apiService.deleteDriveItem(driveItem.id, driveItem.resourceIdentifier?.key?.name);

        this.userNotification.notify('general.successMsg.delete');

        success = true;
      }).catch(e => {
        this.logService.error(`Failed to delete document ${driveItem.id}`, e);
        this.userNotification.notify('general.errorMsg.delete');
      });

      if (success) await this.loadDriveItems();
    }
  }

  async deleteSelectedDriveItems() {
    const selectedItems = this.driveItemsHolder.data.filter(driveItem => driveItem.selected);

    if (selectedItems.length <= 0) return;

    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'documents.deleteConfirmCaption',
        description: 'documents.deleteMultipleConfirmDescription',
        params: {
          count: selectedItems.length,
        },
      },
    });

    const dialogResult = await dialogRef.afterClosed().toPromise();

    if (dialogResult) {
      this.deselectDriveItems();

      let currentItemId: string = '';
      await using(new BusyScope(this.driveItemAction), async _ => {
        for (const selectedItem of selectedItems) {
          currentItemId = selectedItem.id;
          await this.apiService.deleteDriveItem(selectedItem.id, this._resource);
        }

        this.userNotification.notify('general.successMsg.delete');
      })
        .then(async () => {
          await this.loadDriveItems();
        })
        .catch(e => {
          this.logService.error(`Failed to delete document ${currentItemId}`, e);
          this.userNotification.notify('general.error.delete');
        });
    }
  }

  async renameDriveItemName(driveItem: ExtendedDriveItemModel) {
    const regex = /\.([A-Z,a-z,0-9]{2,5})$/i;
    const suffixResult = regex.exec(driveItem.name);
    const suffix = suffixResult ? (suffixResult.length > 1 ? '.' + suffixResult[1] : '') : '';
    const oldName = driveItem.name?.replace(suffix, '');

    const renameRequest = async (newName: string) => {
      try {
        await this.apiService.renameDriveItem(driveItem.id, newName.trim() + suffix, driveItem.resourceIdentifier?.key?.name);

        return true;
      } catch (e) {
        let logMsg = `Failed to rename document ${driveItem.id}`;
        let uiMsgKey =
          'documents.' + (driveItem.type == DriveItemType.Folder ? 'errorRenameFolderFailed' : 'errorRenameFailed');
        if (e instanceof ProblemDetails && e.type == ProblemDetailsErrorType.Conflict) {
          logMsg = `Failed to rename document ${driveItem.id} - name in use`;
          uiMsgKey =
            'documents.' +
            (driveItem.type == DriveItemType.Folder ? 'errorRenameFolderFailedNameInUse' : 'errorRenameFailedNameInUse');
        }
        this.logService.error(logMsg, e);
        this.userNotification.notify(uiMsgKey);

        return false;
      }
    };

    let newName = await this.openDriveItemNameDialog(renameRequest, oldName, suffix);

    if (newName) {
      newName += suffix;

      const changedDriveItem = this.driveItems$.value.find(dI => dI.id == driveItem.id);
      if (changedDriveItem) {
        const pathParts = changedDriveItem.path.split('/');
        pathParts.pop();
        pathParts.push(newName);
        changedDriveItem.name = newName;
        changedDriveItem.path = pathParts.join('/');
      }
    }
  }

  async markDriveItemAsRead(driveItem: ExtendedDriveItemModel) {
    await this.markDriveItemsAsRead([driveItem]);
  }

  async markSelectedDriveItemsAsRead() {
    return await this.markDriveItemsAsRead(this.driveItemsHolder.data.filter(driveItem => driveItem.selected));
  }

  async signDriveItem(driveItem: DriveItemModel) {
    const dialogRef = this.dialog.open(SignSelectionDialogComponent, {
      data: {
        initArgs: <SignSelectionInitArgs>{
          documentId: driveItem.id,
          resource: driveItem.resourceIdentifier?.key?.name,
        },
      },
    });

    const dialogResult = await dialogRef.afterClosed().toPromise();

    if (dialogResult instanceof CallbackResponse) {
      this.userNotification.notify('documents.signSuccess');

      if (this.isSharePrivilegeAllowed) {
        this.openMailNotificationDialog(
          dialogResult.results.map(
            result =>
              <SimpleDriveItemModel>{ id: result.itemId, name: result.itemFileName, resourceIdentifier: result.itemResource }
          )
        );
      }

      await this.loadDriveItems(this.currentPath, this.resource, this.filter, true);
    }
  }

  async getLog(driveItem: DriveItemModel) {
    const request: viewLogDlgData = {
      id: driveItem.id,
      resource: driveItem.resourceIdentifier?.key?.name,
    };

    await this.dialog
      .open(ViewLogComponent, {
        data: request,
        panelClass: 'log-dialog',
      })
      .afterClosed()
      .toPromise();
  }

  async getPrivileges(driveItem: DriveItemModel) {
    const data = await DriveItemPrivilegesComponent.openForDriveItem(this.dialog, driveItem);

    if (data) {
      await this.loadDriveItems(this.currentPath, this.resource, this.filter, true);
    }
  }

  async createFolder() {
    const createDriveItem = async (newName: string) => {
      try {
        await this.apiService.createFolder(newName.trim(), this._currentPath, this._resource);
        return true;
      } catch (e) {
        let logMsg = 'Failed to create folder';
        let uiMsgKey = 'documents.errorCreateFolderFailed';

        if (e instanceof ProblemDetails && e.type == ProblemDetailsErrorType.Conflict) {
          logMsg = 'Failed to create folder - name in use';
          uiMsgKey = 'documents.errorCreateFolderFailedNameInUse';
        }

        this.logService.error(logMsg, e);
        this.userNotification.notify(uiMsgKey);
        return false;
      }
    };

    const newName = await this.openDriveItemNameDialog(createDriveItem);

    if (!!newName) {
      await using(new BusyScope(this.driveItemAction), async _ => {
        await this.loadDriveItems();
      });
    }
  }

  public async openDriveItemNameDialog(
    serverRequestAction: (textInput: string) => Promise<boolean>,
    currentName: string = null,
    suffix: string = null
  ) {
    const data: TextInputDialogParams = {
      title: !currentName ? 'dialogs.newFolder.caption' : 'documents.rename',
      textInput: currentName,
      suffix,
      validators: [CustomValidators.driveItemName],
      serverRequestAction,
    };

    const folderName = await this.dialog
      .open(TextInputDialogComponent, {
        data,
      })
      .afterClosed()
      .toPromise();

    return folderName;
  }

  private async markDriveItemsAsRead(driveItems: ExtendedDriveItemModel[]) {
    let currentDriveItemId: string = '';

    await using(new BusyScope(this.driveItemAction), async _ => {
      for (const driveItem of driveItems) {
        if (!driveItem.unread) continue;
        currentDriveItemId = driveItem.id;
        await this.apiService.markDriveItemAsRead(driveItem.id, driveItem.resourceIdentifier?.key?.name);
        driveItem.unread = false;
      }

      this.unreadDriveItemsSubject.next(this.driveItemsHolder.data.filter(driveItem => driveItem.unread));
    }).catch(e => {
      this.logService.error(`Failed to mark document ${currentDriveItemId} as read`, e);
      this.userNotification.notify('documents.markAsReadError');
    });
  }

  private async uploadData(uploadData: DocumentUploadData[]) {
    let uploadResponse: DriveUploadResultModel[] = [];
    this.deselectDriveItems();
    if (uploadData.length > 0) {
      if (this.driveItemsHolder.data.length <= 0) {
        //insert one fake element on uploading if there are no data
        //reason: better appearance in the UI (progress skeleton visible)
        this.driveItemsHolder.reset(this.initData(1));
        this.driveItemsSubject.next(this.driveItemsHolder.data);
      }

      await using(new BusyScope(this.driveItemAction), async _ => {
        this._uploadProgress = 0;
        uploadResponse = await this.documentUpload.uploadDocuments(
          uploadData,
          (currentDoc: number, totalDocs: number, progressPercent: number) => {
            this._uploadProgress = progressPercent * 100;
            this.logService.debug(`Upload progress: ${100 * progressPercent}% (processing file ${currentDoc} of ${totalDocs})`);
          },
          this.driveActionMetadata
        );
      }).finally(() => {
        this._uploadProgress = undefined;
      });

      await this.loadDriveItems();

      if (uploadResponse?.length) {
        this.driveItemsUploadedSubject.next(uploadResponse);
      }
    }
  }

  async loadChangeSet(changeSetId: string, notificationSendType: NotificationSendType) {
    this._filter = '';

    await this.driveItemsHolder.updateData(async () => {
      // this.pathParts = [];
      // this.path = this._rootPath;
      // this.addFile = false;
      // this.addFolder = false;
      let changeSetModels: DriveItemChangeSetModel[];
      let resultItems: ExtendedDriveItemModel[] = [];
      try {
        changeSetModels = await this.apiService.getChangeSet(changeSetId, notificationSendType);

        //sort by name; reason: we want that the data is then fetched in reding order (== initialSort == sorty by name)
        changeSetModels.sort((a, b) => {
          if (a.driveItemName === b.driveItemName) {
            return 0;
          }
          return a.driveItemName < b.driveItemName ? -1 : 1;
        });

        for (const model of changeSetModels) {
          let driveItem: ExtendedDriveItemModel;
          if (!model.deleted) {
            try {
              driveItem = (await this.apiService
                .getDriveItem(model.driveItemId, model.resourceKey?.name)
                .toPromise()) as ExtendedDriveItemModel;
              driveItem.isSelectable = true;
            } catch (e) {
              if (e instanceof ProblemDetails && e.type == ProblemDetailsErrorType.MissingData) {
                this.logService.info(`Failed to load information for ${model.driveItemId} - is already deleted`, e);

                driveItem = this.getDeletedDriveItem(model);
              } else {
                this.logService.error(`Failed to load information for ${model.driveItemId}`, e);
              }
            }
          } else {
            driveItem = this.getDeletedDriveItem(model);
          }

          if (driveItem) resultItems.push(driveItem);
        }
      } catch (e) {
        this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.notifications', e);
        resultItems = [];
      } finally {
        this.driveItemsSubject.next(resultItems);
        this.unreadDriveItemsSubject.next(resultItems.filter(driveItem => driveItem.unread));
        return resultItems;
      }
    });
  }

  private getDeletedDriveItem(changeSet: DriveItemChangeSetModel): ExtendedDriveItemModel {
    const driveItem = new ExtendedDriveItemModel({
      id: changeSet.driveItemId,
      name: changeSet.driveItemName,
      path: changeSet.driveItemPath,
      type: DriveItemType.File,
      resourceIdentifier: new ResourceIdentifier({
        key: changeSet.resourceKey,
        // add moduleType to DriveItemChangeSetModel?
        moduleType: this.module,
      }),
      createdBy: new IdentityModel({
        displayName: '',
      }),
      lastModifiedBy: new IdentityModel({
        displayName: '',
      }),
      unread: false,
      privilege: new DriveItemPrivilegeModel({
        readContent: true,
      }),
      isInternalChange: true,
    });

    driveItem.isSelectable = false;
    driveItem.deletedItem = true;
    driveItem.c4_grid_disable_expand = true;
    // driveItem.changeSetImmediateState = !cs.deleted;

    return driveItem;
  }

  private async loadDriveItems(
    path: string = this.currentPath,
    resource: string = this.resource,
    filter: string = this.filter,
    forceReload: boolean = false
  ) {
    if (!this.isInitialized) throw 'DocumentsService not initialized!';
    this._resource = resource;
    this._currentPath = path;
    this._filter = filter;

    await this.driveItemsHolder.updateData(async () => {
      // if (functionBeforeUpdate) {
      //   await functionBeforeUpdate();
      // }
      this.deselectDriveItems();

      //load folder
      // const pathParts: string[] = [];
      // if (!this.currentPath) this.currentPath = this.rootPath;

      this.pathSubject.next(this._currentPath.split('/').filter(p => !!p));

      // const last = this.pathParts.length;
      // if (last > 0) this.pathParts[last - 1].last = true;
      // const penultimate = this.pathParts.length - 1;
      // if (penultimate > 0) this.pathParts[penultimate - 1].penultimate = true;

      //get data from path
      let resultItems: ExtendedDriveItemModel[] = [];
      try {
        const result = await this.apiService.getDriveItems(
          this._currentPath,
          this.filter,
          forceReload,
          this._resource,
          this.driveActionMetadata
        );
        const mimeTypesWithPreview = ['image/png', 'image/jpeg'];
        this.isAddFileOnCurrentPathAllowed = result.addFileAllowed;
        this.isAddFolderOnCurrentPathAllowed = result.addFolderAllowed;

        resultItems = result.items.map(function (item) {
          return { ...item } as ExtendedDriveItemModel;
        }); // as DocListModel[];

        for (const item of resultItems) {
          this.driveItemIterator(item);

          item.isSelectable = true;
          // item.c4_grid_preview = undefined;
          item.c4_grid_thumbnail = undefined;
          if (mimeTypesWithPreview.indexOf(item.mimeType) >= 0) {
            item.c4_grid_thumbnail = item.id;
          }
        }

        // this.gridDef.grid.rowCount = this.docData.data ? this.docData.data.length : 0;
        // if (this.showChangeSet) {
        //   //disable paginator when we're in "show change set mode"
        //   this.gridDef.grid.rows = this.gridDef.grid.rowCount;
        // }
      } catch (e) {
        this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.selectedFolder', e);
        resultItems = [];
      } finally {
        this.driveItemsSubject.next(resultItems);
        this.unreadDriveItemsSubject.next(resultItems.filter(driveItem => driveItem.unread));
        return resultItems;
      }
    }, true);
  }

  private async checkPrivileges() {
    try {
      let userPrivileges = await this.apiService.getUserPrivileges();

      if (this.module == ModuleType.Defect) {
        this.isReadLogAllowed = userPrivileges.indexOf(PrivilegeEnum.ReadDefectDriveItemLogs) >= 0;
        this.isReadPrivilegeAllowed = userPrivileges.indexOf(PrivilegeEnum.ReadDefectDriveItemPrivileges) >= 0;
        this.isSharePrivilegeAllowed = userPrivileges.indexOf(PrivilegeEnum.ShareDefect) >= 0;
        this.isGenerateReportSnapshotAllowed = this.isReadLogAllowed;
      } else if (this.module == ModuleType.ConstructionDiary) {
        this.isReadLogAllowed = userPrivileges.indexOf(PrivilegeEnum.ReadConstructionDiaryDriveItemLogs) >= 0;
        this.isReadPrivilegeAllowed = userPrivileges.indexOf(PrivilegeEnum.ReadConstructionDiaryDriveItemPrivileges) >= 0;
        this.isSharePrivilegeAllowed = userPrivileges.indexOf(PrivilegeEnum.ShareConstructionDiary) >= 0;
        this.isGenerateReportSnapshotAllowed = this.isReadLogAllowed;
      } else if (this.module == ModuleType.Bim) {
        this.isReadLogAllowed = userPrivileges.indexOf(PrivilegeEnum.ReadBimDriveItemLogs) >= 0;
        this.isReadPrivilegeAllowed = userPrivileges.indexOf(PrivilegeEnum.ReadBimDriveItemPrivileges) >= 0;
        this.isSharePrivilegeAllowed = userPrivileges.indexOf(PrivilegeEnum.ShareBimDriveItem) >= 0;
        this.isGenerateReportSnapshotAllowed = this.isReadLogAllowed;
      } else if (this.module == ModuleType.RoomBook) {
        this.isReadLogAllowed = userPrivileges.indexOf(PrivilegeEnum.ReadRoomBookDriveItemLogs) >= 0;
        this.isReadPrivilegeAllowed = userPrivileges.indexOf(PrivilegeEnum.ReadRoomBookDriveItemPrivileges) >= 0;
        this.isSharePrivilegeAllowed = userPrivileges.indexOf(PrivilegeEnum.ShareRoomBook) >= 0;
        this.isGenerateReportSnapshotAllowed = this.isReadLogAllowed;
      } else if (this.module == ModuleType.Plan) {
        this.isReadLogAllowed = userPrivileges.indexOf(PrivilegeEnum.ReadPlanDriveItemLogs) >= 0;
        this.isReadPrivilegeAllowed = userPrivileges.indexOf(PrivilegeEnum.ReadPlanDriveItemPrivileges) >= 0;
        this.isSharePrivilegeAllowed = userPrivileges.indexOf(PrivilegeEnum.SharePlanDriveItem) >= 0;
        this.isGenerateReportSnapshotAllowed = this.isReadLogAllowed;
      } else {
        this.isReadLogAllowed = userPrivileges.indexOf(PrivilegeEnum.ReadDriveItemLogs) >= 0;
        this.isReadPrivilegeAllowed = userPrivileges.indexOf(PrivilegeEnum.ReadDriveItemPrivileges) >= 0;
        this.isSharePrivilegeAllowed = userPrivileges.indexOf(PrivilegeEnum.ShareDriveItem) >= 0;
        this.isGenerateReportSnapshotAllowed =
          this.isReadLogAllowed && userPrivileges.indexOf(PrivilegeEnum.GenerateDriveItemReport) >= 0;
      }
    } catch (e) {
      this.logService.error('Failed to get user privileges', e);
      this.isReadLogAllowed = false;
      this.isReadPrivilegeAllowed = false;
      this.isSharePrivilegeAllowed = false;
    }
  }

  private initData(count: number = 5) {
    const response = [];

    for (let i = 0; i < count; i++) {
      const item = new DriveItemModel();
      item.size = 1000;
      item.type = DriveItemType.Folder;
      item.name = 'File' + i;
      item.mimeType = null;
      item.lastModifiedBy = new IdentitySetModel();
      item.lastModifiedBy.user = new IdentityModel();
      item.lastModifiedBy.user.displayName = 'Admin Admin';
      item.createdDateTime = new Date();
      item.privilege = new DriveItemPrivilegeModel();
      response.push(item);
    }

    return response;
  }

  private resetService() {
    this._currentPath = undefined;
    this.pathSubject.next(null);
    this.driveItemsSubject.next(this.driveItemsHolder.data);
    this.selectedDriveItemsSubject.next([]);
    this.unreadDriveItemsSubject.next([]);
  }
}
