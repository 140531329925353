import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { RoomModel } from '@app/api';
import { ApiService } from '@app/core';
import { ISnapshotLoginState } from '@app/core/ISnapshotLoginState';
import { FormComponent } from '@app/core/utils/form-component';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';

@Component({
  selector: 'app-room-to-template-dialog',
  templateUrl: './room-to-template-dialog.component.html',
  styleUrls: ['./room-to-template-dialog.component.scss'],
})
export class RoomToTemplateDialogComponent extends FormComponent implements OnInit, Busy {
  isBusy: boolean;
  rooms: RoomModel[];

  private roomId: string;

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    private userNotification: UserNotificationService,
    public dialogRef: MatDialogRef<RoomToTemplateDialogComponent>
  ) {
    super();

    this.roomId = data?.roomId;
  }

  async ngOnInit() {
    this.initForm();

    await using(new BusyScope(this), async _ => {
      this.rooms = await this.apiService.getRoomsForProject();
    }).catch(e => {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.rooms', e);
    });

    if (this.roomId) {
      const room = this.rooms.find(r => r.id == this.roomId);
      this.f.room.setValue(room);
    }
  }

  async confirm() {
    await using(new BusyScope(this), async _ => {
      var roomId = await this.apiService.createRoomFromTemplate(this.f.room.value.id, this.f.nameTemplate.value);
      this.userNotification.notify('roomBook.success.createTemplateFromRoom');
      this.dialogRef.close(roomId);
    }).catch(_ => {
      this.userNotification.notify('roomBook.error.createTemplateFromRoom');
    });
  }

  cancel() {
    this.dialogRef.close();
  }

  private initForm() {
    this.form = this.formBuilder.group({
      nameTemplate: [null, [Validators.required]],
      room: [null, [Validators.required]],
    });
  }
}
