import { Filesystem, Directory } from '@capacitor/filesystem';
import { Injectable } from '@angular/core';
import { isMoment, Moment } from 'moment';
import {
  AddOrUpdateDefectModel,
  AreaModel,
  C4ApiFilterDefinition,
  ChangeSetModel,
  CommentModel,
  ConfigType,
  ConflictBehavior,
  CraftModel,
  ProjectDefectClient,
  DefectModel,
  DefectReasonModel,
  DefectTypeModel,
  DriveActionMetadata,
  DriveClientExtended,
  DriveItemModel,
  DriveItemPrivilegeModel,
  DriveItemType,
  DriveUploadResultModel,
  FileParameter,
  FilePreviewSpec,
  FileResponse,
  FloorModel,
  IdentityModel,
  IdentitySetModel,
  OrganizationModel,
  PlanSchemaDefinition,
  PlanSchemaModel,
  PropertyBagModel,
  RoomModel,
  SettingsModel,
  SyncAreaCategory,
  SyncAreaModel,
  SyncClient,
  SyncCommentsCategory,
  SyncProjectOrganizationCategory,
  SyncProjectOrganizationCraftCategory,
  SyncProjectOrganizationCraftModel,
  SyncProjectOrganizationModel,
  SyncProjectUserCategory,
  SyncProjectUserModel,
  SyncCraftCategory,
  SyncCraftModel,
  SyncDefectCategory,
  SyncDefectCommentCategory,
  SyncDefectModel,
  SyncDefectTypeCategory,
  SyncDefectTypeModel,
  SyncDriveItemCategory,
  SyncDriveItemModel,
  SyncFloorCategory,
  SyncFloorModel,
  SyncModel,
  SyncOrganizationCategory,
  SyncOrganizationModel,
  SyncReasonCategory,
  SyncReasonModel,
  SyncRegionCategory,
  SyncRegionModel,
  SyncRoomCategory,
  SyncRoomModel,
  SyncSchemaCategory,
  SyncSchemaModel,
  SyncTenantSettingsCategory,
  SyncUserCategory,
  SyncUserModel,
  TeamRole,
  UserModel,
  ProblemDetails,
  ProblemDetailsErrorType,
  SyncDefectUserAssignmentModel,
  PrivilegeEnum,
  ProjectModel,
  SyncProjectModel,
  SyncProjectCategory,
  SyncProjectPrivilegesCategory,
  RegionModel,
  SyncResourceCategory,
  ModuleType,
  SyncResourceModel,
  ResourceIdentifier,
  ResourceKey,
  ISyncDefectModel,
  SyncEmptyEntityCategory,
  SyncZoneGroupCategory,
  SyncZoneCategory,
  ZoneGroupModel,
  SyncZoneGroupModel,
  ZoneModel,
  SyncZoneModel,
  DefectStatePermission,
} from '@app/api';
import { getApiUrl, MigrationStatus, SQLiteTables } from './sqlite/utils';

import { BehaviorSubject } from 'rxjs';
import { DBFilter, SQLStatements } from './SQLStatements';
import { Capacitor } from '@capacitor/core';
import { HttpClient } from '@angular/common/http';
import {
  IProjectStatusMap,
  IPSArea,
  IPSComment,
  IPSProjectOrganization,
  IPSProjectOrganizationCraft,
  IPSProjectUser,
  IPSCraft,
  IPSDefect,
  IPSDefectComment,
  IPSDefectType,
  IPSDriveItem,
  IPSFloor,
  IPSMetadata,
  IPSOrganization,
  IPSProject,
  IPSReason,
  IPSRegion,
  IPSRoom,
  IPSSchema,
  IPSSyncError,
  IPSUser,
  IPSProjectPrivilege,
  IPSResource,
  IPSZoneGroup,
  IPSZone,
  IPSCommentWithUserAndDefectId,
} from './definitions';
import { OfflineServiceFileHandler } from './OfflineServiceFileHandler';
import { ActivityStepError, ActivitySteps, ActivityStepState, ActivityStepWithProgress } from './stepWithProgress';
import { CancellationToken } from 'typescript';
import { IQueryWrapper, QueryCondition, SQLiteQueryWrapper } from './offline-query-wrapper';
import { Http } from '@capacitor-community-http';
import { Utils } from '@app/core/utils';
import { AuthenticationService } from '../authentication/authentication.service';
import { AppConfigService } from '../app-config';
import { DriveItemsResult } from '../api';
import { ProjectService } from '../globals/project.service';
import { LogService } from '../log/log.service';

const offlineServiceStateId = 'FACADE00-B365-1000-0000-000000000001';

enum MetadataKey {
  emptyDefect = 'EMPTY_DEFECT',
  tenantSettings = 'TENANT_SETTINGS',
  updateDate = 'UPDATE_DATE',
}

@Injectable({
  providedIn: 'root',
})
export class OfflineService {
  static OriginalSuffix: string = '/Original.bin';

  public projectStatusChanged: BehaviorSubject<IProjectStatusMap>;

  private queryWrapper: IQueryWrapper;
  private fileHandler = new OfflineServiceFileHandler();
  private unexpectedError: ActivityStepError = {
    topic: 'offline.activitySteps.errors.topic.general',
    description: 'offline.activitySteps.errors.reasons.unexpected',
  };

  private get projectId() {
    return this.projectService.projectId$.value;
  }

  constructor(
    private api: SyncClient,
    private logService: LogService,
    public httpClient: HttpClient,
    public driveClient: DriveClientExtended,
    public defectClient: ProjectDefectClient,
    public authenticationService: AuthenticationService,
    public projectService: ProjectService
  ) {
    this.projectStatusChanged = new BehaviorSubject<IProjectStatusMap>({});

    this.queryWrapper = new SQLiteQueryWrapper(api); //`Bearer ${authenticationService.getCurrentAccessToken()}`);

    this.updateProjects();
  }

  isProjectOffline() {
    if (!Capacitor.isNativePlatform() || !this.projectId) {
      return false;
    }

    const projectId = this.projectId;
    const projectStatus = this.projectStatusChanged.value;

    if (projectStatus[projectId]) {
      return projectStatus[projectId].offline;
    }

    return false;
  }

  async getSyncErrors(): Promise<ActivityStepError[]> {
    const data = await this.queryWrapper.getSyncErrors(this.projectId);
    const result = new Array<ActivityStepError>();

    for (const item of data) {
      const parameters: ActivityStepError = JSON.parse(item.parameters);

      result.push(parameters);
    }

    return result;
  }

  goOffline() {
    const projectId = this.projectId;
    const steps = new ActivitySteps();

    const stepGoOffline = new ActivityStepWithProgress('offline.activitySteps.goOffline');

    const updateLocalData = this.updateLocalData(projectId, steps); // adds steps - don't change order
    steps.addItem(stepGoOffline);

    const goOffline = async (cancellationToken: CancellationToken) => {
      // Update Local Data
      const success = await updateLocalData(cancellationToken);

      if (success) {
        // Go Offline
        stepGoOffline.start();
        try {
          await this.queryWrapper.setProjectOfflineStatus(projectId, true);

          const statusMap = { ...this.projectStatusChanged.value };
          const status = statusMap[projectId] || (statusMap[projectId] = { offline: true });

          status.offline = true;

          this.projectStatusChanged.next(statusMap);
        } catch (e) {
          stepGoOffline.addError(this.unexpectedError);
          return;
        } finally {
          stepGoOffline.complete();
        }
      }

      await this.updateErrorsForProject(steps);
    };

    steps.start = goOffline;

    return steps;
  }

  goOnline() {
    const projectId = this.projectId;
    const steps = new ActivitySteps();

    const stepGoOnline = new ActivityStepWithProgress('offline.activitySteps.goOnline');

    const uploadLocalData = this.uploadLocalData(projectId, steps);
    // adds steps - don't change order
    steps.addItem(stepGoOnline);

    const goOnline = async (cancellationToken: CancellationToken) => {
      // Sync (& Fetch)
      const success = await uploadLocalData(cancellationToken);

      if (success) {
        // Go Online
        stepGoOnline.start();
        try {
          await this.queryWrapper.setProjectOfflineStatus(projectId, false);

          const statusMap = { ...this.projectStatusChanged.value };
          const status = statusMap[projectId] || (statusMap[projectId] = { offline: false });

          status.offline = false;

          this.projectStatusChanged.next(statusMap);
        } catch (e) {
          stepGoOnline.addError(this.unexpectedError);
          return;
        } finally {
          stepGoOnline.complete();
        }
      }

      await this.updateErrorsForProject(steps);
    };

    steps.start = goOnline;

    return steps;
  }

  async recovery(reset = true, complete = false) {
    const apiBase = getApiUrl();

    const recovery = await this.api.createRecovery(this.projectId).toPromise();

    try {
      const api = `${apiBase}/projects/${this.projectId}/sync/recovery/${recovery.key}`;
      const token = `Bearer ${this.authenticationService.getCurrentAccessToken()}`;

      // complete = false;

      var filter: DBFilter | false = false;
      if (!complete) {
        filter = {};
        filter['mode'] = [MigrationStatus.INSERT, MigrationStatus.UPDATE];
        filter['project'] = this.projectId;
      }

      const dump = await this.queryWrapper.dump(filter);

      for (const entity of await this.queryWrapper.getDriveItemsWhereMode(MigrationStatus.INSERT, this.projectId)) {
        const dir = OfflineService.getDirectoryPath(entity.project, entity.resource, entity.path, entity.name);

        await Http.uploadFile({
          url: api,
          name: dir,
          filePath: dir + OfflineService.OriginalSuffix,
          fileDirectory: Directory.Data,
          headers: {
            Authorization: token,
            Resource: entity.resource,
          },
        });
      }

      const request = new XMLHttpRequest();
      const form = new FormData();
      const blob = new Blob([JSON.stringify(dump)]);

      form.set('SQLite.json', blob, 'SQLite.json');

      request.open('POST', api);
      request.setRequestHeader('Authorization', token);
      request.send(form);

      if (reset) {
        await this.queryWrapper.resetProject(this.projectId);

        await this.queryWrapper.setProjectOfflineStatus(this.projectId, false);

        this.updateProjects();
      }

      return recovery.key;
    } catch ($err) {
      if ('message' in $err && $err.message) {
        alert($err.message);
      } else {
        alert('Unkown error');
      }

      return null;
    } finally {
    }
  }

  sync() {
    const projectId = this.projectId;
    const steps = new ActivitySteps();

    const uploadLocalData = this.uploadLocalData(projectId, steps);
    const updateLocalData = this.updateLocalData(projectId, steps);

    const sync = async (cancellationToken: CancellationToken) => {
      await uploadLocalData(cancellationToken);
      await updateLocalData(cancellationToken);

      const statusMap = { ...this.projectStatusChanged.value };

      this.projectStatusChanged.next(statusMap);

      await this.updateErrorsForProject(steps);
    };

    steps.start = sync;

    return steps;
  }

  async getPreview(id: string, spec: FilePreviewSpec, resource: string): Promise<FileResponse> {
    const projectId = this.projectId;

    const results = await this.queryWrapper.getDriveItemsWhereResourceAndId(projectId, resource, id);
    const fileHandler = this.fileHandler;

    for (const file of results) {
      try {
        const directory = OfflineService.getDirectoryPath(projectId, resource, file.path, file.name);

        const buffer = await fileHandler.readFile(directory + '/' + spec + '.bin', Directory.Data);
        const blob = new Blob([buffer], { type: file.mimeType });

        return {
          data: blob,
          status: 200,
        };
      } catch ($err) {
        console.error($err);
      }
    }

    throw new Error('Unable to find');
  }

  static getDirectoryPath(projectId: string, resource, filePath: string, fileName: string) {
    return [projectId, resource, filePath, fileName].join('/');
  }

  async getProjectById(projectId: any): Promise<ProjectModel> {
    const project = await this.queryWrapper.getProjectById(projectId);

    const model: SyncProjectModel = JSON.parse(project.json);

    return new ProjectModel({
      id: model.id,
      name: model.name,
      number: model.number,
      externalId: model.externalId,
    });
  }

  async getDriveItems(
    path: string,
    flatten: boolean,
    searchText: string,
    resource: string,
    metadata: DriveActionMetadata,
    filter: C4ApiFilterDefinition
  ): Promise<DriveItemsResult> {
    const query = ['SELECT * FROM driveItems WHERE'];
    const queryParameters = [] as Array<any>;

    query.push(`project LIKE ?`);
    queryParameters.push(this.projectId);

    query.push(`AND resource LIKE ?`);
    queryParameters.push(resource);

    if (flatten) {
      query.push('AND path LIKE ? AND type LIKE ?');
      queryParameters.push('/' + path + '%');
      queryParameters.push(DriveItemType.File);
    } else {
      query.push('AND path LIKE ?');
      queryParameters.push('/' + path);
    }

    if (metadata?.relatedEntityId) {
      query.push(`AND relatedEntityId LIKE ?`);
      queryParameters.push(metadata?.relatedEntityId);
    }

    if (searchText) {
      searchText = searchText.replace(/is:([A-Z]+)/i, ($0, $1) => {
        switch ($1.toLowerCase()) {
          case 'file':
            query.push('AND type LIKE ?');
            queryParameters.push(DriveItemType.File);
            return '';

          case 'folder':
            query.push('AND type LIKE ?');
            queryParameters.push(DriveItemType.Folder);
            return '';

          default:
            throw new Error('Query Type not found');
        }
      });

      if (searchText.length > 0) {
        query.push('AND name LIKE ?');
        queryParameters.push('%' + searchText + '%');
      }
    }

    query.push('ORDER BY name DESC');

    if (filter) {
      const limit = filter.top || filter.totalCount || 0;
      const offset = 0;

      if (limit) {
        query.push('LIMIT ?');
        queryParameters.push(limit);

        if (offset) {
          query.push('OFFSET ?');
          queryParameters.push(offset);
        }
      }
    }

    const items = new Array<SyncDriveItemModel>();

    const wrapper = <SQLiteQueryWrapper>this.queryWrapper; // dirty hack
    const results = await wrapper.query(query.join(' '), ...queryParameters);

    for (const o of results) {
      const syncModel = JSON.parse(o.json) as SyncDriveItemModel;
      const model = new DriveItemModel(syncModel);

      model.privilege = new DriveItemPrivilegeModel({
        readContent: true,
      });

      items.push(model);
    }

    const resourceIdentifiers = await this.getResourceIdentifiers(this.projectId);
    const addFileAllowed = resourceIdentifiers.some(
      i => i.key.name == resource && [ModuleType.Plan, ModuleType.Defect].contains(i.moduleType)
    );

    return {
      items: items,

      addFileAllowed,
      addFolderAllowed: false,
    };
  }

  async uploadDriveItem(
    fileName: string,
    fileData: any,
    filePath: string,
    existingNameBehavior: ConflictBehavior,
    resource: string,
    metadata: DriveActionMetadata,
    progressCallback: (progressPercent: number) => void
  ) {
    const fileHandler = this.fileHandler;

    const userId = this.authenticationService.getUserId();
    const projectId = this.projectId;

    const fileId = Utils.createUUID();

    const arrayBuffer = await new Promise<ArrayBuffer>(resolve => {
      const fileReader = new FileReader();

      fileReader.onload = e => {
        const data = e.target.result as ArrayBuffer;

        resolve(data);
      };

      fileReader.readAsArrayBuffer(fileData);
    });

    const resourceIdentifiers = await this.getResourceIdentifiers(projectId);
    const resourceIdentifier = resourceIdentifiers.find(r => r.key.name == resource);

    const me = new IdentitySetModel({ user: new IdentityModel({ id: userId }) });
    const directory = OfflineService.getDirectoryPath(projectId, resourceIdentifier.key.name, filePath, fileName);

    const data = new Uint8Array(arrayBuffer);
    const date = new Date();

    await fileHandler.saveFile(directory + OfflineService.OriginalSuffix, Directory.Data, data);

    const driveItem = new DriveItemModel({
      id: fileId,
      name: fileName,
      path: '/' + filePath,
      size: fileData.size,
      type: DriveItemType.File,
      mimeType: fileData.type,

      createdBy: me,
      createdDateTime: date,

      lastModifiedBy: me,
      lastModifiedDateTime: date,

      resourceIdentifier,
    });

    const statements = await this.queryWrapper.getStatements();

    statements.insertOrReplace<IPSDriveItem>(SQLiteTables.DriveItems, {
      id: driveItem.id,
      project: projectId,
      relatedEntityId: metadata?.relatedEntityId,

      type: driveItem.type,
      path: driveItem.path,
      name: driveItem.name,
      mimeType: driveItem.mimeType,
      resource: driveItem.resourceIdentifier.key.name,

      json: JSON.stringify(driveItem),
      mode: MigrationStatus.INSERT,
      deleted: false,
      modifiedOn: driveItem.lastModifiedDateTime,
    });

    if (fileData.type.startsWith('image/')) {
      try {
        await this.createPreviews(fileHandler, directory, fileData);
      } catch ($err) {
        this.logService.error('failed to convert image', $err);
      }
    }

    await statements.execute();

    return new DriveUploadResultModel({
      fileId: fileId,
      fileName: fileName,
    });
  }

  private async createPreviews(fileHandler: OfflineServiceFileHandler, directory: string, fileData: any) {
    const sizes = [
      { size: 800, type: FilePreviewSpec.Preview },
      { size: 80, type: FilePreviewSpec.Thumbnail },
    ];

    const image = new Image();

    await new Promise((res, rej) => {
      image.onerror = async (evt, source, lineNr, columnNr, error) => {
        // Logging in next, try & catch
        rej(error);
      };

      image.onload = async () => {
        try {
          const canvas = document.createElement('canvas');
          const context = canvas.getContext('2d');

          const w = image.naturalWidth;
          const h = image.naturalHeight;

          for (const s of sizes) {
            if (w > h) {
              const f = h / w;
              canvas.width = s.size * 1;
              canvas.height = s.size * f;
            } else {
              const f = w / h;
              canvas.width = s.size * f;
              canvas.height = s.size * 1;
            }

            context.clearRect(0, 0, canvas.width, canvas.height);
            context.drawImage(image, 0, 0, canvas.width, canvas.height);

            const blob = await new Promise<Blob>(res => canvas.toBlob(res, 'image/png'));

            const arrayBuffer = await blob.arrayBuffer();

            const data = new Uint8Array(arrayBuffer);

            await fileHandler.saveFile(directory + '/' + s.type + '.bin', Directory.Data, data);
          }

          canvas.remove();

          res(true);
        } catch ($err) {
          // Logging in next, try & catch
          rej($err);
        }
      };

      image.src = URL.createObjectURL(fileData);
    });

    URL.revokeObjectURL(image.src);

    image.remove();
  }

  getViews(): Promise<PropertyBagModel[]> {
    const array = new Array<PropertyBagModel>();

    return Promise.resolve(array);
  }

  async getOrganizations(): Promise<OrganizationModel[]> {
    const orgs = [];

    for (const o of await this.queryWrapper.getOrganizations(this.projectId)) {
      const syncModel = JSON.parse(o.json) as SyncOrganizationModel;
      const model = new OrganizationModel({
        address: syncModel.address,
        name: syncModel.name,
        id: syncModel.id,
        isPartOfProject: syncModel.isPartOfProject,
      });

      orgs.push(model);
    }

    return orgs;
  }

  async getTenantSettings(): Promise<SettingsModel> {
    const metadata = await this.queryWrapper.getMetadata(MetadataKey.tenantSettings);

    if (!metadata) throw new Error('TENANT SETTINGS NOT FOUND IN DATABASE');

    return new SettingsModel(JSON.parse(metadata));
  }

  async getPlanSchemaForProject(resource: string): Promise<PlanSchemaDefinition> {
    const type = await this.getConfigForResource(resource);
    const schema = await this.queryWrapper.getSchemaWhereType(this.projectId, type);

    if (!schema) throw new Error('Unable to find schema');

    const model: PlanSchemaModel = JSON.parse(schema.json);

    return model.definition;
  }

  async getRolesForProject(): Promise<TeamRole[]> {
    const obj = await this.queryWrapper.getObjectById<SyncSchemaModel>(SQLiteTables.Schema, {
      type: 'Definition',
      project: this.projectId,
    });

    return obj.team.definition.roles;
  }

  async getUserPrivilegesForProject(projectId: string): Promise<PrivilegeEnum[]> {
    const rows = await this.queryWrapper.getUserPrivilegesForProject(projectId);

    return rows.map(r => r.id as PrivilegeEnum);
  }

  async getUserForOrganisationInProject(organizationId: string): Promise<UserModel[]> {
    const projectId = this.projectId;

    const rows = await this.queryWrapper.getProjectUsersWithUser(projectId);

    return rows
      .map(x => {
        const userModel: SyncUserModel = JSON.parse(x.json);
        const projectUserModel: SyncProjectUserModel = JSON.parse(x.projectUserModel);

        return {
          ...userModel,
          isImpersonateAssignment: projectUserModel.isImpersonateAssignment,
        };
      })
      .filter(x => {
        return x.organizationId == organizationId && x.isImpersonateAssignment == false;
      })
      .map(y => {
        return new UserModel({
          id: y.id,
          username: y.username,
        });
      });
  }

  async editDefectComment(comment: CommentModel) {
    const userId = this.authenticationService.getUserId();
    const projectId = this.projectId;
    const modifiedOn = new Date();

    const row = await this.queryWrapper.getCommentById(comment.id);

    const mode = row.mode == MigrationStatus.INSERT ? MigrationStatus.INSERT : MigrationStatus.UPDATE;

    const statements = await this.queryWrapper.getStatements();

    statements.insertOrReplace<IPSComment>(SQLiteTables.Comments, {
      id: comment.id,
      project: projectId,

      text: comment.text,
      editorId: userId,

      mode: mode,
      modifiedOn: modifiedOn,
      deleted: false,
    });

    statements.execute();
  }

  async saveDefect(defect: AddOrUpdateDefectModel, files: FileParameter[]): Promise<string> {
    const userId = this.authenticationService.getUserId();
    const projectId = this.projectId;
    const defectId = defect.id || Utils.createUUID();
    const modifiedOn = new Date();

    let oldDefect: SyncDefectModel;
    if (defect.id) {
      oldDefect = await this.queryWrapper.getObjectById<SyncDefectModel>(SQLiteTables.Defects, defect.id);
    } else {
      const emptyDefect = await this.createEmptyDefect();
      oldDefect = SyncDefectModel.fromJS({
        ...emptyDefect,
        modifiedOn: modifiedOn,
        createdOn: modifiedOn,
        createdId: userId,
        changeSets: [],
        number: 0,
      });
    }

    const resourceIdentifiers = await this.getResourceIdentifiers(projectId);
    const resource = resourceIdentifiers.find(i => i.moduleType === ModuleType.Defect).key.name;

    const userAssignments = defect.userAssignment.map(x => new SyncDefectUserAssignmentModel({ userId: x.id }));

    const syncModelChanges = (oldDefect.changeSets || []).map(ChangeSetModel.fromJS);

    const newState =
      defect.stateId == oldDefect.currentState.key
        ? oldDefect.currentState
        : oldDefect.allowedStates.find(state => state.key == defect.stateId);

    const syncModel: ISyncDefectModel = {
      id: defectId,
      number: oldDefect.number,
      createdId: oldDefect.createdId,
      allowedPermissions: oldDefect.allowedPermissions ?? [DefectStatePermission.Defect_edit_basic],
      changeSets: syncModelChanges,
      userAssignments: userAssignments,
      zonesIds: defect.zones,

      createdOn: this.safeDate(oldDefect.createdOn),
      modifiedOn: modifiedOn,
      graceExpiredMailCreatedOn: this.safeDate(oldDefect.graceExpiredMailCreatedOn),
      deadlineExpiredMailCreatedOn: this.safeDate(oldDefect.deadlineExpiredMailCreatedOn),
      deadlineReminderMailCreatedOn: this.safeDate(oldDefect.deadlineReminderMailCreatedOn),

      stateId: newState.key,
      stateType: newState.stateType,
      currentState: newState,
      allowedStates: oldDefect.allowedStates,

      areaId: defect.areaId,

      regionId: defect.regionId,
      roomId: defect.roomId,
      typeId: defect.typeId,
      craftId: defect.craftId,
      floorId: defect.floorId,
      reasonId: defect.reasonId,
      organizationId: defect.organizationId,
      reduction: defect.reduction,
      approvalExtern: this.safeDate(defect.approvalExtern),
      approvalIntern: this.safeDate(defect.approvalIntern),

      title: defect.title,
      retention: defect.retention,
      description: defect.description,

      grace: this.safeDate(defect.grace),
      deadline: this.safeDate(defect.deadline),
      approvalOfBuilder: this.safeDate(defect.approvalOfBuilder),

      authorId: userId,
      isDeleted: false,
    };

    const mode = syncModel.number ? MigrationStatus.UPDATE : MigrationStatus.INSERT;

    const statements = await this.queryWrapper.getStatements();

    statements.insertOrReplace<IPSDefect>(SQLiteTables.Defects, {
      id: defectId,
      project: projectId,

      json: JSON.stringify(SyncDefectModel.fromJS(syncModel)),
      mode: mode,
      deleted: false,
      modifiedOn: modifiedOn,
    });

    for (const file of files) {
      this.uploadDriveItem(
        file.fileName,
        file.data,
        defectId,
        ConflictBehavior.Fail,
        resource,
        DriveActionMetadata.fromJS({
          relatedEntityId: defectId,
        }),
        null
      );
    }

    for (const comment of defect.comments || []) {
      const mode = comment.id ? MigrationStatus.UPDATE : MigrationStatus.INSERT;
      const commentId = comment.id || Utils.createUUID();
      const modifiedOn = comment.modifiedOn || new Date();

      statements.insertOrReplace<IPSComment>(SQLiteTables.Comments, {
        id: commentId,
        project: projectId,

        text: comment.text,
        editorId: userId,

        mode: mode,
        deleted: false,
        modifiedOn: modifiedOn,
      });

      if (mode == MigrationStatus.INSERT) {
        statements.insertOrReplace<IPSDefectComment>(SQLiteTables.DefectComments, {
          id: Utils.createUUID(),
          project: projectId,

          defectId: defectId,
          commentId: commentId,

          mode: mode,
          deleted: false,
          modifiedOn: modifiedOn,
        });
      }
    }

    statements.execute();

    return syncModel.id;
  }

  async getDefectsForProject(): Promise<DefectModel[]> {
    const defects: DefectModel[] = [];

    const d = await this.queryWrapper.getDefects(this.projectId);

    for (const o of d) {
      const syncModel: SyncDefectModel = JSON.parse(o.json);
      const model = DefectModel.fromJS(syncModel);

      await this.prepareDefectModel(model, syncModel);

      defects.push(model);
    }

    await this.loadNavigationProps(defects);

    return defects;
  }

  private async loadNavigationProps(defects: DefectModel[]) {
    const comments = await this.queryWrapper.getCommentsWithUserWhereDefects(defects.map(d => d.id));

    const rooms = await this.queryWrapper.getObjectsByIds<SyncRoomModel>(
      SQLiteTables.Rooms,
      defects.map(d => d.roomId)
    );
    const areas = await this.queryWrapper.getObjectsByIds<SyncAreaModel>(
      SQLiteTables.Areas,
      defects.map(d => d.areaId)
    );
    const floors = await this.queryWrapper.getObjectsByIds<SyncFloorModel>(
      SQLiteTables.Floors,
      defects.map(d => d.floorId)
    );
    const crafts = await this.queryWrapper.getObjectsByIds<SyncCraftModel>(
      SQLiteTables.Crafts,
      defects.map(d => d.craftId),
      true
    );
    const organizations = await this.queryWrapper.getObjectsByIds<SyncOrganizationModel>(
      SQLiteTables.Organizations,
      defects.map(d => d.organizationId),
      true
    );
    const regions = await this.queryWrapper.getObjectsByIds<SyncRegionModel>(
      SQLiteTables.Regions,
      defects.map(d => d.regionId)
    );
    const creators = await this.queryWrapper.getObjectsByIds<SyncUserModel>(
      SQLiteTables.Users,
      defects.map(d => d.createdBy),
      true
    );

    for (const defect of defects) {
      const room = rooms.find(r => r.id == defect.roomId);
      if (room) {
        defect.roomName = room.title;
        defect.roomNumber = room.internalNumber;
      }
      const area = areas.find(a => a.id == defect.areaId);
      if (area) {
        defect.areaName = area.title;
      }
      const floor = floors.find(f => f.id == defect.floorId);
      if (floor) {
        defect.floorName = floor.title;
      }
      const craft = crafts.find(c => c.id == defect.craftId);
      if (craft) {
        defect.craftName = craft.title;
      }
      const organization = organizations.find(c => c.id == defect.organizationId);
      if (organization) {
        defect.organization = new OrganizationModel({
          name: organization.name,
          address: organization.address,
          isPartOfProject: organization.isPartOfProject,
          id: organization.id,
        });
      }
      const region = regions.find(r => r.id == defect.regionId);
      if (region) {
        defect.regionName = region.title;
      }
      const creator = creators.find(c => c.id == defect.createdBy);
      if (creator) {
        defect.createdBy = creator.displayUsername;
      }

      defect.comments = comments.map(x => {
        const jsonUser = JSON.parse(x.jsonUser);
        return new CommentModel({
          modifiedOn: new Date(x.modifiedOn),
          createdBy: jsonUser.displayUsername,
          createdOn: new Date(x.modifiedOn),
          editorId: x.editorId,
          text: x.text,
          id: x.id,
        });
      });
    }
  }

  private async prepareDefectModel(model: DefectModel, syncModel: SyncDefectModel) {
    const userAssignments = syncModel.userAssignments || [];
    model.assignedUsers = userAssignments.map(x => {
      return new UserModel({
        id: x.userId,
      });
    });
  }

  async createEmptyDefect(): Promise<DefectModel> {
    const metadata = await this.queryWrapper.getMetadata(MetadataKey.emptyDefect);

    if (!metadata) throw new Error('EMPTY DEFECT NOT FOUND IN DATABASE');

    return DefectModel.fromJS(JSON.parse(metadata));
  }

  async getDefect(id: string): Promise<DefectModel> {
    const defect = await this.queryWrapper.getDefectById(id);

    if (!defect) return null;

    const syncModel: SyncDefectModel = JSON.parse(defect.json);

    let zones = [];
    if (syncModel.zonesIds?.length) {
      const allZones = await this.getZonesForProject();
      zones = this.mapZoneIds(syncModel.zonesIds, allZones);
    }

    const model: DefectModel = new DefectModel({ ...syncModel, zones });

    await this.prepareDefectModel(model, syncModel);

    await this.loadNavigationProps([model]);

    return model;
  }

  async getDefectTypes(): Promise<DefectTypeModel[]> {
    const types = new Array<DefectTypeModel>();

    for (const o of await this.queryWrapper.getDefectTypes(this.projectId)) {
      const syncModel: SyncDefectTypeModel = JSON.parse(o.json);
      const model = new DefectTypeModel({
        id: syncModel.id,

        name: syncModel.name,
        code: syncModel.code,
        hexColor: syncModel.hexColor,
        isDefault: syncModel.isDefault,
      });

      types.push(model);
    }

    return types;
  }

  async getReasons(): Promise<DefectReasonModel[]> {
    const crafts = new Array<DefectReasonModel>();

    for (const o of await this.queryWrapper.getDefectReasons(this.projectId)) {
      const syncModel: SyncReasonModel = JSON.parse(o.json);
      const model = new DefectReasonModel({
        id: syncModel.id,

        name: syncModel.name,
        code: syncModel.code,
      });

      crafts.push(model);
    }

    return crafts;
  }

  async getCrafts(): Promise<CraftModel[]> {
    const crafts = new Array<CraftModel>();

    for (const o of await this.queryWrapper.getCrafts()) {
      const syncModel: SyncCraftModel = JSON.parse(o.json);
      const model = new CraftModel({
        name: syncModel.title,
        id: syncModel.id,
        isPartOfProject: syncModel.isPartOfProject,
      });

      crafts.push(model);
    }

    return crafts;
  }

  async getAreasForProject(): Promise<AreaModel[]> {
    const areas = new Array<AreaModel>();
    const zones = await this.getZonesForProject();

    for (const o of await this.queryWrapper.getAreas(this.projectId)) {
      const syncModel: SyncAreaModel = JSON.parse(o.json);

      const model = new AreaModel({
        id: syncModel.id,
        name: syncModel.title,
        zones: this.mapZoneIds(syncModel.zonesIds, zones),

        floors: await this.getFloorsByArea(syncModel.id, { [syncModel.id]: syncModel }, zones),
        regions: await this.getRegionsByArea(syncModel.id),
      });

      areas.push(model);
    }

    return areas;
  }

  async getOrganizationsForProject(): Promise<OrganizationModel[]> {
    const models = new Array<OrganizationModel>();

    for (const o of await this.queryWrapper.getProjectOrganizations(this.projectId)) {
      const syncModel: SyncProjectOrganizationModel = JSON.parse(o.json);

      const organization = await this.queryWrapper.getObjectById<SyncOrganizationModel>(
        SQLiteTables.Organizations,
        syncModel.organizationId
      );

      const model = new OrganizationModel({
        id: organization.id,

        name: organization.name,
        address: organization.address,

        isPartOfProject: syncModel.isPartOfProject,

        crafts: await this.getCraftsByProjectOrganization(o.id),
      });

      models.push(model);
    }

    return models;
  }

  async getRegionsByArea(areaId: string): Promise<RegionModel[]> {
    const models = new Array<RegionModel>();

    for (const o of await this.queryWrapper.getRegionsWhereArea(areaId)) {
      const syncModel: SyncRegionModel = JSON.parse(o.json);

      const model = new RegionModel({
        id: syncModel.id,
        name: syncModel.title,

        areaId: areaId,
      });

      models.push(model);
    }

    return models;
  }

  async getFloorsByArea(areaId: string, areas: Record<string, SyncAreaModel>, zones: ZoneModel[]): Promise<FloorModel[]> {
    const models = new Array<FloorModel>();

    zones = zones ?? (await this.getZonesForProject());

    for (const o of await this.queryWrapper.getFloorsWhereArea(areaId)) {
      const syncModel: SyncFloorModel = JSON.parse(o.json);

      const model = new FloorModel({
        id: syncModel.id,
        name: syncModel.title,

        rooms: await this.getRoomsByFloor(syncModel.id, areas, { [syncModel.id]: syncModel }, zones),
        zones: this.mapZoneIds(syncModel.zonesIds, zones),

        areaId: areaId,
        regionId: syncModel.regionId,
      });

      models.push(model);
    }

    return models;
  }

  async getRoomById(roomId: string): Promise<RoomModel> {
    const roomDtos = await this.queryWrapper.getRoomsWhereId(roomId);
    const rooms = await this.mapRooms(roomDtos);

    return rooms.pop();
  }

  async getRoomsByFloor(
    floorId: string,
    areas: Record<string, SyncAreaModel>,
    floors: Record<string, SyncFloorModel>,
    zones: ZoneModel[]
  ): Promise<RoomModel[]> {
    const roomDtos = await this.queryWrapper.getRoomsWhereFloor(floorId);
    return await this.mapRooms(roomDtos, areas, floors, zones);
  }

  private async mapRooms(
    roomDtos: IPSRoom[],
    areas: Record<string, SyncAreaModel> = {},
    floors: Record<string, SyncFloorModel> = {},
    zones: ZoneModel[] = null
  ) {
    const models = new Array<RoomModel>();

    zones = zones ?? (await this.getZonesForProject());

    for (const o of roomDtos) {
      const syncModel: SyncRoomModel = JSON.parse(o.json);

      const floorId = syncModel.floorId;
      if (floors[floorId] == null) {
        const floorDto = await this.queryWrapper.getFloorWhereId(floorId);
        floors[floorId] = JSON.parse(floorDto.json);
      }

      const areaId = floors[floorId].areaId;
      if (areas[areaId] == null) {
        const areaDto = await this.queryWrapper.getAreaWhereId(areaId);
        areas[areaId] = JSON.parse(areaDto.json);
      }

      const model = new RoomModel({
        id: syncModel.id,
        name: syncModel.title,
        regionId: syncModel.regionId,

        areaId,
        floorId,
        internalNumber: syncModel.internalNumber,

        zones: [
          ...this.mapZoneIds(areas[areaId].zonesIds, zones, true),
          ...this.mapZoneIds(floors[floorId].zonesIds, zones, true),
          ...this.mapZoneIds(syncModel.zonesIds, zones),
        ],
      });

      const index = models.findIndex(m => m.internalNumber > model.internalNumber);

      models.splice(index < 0 ? models.length : index, 0, model);
    }

    return models;
  }

  async getCraftsByProjectOrganization(projectOrganizationId: string): Promise<CraftModel[]> {
    const models = new Array<CraftModel>();

    for (const o of await this.queryWrapper.getProjectOrganizationCrafts(projectOrganizationId)) {
      const syncModel: SyncProjectOrganizationCraftModel = JSON.parse(o.json);

      const craft = await this.queryWrapper.getObjectById<SyncCraftModel>(SQLiteTables.Crafts, syncModel.craftId);

      const model = new CraftModel({
        id: craft.id,
        name: craft.title,
        isPartOfProject: craft.isPartOfProject,
      });

      models.push(model);
    }

    return models;
  }

  async getZoneGroupsForProject(): Promise<ZoneGroupModel[]> {
    const zoneGroups = new Array<ZoneGroupModel>();

    for (const o of await this.queryWrapper.getZoneGroups(this.projectId)) {
      const syncModel: SyncZoneGroupModel = JSON.parse(o.json);

      const model = new ZoneGroupModel({
        id: syncModel.id,
        name: syncModel.name,

        zones: await this.getZonesByZoneGroup(syncModel.id),
      });

      zoneGroups.push(model);
    }

    return zoneGroups;
  }

  async getZonesForProject(): Promise<ZoneModel[]> {
    const zoneDtos = await this.queryWrapper.getZones(this.projectId);
    return this.mapZones(zoneDtos);
  }

  async getZonesByZoneGroup(zoneGroupId: string): Promise<ZoneModel[]> {
    const zoneDtos = await this.queryWrapper.getZonesWhereZoneGroup(zoneGroupId);
    return this.mapZones(zoneDtos);
  }

  private mapZones(zoneDtos: IPSZone[]) {
    const zones = new Array<ZoneModel>();

    for (const o of zoneDtos) {
      const syncModel: SyncZoneModel = JSON.parse(o.json);

      const model = new ZoneModel({
        id: syncModel.id,
        name: syncModel.name,

        zoneGroupId: syncModel.zoneGroupId,
      });

      zones.push(model);
    }

    return zones;
  }

  private async getConfigForResource(resource: string) {
    const resoureIdentifiers = await this.getResourceIdentifiers(this.projectId);
    const module = resoureIdentifiers.find(i => i.key.name == resource).moduleType;

    switch (module) {
      case ModuleType.Bim:
        return ConfigType.BimSchema;
      case ModuleType.Plan:
        return ConfigType.PlanSchema;
      default:
        throw new Error('Unable to find resource: ' + module);
    }
  }

  uploadLocalData(projectId: string, steps: ActivitySteps) {
    const fileHandler = this.fileHandler;

    const stepGetChanges = new ActivityStepWithProgress('offline.activitySteps.computeChanges');
    const stepUploadDefects = new ActivityStepWithProgress(
      'offline.activitySteps.uploadDefects',
      'offline.activitySteps.uploadedDefects'
    );
    const stepUploadFiles = new ActivityStepWithProgress(
      'offline.activitySteps.uploadFiles',
      'offline.activitySteps.uploadedFiles'
    );

    steps.addItem(stepGetChanges);
    steps.addItem(stepUploadDefects);
    steps.addItem(stepUploadFiles);

    return async (cancellationToken: CancellationToken) => {
      const statements = await this.queryWrapper.getStatements();

      // Get Changed Defects
      let defects: IPSDefect[] = [];
      stepGetChanges.start();
      try {
        defects = await this.queryWrapper.getDefectsWhereModified(projectId);
      } catch (e) {
        stepGetChanges.addError(this.unexpectedError);
        stepGetChanges.complete();
        return;
      }

      let files = [];
      try {
        const resoureIdentifiers = await this.getResourceIdentifiers(projectId);
        const resource = resoureIdentifiers.find(i => i.moduleType === ModuleType.Defect).key.name;
        files = await this.queryWrapper.getDriveItemsWhereModeIsInsertAndResource(projectId, resource);
      } catch (e) {
        stepGetChanges.addError(this.unexpectedError);
        stepGetChanges.complete();
        return;
      }

      stepGetChanges.complete();

      // Upload Defects
      stepUploadDefects.maxProgress = defects.length;
      stepUploadDefects.start();

      for (const defect of defects) {
        const canContinue = await this.syncDefect(defect, statements, projectId, stepUploadDefects);

        if (!canContinue) {
          stepUploadDefects.complete();
          return false;
        }

        stepUploadDefects.advance();
      }

      stepUploadDefects.complete();

      // Upload Files
      stepUploadFiles.maxProgress = files.length;
      stepUploadFiles.start();

      for (const file of files) {
        const canContinue = await this.syncFile(projectId, file, fileHandler, statements, stepUploadFiles);

        if (!canContinue) {
          stepUploadFiles.complete();
          return false;
        }

        stepUploadFiles.advance();
      }

      stepUploadFiles.complete();

      return (
        stepUploadDefects.currentState == ActivityStepState.success && stepUploadFiles.currentState == ActivityStepState.success
      );
    };
  }

  updateLocalData(projectId: string, steps: ActivitySteps) {
    const stepOfFetchChanges = new ActivityStepWithProgress('offline.activitySteps.fetchChanges');
    const stepOfUpdateDatabase = new ActivityStepWithProgress(
      'offline.activitySteps.migrateTables',
      'offline.activitySteps.migratedTables',
      22
    ); // all executes until files
    const stepOfDownloadFiles = new ActivityStepWithProgress(
      'offline.activitySteps.downloadFiles',
      'offline.activitySteps.downloadedFiles'
    );

    steps.addItem(stepOfFetchChanges);
    steps.addItem(stepOfUpdateDatabase);
    steps.addItem(stepOfDownloadFiles);

    const fetchChanges = async (cancellationToken: CancellationToken) => {
      const statements = await this.queryWrapper.getStatements();
      const startDate = new Date();

      stepOfFetchChanges.start();
      let changes: SyncModel;
      const syncInfo = {
        sync_checksum: null,
        offline: true,
      };

      try {
        const project = await this.queryWrapper.getProjectById(projectId);
        Object.assign(syncInfo, project);

        const changeRequest = new SyncModel({
          syncFingerprint: syncInfo.sync_checksum,

          tenantSettings: new SyncTenantSettingsCategory(),

          emptyEntities: new SyncEmptyEntityCategory(),

          resources: new SyncResourceCategory(),

          projectOrganizationCrafts: new SyncProjectOrganizationCraftCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.ProjectOrganizationCrafts),
          }),
          projectOrganizations: new SyncProjectOrganizationCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.ProjectOrganizations),
          }),
          projectPrivileges: new SyncProjectPrivilegesCategory({
            full: true,
          }),
          projectUsers: new SyncProjectUserCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.ProjectUsers),
          }),
          project: new SyncProjectCategory({
            full: true,
          }),

          schema: new SyncSchemaCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.Schema),
          }),

          driveItems: new SyncDriveItemCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.DriveItems, QueryCondition.onlyOriginal),
          }),

          defects: new SyncDefectCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.Defects, QueryCondition.onlyOriginal),
          }),
          defectTypes: new SyncDefectTypeCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.DefectTypes),
          }),
          defectComments: new SyncDefectCommentCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.DefectComments, QueryCondition.onlyOriginal),
          }),

          organizations: new SyncOrganizationCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.Organizations),
          }),
          comments: new SyncCommentsCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.Comments, QueryCondition.onlyOriginal),
          }),
          reasons: new SyncReasonCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.DefectReasons),
          }),
          regions: new SyncRegionCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.Regions),
          }),
          crafts: new SyncCraftCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.Crafts),
          }),
          floors: new SyncFloorCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.Floors),
          }),
          areas: new SyncAreaCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.Areas),
          }),
          rooms: new SyncRoomCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.Rooms),
          }),
          zoneGroups: new SyncZoneGroupCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.ZoneGroups),
          }),
          zones: new SyncZoneCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.Zones),
          }),
          users: new SyncUserCategory({
            from: await this.queryWrapper.getLastModified(SQLiteTables.Users),
          }),
        });

        try {
          changes = await this.api.getChanges(projectId, changeRequest).toPromise();
        } catch {
          stepOfFetchChanges.addError({
            topic: 'offline.activitySteps.errors.topic.fetch',
            description: 'offline.activitySteps.errors.reasons.fetchtryagain',
          });
          return false;
        }
      } catch (e) {
        stepOfUpdateDatabase.addError(this.unexpectedError);
        return false;
      } finally {
        // console.info('fetchChanges done');
        stepOfFetchChanges.complete();
      }

      const filesToPreview = [];
      stepOfUpdateDatabase.start();
      try {
        statements.insertOrReplace<IPSMetadata>(SQLiteTables.Metadata, {
          name: MetadataKey.updateDate,
          data: startDate.toString(),
        });

        if (changes.tenantSettings) {
          // we could store it like all the other tables
          const settings = changes.tenantSettings;

          statements.insertOrReplace<IPSMetadata>(SQLiteTables.Metadata, {
            name: MetadataKey.tenantSettings,
            data: JSON.stringify(settings),
          });
        }

        if (changes.emptyEntities) {
          const emptyEntities = changes.emptyEntities;

          statements.insertOrReplace<IPSMetadata>(SQLiteTables.Metadata, {
            name: MetadataKey.emptyDefect,
            data: JSON.stringify(emptyEntities.defectModel),
          });
        }

        await statements.execute();

        stepOfUpdateDatabase.advance();

        // console.info('tenant settings done');

        if (changes.resources) {
          statements.remove(SQLiteTables.Resources, { project: projectId });

          for (const resource of changes.resources.entries) {
            statements.insertOrReplace<IPSResource>(SQLiteTables.Resources, {
              id: resource.key.name,
              project: projectId,
              json: JSON.stringify(resource),
            });
          }
        }

        await statements.execute();

        stepOfUpdateDatabase.advance();

        // console.info('resources done');

        if (changes.project) {
          if (changes.project.full) {
            statements.remove(SQLiteTables.Projects, { id: projectId });
          }

          for (const project of changes.project.entries) {
            statements.insertOrReplace<IPSProject>(SQLiteTables.Projects, {
              id: project.id,
              json: JSON.stringify(project),
              offline: true,
              modifiedOn: startDate,
              sync_checksum: changes.syncFingerprint,
              sync_datetime: startDate,
            });
          }
        }

        await statements.execute();

        stepOfUpdateDatabase.advance();

        // console.info('projects done');

        if (changes.projectPrivileges) {
          if (changes.projectPrivileges.full) {
            statements.remove(SQLiteTables.ProjectPrivileges, { project: projectId });
          }

          for (const projectPrivilege of changes.projectPrivileges.entries) {
            statements.insertOrReplace<IPSProjectPrivilege>(SQLiteTables.ProjectPrivileges, {
              id: projectPrivilege.name,
              project: projectId,

              json: JSON.stringify(projectPrivilege),

              deleted: false,
              modifiedOn: startDate,
            });
          }
        }

        await statements.execute();

        stepOfUpdateDatabase.advance();

        // console.info('project privileges done');

        if (changes.crafts.entries) {
          if (changes.crafts.full) {
            statements.remove(SQLiteTables.Crafts, { project: projectId });
          }

          for (const craft of changes.crafts.entries) {
            statements.insertOrReplace<IPSCraft>(SQLiteTables.Crafts, {
              id: craft.id,
              project: projectId,

              json: JSON.stringify(craft),

              deleted: craft.isDeleted,
              modifiedOn: craft.modifiedOn,
            });
          }
        }

        await statements.execute();

        stepOfUpdateDatabase.advance();

        // console.info('crafts done');

        if (changes.areas.entries) {
          if (changes.areas.full) {
            statements.remove(SQLiteTables.Areas, { project: projectId });
          }

          for (const area of changes.areas.entries) {
            statements.insertOrReplace<IPSArea>(SQLiteTables.Areas, {
              id: area.id,
              project: projectId,

              json: JSON.stringify(area),

              deleted: area.isDeleted,
              modifiedOn: area.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('areas done');

        if (changes.defects.entries) {
          if (changes.defects.full) {
            statements.remove(SQLiteTables.Defects, { project: projectId, mode: MigrationStatus.EXISTS });
          }

          for (const defect of changes.defects.entries) {
            statements.insertOrReplace<IPSDefect>(SQLiteTables.Defects, {
              id: defect.id,
              project: projectId,

              json: JSON.stringify(defect),

              mode: 'EXISTS',
              deleted: defect.isDeleted,
              modifiedOn: defect.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('defects done');

        if (changes.defectTypes.entries) {
          if (changes.defectTypes.full) {
            statements.remove(SQLiteTables.DefectTypes, { project: projectId });
          }

          for (const defectType of changes.defectTypes.entries) {
            statements.insertOrReplace<IPSDefectType>(SQLiteTables.DefectTypes, {
              id: defectType.id,
              project: projectId,

              code: defectType.code,

              json: JSON.stringify(defectType),

              deleted: defectType.isDeleted,
              modifiedOn: defectType.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('defectTypes done');

        if (changes.defectComments.entries) {
          if (changes.defectComments.full) {
            statements.remove(SQLiteTables.DefectComments, { project: projectId, mode: MigrationStatus.EXISTS });
          }

          for (const defectComment of changes.defectComments.entries) {
            statements.insertOrReplace<IPSDefectComment>(SQLiteTables.DefectComments, {
              id: defectComment.id,
              project: projectId,

              defectId: defectComment.defectId,
              commentId: defectComment.commentId,

              mode: 'EXISTS',
              deleted: defectComment.isDeleted,
              modifiedOn: defectComment.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('defectComments done');

        if (changes.comments.entries) {
          if (changes.comments.full) {
            statements.remove(SQLiteTables.Comments, { project: projectId, mode: MigrationStatus.EXISTS });
          }

          for (const comment of changes.comments.entries) {
            statements.insertOrReplace<IPSComment>(SQLiteTables.Comments, {
              id: comment.id,
              project: projectId,

              text: comment.text,

              editorId: comment.editorId,
              replyToCommentId: comment.replyToCommentId,

              mode: 'EXISTS',
              deleted: comment.isDeleted,
              modifiedOn: comment.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('comments done');

        if (changes.floors.entries) {
          if (changes.floors.full) {
            statements.remove(SQLiteTables.Floors, { project: projectId });
          }

          for (const floor of changes.floors.entries) {
            statements.insertOrReplace<IPSFloor>(SQLiteTables.Floors, {
              id: floor.id,
              project: projectId,

              areaId: floor.areaId,

              json: JSON.stringify(floor),

              deleted: floor.isDeleted,
              modifiedOn: floor.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('floors done');

        if (changes.regions.entries) {
          if (changes.regions.full) {
            statements.remove(SQLiteTables.Regions, { project: projectId });
          }

          for (const region of changes.regions.entries) {
            statements.insertOrReplace<IPSRegion>(SQLiteTables.Regions, {
              id: region.id,
              project: projectId,

              areaId: region.areaId,

              json: JSON.stringify(region),

              deleted: region.isDeleted,
              modifiedOn: region.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('regions done');

        if (changes.zoneGroups.entries) {
          if (changes.zoneGroups.full) {
            statements.remove(SQLiteTables.ZoneGroups, { project: projectId });
          }

          for (const zoneGroup of changes.zoneGroups.entries) {
            statements.insertOrReplace<IPSZoneGroup>(SQLiteTables.ZoneGroups, {
              id: zoneGroup.id,
              project: projectId,

              json: JSON.stringify(zoneGroup),

              deleted: zoneGroup.isDeleted,
              modifiedOn: zoneGroup.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        if (changes.zones.entries) {
          if (changes.zones.full) {
            statements.remove(SQLiteTables.Zones, { project: projectId });
          }

          for (const zone of changes.zones.entries) {
            statements.insertOrReplace<IPSZone>(SQLiteTables.Zones, {
              id: zone.id,
              project: projectId,

              zoneGroupId: zone.zoneGroupId,

              json: JSON.stringify(zone),

              deleted: zone.isDeleted,
              modifiedOn: zone.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        if (changes.reasons.entries) {
          if (changes.reasons.full) {
            statements.remove(SQLiteTables.DefectReasons, { project: projectId });
          }

          for (const reason of changes.reasons.entries) {
            statements.insertOrReplace<IPSReason>(SQLiteTables.DefectReasons, {
              id: reason.id,
              project: projectId,

              code: reason.code,

              json: JSON.stringify(reason),

              deleted: reason.isDeleted,
              modifiedOn: reason.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('reasons done');

        if (changes.rooms.entries) {
          if (changes.rooms.full) {
            statements.remove(SQLiteTables.Rooms, { project: projectId });
          }

          for (const room of changes.rooms.entries) {
            statements.insertOrReplace<IPSRoom>(SQLiteTables.Rooms, {
              id: room.id,
              project: projectId,

              floorId: room.floorId,

              json: JSON.stringify(room),

              deleted: room.isDeleted,
              modifiedOn: room.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('rooms done');

        if (changes.schema.entries) {
          if (changes.schema.full) {
            statements.remove(SQLiteTables.Schema, { project: projectId });
          }

          for (const schema of changes.schema.entries) {
            const data = schema.plan || schema.team;
            const json = data ? JSON.stringify(data) : null;

            statements.insertOrReplace<IPSSchema>(SQLiteTables.Schema, {
              id: schema.id,
              project: projectId,

              type: schema.type,
              json,

              deleted: false,
              modifiedOn: schema.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('schema done');

        if (changes.driveItems.entries) {
          if (changes.driveItems.full) {
            for (const driveItem of await this.queryWrapper.getDriveItemsWhereMode(MigrationStatus.EXISTS, projectId)) {
              const directory = OfflineService.getDirectoryPath(projectId, driveItem.resource, driveItem.path, driveItem.name);

              try {
                Filesystem.rmdir({
                  path: directory,
                  directory: Directory.Data,
                  recursive: true,
                });
              } catch {
                // ignore
              }
            }

            statements.remove(SQLiteTables.DriveItems, { project: projectId, mode: MigrationStatus.EXISTS });
          }

          for (const driveItem of changes.driveItems.entries) {
            statements.insertOrReplace<IPSDriveItem>(SQLiteTables.DriveItems, {
              id: driveItem.id,
              project: projectId,
              relatedEntityId: driveItem.relatedEntityId,

              type: driveItem.type,
              path: driveItem.path,
              name: driveItem.name,
              mimeType: driveItem.mimeType || 'application/octet-stream',
              resource: driveItem.resourceIdentifier.key.name,

              json: JSON.stringify(driveItem),
              mode: 'EXISTS',
              deleted: false,
              modifiedOn: driveItem.lastModifiedDateTime,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('driveItems done');

        if (changes.organizations.entries) {
          if (changes.organizations.full) {
            statements.remove(SQLiteTables.Organizations, { project: projectId });
          }

          for (const organization of changes.organizations.entries) {
            statements.insertOrReplace<IPSOrganization>(SQLiteTables.Organizations, {
              id: organization.id,
              project: projectId,

              json: JSON.stringify(organization),

              deleted: organization.isDeleted,
              modifiedOn: organization.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('organizations done');

        if (changes.users.entries) {
          if (changes.users.full) {
            statements.remove(SQLiteTables.Users, { project: projectId });
          }

          for (const user of changes.users.entries) {
            statements.insertOrReplace<IPSUser>(SQLiteTables.Users, {
              id: user.id,
              project: projectId,

              json: JSON.stringify(user),

              deleted: user.isDeleted,
              modifiedOn: user.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('users done');

        if (changes.projectUsers.entries) {
          if (changes.projectUsers.full) {
            statements.remove(SQLiteTables.ProjectUsers, { project: projectId });
          }

          for (const projectUser of changes.projectUsers.entries) {
            statements.insertOrReplace<IPSProjectUser>(SQLiteTables.ProjectUsers, {
              id: projectUser.id,
              project: projectId,

              userId: projectUser.userId,

              json: JSON.stringify(projectUser),

              deleted: projectUser.isDeleted,
              modifiedOn: projectUser.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('projectUsers done');

        if (changes.projectOrganizations.entries) {
          if (changes.projectOrganizations.full) {
            statements.remove(SQLiteTables.ProjectOrganizations, { project: projectId });
          }

          for (const projectOrganization of changes.projectOrganizations.entries) {
            statements.insertOrReplace<IPSProjectOrganization>(SQLiteTables.ProjectOrganizations, {
              id: projectOrganization.id,
              project: projectId,

              organizationId: projectOrganization.organizationId,

              json: JSON.stringify(projectOrganization),

              deleted: projectOrganization.isDeleted,
              modifiedOn: projectOrganization.modifiedOn,
            });
          }

          await statements.execute();
        }

        stepOfUpdateDatabase.advance();

        // console.info('projectOrganization done');

        if (changes.projectOrganizationCrafts.entries) {
          if (changes.projectOrganizationCrafts.full) {
            statements.remove(SQLiteTables.ProjectOrganizationCrafts, { project: projectId });
          }

          for (const projectOrganizationCraft of changes.projectOrganizationCrafts.entries) {
            statements.insertOrReplace<IPSProjectOrganizationCraft>(SQLiteTables.ProjectOrganizationCrafts, {
              id: projectOrganizationCraft.id,
              project: projectId,

              projectOrganizationId: projectOrganizationCraft.projectOrganizationId,

              json: JSON.stringify(projectOrganizationCraft),

              deleted: projectOrganizationCraft.isDeleted,
              modifiedOn: projectOrganizationCraft.modifiedOn,
            });
          }

          await statements.execute();

          // console.info('projectOrganizationCrafts done');
        }
      } catch (e) {
        stepOfUpdateDatabase.addError(this.unexpectedError);
        return false;
      } finally {
        stepOfUpdateDatabase.complete();
      }

      try {
        for (const driveItem of await this.queryWrapper.getDriveItems(projectId)) {
          const directory = OfflineService.getDirectoryPath(projectId, driveItem.resource, driveItem.path, driveItem.name);

          if (driveItem.mimeType && driveItem.mimeType.startsWith('image')) {
            const files: string[] = [];
            try {
              const dir = await Filesystem.readdir({
                path: directory,
                directory: Directory.Data,
              });

              files.push(...dir.files.map(x => x.name));
            } catch {}

            for (const spec of [FilePreviewSpec.Preview, FilePreviewSpec.Thumbnail]) {
              if (files.every(x => x != `${spec}.bin`)) {
                filesToPreview.push({
                  driveItem,
                  fileSpec: spec,
                });
              }
            }
          }
        }

        stepOfDownloadFiles.maxProgress = filesToPreview.length;
        stepOfDownloadFiles.start();

        const token = `Bearer ${this.authenticationService.getCurrentAccessToken()}`;

        const directories = {};

        let failedToDownloadFiles = 0;
        for (const o of filesToPreview) {
          const fileSpec: FilePreviewSpec = o.fileSpec;
          const driveItem: IPSDriveItem = o.driveItem;

          const directory = OfflineService.getDirectoryPath(projectId, driveItem.resource, driveItem.path, driveItem.name);

          if (!(directory in directories)) {
            try {
              await Filesystem.mkdir({
                path: directory,
                directory: Directory.Data,
                recursive: true,
              });

              directories[directory] = true;
            } catch ($err) {
              if ($err && $err.message == 'Directory exists') {
                directories[directory] = true;
              }

              console.error('mkdir', $err.message);
              continue;
            }
          }

          try {
            await Filesystem.downloadFile({
              headers: {
                resource: driveItem.resource,

                Accept: 'application/json',
                Authorization: token,
              },
              url: `${window.location.origin}/api/projects/${this.projectId}/items/${driveItem.id}/preview/${fileSpec}`,
              path: `${directory}/${fileSpec}.bin`,
              directory: Directory.Data,
            });

            statements.execute();
          } catch ($err) {
            ++failedToDownloadFiles;
            console.warn('Unable to download / store file', driveItem.id, fileSpec, driveItem.resource, $err);
          } finally {
            stepOfDownloadFiles.advance();
          }
        }

        // Dont report failed files as warning - requested by Vollack
        // if (failedToDownloadFiles > 0) {
        //   stepOfDownloadFiles.addWarning({
        //     topic: 'offline.activitySteps.errors.topic.file',
        //     description: `offline.activitySteps.errors.reasons.failedDownloadFiles`,
        //     translationParams: {
        //       count: failedToDownloadFiles,
        //     },
        //   });
        // }

        stepOfDownloadFiles.complete();
      } catch (e) {
        stepOfUpdateDatabase.addError(this.unexpectedError);
        return false;
      } finally {
        stepOfUpdateDatabase.complete();
      }

      return true;
    };

    return fetchChanges;
  }

  private safeDate(s?: string | Moment | Date | null) {
    if (!s || s == null) {
      return null;
    }

    if (typeof s == 'string') {
      return new Date(s);
    }

    if (typeof s == 'number') {
      return new Date(s);
    }

    if (typeof s == 'object') {
      if (s instanceof Date) {
        return s;
      }

      if (isMoment(s)) {
        return s.toDate();
      }
    }

    console.error(s);

    throw new Error('Cannot convert from object to date');
  }

  private async updateErrorsForProject(steps: ActivitySteps) {
    const errors = steps.items
      .filter(s => s.currentState == ActivityStepState.failure)
      // flat map
      .reduce((previous, step) => [...previous, ...step.errors], []);

    const statements = await this.queryWrapper.getStatements();

    statements.remove(SQLiteTables.SyncErrors, {
      project: this.projectId,
    });

    for (const x of errors) {
      statements.insertOrReplace<IPSSyncError>(SQLiteTables.SyncErrors, {
        id: Utils.createUUID(),
        project: this.projectId,
        createdOn: new Date(),
        parameters: JSON.stringify(x),
      });
    }

    await statements.execute();
  }

  private async syncFile(
    projectId: string,
    file: IPSDriveItem,
    fileHandler: OfflineServiceFileHandler,
    statements: SQLStatements,
    step: ActivityStepWithProgress
  ) {
    try {
      const directory = OfflineService.getDirectoryPath(projectId, file.resource, file.path, file.name);

      const array = await fileHandler.readFile(directory + OfflineService.OriginalSuffix, Directory.Data);

      const blob = new Blob([array], { type: file.mimeType });

      let wasFileUploaded = false;
      try {
        await this.driveClient.uploadWithProgress(
          projectId,
          { data: blob, fileName: file.name },
          file.path.substring(1),
          file.resource,
          ConflictBehavior.Fail,
          new DriveActionMetadata({
            relatedEntityId: file.relatedEntityId,
          }),
          null
        );

        wasFileUploaded = true;
      } catch (e) {
        const stepError: ActivityStepError = {
          topic: 'offline.activitySteps.errors.topic.file',
          description: 'offline.activitySteps.errors.reasons.tryagain',
          routerLink: '/defects',
          queryParams: { edit: true, id: file.relatedEntityId, files: true },
        };

        const error = e.error;
        if (error?.type) {
          switch (error.type) {
            case ProblemDetailsErrorType.BlocklistedName:
              stepError.description = 'offline.activitySteps.errors.reasons.nameinvalid';
              break;
            case ProblemDetailsErrorType.PayloadTooBig:
              stepError.description = 'offline.activitySteps.errors.reasons.filetoobig';
              break;
            case ProblemDetailsErrorType.Conflict:
              stepError.description = 'offline.activitySteps.errors.reasons.alreadyexists';
          }
        }

        step.addError(stepError);
      }

      if (wasFileUploaded) {
        const resourceIdentifiers = await this.getResourceIdentifiers(projectId);
        const resourceIdentifier = resourceIdentifiers.find(r => r.moduleType === ModuleType.Defect);

        statements.remove(SQLiteTables.DriveItems, { id: file.id, project: projectId, resource: resourceIdentifier.key.name });
        await statements.execute();
      } else {
        statements.discard();
      }

      return true;
    } catch (e) {
      step.addError(this.unexpectedError);
      return false;
    }
  }

  private async syncDefect(o: IPSDefect, statements: SQLStatements, projectId: string, step: ActivityStepWithProgress) {
    try {
      const fileHandler = this.fileHandler;

      const model: SyncDefectModel = JSON.parse(o.json);
      const addOrUpdateDefect = new AddOrUpdateDefectModel();

      if (o.mode == MigrationStatus.UPDATE) {
        addOrUpdateDefect.id = model.id;
      }

      const userAssignments = model.userAssignments.map(x => new UserModel({ id: x.userId }));

      addOrUpdateDefect.areaId = model.areaId;
      addOrUpdateDefect.roomId = model.roomId;
      addOrUpdateDefect.typeId = model.typeId;
      addOrUpdateDefect.stateId = model.stateId;
      addOrUpdateDefect.craftId = model.craftId;
      addOrUpdateDefect.floorId = model.floorId;
      addOrUpdateDefect.reasonId = model.reasonId;
      addOrUpdateDefect.regionId = model.regionId;
      addOrUpdateDefect.organizationId = model.organizationId;
      addOrUpdateDefect.userAssignment = userAssignments;
      addOrUpdateDefect.zones = model.zonesIds;

      if (!addOrUpdateDefect.stateId || addOrUpdateDefect.stateId == '') {
        addOrUpdateDefect.stateId = offlineServiceStateId;
        addOrUpdateDefect.stateType = model.stateType;
      }

      addOrUpdateDefect.title = model.title;
      addOrUpdateDefect.retention = model.retention;
      addOrUpdateDefect.reduction = model.reduction;
      addOrUpdateDefect.description = model.description;

      addOrUpdateDefect.grace = this.safeDate(model.grace);
      addOrUpdateDefect.deadline = this.safeDate(model.deadline);
      addOrUpdateDefect.approvalOfBuilder = this.safeDate(model.approvalOfBuilder);
      addOrUpdateDefect.approvalExtern = this.safeDate(model.approvalExtern);
      addOrUpdateDefect.approvalIntern = this.safeDate(model.approvalIntern);

      const comments = [];

      addOrUpdateDefect.comments = comments;

      const oldDefectId = model.id;

      const createdCommends = await this.queryWrapper.getCommentsWhereModeAndDefect(MigrationStatus.INSERT, oldDefectId);

      for (const comment of createdCommends) {
        const commentModel = new CommentModel({
          text: comment.text,
          editorId: comment.editorId,
          // createdBy: comment.createdBy,
          // createdOn: this.safeDate(comment.createdOn),
          modifiedOn: this.safeDate(comment.modifiedOn),
        });

        comments.push(commentModel);

        statements.remove(SQLiteTables.Comments, { id: comment.id, project: projectId });
        statements.remove(SQLiteTables.DefectComments, { commentId: comment.id, project: projectId });
      }

      const fileParameters = new Array<FileParameter>();

      const resourceIdentifiers = await this.getResourceIdentifiers(projectId);
      const resource = resourceIdentifiers.find(i => i.moduleType === ModuleType.Defect).key.name;

      if (o.mode == MigrationStatus.INSERT) {
        const files = await this.queryWrapper.getDriveItemsWhereModeIsInsertAndPath(
          projectId,
          '/' + (model.number || oldDefectId),
          resource
        );

        for (const file of files) {
          const directory = OfflineService.getDirectoryPath(projectId, resource, file.path, file.name);
          const array = await fileHandler.readFile(directory + OfflineService.OriginalSuffix, Directory.Data);

          const blob = new Blob([array], { type: file.mimeType });

          fileParameters.push({
            data: blob,
            fileName: file.name,
          });

          statements.remove(SQLiteTables.DriveItems, { id: file.id, project: projectId, resource: resource });
        }
      }

      statements.remove(SQLiteTables.Defects, { id: oldDefectId, project: projectId });

      let wasDefectSaved = false;
      try {
        await this.defectClient.save(this.projectId, addOrUpdateDefect, fileParameters).toPromise();
        wasDefectSaved = true;
      } catch (e) {
        const stepError: ActivityStepError = {
          topic: 'offline.activitySteps.errors.topic.defect',
          description: 'offline.activitySteps.errors.reasons.tryagain',
          routerLink: '/defects',
          queryParams: { edit: true, id: model.id },
          linkText: model.title,
        };

        if (e instanceof ProblemDetails) {
          switch (e.type) {
            case ProblemDetailsErrorType.InconsistentModelState:
            case ProblemDetailsErrorType.MissingEntity:
              stepError.description = 'offline.activitySteps.errors.reasons.defectreferences';
              break;
            case ProblemDetailsErrorType.BlocklistedName:
              stepError.description = 'offline.activitySteps.errors.reasons.nameinvalid';
              break;
            case ProblemDetailsErrorType.PayloadTooBig:
              stepError.description = 'offline.activitySteps.errors.reasons.filetoobig';
              break;
          }
        }

        step.addError(stepError);
      }

      if (wasDefectSaved) await statements.execute();
      else statements.discard();

      return true;
    } catch (e) {
      step.addError(this.unexpectedError);
      return false;
    }
  }

  private updateProjects() {
    if (Capacitor.isNativePlatform()) {
      const projectsQuery = this.queryWrapper.getProjects();

      projectsQuery.then(projects => {
        const projectStatus: IProjectStatusMap = {};

        for (const project of projects) {
          projectStatus[project.id] = {
            offline: project.offline,
          };
        }

        this.projectStatusChanged.next(projectStatus);
      });
    }
  }

  private async getResourceIdentifiers(projectId: string): Promise<ResourceIdentifier[]> {
    const items = await this.queryWrapper.getResourceIdentifiers(projectId);
    return items.map(i => ResourceIdentifier.fromJS(JSON.parse(i.json)));
  }

  private mapZoneIds(zonesIds: string[], zones: ZoneModel[], isImplicit: boolean = false) {
    return zonesIds.reduce((foundZones: ZoneModel[], id) => {
      const zone = zones.find(z => z.id == id);
      if (zone) foundZones.push(new ZoneModel({ ...zone, isImplicit }));
      return foundZones;
    }, []);
  }
}
