import { Injectable, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import {
  AuthenticationChangedData,
  AuthenticationCapabilites as AuthenticationCapabilites,
} from './api-authentication.interfaces';
import { LogService } from '../log/log.service';
import { ProjectWithUserSettingsModel, RefreshTokenResultModel } from '@app/api';
import { AppConfigService } from '../app-config/app-config.service';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalsService } from '../globals/globals.service';
import { Utils } from '@app/core/utils';

@Injectable({
  providedIn: 'root',
})
export class AuthenticationService implements OnDestroy {
  private projectUpdatedSource = new Subject<string>();
  public projectUpdated = this.projectUpdatedSource.asObservable();
  private loginStateChangedSource = new Subject<AuthenticationChangedData>();
  public loginStateChanged = this.loginStateChangedSource.asObservable();
  private expectedToken: string = '';
  private refreshFunction: (accessToken: string, refreshToken: string) => Promise<RefreshTokenResultModel>;
  private logoutFunction: (refreshToken: string) => Promise<void>;

  constructor(private route: ActivatedRoute, private router: Router, private log: LogService, private globals: GlobalsService) {
    window.addEventListener('storage', event => this.storageChanged(event), false);
  }

  ngOnDestroy() {
    window.removeEventListener('storage', event => this.storageChanged(event), false);
  }

  static accessTokenName() {
    return `${AppConfigService.settings.cookiePrefix}c4customerportalaccesstoken`;
  }

  static refreshTokenName() {
    return `${AppConfigService.settings.cookiePrefix}c4customerportalrefreshtoken`;
  }

  static teamsSessionName() {
    return `${AppConfigService.settings.cookiePrefix}c4customerportalteamssession`;
  }

  storageChanged(event): void {
    if (event.key != AuthenticationService.accessTokenName()) return;

    const token = this.getCurrentAccessToken();
    const isAuthenticated = token !== undefined && token !== null && token.match(/^\s*$/) === null;
    if (event.storageArea === localStorage && this.expectedToken != token && !this.globals.isTeams) {
      if (isAuthenticated) {
        let redirectUrl = new URL(location.toString());

        if (redirectUrl.pathname.startsWith('/logout')) {
          redirectUrl = new URL(location.origin);
        }

        location.replace(redirectUrl.toString());
      } else {
        location.reload();
      }
    }
  }

  isAuthenticated(): boolean {
    const token = this.getCurrentAccessToken();
    const isAuthenticated = token !== undefined && token !== null && token.match(/^\s*$/) === null;

    // shouldn't be needed since there is a localStorage change event
    //check if token has changed (usually because of login/logout related actions in another tab)
    // if (token !== this.expectedToken) {
    //   if (isAuthenticated) {
    //     const wasAuthenticated = (this.expectedToken !== undefined && this.expectedToken !== null && this.expectedToken.match(/^\s*$/) === null);
    //     if (wasAuthenticated) {
    //       //another tab has logged out and logged in as someone else
    //       //do a full page reload to ensure that we fully reinitialize correclty as the new user
    //       location.reload();
    //     } else {
    //       this.expectedToken = token;
    //     }
    //   } else {
    //     //another tab has logged out
    //     this.logoutInternal();
    //   }
    // }

    if (isAuthenticated) {
      try {
        Utils.decodeToken(token);
        return true;
      } catch (e) {
        this.logout();
      }
    }

    return false;
  }

  getCurrentAccessToken(): string {
    return localStorage.getItem(AuthenticationService.accessTokenName());
  }

  getCurrentRefreshToken(): string {
    return localStorage.getItem(AuthenticationService.refreshTokenName());
  }

  getUserId(): string {
    const jwt = this.getCurrentAccessToken();

    if (!jwt) return null;

    const begin = jwt.indexOf('.') + 1;
    const after = jwt.indexOf('.', begin);

    const json = jwt.substring(begin, after);
    const text = atob(json);
    const info = JSON.parse(text);

    return info['http://schemas.xmlsoap.org/ws/2005/05/identity/claims/nameidentifier'];
  }

  setLogoutFunction(logoutFunction: (refreshToken: string) => Promise<void>) {
    this.logoutFunction = logoutFunction;
  }

  setRefreshFunction(refreshFunction: (accessToken: string, refreshToken: string) => Promise<RefreshTokenResultModel>) {
    this.refreshFunction = refreshFunction;
  }

  async logout() {
    try {
      await this.logoutFunction(this.getCurrentRefreshToken());
    } catch (e) {
      this.log.error('Server logout failed', e);
    } finally {
      this.logoutInternal();
    }
  }

  loginWithToken(
    accessToken: string,
    refreshToken: string,
    passwordChangeRequired: boolean,
    availableCompanies: ProjectWithUserSettingsModel[],
    userDataChangeRequired: boolean
  ) {
    this.setAccessToken(accessToken);
    localStorage.setItem(AuthenticationService.refreshTokenName(), refreshToken);

    this.loginStateChangedSource.next({
      authenticated: true,
      with: {},

      availableCompanies,
      passwordChangeRequired,
      userDataChangeRequired,
    });
  }

  notifyProjectUpdated(projectId: string) {
    if (!projectId) throw 'Must provide value for project id';
    this.projectUpdatedSource.next(projectId);
  }

  //don't use this function in any other instance than the http interceptor!
  async refreshToken(): Promise<RefreshTokenResultModel> {
    const oldToken = this.getCurrentAccessToken();
    const refreshToken = this.getCurrentRefreshToken();
    if (oldToken === null || refreshToken === null || this.refreshFunction === undefined) {
      return null;
    }

    const refreshResult = await this.refreshFunction(oldToken, refreshToken);

    if (!refreshResult) {
      return null;
    }

    this.setAccessToken(refreshResult.accessToken);
    localStorage.setItem(AuthenticationService.refreshTokenName(), refreshResult.refreshToken);

    return refreshResult;
  }

  private logoutInternal() {
    localStorage.removeItem(AuthenticationService.accessTokenName());
    localStorage.removeItem(AuthenticationService.refreshTokenName());
    this.expectedToken = null;
    this.loginStateChangedSource.next({
      authenticated: false,
      passwordChangeRequired: false,
      userDataChangeRequired: false,

      with: {
        impersonation: false,
      },
    });
  }

  private setAccessToken(token: string) {
    // this.decodeToken(token);
    localStorage.setItem(AuthenticationService.accessTokenName(), token);
    this.expectedToken = token;
  }

  // private decodeToken(tokenString: string): DecodedToken {
  //   const decoded = jwt_decode(tokenString);
  //   return {
  //     token: tokenString,
  //     expiration: new Date(~~decoded.exp * 1000)
  //   };
  // }
}
