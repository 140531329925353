import { Component, ViewChild, TemplateRef, Output, EventEmitter, Input, OnInit, OnDestroy } from '@angular/core';
import { C4GridDef, C4GridMatchMode, C4GridColumn, C4GridSelectOptions, GridComponent, C4GridFilterType } from '../../grid';
import {
  DataHolder,
  GlobalsService,
  ApiService,
  LogService,
  Utils,
  BaseSubscriptionComponent,
  ProjectService,
  pathFragmentsTo,
  AppRoutingData,
} from '@app/core';
import {
  DocumentUploadData,
  DocumentUploadService,
  UserNotificationService,
  DocumentDownloadService,
  LongRunningTaskService,
  TableQuery,
} from '@app/shared/services';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { ExtendedPlanFieldModel, PlanApproval, PlanCodes, _planSeparator } from '../schema-list.interfaces';
import { SortMeta } from 'primeng/api/sortmeta';
import { FileSize, LocalizedDatePipe } from '@app/shared/pipes';
import {
  DriveItemConversionModel,
  DriveItemIdentifierModel,
  DriveItemModel,
  DriveUploadResultModel,
  LongRunningTaskState,
  LRTaskState,
  MetadataDriveItemModel,
  ModuleType,
  PlanSchemaDefinition,
  PrivilegeEnum,
  ShareDriveModel,
  SwaggerException,
  TextFormatType,
} from '@app/api';
import { PlanDeleteDialogComponent } from '../plan-delete-dialog/plan-delete-dialog.component';
import { PlanUploadDialogComponent, UploadDialogResponseData } from '../plan-upload-dialog/plan-upload-dialog.component';
import { PlanUtils } from '../planningUtils';
import { ActivatedRoute, Router } from '@angular/router';
import { filter, map, skip, takeWhile } from 'rxjs/operators';
import { BehaviorSubject, firstValueFrom, Subscription } from 'rxjs';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { MailNotificationDialogComponent } from '../../dialogs/mail-notification-dialog/mail-notification-dialog.component';
import { ZipDownloadUrlDialogComponent } from '../../dialogs/zip-download-url-dialog/zip-download-url-dialog.component';
import { ViewLogComponent, viewLogDlgData, viewLogMultiDlgData } from '../../dialogs/view-log';
import { ApiNotificationCall } from '../../dialogs/mail-notification-dialog/mail-notification-dialog.interfaces';
import { DriveItemPrivilegesComponent } from '../../privileges/drive-item-privileges/drive-item-privileges.component';
import { ConfirmDialogComponent } from '../../dialogs';
import {
  PlanVerificationState,
  PlanVerifyDialogComponent,
} from '../../dialogs/plan-verify-dialog/plan-verify-dialog.component';
import { FilterMetadata } from 'primeng/api';

const editor = 'editor';

export interface MappedPlan {
  files?: ExtendedMetadataDriveItemModel[];
  lastIndex?: string;
}

interface DialogData {
  items: DocumentUploadData[];
  def: ExtendedPlanFieldModel[];
  map: any;
  seperator?: string;
  resource: string;
  module: ModuleType;
}

interface ConversionTaskResult {
  modelId: string;
  taskId: string;
}

// caution - contains all fields of plan schema with values as well
export interface ExtendedMetadataDriveItemModel extends MetadataDriveItemModel {
  selected?: boolean;
  Filetype?: string;
  canBeConverted?: boolean;
  verify?: boolean;
  stateCode?: string;
}

@Component({
  selector: 'app-plan-list',
  templateUrl: './plan-list.component.html',
  styleUrls: ['./plan-list.component.scss'],
})
export class PlanListComponent extends BaseSubscriptionComponent implements OnInit, OnDestroy, Busy {
  //private allowDataLoad: boolean;
  isBusy: boolean;
  schemaPendingClass: string = 'schema-pending';
  gridDef: C4GridDef;
  @Input() moduleType: ModuleType;
  domModuleType = ModuleType;
  planData: DataHolder<any>;
  dataCached: boolean = false;
  currentCache: ExtendedMetadataDriveItemModel[];
  allCache: ExtendedMetadataDriveItemModel[];
  mappedPlans: Record<string, MappedPlan>;
  seperator: string = _planSeparator;
  viewAll: boolean = false;
  showResponsiveColumns: boolean = false;
  isDragDrop = false;
  visibleOverviewFields: string[] = [];
  addFileAllowed: boolean;
  @ViewChild(GridComponent) list: GridComponent;
  @ViewChild('fileName') filename: TemplateRef<any>;
  @ViewChild('fileType') filetype: TemplateRef<any>;
  @ViewChild('uploadDate') uploaddate: TemplateRef<any>;
  @ViewChild('selectRow') selectRow: TemplateRef<any>;
  @ViewChild('selectAll') selectAll: TemplateRef<any>;
  @ViewChild('contextMenu') contextMenu: TemplateRef<any>;
  @ViewChild('conversionState') conversionState: TemplateRef<any>;
  @ViewChild('stateBubble') stateBubble: TemplateRef<any>;

  @Output() itemsChanged: EventEmitter<boolean> = new EventEmitter();
  @Output() customSelectionChange = new EventEmitter<any[]>();
  @Output() toggleViewState = new EventEmitter<boolean>();
  @Output() gridFilterChange = new EventEmitter<Record<string, FilterMetadata>>();
  uploadProgress: number;
  requestInProgress = false;
  planDef: ExtendedPlanFieldModel[];
  versionOk: boolean = false;
  versionField: string = '';
  versionValues: string[] = [];
  approval = PlanApproval;
  stateIndex: number = -1; // [array] index of state field
  stateField: string;
  canWriteRestricted: boolean = false;
  canReadLog: boolean = false;
  uploadAllowed: boolean = true;
  canReadPrivilege: boolean = false;
  canSharePrivilege: boolean = false;
  companyId: string;
  lrtSubs: Subscription[] = [];
  verifyPlanVersion$ = new BehaviorSubject<string>(null);

  get dragDropClass(): string {
    if (this.addFileAllowed) {
      if (this.isDragDrop) {
        return 'app-docExDragDrop';
      }
      if (this.globals.isDragDrop) {
        return 'app-dragDrop';
      }
    }

    return '';
  }

  private allowedBimConversionFileTypes: string[] = [];
  private resource: string;

  constructor(
    private apiService: ApiService,
    private datePipe: LocalizedDatePipe,
    private dialog: MatDialog,
    private activatedRoute: ActivatedRoute,
    private documentDownload: DocumentDownloadService,
    private documentUpload: DocumentUploadService,
    private fileSize: FileSize,
    private log: LogService,
    private lrtService: LongRunningTaskService,
    private projectService: ProjectService,
    private router: Router,
    private userNotification: UserNotificationService,
    public globals: GlobalsService
  ) {
    super();
  }

  get responsiveColumns(): boolean {
    return this.gridDef.grid.mobileExpanded;
  }

  set responsiveColumns(value: boolean) {
    this.list.responsiveColumns = value;
  }

  async ngOnInit() {
    this.globals.registerDragDropItem(this);

    this.addFileAllowed = true;

    this.subscribe(this.activatedRoute.queryParams.pipe(map(params => params['verifyPlanId'])), this.verifyPlanVersion$);
    this.subscribe(
      this.verifyPlanVersion$.pipe(
        skip(1),
        filter(id => !!id)
      ),
      async verifyPlanId => {
        this.verfiyPlanIndex(verifyPlanId);
      }
    );

    this.subscribe(this.projectService.projectId$, async projectId => {
      this.gridDef = null;

      const resourceIdentifiers = projectId ? await this.apiService.getResourceIdentifiers() : [];
      this.resource = resourceIdentifiers.find(i => i.moduleType == this.moduleType)?.key?.name;

      const privileges = projectId ? await this.apiService.getUserPrivileges() : [];
      if (this.moduleType == ModuleType.Plan) {
        if (privileges.includes(PrivilegeEnum.ReadPlan)) {
          await this.updateGrid();
          this.canWriteRestricted = privileges.indexOf(PrivilegeEnum.ReadPlan) >= 0;
          this.canReadLog = privileges.indexOf(PrivilegeEnum.ReadPlanDriveItemLogs) >= 0;
          this.canReadPrivilege = privileges.indexOf(PrivilegeEnum.ReadPlanDriveItemPrivileges) >= 0;
          this.canSharePrivilege = privileges.indexOf(PrivilegeEnum.SharePlanDriveItem) >= 0;
        }
      } else if (this.moduleType == ModuleType.Bim) {
        if (privileges.includes(PrivilegeEnum.ReadBim)) {
          this.allowedBimConversionFileTypes = await this.apiService.getConvertableFileTypes();
          await this.updateGrid();
          this.canWriteRestricted = privileges.indexOf(PrivilegeEnum.ReadWriteRestrictedBim) >= 0;
          this.canReadLog = privileges.indexOf(PrivilegeEnum.ReadBimDriveItemLogs) >= 0;
          this.canReadPrivilege = privileges.indexOf(PrivilegeEnum.ReadBimDriveItemPrivileges) >= 0;
          this.canSharePrivilege = privileges.indexOf(PrivilegeEnum.ShareBimDriveItem) >= 0;
        }
      }

      this.verifyPlanVersion$.next(this.verifyPlanVersion$.value);
    });
  }

  ngOnDestroy() {
    this.globals.unregisterDragDropItem(this);
    this.lrtSubs.forEach(sub => sub.unsubscribe());
    this.lrtSubs = [];
    return super.ngOnDestroy();
  }

  gridFilterChanged(filter: Record<string, FilterMetadata>) {
    for (const file of this.allCache ?? []) file.verify = false;
    this.deselectAll();
    this.gridFilterChange.emit(filter);
  }

  async toggleShownPlans(viewAll: boolean) {
    this.viewAll = viewAll;
    await this.updatePlanData(false, true);
  }

  async updateGrid() {
    await using(new BusyScope(this), async busy => {
      this.planDef = await this.getPlanschema();
      this.gridDef = this.generateGridDef();
    });
    this.visibleOverviewFields = [];
    this.gridDef.cols.forEach(c => {
      if (!c.hidden) this.visibleOverviewFields.push(c.field);
    });
    this.planData = new DataHolder(this.initData(3));
    await this.updatePlanData();
    if (!this.planData.cachedData) this.planData.cachedData = [];
  }

  generateGridDef() {
    //sort palndef by Order
    this.visibleOverviewFields = [];
    const schemaForGrid = this.planDef.slice();
    schemaForGrid.sort((a, b) => {
      if (!a.order && !b.order) return 0;
      if (!a.order) return 1;
      if (!b.order) return -1;
      return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
    });

    const initialSorting: SortMeta[] = [];
    initialSorting.push({ field: 'Filename', order: 1 });
    const result: C4GridDef = {
      grid: {
        filterRow: true,
        globalFilter: false,
        responsive: false,
        rowExpand: true,
        paging: true,
        rows: 100,
        rowCount: 5,
        rowsOptions: [5, 10, 20, 50, 100],
        lazy: false,
        lazyInit: false,
        scrollable: true,
        prefix: 'planning.grid.',
      },
      row: { link: false, path: '', param: '' },
      cols: [
        {
          field: 'select',
          header: 'none',
          width: '3.5em',
          minWidth: '3.5em',
          priority: 4,
          cssClass: 'planbutton',
          sortable: false,
          template: this.selectRow,
          headerTemplate: this.selectAll,
        },
        //TODO; Filetype field  cfg from json?
        {
          field: 'fileIcon',
          width: '2em',
          sortable: false,
          header: 'none',
          minWidth: '2em',
          priority: 1,
          template: this.filetype,
          cssClass: 'fileicon',
        },
        {
          field: 'name',
          width: '2*',
          filterMatchMode: C4GridMatchMode.contains,
          sortable: true,
          header: 'filename',
          minWidth: '22.5em',
          priority: 1,
          filterType: C4GridFilterType.text,
          cssClass: 'plan-file-name',
          template: this.filename,
        },
        {
          field: 'contextmenu',
          header: 'none',
          width: '4em',
          minWidth: '4em',
          priority: 1,
          sortable: false,
          template: this.contextMenu,
          cssClass: 'planbutton',
        },
      ],
      initialSorting,
    };

    if (this.moduleType === ModuleType.Bim) {
      result.cols.push({
        field: 'conversionstate',
        header: 'none',
        width: '3em',
        minWidth: '3em',
        priority: 2,
        sortable: false,
        template: this.conversionState,
      });
    }

    let prio = 4; // generate auto prio

    for (const item of schemaForGrid) {
      if (item.isSeparator) continue;

      prio++;
      if (item.isVersion) {
        this.versionOk = true;
        this.versionField = item.field;
        this.versionValues = !item.options || item.options.length <= 0 ? [] : item.options.map(x => x.value);
      }
      let itemOptions: C4GridSelectOptions[] = null;
      let maxOptionLength: number = 0;
      if (item.options && !item.isLogicalGrouping) {
        itemOptions = [];
        item.options.forEach(o => {
          const option: C4GridSelectOptions = {
            label: (o.value ? o.value : o.range.join(' - ')) + ' | ' + o.label,
            value: o.value ? o.label : o.range ? o.label : null,
            range: o.range ? o.range : null,
          };
          maxOptionLength = Math.max(maxOptionLength, o.label.length);
          itemOptions.push(option);
          if (o.range) item.isRange = true;
        });
      }
      const minColWidth = Math.max(
        item.width ? item.width : maxOptionLength > 0 ? Math.min(12, Math.floor(maxOptionLength / 1.85)) : 5,
        // min width to avoid truncation of filter dropdown
        6
      );

      const col: C4GridColumn = {
        field: item.field,
        width: '1*',
        sortable: true,
        options: itemOptions,
        filterMatchMode: itemOptions ? C4GridMatchMode.equals : C4GridMatchMode.contains,
        header: item.displayName,
        noTranslate: true,
        minWidth: minColWidth + 'em',
        priority: prio,
        isRange: item.isRange && !item.isLogicalGrouping ? item.isRange : false,
        filterType: itemOptions ? C4GridFilterType.multiselect : C4GridFilterType.text,
        template: item.code == PlanCodes.Approval ? this.stateBubble : null,
      };
      result.cols.push(col);
    }

    result.cols.push({
      field: 'size',
      width: '1*',
      filterMatchMode: C4GridMatchMode.contains,
      sortable: true,
      header: 'size',
      minWidth: '6em',
      priority: 3,
      pipe: this.fileSize,
    });
    result.cols.push({
      field: editor,
      width: '1*',
      filterMatchMode: C4GridMatchMode.contains,
      sortable: true,
      header: 'changed',
      minWidth: '12em',
      priority: 3,
    });
    result.cols.push({
      field: 'lastModifiedDateTime',
      width: '1*',
      filterMatchMode: C4GridMatchMode.contains,
      template: this.uploaddate,
      sortable: true,
      header: 'date',
      minWidth: '7em',
      priority: 3,
      pipe: this.datePipe,
    });
    if (!this.versionOk || this.versionValues.length <= 0)
      this.userNotification.notify('planning.error.message.schemaIndexError');
    return result;
  }

  initData(count: number = 5) {
    const response = [];
    let suffix = '';
    for (let i = 0; i < count; i++) {
      const item: any = {};
      const fileparts: string[] = [];
      this.planDef.forEach(d => {
        if (d.options && !d.isLogicalGrouping) {
          const oCount = d.options.length;
          const index = Math.floor(Math.random() * oCount);
          item[d.field] = d.options[index].label;
          if (d.isRange) {
            const nrpart = '00' + (Math.floor(Math.random() * 998) + 1);
            fileparts.push(nrpart.slice(-3));
            const oNumber = ~~nrpart;
            for (let ri = 0; ri < oCount; ri++) {
              if (oNumber > d.options[ri].range[0] && oNumber < d.options[ri].range[1]) {
                item[d.field] = d.options[ri].label;
                break;
              }
            }
          } else {
            fileparts.push(d.options[index].value);
          }
          suffix = d.options[index].label;
        } else {
          const itemwidth = d.width ? d.width : 6;
          item[d.field] = this.rndString(25).substring(0, itemwidth);
          fileparts.push(item[d.field]);
        }
      });

      fileparts.pop();
      item.name = fileparts.join(this.seperator) + '.' + suffix;
      item.size = (Math.random() * 1250).toFixed(2) + 'KB';
      const uploader = this.rndString(12);
      item.lastModifiedBy = {
        user: { displayName: uploader },
      };
      item.createdDateTime = this.randomDate(new Date(2018, 0, 1), new Date());
      response.push(item);
    }
    return response;
  }
  randomDate(start, end) {
    return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
  }

  rndString(length: number = 9, caps: number = 1) {
    let tOff = new Date().getTime();
    const reglength = Array(length).join('x');
    const val = reglength.replace(/[x]/g, function (c) {
      const r = (tOff + Math.random() * 26) % 26 | 0;
      tOff = Math.floor(tOff / 7);
      const tk = r.toString(26);
      const tr = caps < 1 ? tk.toUpperCase() : tk.toLowerCase();
      return tr;
    });
    return val;
  }

  onDragLeave(event) {
    if (!this.uploadAllowed) return;
    this.enableDragDrop(event, false);
  }

  onDragEnd(event) {
    if (!this.uploadAllowed) return;
    this.enableDragDrop(event, false);
  }

  onDragOver(event) {
    if (!this.uploadAllowed) return;
    this.enableDragDrop(event, true);
  }

  onDragEnter(event) {
    if (!this.uploadAllowed) return;
    this.enableDragDrop(event, true);
  }

  private enableDragDrop(event, isDragDrop: boolean) {
    this.isDragDrop = isDragDrop;
    if (isDragDrop && event.dataTransfer) {
      event.dataTransfer.dropEffect = 'copy';
      event.dataTransfer.effectAllowed = 'copy';
    }
    event.stopPropagation();
    event.preventDefault();
  }

  async onDrop(event) {
    if (!this.uploadAllowed) return;
    this.enableDragDrop(event, false);
    const uploadItems = await this.documentUpload.decodeDropEvent(event, '', this.resource);
    this.uploadDialog(uploadItems);
  }

  async uploadDialog(documents: DocumentUploadData[] = []) {
    if (!this.planDef || this.planDef.length < 1 || !this.versionOk) {
      this.userNotification.notify('planning.notifications.uploadSchemaMissing');
      return;
    }

    const dialogData: DialogData = {
      items: documents,
      def: this.planDef,
      map: this.mappedPlans,
      resource: this.resource,
      module: this.moduleType,
      seperator: this.seperator,
    };

    const dialogResult = (await this.dialog
      .open(PlanUploadDialogComponent, { data: dialogData, disableClose: true })
      .afterClosed()
      .toPromise()) as UploadDialogResponseData;
    if (dialogResult) {
      const uploadDocs = dialogResult.valid as DocumentUploadData[];
      const uploadResponse = await this.uploadFiles(uploadDocs);
      dialogResult.invalid.push(
        ...uploadDocs.filter(
          doc =>
            uploadResponse?.all((uploaded: DriveUploadResultModel) => {
              return uploaded.fileName != doc.fileName;
            }) ?? true
        )
      );

      //notification
      if (uploadResponse?.length) {
        const selectedRowNames = uploadResponse.map(n => n.fileName);

        const selectedRowIds = [];
        uploadResponse.forEach(x => {
          selectedRowIds.push(
            new DriveItemIdentifierModel({
              driveItemId: x.fileId,
              resource: this.resource,
            })
          );
        });

        if (this.moduleType == ModuleType.Bim) {
          await this.convertModel(uploadResponse);
        }

        if (this.moduleType == ModuleType.Plan) {
          await this.convertModel(uploadResponse);
        }

        await this.dialog
          .open(MailNotificationDialogComponent, {
            data: {
              itemNames: selectedRowNames,
              isCustomMailAddressSupported: true,
              sendNotification: this.getNotificationCall(selectedRowIds),
            },
            disableClose: true,
          })
          .afterClosed()
          .toPromise();
      }

      if (dialogResult.invalid.length > 0) {
        this.log.info('invalid upload files', dialogResult.invalid);
        this.uploadDialog(dialogResult.invalid);
      }
    }
  }

  getConversionStateIcon(row: any) {
    switch (row.conversion?.currentState) {
      case LongRunningTaskState.Success:
        return 'mdi-cloud-check-variant';
      case LongRunningTaskState.Working:
        return 'mdi-cloud-sync';
      case LongRunningTaskState.Failure:
        return 'mdi-cloud-alert';
      case LongRunningTaskState.Pending:
        return 'mdi-cloud-upload';
      default:
        return 'mdi-cloud-question';
    }
  }

  private canBeConverted(fileName: string) {
    const lowerCaseFileName = fileName.toLowerCase();
    return this.allowedBimConversionFileTypes.some(type => lowerCaseFileName.endsWith(type));
  }

  private async convertModel(uploadResponse: DriveUploadResultModel[]) {
    this.requestInProgress = true;

    const conversionTasks: Promise<ConversionTaskResult>[] = [];
    const errors = [];
    for (const model of uploadResponse) {
      if (!this.canBeConverted(model.fileName)) continue;

      conversionTasks.push(
        this.apiService
          .convertModel(model.fileId, model.fileName.split('.').pop())
          .then(taskResult => {
            this.allCache.first(driveItem => driveItem.id === model.fileId).conversion = new DriveItemConversionModel({
              lrTaskId: taskResult.id,
              currentState: LongRunningTaskState.Pending,
            });
            return { modelId: model.fileId, taskId: taskResult.id };
          })
          .catch(e => {
            errors.push(e);
            return null;
          })
      );
    }

    if (conversionTasks.length > 0) {
      this.userNotification.notify('planning.notifications.convertModel');
    }

    const conversionResults = await Promise.all(conversionTasks);

    this.requestInProgress = false;

    if (errors.length > 0) {
      this.log.error(`Conversion for ${errors.length} models failed`, errors);
    }

    this.subscribeToTaskUpdates(conversionResults.filter(result => !!result));
  }

  private subscribeToTaskUpdates(taskResults: { modelId: string; taskId: string }[]) {
    for (const entry of taskResults) {
      const taskObs = this.lrtService.getObservableForTask(entry.taskId);

      let sub = taskObs
        .pipe(
          takeWhile(
            updatedStatus => updatedStatus.state != LRTaskState.Failure && updatedStatus.state != LRTaskState.Success,
            true
          )
        )
        .subscribe(
          async updatedStatus => {
            const planEntry = this.allCache.firstOrDefault(x => x.id === entry.modelId);
            switch (updatedStatus.state) {
              case LRTaskState.Working:
                if (planEntry && planEntry.conversion) {
                  planEntry.conversion.currentState = LongRunningTaskState.Working;
                }
                return;
              case LRTaskState.Failure:
                this.userNotification.notify('planning.notifications.convertFailed');

                if (planEntry && planEntry.conversion) {
                  planEntry.conversion.currentState = LongRunningTaskState.Failure;
                }
                return;
              case LRTaskState.Success:
                const updatedDriveItem = await this.apiService.getDriveItem(entry.modelId, this.resource).toPromise();

                if (planEntry) {
                  if (updatedDriveItem && updatedDriveItem.conversion) planEntry.conversion = updatedDriveItem.conversion;
                  else planEntry.conversion.currentState = LongRunningTaskState.Failure;
                }
                return;
            }
          },
          e => {
            this.log.error('Conversion failed', e);
            this.userNotification.notify('planning.notifications.convertFailed');

            const planEntry = this.allCache.firstOrDefault(x => x.id === entry.modelId);
            if (planEntry && planEntry.conversion) {
              planEntry.conversion.currentState = LongRunningTaskState.Failure;
            }
          }
        );

      this.lrtSubs.push(sub);
    }
  }

  async shareDoc(id: string, name: string) {
    const selectedRowIds = [
      new DriveItemIdentifierModel({
        driveItemId: id,
        resource: this.resource,
      }),
    ];
    const selectedRowNames = [name];

    await this.dialog
      .open(MailNotificationDialogComponent, {
        data: {
          itemNames: selectedRowNames,
          userEvent: true,
          isCustomMailAddressSupported: true,
          sendNotification: this.getNotificationCall(selectedRowIds),
        },
        disableClose: true,
      })
      .afterClosed()
      .toPromise();
  }

  async generateZipDownloadUrl(documentIds: string[]) {
    const dialogRef = this.dialog.open(ZipDownloadUrlDialogComponent, {
      data: {
        documentIds,
        resource: this.resource,
      },
      autoFocus: false,
    });

    return await dialogRef.afterClosed().toPromise();
  }

  async deletePlan(plan: any) {
    const { fullKey } = PlanUtils.getPlanProperties(plan.name, this.planDef, this.seperator);
    const fullPlan: MappedPlan = this.mappedPlans[fullKey] ? this.mappedPlans[fullKey] : {};
    const dialogData = { doc: plan, map: fullPlan };
    const dialogResult = await this.dialog.open(PlanDeleteDialogComponent, { data: dialogData }).afterClosed().toPromise();
    if (dialogResult) {
      this.deleteItems(dialogResult);
    }
  }

  async getLog(plan?: any) {
    let request: viewLogMultiDlgData | viewLogDlgData;
    let ids: string[];

    if (plan) {
      const { fullKey } = PlanUtils.getPlanProperties(plan.name, this.planDef, this.seperator);
      const fullPlan: MappedPlan = this.mappedPlans[fullKey] ? this.mappedPlans[fullKey] : {};
      ids = fullPlan.files.map(p => p.id);
    }

    request = {
      ids,
      resource: this.resource,
      sortBy: 'createdOn',
      sortDirection: 'DESC',
    };

    await this.dialog
      .open(ViewLogComponent, {
        data: request,
      })
      .afterClosed()
      .toPromise();
  }

  async getPrivileges(driveItem: DriveItemModel) {
    await DriveItemPrivilegesComponent.openForDriveItem(this.dialog, driveItem);
  }

  async getPlanschema() {
    try {
      const schema: PlanSchemaDefinition = await this.apiService.getPlanSchemaByResource(this.resource);
      this.seperator = schema.seperator ?? _planSeparator;
      return schema.planFields as ExtendedPlanFieldModel[];
    } catch (e) {
      this.userNotification.notifyFailedToLoadDataAndLog('planning.error.failedDataKeys.planSchema', e);
      return [];
    } finally {
      this.schemaPendingClass = '';
    }
  }

  selectBar(): boolean {
    if (!this.planData.data) return false;
    return this.planData.data.some(s => s.selected);
  }

  rowSelect(row: any) {
    row.selected = !row.selected;
    const selectedPlans = this.planData.data.filter(r => r.selected);
    this.customSelectionChange.emit(selectedPlans);
  }

  //REDUNDANCY WARNING (defect-list, diary-list)
  allRowsSelect(e: Event) {
    e.stopImmediatePropagation();

    const data =
      this.list.dataTable.filteredValue && this.list.dataTable.filteredValue.length > 0
        ? this.list.dataTable.filteredValue
        : this.planData.data.slice(this.list.dataTable.first, this.list.dataTable.first + this.list.dataTable.rows) ?? [];

    let areAllSelected = data.every(r => r.selected);

    const select = !areAllSelected;
    data.forEach(r => (r.selected = select));

    this.customSelectionChange.emit(select ? data : []);
  }

  deselectAll() {
    this.planData.data.forEach(s => {
      s.selected = false;
    });
    this.customSelectionChange.emit([]);
  }

  async updatePlanData(forceReload: boolean = false, toggle: boolean = false) {
    let path = this.moduleType === ModuleType.Bim ? 'IFC' : '';
    try {
      await this.planData.updateData(async () => {
        if (toggle && this.dataCached) {
          if (this.viewAll) {
            this.gridDef.grid.rowCount = this.allCache.length;
            this.list.dataTable.paginator = this.gridDef.grid.rowCount > this.list.dataTable.rows;
            return this.allCache; //all files
          } else {
            this.gridDef.grid.rowCount = this.currentCache.length;
            this.list.dataTable.paginator = this.gridDef.grid.rowCount > this.list.dataTable.rows;
            return this.currentCache; // files - highest index only
          }
        } else {
          // const result = await this.apiService.getDriveItems(path, 'is:file', forceReload, this.resource);
          const result = await firstValueFrom(this.apiService.getMetadataDriveItems(this.resource));
          this.uploadAllowed = result.addFileAllowed;

          const refinedResult = this.refineResult(result.items);
          this.gridDef.grid.rowCount = refinedResult ? refinedResult.length : 0;
          this.dataCached = true;
          let pendingConversions = refinedResult
            .filter(
              i =>
                i.conversion?.currentState === LongRunningTaskState.Working ||
                i.conversion?.currentState === LongRunningTaskState.Pending
            )
            .map(i => {
              return { modelId: i.id, taskId: i.conversion.lrTaskId };
            });
          if (pendingConversions && pendingConversions.length > 0) {
            this.subscribeToTaskUpdates(pendingConversions);
          }
          return refinedResult;
        }
      }, true);
    } catch (e) {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.selectedFolder', e);
    }
    this.itemsChanged.emit(this.uploadAllowed);
  }

  async verfiyPlanIndex(id: string) {
    let state = PlanVerificationState.latest;
    let driveItemToVerify: ExtendedMetadataDriveItemModel = null;
    await using(new BusyScope(this), async _ => {
      let allVersions: ExtendedMetadataDriveItemModel[] = [];
      for (const planKey in this.mappedPlans) {
        if (this.mappedPlans.hasOwnProperty(planKey)) {
          const allVersionsOfCurrentKey = this.mappedPlans[planKey].files?.slice() ?? [];

          for (const file of allVersionsOfCurrentKey) {
            file.verify = false;
            if (file.id === id) {
              driveItemToVerify = file;
              allVersions = allVersionsOfCurrentKey;
              // do not break here to set verify flag for other files
            }
          }
        }
      }

      if (!driveItemToVerify) {
        this.userNotification.notify('planning.error.message.notFound');
        return;
      }

      //filter query
      const tableQuery = this.singlePlanFilterQuery(driveItemToVerify);

      //get index field form fileschema
      allVersions.sort(this.planIndexSortAsc.bind(this));
      for (const item of allVersions) {
        const { fileNameParts } = PlanUtils.parseFileName(item.name, this.planDef, this.seperator);
        const isApproved = fileNameParts[this.stateIndex] == PlanApproval.ApprovedCode;

        if (item == driveItemToVerify) {
          if (!isApproved) state = PlanVerificationState.latestButUnapproved;
          break;
        }

        if (isApproved) {
          state = PlanVerificationState.outdated;
          break;
        } else {
          state = PlanVerificationState.latestApproved;
        }
      }

      // set (grid) filter & state
      await this.toggleShownPlans(true);
      this.toggleViewState.emit(false);
      this.list.filterTable(tableQuery);
    });

    await this.dialog
      .open(PlanVerifyDialogComponent, {
        data: { state },
        disableClose: false,
      })
      .afterClosed()
      .toPromise();

    // set style here since filter will reset the flag
    driveItemToVerify.verify = true;
    this.router.navigate([], { queryParams: { verifyPlanId: null }, queryParamsHandling: 'merge' });
  }

  private singlePlanFilterQuery(file: ExtendedMetadataDriveItemModel) {
    const tableQuery: TableQuery = { filters: {} };

    this.planDef.forEach(planFieldDefinition => {
      if (planFieldDefinition.isKey) {
        if (file.hasOwnProperty(planFieldDefinition.field)) {
          tableQuery.filters[planFieldDefinition.field] = {
            value: [file[planFieldDefinition.field]],
            matchMode: C4GridMatchMode.in,
          };
        }
      }

      if (planFieldDefinition.isVersion) {
        tableQuery.sorting = [{ field: planFieldDefinition.field, order: 1 }];
      }
    });

    return tableQuery;
  }

  // DriveItem planschema specific data refine & name parse
  refineResult(files: any[]) {
    files.sort((file, other) => {
      //initial sort
      if (!file.createdDateTime || !other.createdDateTime) return 0;
      return file.createdDateTime > other.createdDateTime ? -1 : 1;
    });
    this.allCache = files;
    this.mappedPlans = {}; // cache same/similar files
    this.allCache.forEach(file => {
      // add editor
      const lastModifiedBy = file.lastModifiedBy;
      file[editor] = lastModifiedBy?.user?.displayName;

      let mapKey = '';
      file.selected = false;
      const { fileNameParts, fileExtension } = PlanUtils.parseFileName(file.name, this.planDef, this.seperator);
      this.planDef.forEach((planFieldDefinition, indexOfDefinition) => {
        const planFieldValue = fileNameParts[indexOfDefinition] ? fileNameParts[indexOfDefinition] : null;
        let fieldValue = planFieldValue;
        let rangeValue = null;
        if (planFieldDefinition.isKey && planFieldValue) mapKey += planFieldValue.toLowerCase() + this.seperator;
        if (planFieldDefinition.code === PlanCodes.Approval) {
          this.stateIndex = indexOfDefinition;
          this.stateField = planFieldDefinition.field;
          file.stateCode = planFieldValue;
        }
        if (planFieldDefinition.options && !planFieldDefinition.isLogicalGrouping && planFieldValue) {
          const optionsCount = planFieldDefinition.options.length;
          for (let i = 0; i < optionsCount; i++) {
            const option = planFieldDefinition.options[i];
            if (option.range) {
              const intVal: number = ~~planFieldValue;
              if (planFieldValue && intVal >= option.range[0] && intVal <= option.range[1]) {
                fieldValue = option.label;
                rangeValue = intVal;
                break;
              }
            } else if (option.value.toLowerCase() === planFieldValue.toLowerCase()) {
              fieldValue = option.label;
              break;
            }
          }
        }
        file[planFieldDefinition.field] = fieldValue;
        if (rangeValue != null) file[Utils.rangePrefix + planFieldDefinition.field] = rangeValue;
      });
      // special case for file type ( suffix)
      if (fileExtension) {
        // the last element in the schema is always the extension
        file[this.planDef[this.planDef.length - 1].field] = fileExtension;
        // Set the Filetype manually just in case the last element is not named "Filetype"
        file.Filetype = fileExtension;
        file.canBeConverted = this.allowedBimConversionFileTypes.contains(fileExtension);
        mapKey += fileExtension;
      }
      if (!this.mappedPlans[mapKey]) {
        const newPlanMap: MappedPlan = { files: [], lastIndex: '' };
        this.mappedPlans[mapKey] = newPlanMap;
      }
      this.mappedPlans[mapKey].files.push(file);
    });
    //refine latest index files
    this.currentCache = [];
    for (const plan in this.mappedPlans) {
      if (this.mappedPlans.hasOwnProperty(plan)) {
        const planFiles = this.mappedPlans[plan].files;
        // new index sort by index option values
        planFiles.sort(this.planIndexSortAsc.bind(this));
        this.currentCache.push(planFiles[0]);
        this.mappedPlans[plan].lastIndex = planFiles[0]['r_Index'] ?? planFiles[0][this.versionField];
      }
    }
    if (this.viewAll) {
      return this.allCache; //all files
    } else {
      return this.currentCache; // files - highest index only
    }
  }

  private planIndexSortAsc(file: ExtendedMetadataDriveItemModel, other: ExtendedMetadataDriveItemModel) {
    return this.versionValues.indexOf(file[this.versionField]) - this.versionValues.indexOf(other[this.versionField]);
  }

  async uploadFiles(uploadData: DocumentUploadData[]) {
    let uploadResponse: DriveUploadResultModel[] = null;

    if (uploadData.length > 0) {
      try {
        this.uploadProgress = 0;
        uploadResponse = await this.documentUpload.uploadDocuments(
          uploadData,
          (currentDoc: number, totalDocs: number, progressPercent: number) => {
            this.uploadProgress = progressPercent * 100;
            this.log.debug(`Upload progress: ${100 * progressPercent}% (processing file ${currentDoc} of ${totalDocs})`);
          }
        );
      } finally {
        this.uploadProgress = undefined;
      }

      await this.updatePlanData(true);
    }

    return uploadResponse;
  }
  // actions for diff- resources
  filenameAction(row: DriveItemModel) {
    switch (this.moduleType) {
      case ModuleType.Plan: {
        this.downloadDoc(row.name, row.id, true);
        break;
      }
      // case ModuleType.Plan: {
      //   this.router.navigate(pathFragmentsTo(this.projectService.projectId, AppRoutingData.planning.path, 'viewer'), {
      //     queryParams: { planId: row.id },
      //   });
      //   break;
      // }
      case ModuleType.Bim: {
        if (row.conversion && row.conversion.mappedId) this.openModelViewer(row.conversion.mappedId);
        else this.userNotification.notify('planning.notifications.conversionMissing');
        break;
      }
      default: {
        console.warn('ModuleType not supported!', this.moduleType);
      }
    }
  }

  async openModelViewer(modelId: string) {
    this.router.navigate(pathFragmentsTo(this.projectService.projectId, AppRoutingData.bim.path), { queryParams: { modelId } });
  }

  async generateModel(item: any) {
    await this.convertModel([
      new DriveUploadResultModel({
        fileId: item.id,
        fileName: item.name,
      }),
    ]);
  }

  async downloadDoc(name: string, id: string, preview: boolean = false) {
    await this.documentDownload.downloadDocument(
      {
        fileName: name,
        id: id,
        resource: this.resource,
      },
      preview
    );
  }

  async downloadDocs() {
    const count = this.planData.data.length;
    //download each selected file seq. & deselects
    for (let i = 0; i < count; i++) {
      // for of instead when IE dead
      const f = this.planData.data[i];
      if (f.selected) {
        await this.documentDownload.downloadDocument({
          fileName: f.name,
          id: f.id,
          resource: this.resource,
        });
        f.selected = false;
      }
    }
    this.deselectAll();
  }

  async deleteItems(files: any[]) {
    this.requestInProgress = true;
    this.deselectAll();
    // send requests parallel
    await Promise.all(
      files.map(async f => {
        try {
          await this.apiService.deleteDriveItem(f.id, this.resource);
        } catch (e) {
          this.log.error(`Failed to delete document ${f.id}`, e);
          this.userNotification.notify('planning.error.message.deleteError', { filename: f.name });
        }
      })
    );
    this.requestInProgress = false;
    await this.updatePlanData(true);
  }

  async approve(entry: any, isApproved: boolean) {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: isApproved
          ? 'planning.notifications.approveConfirmCaption'
          : 'planning.notifications.rejectApproveConfirmCaption',
        description: isApproved
          ? 'planning.notifications.approveConfirmDescription'
          : 'planning.notifications.rejectApproveConfirmDescription',
      },
    });

    if (await dialogRef.afterClosed().toPromise()) {
      entry.pending = true;
      const { fileNameParts, fileExtension } = PlanUtils.parseFileName(entry.name, this.planDef, this.seperator);
      fileNameParts[this.stateIndex] = isApproved ? PlanApproval.ApprovedCode : PlanApproval.AuditCode;
      const name = PlanUtils.getFileNameFromParts(fileNameParts, this.planDef, fileExtension, this.seperator);
      try {
        await this.apiService.changeReleaseState(entry.id, name, this.resource);
        entry.name = name;
        entry.Status = isApproved ? PlanApproval.ApprovedLabel : PlanApproval.AuditLabel;
      } catch (e) {
        let logMsg = `Failed to rename document ${entry.id}`;
        let uiMsgKey = 'documents.errorRenameFailed';
        if (SwaggerException.isSwaggerException(e)) {
          e = e as SwaggerException;
          if (e.status === 409) {
            logMsg = `Failed to rename document ${entry.id} - name in use`;
            uiMsgKey = 'documents.errorRenameFailedNameInUse';
          }
        }
        this.log.error(logMsg, e);
        this.userNotification.notify(uiMsgKey);
      } finally {
        entry.pending = false;
      }
    }
  }

  private getNotificationCall(itemIds: DriveItemIdentifierModel[]): ApiNotificationCall {
    return async model => {
      return await this.apiService.shareFileNotifaction(
        new ShareDriveModel({
          to: model.to,
          message: model.message ?? '',
          subject: model.subject ?? '',
          format: TextFormatType.Text,
          items: itemIds,
        })
      );
    };
  }
}
