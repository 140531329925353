<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:true }"></ngx-loading>
<button *ngIf="isOffline; else onlineButton" mat-icon-button class="offline-state-button"
				[matTooltip]="'offline.actions' | translate" [matMenuTriggerFor]="offlineMenu">
	<span class="mdi mdi-cloud-off-outline"></span>
	<span *ngIf="errorCount > 0" class="badge">
		<span>{{ errorCount < 10 ? errorCount : '9+' }}</span>
		</span>
</button>

<ng-template #onlineButton>
	<button mat-icon-button class="offline-state-button" (click)="goOffline()"
					[matTooltip]="'offline.menu.goOffline' | translate">
		<span class="mdi mdi-cloud-outline"></span>
	</button>
</ng-template>

<mat-menu #offlineMenu="matMenu">
	<button mat-menu-item (click)="sync()">{{ 'offline.menu.sync' | translate }}</button>
	<button mat-menu-item (click)="goOnline()">{{ 'offline.menu.goOnline' | translate }}</button>
	<button mat-menu-item *ngIf="errorCount > 0" (click)="showSyncError()">{{ 'offline.error.syncTitle' | translate
		}}</button>
</mat-menu>