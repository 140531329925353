import { Component, HostListener, Inject, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { SafeUrl } from '@angular/platform-browser';
import {
  FilePreviewSpec,
  GeoJSONFeatureCollection,
  GeoJSONRelationType,
  GeoJSONType,
  IGeoJSONFeatureCollection,
} from '@app/api';
import { ApiService, FilePreviewCacheService, OfflineService } from '@app/core';
import { UserNotificationService } from '@app/shared/services';
import { FilePreviewOverlayRef } from '@app/shared/services/file-preview/file-preivew-overlay-ref';
import { IFilePreviewConfig, FILE_PREVIEW_OVERLAY_DATA } from '@app/shared/services/file-preview/FilePreviewConfig';
import { BehaviorSubject, Observable, of, ReplaySubject } from 'rxjs';
import { map, catchError, mapTo, mergeMap } from 'rxjs/operators';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { GeojsonEditorComponent, IGeoJsonChange } from '../geojson';

interface IState {
  imageUrl?: string | SafeUrl;
  show: boolean;
  draw: boolean;
}
@Component({
  selector: 'app-file-preview-overlay',
  templateUrl: './file-preview-overlay.component.html',
  styleUrls: ['./file-preview-overlay.component.scss'],
})
export class FilePreviewOverlayComponent {
  private subject = new ReplaySubject<IFilePreviewConfig>(1);

  public form: UntypedFormGroup;
  public fileURL$: Observable<IState>;
  public markers$ = new BehaviorSubject<IGeoJSONFeatureCollection | null>(null);

  public isBusy = true;

  constructor(
    private overlayRef: FilePreviewOverlayRef,
    private dialog: MatDialog,
    private api: ApiService,
    private offlineService: OfflineService,
    private filePreviewCacheService: FilePreviewCacheService,
    private userNotificationService: UserNotificationService,
    private formBuilder: UntypedFormBuilder,
    @Inject(FILE_PREVIEW_OVERLAY_DATA) private config: IFilePreviewConfig
  ) {
    this.subject.next(config);

    this.form = this.formBuilder.group({
      geojson: this.formBuilder.control({}),
    });

    this.config = config;

    this.fileURL$ = this.subject.pipe(
      mergeMap(x => this.filePreviewCacheService.getPreview(x.fileId, FilePreviewSpec.Preview, x.fileResource)),
      map(x => {
        this.isBusy = true;

        return {
          imageUrl: x,
          draw: this.drawable,
          show: true,
        } as IState;
      }),
      catchError($err => {
        console.error($err);

        const imageUrl = this.filePreviewCacheService.getErrorImageUrl({});

        return of({
          imageUrl: imageUrl,
          draw: false,
          show: true,
        } as IState);
      })
    );

    this.fileURL$.forEach(async () => {
      if (this.drawable) {
        await this.load();
      }
    });
  }

  private get drawable(): boolean {
    return this.config.fileMetadataId && !this.offlineService.isProjectOffline();
  }

  // Listen on keydown events on a document level
  @HostListener('document:keydown', ['$event']) private async handleKeydown(event: KeyboardEvent) {
    if (event.key == 'Escape') {
      this.overlayRef.close();
    } else if (event.code == 'ArrowLeft') {
      if ((this.drawable && this.form.pristine) || (await this.confirm())) this.prev();
    } else if (event.code == 'ArrowRight') {
      if ((this.drawable && this.form.pristine) || (await this.confirm())) this.next();
    }
  }

  public fireMarkersChange($evt: IGeoJsonChange) {
    this.form.setValue({
      geojson: GeoJSONFeatureCollection.fromJS({
        features: $evt.features,
        type: GeoJSONType.FeatureCollection,
      }),
    });

    this.form.markAsDirty();
  }

  public next() {
    if (this.config.events.onnext) {
      this.config = this.config.events.onnext(this.config);

      this.subject.next(this.config);
    }
  }

  public prev() {
    if (this.config.events.onprev) {
      this.config = this.config.events.onprev(this.config);

      this.subject.next(this.config);
    }
  }

  async load() {
    if (!this.drawable) {
      return;
    }

    const config = this.config;

    const result = await this.api.getGeoJSON(config.fileMetadataId, GeoJSONRelationType.Self);
    const featureCollection: IGeoJSONFeatureCollection = result.toJSON();

    this.markers$.next(featureCollection);

    this.form.setValue({
      geojson: featureCollection,
    });

    this.form.markAsPristine();
  }

  async save() {
    setTimeout(async () => {
      if (!this.drawable) {
        return;
      }

      const config = this.config;

      const { geojson } = this.form.value;

      try {
        const result = await this.api.putGeoJSON(config.fileMetadataId, geojson, GeoJSONRelationType.Self, null);

        this.form.markAsPristine();

        this.markers$.next(geojson);

        if (this.config.events.onsave) {
          this.config.events.onsave({
            deleted: result.deleted,

            fileId: this.config.fileId,
            fileResource: this.config.fileResource,
            fileMetadataId: this.config.fileMetadataId,
          });
        }

        this.userNotificationService.notify('dialogs.image-editor.actions.save.success');
      } catch ($err) {
        console.error($err);
        this.userNotificationService.notify('dialogs.image-editor.actions.save.failure');
      }
    }, 0);
  }

  reset() {
    if (!this.drawable) {
      return;
    }

    // async pipe caches the value, so we need to force it to emit
    // this restores with the saved value from server
    this.markers$.next({ ...this.markers$.value });

    this.form.reset({
      geojson: this.markers$.value,
    });
  }

  async close() {
    if (this.drawable && !this.form.pristine && !(await this.confirm())) {
      return;
    }

    this.overlayRef.close();
  }

  async confirm() {
    const dialogRef = this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: 'dialogs.confirmImageEditorClose.title',
        description: 'dialogs.confirmImageEditorClose.description',
      },
      disableClose: true,
    });

    const dialogResult = await dialogRef.afterClosed().toPromise();
    return dialogResult;
  }

  complete() {
    this.isBusy = false;
  }
}
