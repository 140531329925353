<div class="comments-container">
	<div class="comment">
		<app-user-image [userId]="userId" sizeRem="3.6">
		</app-user-image>

		<div class="comment-bubble" [class.expanded]="!comments?.length || comment || isAddCommentFocused || mainViewpoint">
			<div class="comment-input-container">
				<textarea matInput class="comment-input" (focus)="isAddCommentFocused = true"
									(blur)="isAddCommentFocused = false" [(ngModel)]="comment" (input)="formChanged()"
									[placeholder]="'comments.addPlaceholder' | translate" [disabled]="service?.isBusy"
									(keydown.control.enter)="onEnter($event)">
				</textarea>

				<app-bim-viewpoint *ngIf="viewpointsLoader != null" class="comment-viewpoint"
													 [canAddViewpoint]="canAddViewpoint" [viewpointCount]="viewpointCount"
													 [showViewpointCount]="false" [mainViewpoint]="mainViewpoint"
													 [viewpointsLoader]="viewpointsLoader" deletionMode="main" (newViewpoint)="addViewpoint()"
													 (viewpointClicked)="viewpointClicked($event)"
													 (deleteViewpoint)="removeViewpoint()"></app-bim-viewpoint>
			</div>

			<div *ngIf="showAddButton" class="content-row center">
				<span class="grow"></span>
				<button mat-stroked-button (click)="addComment()">
					<span>{{ 'comments.comment' | translate }}</span>
				</button>
			</div>
		</div>
	</div>

	<app-comment-bubble [class.c4-busy]="service.isBusy" *ngFor="let comment of comments" [comment]="comment"
											[service]="service" [viewpointCount]="viewpointCount" [createNewViewpoint]="createNewViewpoint"
											[viewpointsLoader]="viewpointsLoader" (isEditChange)="commentEditChange($event)">
	</app-comment-bubble>
</div>