<!-- Gallery -->
<app-progress-bar [progress]="uploadProgress" type="determinate"></app-progress-bar>
<div #galleryList class="gallery" [class.app-docExDragDrop]="isDragDrop" (drop)="onDrop($event)"
		 (dragover)="onDragOver($event)" (dragenter)="onDragEnter($event)" (dragleave)="onDragLeave($event)"
		 (dragend)="onDragEnd($event)">
	<ng-container *ngIf="imagesToday?.length" [ngTemplateOutlet]="imagesTemplate" [ngTemplateOutletContext]="{
		title: 'general.date.today',
		images: imagesToday
	}"></ng-container>
	<ng-container *ngIf="imagesLastSevenDays?.length" [ngTemplateOutlet]="imagesTemplate" [ngTemplateOutletContext]="{
		title: 'general.date.lastSevenDays',
		images: imagesLastSevenDays
	}"></ng-container>
	<ng-container *ngIf="imagesLastThirtyDays?.length" [ngTemplateOutlet]="imagesTemplate" [ngTemplateOutletContext]="{
		title: 'general.date.lastThirtyDays',
		images: imagesLastThirtyDays
	}"></ng-container>
	<ng-container *ngIf="imagesOlder?.length" [ngTemplateOutlet]="imagesTemplate" [ngTemplateOutletContext]="{
		title: 'general.date.older',
		images: imagesOlder
	}"></ng-container>

	<div class="center-content">
		<button *ngIf="hasMoreImages" mat-button (click)="renderImages()">
			<span>{{ 'general.showMore' | translate }}</span>
		</button>
	</div>

	<div *ngIf="!hasImages; else emptyFilter" class="gallery-empty">
		<span class="c4-grid-empty-ico mdi mdi-blur-off"></span>{{ 'gallery.noDataText' | translate}}
	</div>

	<ng-template #emptyFilter>
		<div *ngIf="!hasFilteredImages" class="gallery-empty">
			<span class="c4-grid-empty-ico mdi mdi-blur-off"></span>{{ 'general.noFilterResult' | translate}}
		</div>
	</ng-template>
</div>

<ng-template #imagesTemplate let-title="title" let-images="images">
	<div class="title">{{ title | translate }}</div>
	<div class="image-grid">
		<ng-container *ngFor="let image of images">
			<div class="image-item c4-busy" *ngIf="image.shown">
				<div class="image-border" [class.selected]="image == selectedImage">
					<div class="image-container">
						<ng-template #imageTemplate let-imageUrl [ngTemplateOutlet]="imageTemplate" [ngTemplateOutletContext]="{
							$implicit: image.id | imagePreview:image.resourceIdentifier?.key.name:'Preview':image.lastModifiedDateTime:{unsafe:true, onlyImage:true} | async
						}">
							<div class="image c4-busy-content">
							</div>
							<div class="image" [lazyLoad]="imageUrl" [scrollTarget]="scrollContainer" [offset]="offsetPxLoading"
									 (click)="imageClicked(image, $event, false)">
							</div>
						</ng-template>
						<span *ngIf="!areImagesSelected" class="image-item-watermark mdi mdi-draw-pen" [class.loading]="isBusy"
									[class.visible]="image.hasGeoJson" (click)="previewImage(image)">
						</span>
						<div class="select-image-checkbox" [class.loading]="isBusy" [class.visible]="areImagesSelected">
							<app-multi-select-overlay [checked]="image.selected" (ownClick)="checkboxClick(image,$event)"
																				[preventClick]="true">
							</app-multi-select-overlay>
						</div>
					</div>
				</div>
				<div class="image-info-container">
					<span class="image-name" [class.c4-busy-content]="isBusy" [matTooltip]="image.name">
						{{ image.name }}
					</span>
					<span class="image-size" [class.c4-busy-content]="isBusy">
						{{ image.size | filesize:locale }}
					</span>
				</div>
			</div>
		</ng-container>
	</div>
</ng-template>