import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, Inject, ViewChild, ElementRef, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import {
  MatLegacyAutocompleteSelectedEvent as MatAutocompleteSelectedEvent,
  MatLegacyAutocompleteTrigger as MatAutocompleteTrigger,
} from '@angular/material/legacy-autocomplete';
import { MatLegacyChipInputEvent as MatChipInputEvent, MatLegacyChipList as MatChipList } from '@angular/material/legacy-chips';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog,
} from '@angular/material/legacy-dialog';
import { MultiStatusResult, ReceiverModel, ReceiverType, StandardTextModel } from '@app/api';

import { ApiService, AppConfigService, LogService, TranslationManagementService, Utils } from '@app/core';
import { FormComponent } from '@app/core/utils/form-component';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { NotificationDialogData } from './mail-notification-dialog.interfaces';
import { StandardTextSelectionDialogComponent } from '../../standard-text-input';

@Component({
  selector: 'app-mail-notification-dialog',
  templateUrl: './mail-notification-dialog.component.html',
  styleUrls: ['./mail-notification-dialog.component.scss'],
})
export class MailNotificationDialogComponent extends FormComponent implements OnInit, Busy {
  @ViewChild('userInput') userInput: ElementRef<HTMLInputElement>;
  @ViewChild('chipList') chipList: MatChipList;
  @ViewChild('messageText') textInput: ElementRef<any>;
  @ViewChild('trigger') autocompleteTrigger: MatAutocompleteTrigger;

  isBusy: boolean;
  users: ReceiverModel[];
  autoUsers: ReceiverModel[] = [];
  selectedUsers: ReceiverModel[] = [];
  separatorKeysCodes: number[] = [ENTER, COMMA];
  receiverType = ReceiverType;
  isCustomMessageSupported: boolean = true;

  dialogParams: NotificationDialogData = null;
  titleLabel: string = 'dialogs.fileNotification.titleAlt';
  cancelLabel: string = 'general.skip';

  constructor(
    @Inject(MAT_DIALOG_DATA) data: NotificationDialogData,
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    private log: LogService,
    private userNotification: UserNotificationService,
    public dialogRef: MatDialogRef<MailNotificationDialogComponent>,
    private translationService: TranslationManagementService,
    private dialog: MatDialog
  ) {
    super();
    // this.notifyData = data ? data : null;
    if (data.sendNotification == undefined) {
      console.error('API callback not specified');
      dialogRef.close(false);
    }

    this.dialogParams = data;

    if (data && data.userEvent) {
      this.cancelLabel = 'general.cancel';
      this.titleLabel = 'dialogs.fileNotification.title';
    }

    if (data?.isCustomMessageSupported != undefined) {
      this.isCustomMessageSupported = data.isCustomMessageSupported;
    }
  }

  async addStandardText() {
    if (this.form.enabled) {
      const selectionStart = this.textInput.nativeElement.selectionStart;
      const selectionEnd = this.textInput.nativeElement.selectionEnd;
      const value: string = this.f.message.value ?? '';
      let standardTexts: StandardTextModel[] = [];
      let success = false;

      await using(new BusyScope(this), async busy => {
        try {
          standardTexts = await this.apiService.getStandardTexts();
          if (standardTexts.length > 0) {
            success = true;
          } else {
            await this.userNotification.notify('standardTexts.error.empty');
          }
        } catch (e) {
          await this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.standardTexts', e);
        }
        if (success) {
          const standardText = (await this.dialog
            .open(StandardTextSelectionDialogComponent, {
              data: {
                standardTexts,
              },
            })
            .afterClosed()
            .toPromise()) as StandardTextModel;

          if (standardText) {
            this.f.message.patchValue(value.slice(0, selectionStart) + standardText.text + value.slice(selectionEnd));
          }
        }
      });
    }
  }

  async ngOnInit() {
    this.initForm();

    await using(new BusyScope(this), async busy => {
      try {
        const userSession = await this.apiService.getUserSession();
        const signature = userSession.signature;

        if (signature) {
          this.f.message.setValue('\r\n\r\n' + signature, { emitEvent: false });
        }
      } catch {}

      try {
        const users = await this.apiService.getUserForNotification();
        const everyoneId = AppConfigService.settings.defaultRoleIds.everyone.toLowerCase();

        const rolesEveryoneIndex = users.findIndex(x => everyoneId == x.id.toLowerCase());
        if (rolesEveryoneIndex >= 0) {
          users[rolesEveryoneIndex].label = this.translationService.instant('projectConfig.roles.entireProjectTeam');
        }

        this.users = users.orderByMultiple(
          user => (user.type == ReceiverType.Role ? 0 : 1),
          user => user.name
        );

        if (this.dialogParams.receiverIds) {
          this.selectedUsers = this.users.filter(u => this.dialogParams.receiverIds.includes(u.id));
          this.f.to.setValue(this.selectedUsers, { emitEvent: false });
        }

        if (this.dialogParams.message) {
          this.f.message.setValue(this.dialogParams.message.replace(new RegExp('<br>', 'g'), '\n'));
        }

        if (this.dialogParams.subject) {
          this.f.subject.setValue(this.translationService.instant(this.dialogParams.subject));
        }
      } catch {
        this.userNotification.notify('general.errorMsg.getReceiversFailed');
        this.users = [];
      }
    });
  }

  async ok() {
    if (this.form.invalid) return;

    await using(new BusyScope(this), async busy => {
      try {
        const result = await this.dialogParams.sendNotification({
          to: this.f.to.value,
          subject: this.f.subject.value,
          message: this.f.message.value,
        });

        const multiResult = result as MultiStatusResult;
        if (multiResult !== undefined) {
          if (multiResult.metadata.successful == multiResult.metadata.total) {
            this.userNotification.notify('general.successMsg.shareDetail', {
              notified: multiResult.metadata.successful,
            });
          } else {
            this.userNotification.notify('general.errorMsg.shareDetail', {
              total: multiResult.metadata.successful + multiResult.metadata.failed,
              failures: multiResult.metadata.failed,
            });
            this.log.error('Send Mail Notification', multiResult);
          }
        } else {
          this.userNotification.notify('general.successMsg.share');
        }
      } catch (e) {
        this.userNotification.notify('general.errorMsg.saveNotification');
        return;
      }

      this.dialogRef.close(true);
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }

  autofilter(value: string = '') {
    if (!this.users) {
      this.autoUsers = [];
      return;
    }

    const filterValue = value?.toLowerCase()?.trim() ?? '';
    const filtered = this.users.filter(user => {
      return (
        (user.label && user.label.toLowerCase().indexOf(filterValue) >= 0) ||
        (user.emailAddress && user.emailAddress.toLocaleLowerCase().indexOf(filterValue) >= 0) ||
        (user.username && user.username.toLocaleLowerCase().indexOf(filterValue) >= 0)
      );
    });

    if (filtered.length < 100) {
      this.autoUsers = this.reduceUsers(filtered);
    }
  }

  reduceUsers(filtered: ReceiverModel[]) {
    if (this.selectedUsers && this.selectedUsers.length > 0) {
      this.selectedUsers.forEach(ir => {
        for (let i = 0; i < filtered.length; i++) {
          if (ir.label === filtered[i].label) {
            filtered.splice(i, 1);
            break;
          }
        }
      });
    }
    return filtered.slice();
  }

  addCustomEmail(event: MatChipInputEvent): void {
    const input = event.chipInput.inputElement;
    if (input.value) {
      if (this.checkEmailValidity(input.value) == false) this.userNotification.notify('general.errorForms.email');

      if (input) {
        input.value = '';
        this.autofilter();
        this.autocompleteTrigger.openPanel();
      }
    }
  }

  private checkEmailValidity(email: string): boolean {
    if (this.dialogParams.isCustomMailAddressSupported) {
      const value = email?.trim();
      if (value) {
        if (Utils.isEmail(value)) {
          const receiver = new ReceiverModel({
            label: value.trim(),
            id: '00000000-0000-0000-0000-000000000000',
            type: ReceiverType.Mail,
            roleIds: [],
          });
          const selectedUsers = [...this.selectedUsers];
          selectedUsers.push(receiver);
          this.f.to.setValue(selectedUsers);
          return true;
        } else {
          return false;
        }
      }
    }
    return true;
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    const selected = event.option.value;
    const receiver = new ReceiverModel({
      label: selected.label.trim(),
      id: selected.id,
      type: selected.type,
      emailAddress: selected.emailAddress,
      roleIds: selected.roleIds,
    });
    const selectedUsers = [...this.selectedUsers];
    selectedUsers.push(receiver);
    this.f.to.setValue(selectedUsers);
    this.userInput.nativeElement.value = '';
    this.autofilter();
    setTimeout(() => {
      this.autocompleteTrigger.openPanel();
    }, 0);
  }

  removeUser(index: number): void {
    const selectedUsers = [...this.selectedUsers];
    selectedUsers.splice(index, 1);
    this.f.to.setValue(selectedUsers);
    this.autofilter();
  }

  checkForChiplistError() {
    this.chipList.errorState = !!this.f.to.errors;
  }

  onKeyDownChipListInput(event: KeyboardEvent) {
    if (event.code == 'Tab') {
      if (this.userInput.nativeElement.value) {
        if (this.checkEmailValidity(this.userInput.nativeElement.value) == false) {
          this.userNotification.notify('general.errorForms.emailAndUser');
        }

        this.userInput.nativeElement.value = '';
        this.autofilter();
      }
    }
  }

  onFocusTextInput() {
    const textArea = this.textInput.nativeElement;
    if (textArea) textArea.selectionEnd = 0;
  }

  splitGroup(model: ReceiverModel) {
    if (model.type == ReceiverType.Role) {
      const roleIds = [model.id, ...model.roleIds];
      for (const user of this.users.filter(u => u.type == ReceiverType.User && u.roleIds.some(id => roleIds.includes(id)))) {
        if (this.selectedUsers.some(u => u.id == user.id)) continue;

        this.selectedUsers.push(user);
      }

      this.selectedUsers.remove(model);
    }
  }

  private initForm() {
    this.form = this.formBuilder.group({
      to: ['', [Validators.required]],
      subject: ['', [Validators.maxLength(255)]],
      message: [''],
    });

    this.f.to.valueChanges.subscribe(value => {
      this.selectedUsers = value;
      this.checkForChiplistError();
    });
  }
}
