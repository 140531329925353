import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import {
  DriveItemTagsUpdateMode,
  DriveItemTagsUpdateModel,
  DriveUploadResultModel,
  GalleryDriveItemModel,
  ModuleType,
  PrivilegeEnum,
} from '@app/api';
import { ApiService, BaseSubscriptionComponent, LogService, ProjectService, Utils } from '@app/core';
import { DocumentUploadData, DocumentUploadService, UserNotificationService } from '@app/shared/services';
import { FilePreviewOverlayService } from '@app/shared/services/file-preview/file-preview-overlay-config.service';
import { IFilePreviewConfig, IPreviewSaveEvent } from '@app/shared/services/file-preview/FilePreviewConfig';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { ImageTagDialogComponent } from '../../dialogs/image-tag-dialog/image-tag-dialog.component';
import { IImageTag, ImageTags, ImageTagService, setImageTagProperties } from '../image-tag.service';
import { ImageFilter } from '../image.interfaces';
import { FileUtils } from '@app/core/utils/file-utils';

interface ExtendedGalleryDriveItemModel extends GalleryDriveItemModel {
  shown?: boolean;
  selected?: boolean;
}

@Component({
  selector: 'app-gallery-list',
  templateUrl: './gallery-list.component.html',
  styleUrls: ['./gallery-list.component.scss'],
})
export class GalleryListComponent extends BaseSubscriptionComponent implements OnInit, Busy {
  @ViewChild('galleryList', { static: true }) galleryList: ElementRef<HTMLDivElement>;

  @Input() selectedImage: ExtendedGalleryDriveItemModel;
  @Input() validTypes: string[];
  @Output() selectedImageChange = new EventEmitter<GalleryDriveItemModel>();
  @Output() selectedImagesChange = new EventEmitter<GalleryDriveItemModel[]>();
  @Input() scrollContainer: HTMLElement;
  @Input() set filter(filter: ImageFilter) {
    this.indexOfLatestImageClicked = null;
    this.imageFilter = filter;
    if (this.areImagesLoaded) this.filterImages();
  }

  isBusy: boolean;
  isDragDrop: boolean;
  uploadProgress: number = undefined;
  fakeImages: ExtendedGalleryDriveItemModel[] = [];
  imagesToday: ExtendedGalleryDriveItemModel[] = [];
  imagesLastSevenDays: ExtendedGalleryDriveItemModel[] = [];
  imagesLastThirtyDays: ExtendedGalleryDriveItemModel[] = [];
  imagesOlder: ExtendedGalleryDriveItemModel[] = [];
  locale: string;
  areImagesSelected: boolean = false;

  readonly offsetPxLoading: number = 500;

  private galleryResource: string;
  private areImagesLoaded: boolean;
  private dateToday: Date = moment().startOf('day').toDate();
  private dateAWeekAgo: Date = moment().startOf('day').subtract(1, 'weeks').toDate();
  private dateThirtyDaysAgo: Date = moment().startOf('day').subtract(30, 'days').toDate();
  private displayedImages: number = 0;
  private allImages: ExtendedGalleryDriveItemModel[] = [];
  private filteredImages: ExtendedGalleryDriveItemModel[] = [];
  private imageFilter: ImageFilter;
  private addFileAllowed: boolean;

  constructor(
    private apiService: ApiService,
    private documentUpload: DocumentUploadService,
    private log: LogService,
    private projectService: ProjectService,
    private translate: TranslateService,
    private userNotification: UserNotificationService,
    private filePreviewService: FilePreviewOverlayService,
    private dialog: MatDialog,
    private svcImageTagService: ImageTagService
  ) {
    super();
    this.locale = translate.currentLang;
  }

  get hasImages(): boolean {
    return !!this.allImages?.length;
  }

  get hasFilteredImages(): boolean {
    return !!this.filteredImages?.length;
  }

  get hasMoreImages(): boolean {
    return this.displayedImages < (this.filteredImages?.length ?? 0);
  }

  private indexOfLatestImageClicked: number = null;

  async ngOnInit() {
    for (let i = 0; i < 5; ++i) {
      this.fakeImages.push(
        new GalleryDriveItemModel({
          name: 'fakeData',
          size: 1234,
          lastModifiedDateTime: new Date(),
        })
      );
      this.fakeImages[i].shown = true;
    }

    this.subscribe(this.translate.onLangChange, langChangeEvent => {
      this.locale = langChangeEvent.lang;
    });

    this.resetImages(true);

    this.scrollContainer.onscroll = (event: any) => {
      // +1: pixel rounding error
      if (event.target.offsetHeight + event.target.scrollTop + 1 >= event.target.scrollHeight) {
        this.renderImages();
      }
    };

    this.subscribe(this.projectService.projectId$, async projectId => {
      if (projectId) {
        await using(new BusyScope(this), async _ => {
          const resourceIdentifiers = await this.apiService.getResourceIdentifiers();
          this.galleryResource = resourceIdentifiers.find(i => i.moduleType === ModuleType.Gallery).key.name;

          const privileges = projectId ? await this.apiService.getUserPrivileges() : [];
          this.addFileAllowed = privileges.some(p => p == PrivilegeEnum.ReadWriteGallery);
          if (privileges.includes(PrivilegeEnum.ReadGallery)) {
            await this.loadImages();
          }
        });
      }
    });
  }

  updateImage(newImage: ExtendedGalleryDriveItemModel, oldId: string = null) {
    const id = oldId ?? newImage.id;
    const oldImage = this.allImages.find(i => i.id == id);
    oldImage.init(newImage);
  }

  imageClicked(image: ExtendedGalleryDriveItemModel, event: MouseEvent, isCheckBox: boolean) {
    const imageIndex = this.allImages.indexOf(image);

    if (event.ctrlKey) {
      if (this.selectedImage != null) this.multiSelectImage(this.selectedImage, true);

      this.multiSelectImage(image, !image.selected);
    } else if (event.shiftKey && this.indexOfLatestImageClicked != null && this.indexOfLatestImageClicked != imageIndex) {
      this.multiSelect(imageIndex);

      return; // do not reset indexOfLatestImageClicked
    } else {
      if (this.areImagesSelected) this.deselectImages();

      if (!isCheckBox) {
        if (image != this.selectedImage) {
          if (this.isBusy) return;

          this.selectedImage = image;
          this.selectedImageChange.emit(image);
        } else {
          this.deselectImage();
        }
      }
    }

    this.indexOfLatestImageClicked = imageIndex;
  }

  async tagImages(selectedImages: GalleryDriveItemModel[]) {
    const combinedTags = new Map<string, number>();

    selectedImages.forEach(selectedImage => {
      if (selectedImage.tags) {
        selectedImage.tags.forEach(selectedImageTag => {
          const count = combinedTags.get(selectedImageTag) || 0;

          combinedTags.set(selectedImageTag, count + 1);
        });
      }
    });

    const selectedTags = new Array<IImageTag>();

    combinedTags.forEach((count, key) => {
      const supportsPartial = count < selectedImages.length;
      const imageTag = setImageTagProperties(
        {
          text: key,
          supportsPartial: supportsPartial,
        },
        supportsPartial
      );

      selectedTags.push(imageTag);
    });

    const dialogRef = await this.dialog.open(ImageTagDialogComponent, {
      data: {
        selectedTags: [...selectedTags],
        params: {
          count: selectedImages.length,
        },
        onsave: async (updatedTags: ImageTags) => {
          const createdTags = updatedTags.filter(updatedTag => updatedTag.partial == false).map(x => x.text);
          const deletedTags = selectedTags
            .filter(selectedTag => updatedTags.some(updatedTag => updatedTag.text == selectedTag.text) == false)
            .map(x => x.text);
          const model = new DriveItemTagsUpdateModel({
            ids: selectedImages.map(x => x.metatdataId),
            mode: DriveItemTagsUpdateMode.Merge,
            createdTags: createdTags,
            deletedTags: deletedTags,
          });

          try {
            const response = await this.apiService.updateTags(model);

            for (const selectedImage of selectedImages) {
              selectedImage.tags = response.tagsForItems[selectedImage.metatdataId].tags;
            }

            await this.svcImageTagService.createWithProject();

            // re-trigger for details (tags)
            this.selectedImageChange.emit(this.selectedImage);

            this.userNotification.notify('dialogs.image-tag-dialog.actions.success');

            return true;
          } catch ($err) {
            this.log.error($err);

            this.userNotification.notify('dialogs.image-tag-dialog.actions.failure');

            return false;
          }
        },
      },
    });

    await dialogRef.afterClosed().forEach(function () {});
  }

  deselectImage() {
    if (this.selectedImage) {
      this.selectedImage = null;
      this.selectedImageChange.emit(null);
    }
  }

  multiSelectImage(image: ExtendedGalleryDriveItemModel, selected: boolean) {
    image.selected = selected;
    const selectedImages = this.allImages.filter(i => i.selected);
    this.areImagesSelected = selectedImages.length > 0;
    this.selectedImagesChange.next(selectedImages);
    if (this.areImagesSelected && this.selectedImage != null) this.deselectImage();
  }

  checkboxClick(image: ExtendedGalleryDriveItemModel, event: MouseEvent) {
    const imageIndex = this.allImages.indexOf(image);

    if (event.shiftKey && this.indexOfLatestImageClicked != null && this.indexOfLatestImageClicked != imageIndex) {
      this.multiSelect(imageIndex);
      return; // do not reset indexOfLatestImageClicked
    } else {
      const selectedImages = this.allImages.filter(i => i.selected == true);

      // if no image is selected
      if (!image.selected && selectedImages.length == 0) this.indexOfLatestImageClicked = imageIndex;

      if (image.selected) {
        // if last selected image is deselected
        if (selectedImages.length == 1) this.indexOfLatestImageClicked = null;

        if (selectedImages.length > 1 && imageIndex == this.indexOfLatestImageClicked) {
          const index = selectedImages.indexOf(image);
          const newIndex = index == 0 ? index + 1 : index - 1;
          const newIndexImage = selectedImages[newIndex];
          this.indexOfLatestImageClicked = this.allImages.indexOf(newIndexImage);
        }
      }

      this.multiSelectImage(image, !image.selected);
    }
  }

  deselectImages() {
    for (const image of this.allImages) {
      image.selected = false;
    }
    this.areImagesSelected = false;
    this.selectedImagesChange.next([]);
  }

  filterImages() {
    this.resetImages(false);
    const filter = this.imageFilter;
    this.filteredImages = this.allImages.filter(image => this.shouldDisplayImage(image, filter));
    this.setImages();
    this.renderImages();
  }

  private shouldDisplayImage(image: ExtendedGalleryDriveItemModel, filter: ImageFilter) {
    const from = filter?.date?.from ? filter.date.from.clone().startOf('day') : null;
    const to = filter?.date?.to ? filter.date.to.clone().endOf('day') : null;
    const tags = filter?.tags?.length ? filter.tags.map(x => x.text) : null;
    const imageRoomBookEntityId =
      image.additionalProperties.find(p => p.fieldName == 'RoomId')?.value ??
      image.additionalProperties.find(p => p.fieldName == 'FloorId')?.value ??
      image.additionalProperties.find(p => p.fieldName == 'AreaId')?.value ??
      image.relatedEntityId;

    return (
      (!filter?.imageTitle || image.name.toLowerCase().includes(filter.imageTitle.toLowerCase())) &&
      (!from || from.isBefore(this.getDate(image))) &&
      (!to || to.isAfter(this.getDate(image))) &&
      (!tags || Utils.intersectsWith(tags, image.tags)) &&
      (!filter?.users?.length || filter.users.some(user => image.lastModifiedBy?.user?.id === user.id)) &&
      (!filter?.modules?.length || filter.modules.some(module => image.resourceIdentifier.moduleType === module.type)) &&
      (!filter?.roomBookIds?.length || filter.roomBookIds.some(id => imageRoomBookEntityId === id))
    );
  }

  deleteImages(images: ExtendedGalleryDriveItemModel[]) {
    for (const image of images) {
      const index = this.allImages.indexOf(image);
      this.allImages.splice(index, 1);
      if (this.selectedImage == image) this.deselectImage();
    }
    this.selectedImagesChange.emit(this.allImages.filter(i => i.selected));
    this.filterImages();
  }

  onDragLeave(event) {
    this.enableDragDrop(event, false);
  }

  onDragEnd(event) {
    this.enableDragDrop(event, false);
  }

  onDragOver(event) {
    this.enableDragDrop(event, true);
  }

  onDragEnter(event) {
    this.enableDragDrop(event, true);
  }

  async onDrop(event) {
    this.enableDragDrop(event, false);

    const uploadItems = await this.documentUpload.decodeDropEvent(event, '', this.galleryResource, true);

    if (!uploadItems) return;

    await this.uploadData(uploadItems);
  }

  async uploadFiles(files: File[]) {
    const uploadItems = await this.documentUpload.decodeFiles(files, '', this.galleryResource, true);

    if (!uploadItems) return;

    await this.uploadData(uploadItems);
  }

  private async uploadData(uploadData: DocumentUploadData[]) {
    let uploadResponse: DriveUploadResultModel[];

    try {
      this.uploadProgress = 0;
      uploadResponse = await this.documentUpload.uploadDocuments(
        uploadData,
        (currentFile: number, totalFiles: number, progressPercent: number) => {
          this.uploadProgress = progressPercent * 100;
          this.log.debug(`Upload progress: ${100 * progressPercent}% (processing file ${currentFile} of ${totalFiles})`);
        }
      );
    } catch {
      this.userNotification.notify('general.errorMsg.upload');
    } finally {
      this.uploadProgress = undefined;
    }

    // could be overwritten
    this.allImages = this.allImages.filter(i => !uploadResponse.some(ei => ei.fileId == i.id));
    const images = await Promise.all(uploadResponse.map(r => this.apiService.getGalleryItem(r.fileId, this.galleryResource)));

    this.allImages.unshift(...images);
    this.allImages.sort((a, b) => this.getDate(b).getTime() - this.getDate(a).getTime());
    this.filterImages();
  }

  previewImage(image: ExtendedGalleryDriveItemModel) {
    const index = this.filteredImages.findIndex(i => i.metatdataId == image.metatdataId);

    const first = 0;
    const final = this.filteredImages.length - 1;

    const dialogRef = this.filePreviewService.open({
      data: {
        fileId: image.id,
        fileResource: image.resourceIdentifier.key.name,
        fileMetadataId: image.metatdataId,

        events: {
          onsave: image => this.markImage(image),
          onprev: index > first ? image => this.showImage(image, -1) : null,
          onnext: index < final ? image => this.showImage(image, +1) : null,
        },
      },
    });
  }

  private markImage(evt: IPreviewSaveEvent) {
    const image = this.allImages.find(image => image.metatdataId == evt.fileMetadataId);

    image.hasGeoJson = !evt.deleted;
  }

  private showImage(evt: IFilePreviewConfig, offset: number) {
    const index = this.filteredImages.findIndex(i => i.metatdataId == evt.fileMetadataId) + offset;
    const image = this.filteredImages[index];

    const first = 0;
    const final = this.filteredImages.length - 1;

    const fn: IFilePreviewConfig = {
      fileId: image.id,
      fileResource: image.resourceIdentifier.key.name,
      fileMetadataId: image.metatdataId,
      events: {
        onsave: image => this.markImage(image),
        onprev: index > first ? image => this.showImage(image, -1) : null,
        onnext: index < final ? image => this.showImage(image, +1) : null,
      },
    };

    return fn;
  }

  private resetImages(useFakeData: boolean) {
    this.deselectImage();
    this.displayedImages = 0;

    if (useFakeData) {
      this.allImages = this.fakeImages;
    } else {
      for (const image of this.allImages) {
        image.shown = false;
        image.selected = false;
      }

      this.areImagesSelected = false;
      this.selectedImagesChange.next([]);
    }

    this.filteredImages = this.allImages;
    this.setImages();
  }

  private enableDragDrop(event: DragEvent, isDragDrop: boolean) {
    if (!this.addFileAllowed || this.isBusy) return;

    const files = event.dataTransfer.items;
    let isValid = files.length > 0;

    for (let i = 0; i < files.length; i++) {
      if (isValid && !this.isValidFileType(files[i].type)) {
        isValid = false;
        i = files.length;
      }
    }

    this.isDragDrop = isDragDrop;
    if (isDragDrop && event.dataTransfer && isValid) {
      event.dataTransfer.dropEffect = 'copy';
      event.dataTransfer.effectAllowed = 'copy';
    } else {
      event.dataTransfer.dropEffect = 'none';
      event.dataTransfer.effectAllowed = 'none';
    }
    event.stopPropagation();
    event.preventDefault();
  }

  private async loadImages() {
    this.resetImages(true);

    try {
      const images = await this.apiService.getGalleryItems();
      this.allImages = images.sort((a, b) => this.getDate(b).getTime() - this.getDate(a).getTime());
    } catch (e) {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.images', e);
      this.allImages = this.filteredImages = [];
    }

    this.filterImages();
    this.areImagesLoaded = true;
  }

  private setImages() {
    this.imagesToday = this.filteredImages.filter(x => this.getDate(x) >= this.dateToday);
    this.imagesLastSevenDays = this.filteredImages.filter(
      x => this.getDate(x) < this.dateToday && this.getDate(x) >= this.dateAWeekAgo
    );
    this.imagesLastThirtyDays = this.filteredImages.filter(
      x => this.getDate(x) >= this.dateThirtyDaysAgo && this.getDate(x) < this.dateAWeekAgo
    );
    this.imagesOlder = this.filteredImages.filter(x => this.getDate(x) < this.dateThirtyDaysAgo);
  }

  private renderImages() {
    const imagesPerPage = 20;
    const newDisplayedImages = this.displayedImages + imagesPerPage;
    for (let i = this.displayedImages; i < newDisplayedImages; ++i) {
      if (i >= this.filteredImages.length) {
        break;
      }

      this.filteredImages[i].shown = true;
      this.displayedImages++;
    }
  }

  private isValidFileType(type: string) {
    return FileUtils.isValidType(type, this.validTypes);
  }

  private getDate(item: GalleryDriveItemModel): Date {
    return item.predictedCreationDate ?? item.lastModifiedDateTime;
  }

  private multiSelect(imageIndex: number) {
    const fromIndex = this.indexOfLatestImageClicked < imageIndex ? this.indexOfLatestImageClicked : imageIndex;
    const toIndex = this.indexOfLatestImageClicked < imageIndex ? imageIndex : this.indexOfLatestImageClicked;

    if (this.areImagesSelected) this.deselectImages();

    for (let i = fromIndex; i <= toIndex; ++i) {
      const img = this.allImages[i];
      if (img.shown) this.multiSelectImage(img, true);
    }
  }
}
