import { Component, OnInit } from '@angular/core';
import { UntypedFormGroup, Validators, UntypedFormBuilder, AbstractControl } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ChangePasswordFormData } from './change-password-form-data';
import { ApiService, PasswordUpdateError } from '@app/core/services';
import { UserNotificationService, PasswordPolicyService } from '@app/shared/services';
import { ShowWhenParentHasError } from '@app/core';
import { PasswordComplexityModel } from '@app/api';

@Component({
  selector: 'c4-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {
  changePasswordForm: UntypedFormGroup;
  hideOld = true;
  hideNew = true;
  hideConfirm = true;
  passwordComplexityModel: PasswordComplexityModel;
  confirmNewPasswordErrrorMatcher: ErrorStateMatcher;
  errorPasswordInvalid: string;

  constructor(
    private dialogRef: MatDialogRef<ChangePasswordComponent>, 
    private formBuilder: UntypedFormBuilder,
    private apiService: ApiService,
    private userNotification: UserNotificationService,
    private passwordPolicy: PasswordPolicyService) { 
    this.confirmNewPasswordErrrorMatcher = new ShowWhenParentHasError();
  }

  async ngOnInit() {
    this.changePasswordForm = this.formBuilder.group({
      oldPassword: ['', Validators.required],
      newPassword: this.formBuilder.group({
        password: ['', [Validators.required, this.passwordValidator()]],
        passwordConfirm: ['', Validators.required]
      }, { validator: this.checkNewPasswords })
    });
    this.changePasswordForm.disable();
    try {
      this.passwordComplexityModel = await this.apiService.getPasswordComplexityModel();
    } catch (e) {
      await this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.passwordConfig', e);
      this.dialogRef.close();
      return;
    }
    this.changePasswordForm.enable();
    this.errorPasswordInvalid = await this.passwordPolicy.buildRequirementsString(this.passwordComplexityModel);
  }

  async changePassword(formData: ChangePasswordFormData) {
    try {
      await this.apiService.changePassword(formData.oldPassword, formData.newPassword.password);
      this.dialogRef.close();
      await this.userNotification.notify('dialogs.changePassword.success');
    } catch (passwordChangeError) {
      const errors: any = {};
      errors[passwordChangeError] = true;
      if (passwordChangeError === PasswordUpdateError.WrongOldPassword) {
        this.changePasswordForm.controls.oldPassword.setErrors(errors);
      }
      await this.userNotification.notify('dialogs.changePassword.error' + passwordChangeError);
    }
  }

  abort() {
    this.dialogRef.close();
  }

  private passwordValidator() {
    return (control: AbstractControl): {[key: string]: any} | null => {
      const ok: boolean = this.passwordPolicy.validate(control.value as string, this.passwordComplexityModel);
      return ok ? null : { passwordInvalid: true };
    };
  }

  private checkNewPasswords(group: UntypedFormGroup) {
    const password = group.controls.password.value;
    const passwordConfirm = group.controls.passwordConfirm.value;
    return password === passwordConfirm ? null : { passwordsNotEqual: true };
  }
}
