import { PlanFieldModel } from '@app/api';
import { ExtendedPlanFieldModel, _planSeparator } from './schema-list.interfaces';

export class PlanUtils {
  static getPlanProperties(filename: string, schema: ExtendedPlanFieldModel[], separator: string) {
    const { fileNameParts, fileExtension } = this.parseFileName(filename, schema, separator);
    let fullKey: string = '';
    let versionIndex: number = -1;
    schema.forEach((planFieldDefinition, indexOfDefinition) => {
      const planFieldValue = fileNameParts[indexOfDefinition] ? fileNameParts[indexOfDefinition] : null;
      if (planFieldDefinition.isKey && planFieldValue) fullKey += planFieldValue.toLowerCase() + separator;
      if (planFieldDefinition.isVersion && planFieldValue) {
        const options = planFieldDefinition.options?.map(o => o.value).filter(o => !!o) ?? [];
        versionIndex = options.indexOf(planFieldValue);
      }
    });
    if (fileExtension) {
      fullKey += fileExtension;
    }
    return { fullKey, versionIndex };
  }

  static getFileNameFromParts(
    parts: string[],
    schema: PlanFieldModel[],
    fileExtension: string,
    separator: string = _planSeparator
  ): string {
    let name = '';
    for (let i = 0; i < schema.length; i++) {
      const element: PlanFieldModel = schema[i];
      const prevElement: PlanFieldModel | undefined = schema[i - 1];

      let namePart = '';
      // if schema is bigger than given nameparts, just use empty values
      if (parts.length > i) {
        namePart = parts[i];
      }

      if (i === 0) name = namePart;
      else if (i === schema.length - 1 && !fileExtension) name = `${name}.${namePart}`;
      else if (element.isSeparator || prevElement?.isSeparator) name = `${name}${namePart}`;
      else name = `${name}${separator}${namePart}`;
    }

    if (fileExtension) name = `${name}.${fileExtension}`;

    return name;
  }

  static parseFileName(fullFileName: string, schema: PlanFieldModel[], separator: string = _planSeparator) {
    let fileExtensionIndex = fullFileName.lastIndexOf('.');
    if (fileExtensionIndex < 0) fileExtensionIndex = fullFileName.length;
    const [fileName, fileExtension] = [
      fullFileName.substring(0, fileExtensionIndex),
      fullFileName.substring(fileExtensionIndex + 1)?.toLowerCase(),
    ];
    let fileNameParts = [];

    if (!fileName) return { fileNameParts, fileExtension };

    let remainingFileName = fileName;

    for (let i = 0; i < schema.length - 1; i++) {
      const planFieldDef: PlanFieldModel = schema[i];

      // if its a separator, just add the field as namepart as we handle a separator elements differently
      if (planFieldDef.isSeparator) {
        fileNameParts.push(planFieldDef.field);
        continue;
      }

      const nextPlanFieldDef: PlanFieldModel | undefined = schema[i + 1];

      let fileNamePartIndex = -1;

      // try to find custom seperator element if existing
      if (nextPlanFieldDef?.isSeparator) fileNamePartIndex = remainingFileName.indexOf(nextPlanFieldDef.field);

      // try to find default seperator
      if (fileNamePartIndex === -1) fileNamePartIndex = remainingFileName.indexOf(separator);

      // if separator is not found, just get the remaining string
      if (fileNamePartIndex === -1) fileNamePartIndex = remainingFileName.length;

      const fileNamePart = remainingFileName.substring(0, fileNamePartIndex);
      fileNameParts.push(fileNamePart);

      // get the remaining string to parse
      remainingFileName = remainingFileName.substring(fileNamePartIndex + 1);

      // if we have no more filename to parse (incomplete filename) just return
      if (!remainingFileName) {
        return { fileNameParts, fileExtension };
      }
    }

    return { fileNameParts, fileExtension };
  }
}
