import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER, ErrorHandler } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ApiModule } from './api/api.module';
import { CoreModule } from './core/core.module';
import { SharedModule } from './shared/shared.module';
import {
  AuthenticationGuard,
  NotAuthenticatedGuard,
  ProjectGuard,
  SetupGuard,
  PrivilegesGuard,
  CapacitorAppGuard,
  TenantGuard,
  CanLeaveGuard,
} from './core/guards';
import { API_BASE_URL } from './api';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { MultiTranslateHttpLoader } from 'ngx-translate-multi-http-loader';
import { AppConfigService, FilePreviewCacheService } from './core/services';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import {
  MatLegacyTooltipDefaultOptions as MatTooltipDefaultOptions,
  MAT_LEGACY_TOOLTIP_DEFAULT_OPTIONS as MAT_TOOLTIP_DEFAULT_OPTIONS,
} from '@angular/material/legacy-tooltip';
import { NgcCookieConsentModule, NgcCookieConsentConfig } from 'ngx-cookieconsent';
import { MsalModule, MsalService, MSAL_INSTANCE } from '@azure/msal-angular';
import { AdminGuard } from './core/guards/admin.guard';
import { IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { OAuthFlowGuard } from './core/guards/oauth-flow.guard';
import { createGlobalErrorHandler, GlobalErrorHandler } from './shared/utils/global-error-handler';
import { NavigationError, Router } from '@angular/router';
import { CapacitorUtils } from './core/utils/capacitor-utils';
import { DateModule } from './date.module';
import * as Sentry from '@sentry/angular-ivy';
import { MaterialCssVarsModule } from 'angular-material-css-vars-legacy';

const defaultCookieConfig: NgcCookieConsentConfig = AppConfigService.defaultValues.cookieConsentConfig;

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function AppConfigFactory(appConfig: AppConfigService) {
  return () => appConfig.load(environment.settingsFile);
}

export function ApiBaseUrlFactory(appConfig: AppConfigService) {
  return AppConfigService.settings.api.url;
}

export function MatTooltipOptionsFactory(appConfig: AppConfigService): MatTooltipDefaultOptions {
  return {
    showDelay: AppConfigService.settings.tooltip.showDelayInMs,
    hideDelay: AppConfigService.settings.tooltip.hideDelayInMs,
    touchendHideDelay: AppConfigService.settings.tooltip.touchendHideDelayInMs,
    disableTooltipInteractivity: true,
  };
}

export function HttpLoaderFactory(http: HttpClient): MultiTranslateHttpLoader {
  return new MultiTranslateHttpLoader(http, [
    { prefix: environment.languagesFolder, suffix: environment.languagesSuffix },
    { prefix: environment.languagesCustomizationFolder, suffix: environment.languagesSuffix },
  ]);
}

export function MSALInstanceFactory(appConfig: AppConfigService): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: AppConfigService.settings.msal.auth.clientId,
      authority: AppConfigService.settings.msal.auth.authority,
      redirectUri: AppConfigService.settings.msal.auth.redirectUri,
      postLogoutRedirectUri: AppConfigService.settings.msal.auth.postLogoutRedirectUri,
      navigateToLoginRequestUrl: false,
    },
    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
  });
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    ApiModule,
    MsalModule,
    CoreModule,
    SharedModule,
    AppRoutingModule,
    NgcCookieConsentModule.forRoot(defaultCookieConfig),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
      isolate: true,
    }),
    DateModule, // import here to provide as singleton
    MaterialCssVarsModule.forRoot({
      primary: '#002f5d',
      accent: '#01abdf',
      warn: '#f99a24',
      isAutoContrast: true,
      darkThemeClass: 'isDarkTheme',
      lightThemeClass: 'isLightTheme',
    }),
  ],
  providers: [
    FilePreviewCacheService,
    NotAuthenticatedGuard,
    AuthenticationGuard,
    AdminGuard,
    ProjectGuard,
    CapacitorAppGuard,
    TenantGuard,
    OAuthFlowGuard,
    AppConfigService,
    MsalService,
    SetupGuard,
    PrivilegesGuard,
    CanLeaveGuard,
    //{ provide: LOCALE_ID, useValue: AppConfigService.language },
    {
      provide: APP_INITIALIZER,
      useFactory: AppConfigFactory,
      deps: [AppConfigService, Sentry.TraceService],
      multi: true,
    },
    {
      provide: API_BASE_URL,
      useFactory: ApiBaseUrlFactory,
      deps: [AppConfigService, APP_INITIALIZER],
    },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useFactory: MatTooltipOptionsFactory,
      deps: [AppConfigService, APP_INITIALIZER],
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
      deps: [AppConfigService],
    },
    {
      provide: ErrorHandler,
      useValue: createGlobalErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router, errorHandler: ErrorHandler) {
    try {
      const key = 'c4customerportal.version';

      const { [key]: version } = localStorage;

      if (!version || version != environment.indexdbversion) {
        FilePreviewCacheService.reset();

        localStorage.setItem(key, environment.indexdbversion);
      }

      if (!CapacitorUtils.isApp()) {
        if (navigator.serviceWorker) {
          navigator.serviceWorker.getRegistrations().then(function (registrations) {
            for (let registration of registrations) {
              registration.unregister();
            }
          });
        }

        router.events.subscribe((event: any) => {
          if (event instanceof NavigationError) {
            if ('name' in event.error && event.error.name === 'ChunkLoadError') {
              if (errorHandler instanceof GlobalErrorHandler) {
                errorHandler.notifyReloadPending();
              }

              const url = event.url;
              const seperator = url.indexOf('?') < 0 ? '?' : '&';

              window.location.assign(`${window.location.origin}${url}${seperator}v=${Date.now()}`);
            }
          }
        });
      }
    } catch (e) {
      // needed to call manually since ErrorHandler isn't tracking yet
      errorHandler.handleError(e);
    }
  }
}
