import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService, LogService, ApiService, GlobalsService } from '@app/core/services';
import { Observable } from 'rxjs';
import { UserNotificationService } from '@app/shared/services';
import { AppRoutingData } from '..';

@Injectable()
export class AuthenticationGuard {
  constructor(
    private apiService: ApiService,
    private authenticationService: AuthenticationService,
    private globals: GlobalsService,
    private log: LogService,
    private router: Router,
    private userNotification: UserNotificationService
  ) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    this.log.debug(`AuthenticationGuard: ${state.url}`);
    const queryUserName = next.queryParams.userName;
    const queryTeams = next.queryParams.teams;
    const isTeams = this.globals.isTeams;
    const isAuthenticated = this.authenticationService.isAuthenticated();

    if (isAuthenticated) {
      if (this.globals.isOAuth) {
        // Only allow access to consent page for OAuth
        return state.url.startsWith('/consent');
      }

      try {
        const userSession = await this.apiService.getUserSession();

        if (
          isTeams &&
          userSession.tenantId == this.globals.currentTeamsTenantId &&
          userSession.externalId != this.globals.currentTeamsUserId
        ) {
          await this.authenticationService.logout();
          await this.router.navigate(['login'], { queryParams: { teams: 'true' } });
          return false;
        }

        if (queryUserName) {
          //if the new route contains a userName - compare userName, reject if does not match and redirect to dashboard

          if (queryUserName && userSession.username !== queryUserName) {
            this.log.error(
              `Unable to open URL - is for another user (${queryUserName}) than the current user (${userSession.username})`
            );
            await this.userNotification.notify('guards.errorUrlForAnotherUser', {
              user: queryUserName,
            });
            await this.router.navigate([AppRoutingData.dashboard.path]);
            return false;
          }
        }

        return true;
      } catch (error) {
        this.log.error(error);
      }
    }
    // navigate to login page
    this.log.info('Route not allowed, redirect to login', next);

    let returnUrl: string = state.url;

    const queryParams: any = {
      returnUrl,
    };
    if (queryUserName) {
      queryParams.userName = queryUserName;
    }
    if (queryTeams) {
      queryParams.teams = queryTeams;
    }
    await this.router.navigate([isAuthenticated ? 'logout' : 'login'], { queryParams });
    return false;
  }

  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.canActivate(childRoute, state);
  }
}
