<div *ngIf="gridDef" class="c4-document-browser" [class]="containerClasses">
	<div #docGridContainer class="c4-docgrid-container" [class.c4-busy]="documentsService.isBusy" [class]="dragDropClass"
			 (drop)="onDrop($event)" (dragover)="onDragOver($event)" (dragenter)="onDragEnter($event)"
			 (dragleave)="onDragLeave($event)" (dragend)="onDragEnd($event)">
		<c4-grid #grid [gridDef]="gridDef" [source]="documentsService.driveItems$ | async"
						 [noDataText]="'documents.noDataText'">
		</c4-grid>
	</div>
</div>

<ng-template #selectAll>
	<div class="c4-no-focus offset-left">
		<button mat-icon-button class="c4-grid-btn" (click)="selectDriveItems($event)">
			<span class="mdi mdi-check-all"></span>
		</button>
	</div>
</ng-template>

<ng-template #selectRow let-driveItem='row'>
	<div class="c4-no-focus offset-left">
		<button *ngIf="driveItem.isSelectable" mat-icon-button class="c4-grid-btn"
						(click)="toogleDriveItemSelection(driveItem)">
			<span class="mdi mdi-check"></span>
		</button>
	</div>
</ng-template>

<ng-template #fileType let-col='col' let-driveItem='row'>
	<div *ngIf="driveItem.deletedItem" class="c4-no-focus">
		<button mat-icon-button class="c4-grid-btn inactive-btn">
			<span class="mdi c4-file-icon mdi-minus-circle-outline c4-file-icon-readOnly"></span>
		</button>
	</div>
	<div *ngIf="!driveItem.deletedItem && isFolder(driveItem)" class="c4-no-focus">
		<button mat-icon-button class="c4-grid-btn" (click)="navigateToFolder(driveItem)">
			<span class="mdi c4-file-icon mdi-folder" matBadge="&#8203;" matBadgeSize="medium"
						[matBadgeHidden]="!driveItem.hasNonImplicitPrivileges" matBadgePosition="below after"
						[class.c4-file-icon-readOnly]="!driveItem.privilege.addFile"></span>
		</button>
	</div>
	<div *ngIf="!driveItem.deletedItem && !isFolder(driveItem)">
		<button mat-icon-button class="c4-grid-btn inactive-btn">
			<span class="mdi c4-file-icon mdi-file" matBadge="&#8203;" matBadgeSize="medium"
						[matBadgeHidden]="!driveItem.hasNonImplicitPrivileges" matBadgePosition="below after"
						[class.c4-file-icon-readOnly]="(!driveItem.privilege.delete && !driveItem.privilege.modify)"
						[ngClass]="['documents.icon.'+ (driveItem.name| filetype| lowercase) | translate]"></span>
		</button>
	</div>
</ng-template>

<ng-template #fileName let-col='col' let-driveItem='row'>
	<ng-container *ngIf="isFolder(driveItem)">
		<a class="c4-topline c4-tree-link" (click)="navigateToFolder(driveItem)" [matTooltip]="driveItem[col.field]">
			{{ driveItem[col.field] }}
		</a>
		<span>{{'documents.folder' | translate}}</span>
	</ng-container>
	<ng-container *ngIf="!isFolder(driveItem)">
		<ng-container *ngIf="fileNameTemplate; else defaultTemplate" [ngTemplateOutlet]="fileNameTemplate"
									[ngTemplateOutletContext]="{ driveItem: driveItem }">
		</ng-container>
		<ng-template #defaultTemplate>
			<span class="c4-topline link-like-hover" [matTooltip]="driveItem[col.field]"
						[ngClass]="driveItem.unread ? 'c4-line-with-dot ' : ''" [class.disabled]="isOffline && !enablePreview"
						(click)="isOffline ? toggleOverlay(driveItem) : downloadDriveItem(driveItem)">
				{{ driveItem[col.field] | filename }}
			</span>
			<span>{{'documents.file' | translate:{fileType:(driveItem[col.field] | filetype | uppercase )} }}</span>
		</ng-template>
	</ng-container>
</ng-template>

<ng-template #contextMenuButton let-driveItem='row'>
	<div class="c4-no-focus">
		<button *ngIf="!driveItem.deletedItem && ((!isFolder(driveItem) || driveItem.privilege.delete || driveItem.privilege.modify || driveItem.unread) || canReadLog || canReadPrivilege)"
						mat-icon-button class="c4-grid-btn" [matMenuTriggerFor]="contextMenu"
						[matMenuTriggerData]="{driveItem: driveItem}" [matTooltip]="'general.actions.title' | translate">
			<span class="mdi mdi-dots-horizontal"></span>
		</button>
	</div>
</ng-template>
<mat-menu #contextMenu="matMenu">
	<ng-template matMenuContent let-driveItem='driveItem'>
		<button *ngIf="!isFolder(driveItem) && !isOffline" mat-menu-item class="c4-context-menu-item"
						(click)="downloadDriveItem(driveItem)">
			<span class="mdi mdi-download"></span>
			<span>{{ 'documents.download' | translate }}</span>
		</button>
		<button *ngIf="!isFolder(driveItem) && !isOffline && canSharePrivilege" mat-menu-item class="c4-context-menu-item"
						(click)="shareDriveItem(driveItem,driveItem.name)">
			<span class="mdi mdi-share-variant"></span>
			<span>{{ 'general.share' | translate }}</span>
		</button>
		<button *ngIf="!isFolder(driveItem) && !isOffline && canSharePrivilege" mat-menu-item class="c4-context-menu-item"
						(click)="generateZipDownloadUrl(driveItem)">
			<span class="mdi mdi-link"></span>
			<span>{{ 'documents.generateUrl' | translate }}</span>
		</button>
		<button *ngIf="driveItem.privilege.delete && canMove(driveItem)" mat-menu-item
						(click)="documentsService.moveDriveItems([driveItem], supportedMoveModuleTypes, DriveItemMoveOperations.Move)"
						[matTooltip]="'documents.moveSelectedTooltip' | translate">
			<span class="mdi mdi-image-move"></span>
			<span class="c4-ctrl-label">{{ 'general.move' | translate }}</span>
		</button>
		<button *ngIf="canMove(driveItem)" mat-menu-item
						(click)="documentsService.moveDriveItems([driveItem], supportedMoveModuleTypes, DriveItemMoveOperations.Copy)"
						[matTooltip]="'documents.copySelectedTooltip' | translate">
			<span class="mdi mdi-image-multiple"></span>
			<span class="c4-ctrl-label">{{ 'general.copy' | translate }}</span>
		</button>
		<button *ngIf="driveItem.privilege.delete && isModificationEnabled" mat-menu-item class="c4-context-menu-item"
						(click)="deleteDriveItem(driveItem)">
			<span class="mdi mdi-trash-can-outline"></span>
			<span>{{ 'documents.delete' | translate }}</span>
		</button>
		<button *ngIf="driveItem.privilege.modify && isModificationEnabled" mat-menu-item class="c4-context-menu-item"
						(click)="renameDriveItem(driveItem)">
			<span class="mdi mdi-rename-box"></span>
			<span>{{ 'documents.rename' | translate }}</span>
		</button>
		<button *ngIf="driveItem.unread" mat-menu-item class="c4-context-menu-item"
						(click)="markDriveItemAsRead(driveItem)">
			<span class="mdi mdi-check"></span>
			<span>{{ 'documents.markRead' | translate }}</span>
		</button>
		<button *ngIf="!isFolder(driveItem)" mat-menu-item class="c4-context-menu-item" (click)="signDriveItem(driveItem)">
			<span class="mdi mdi-signature-freehand"></span>
			<span>{{ 'documents.signDoc' | translate }}</span>
		</button>
		<button *ngIf="canReadLog" mat-menu-item class="c4-context-menu-item" (click)="getLog(driveItem)">
			<span class="mdi mdi-history"></span>
			<span>{{ 'documents.getLog' | translate }}</span>
		</button>
		<button *ngIf="canReadPrivilege" mat-menu-item class="c4-context-menu-item" (click)="getPrivileges(driveItem)">
			<span class="mdi mdi-table"></span>
			<span>{{ 'documents.getPrivileges' | translate }}</span>
		</button>
	</ng-template>
</mat-menu>

<ng-template #docEditor let-col='col' let-driveItem='row'>
	<div class="c4-partial-busy-indication-content">
		<span class="c4-partial-busy-content c4-topline">{{ driveItem[col.field] | localizedDate:'relative' }}</span>
		<span class="c4-partial-busy-content" *ngIf="driveItem.lastModifiedBy && driveItem.lastModifiedBy.user">{{
			driveItem.lastModifiedBy.user.displayName }}</span>
		<span class="c4-partial-busy-content" *ngIf="!driveItem.lastModifiedBy.user && driveItem.createdBy.user">{{
			driveItem.createdBy.user.displayName }}</span>
	</div>
</ng-template>
<ng-template #docEditorMobile let-col='col' let-driveItem='row' let-prefix='prefix'>
	<div class="c4-multiline-wrapper">
		<span class="c4-cell-col c4-label">{{ prefix + col.header | translate }}</span>
		<div class="c4-cell-col c4-value">
			<div>{{ driveItem[col.field] | localizedDate:'relative' }}</div>
			<div *ngIf="driveItem.lastModifiedBy && driveItem.lastModifiedBy.user">{{
				driveItem.lastModifiedBy.user.displayName }}</div>
			<div *ngIf="!driveItem.lastModifiedBy.user && driveItem.createdBy.user">{{ driveItem.createdBy.user.displayName }}
			</div>
		</div>
	</div>
</ng-template>

<ng-template #size let-col='col' let-driveItem='row'>
	<div class="c4-partial-busy-indication-content">
		<span class="c4-partial-busy-content c4-topline">{{ driveItem[col.field] | filesize }}</span>
	</div>
</ng-template>

<ng-template #download let-driveItem='row'>
	<div class="c4-no-focus">
		<button *ngIf="!isFolder(driveItem) && !driveItem.deletedItem && !isOffline" mat-icon-button class="c4-grid-btn"
						(click)="downloadDriveItem(driveItem)"
						[matTooltip]="'documents.downloadTooltip' | translate:{file:driveItem.name}">
			<span class="mdi mdi-download"></span>
		</button>
	</div>
</ng-template>

<ng-template #fileThumbnail let-col='col' let-driveItem='row'>
	<div class="image-thumbnail" (click)="previewDriveItem(driveItem)">
		<div class="image-thumbnail-container">
			<img
					 [src]="driveItem[col.field] | imagePreview:documentsService.resource:'Thumbnail':driveItem['lastModifiedDateTime']:{ onlyImage: true } | async">
			<span *ngIf="driveItem.hasGeoJson" class="image-item-watermark mdi mdi-draw-pen"></span>
		</div>
	</div>
</ng-template>