import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, UntypedFormGroup, NG_VALUE_ACCESSOR } from '@angular/forms';
import { BcfViewpointModel } from '@app/api';
import { AdditionalDataLoader, AuthenticationService } from '@app/core';
import { ICommentsService, ViewpointWithThumbnail } from '@app/shared/services';

export interface IViewpointComment {
  text: string;
  viewpoint?: ViewpointWithThumbnail;
}

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: CommentsComponent,
    },
  ],
})
export class CommentsComponent implements ControlValueAccessor {
  @Input() service: ICommentsService;
  @Input() showAddButton: boolean = false;
  @Input() viewpointCount: number = 0;
  @Input() createNewViewpoint: () => Promise<ViewpointWithThumbnail>;
  @Input() viewpointsLoader: AdditionalDataLoader<ViewpointWithThumbnail[]>;
  @Output() editComment = new EventEmitter<void>();

  userId: string;
  comment: string = '';
  isDisabled: boolean = false;
  form: UntypedFormGroup;
  isAddCommentFocused: boolean = false;
  mainViewpoint: ViewpointWithThumbnail;

  onChange = (_: string | IViewpointComment) => {};
  private onTouched = () => {};
  private isTouched: boolean = false;

  constructor(authenticationService: AuthenticationService) {
    this.userId = authenticationService.getUserId();
  }

  get comments() {
    return this.service?.comments ?? [];
  }

  get canAddViewpoint() {
    return this.createNewViewpoint != undefined;
  }

  async addComment() {
    const success = await this.service.add(this.comment, this.mainViewpoint);
    if (success) {
      this.comment = '';
      this.mainViewpoint = null;
    }
  }

  onEnter(event: KeyboardEvent) {
    if (this.showAddButton && event.ctrlKey) this.addComment();
  }

  commentEditChange(isEdit: boolean) {
    if (isEdit) {
      this.editComment.emit();
    }
  }

  async addViewpoint() {
    this.mainViewpoint = await this.createNewViewpoint();
    this.formChanged();
  }

  viewpointClicked(viewpoint: ViewpointWithThumbnail) {
    if (viewpoint == this.mainViewpoint) {
      this.service.selectViewpoint(viewpoint);
    } else {
      this.mainViewpoint = viewpoint;
      this.formChanged();
      this.viewpointsLoader.load();
    }
  }

  removeViewpoint() {
    this.mainViewpoint = null;
    this.formChanged();
    this.viewpointsLoader.load();
  }

  formChanged() {
    const text = this.comment;
    const viewpoint = this.mainViewpoint;

    this.onChange(
      this.viewpointsLoader != null
        ? {
            text,
            viewpoint,
          }
        : text
    );
  }

  // ----- Reactive Forms Methods -----

  writeValue(comment: string | IViewpointComment): void {
    if (this.viewpointsLoader != null) {
      const commentWithViewpoint = comment as IViewpointComment;

      this.comment = commentWithViewpoint?.text;
      this.mainViewpoint = commentWithViewpoint?.viewpoint;
    } else {
      this.comment = comment?.toString() ?? '';
    }
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
