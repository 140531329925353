<mat-sidenav-container class="c4-sidebar-container" (backdropClick)="backdropClick()">
  <mat-sidenav id="c4MainSide" class="c4-sidebar" (openedStart)="sidenavOpened()" (closedStart)="sidenavClosed()"
               #sidenav [opened]="!globals.isMobileMenu" [mode]="globals.isMobileMenu ? 'over' : 'side'"
               [disableClose]="true">
    <div class="sidebar-content">
      <div class="menu" [ngSwitch]="currentPage">
        <app-sidebar-navigation-item *ngSwitchCase="PageType.overview" disabled [expanded]="!isMinified">
          <app-label-icon [name]="tenantName" [sizeRem]="2.4">
          </app-label-icon>
          <span label>{{ tenantName }}</span>
        </app-sidebar-navigation-item>

        <app-sidebar-navigation-item *ngSwitchCase="PageType.project" (click)="changeProject()"
                                     [expanded]="!isMinified">
          <app-project-image [projectId]="projectId" [sizeRem]="2.4">
          </app-project-image>
          <span label>{{ projectName }}</span>
        </app-sidebar-navigation-item>

        <app-sidebar-navigation-item *ngSwitchCase="PageType.admin" disabled [expanded]="!isMinified">
          <app-label-icon [name]="'admin.title' | translate" [sizeRem]="2.4" letterCount="2" colorSchemeClass="app">
          </app-label-icon>
          <span label>{{ 'admin.title' | translate }}</span>
        </app-sidebar-navigation-item>
      </div>

      <app-divider></app-divider>

      <div class="menu main" [ngSwitch]="currentPage">
        <ng-container *ngSwitchCase="PageType.overview" [ngTemplateOutlet]="overviewSidebarTemplate"></ng-container>
        <ng-container *ngSwitchCase="PageType.project" [ngTemplateOutlet]="projectSidebarTemplate"></ng-container>
        <ng-container *ngSwitchCase="PageType.admin" [ngTemplateOutlet]="adminSidebarTemplate"></ng-container>
      </div>

      <div *ngIf="!isMinified && currentPage == PageType.overview && importantLinks.length > 0" class="important-links">
        <div class="important-links-header">{{ 'sidebar.importantLinks' | translate }}</div>
        <div class="important-links-container" *ngFor="let link of importantLinks">
          <a class="c4-href-like" [href]="link.url" target="_blank">
            <span>{{ link.title | translate }}</span>
          </a>
        </div>
      </div>

      <app-divider></app-divider>

      <div class="menu sidebar-toggle-container" [class.item-padding-bottom]="isMinified" [ngSwitch]="currentPage">
        <ng-container *ngSwitchCase="PageType.project">
          <app-sidebar-navigation-item *ngIf="isProjectAdmin" [link]="pathToProjectSettings" [exact]="false"
                                       [expanded]="!isMinified">
            <span class="mdi mdi-cog"></span>
            <span label>{{ 'settings.title' | translate }}</span>
          </app-sidebar-navigation-item>
        </ng-container>
        <ng-container *ngSwitchCase="PageType.overview">
          <app-sidebar-navigation-item *ngIf="isAdmin" [link]="pathToAdmin" [expanded]="!isMinified">
            <span class="mdi mdi-cog"></span>
            <span label>{{ 'layout.toolbar.userDropdown.switchToAdministration' | translate }}</span>
          </app-sidebar-navigation-item>
        </ng-container>

        <div class="sidebar-toggle" (click)="toggleNavSize()" matRipple>
          <span class="mdi" [class]="isMinified ? 'mdi-chevron-double-right' : 'mdi-chevron-double-left'"></span>
        </div>
      </div>
    </div>
  </mat-sidenav>
  <mat-sidenav-content class="c4-sidenav-page-content" id="c4MainContent" [class.expanded]="isMinified">
    <div class="c4-content-wrapper">
      <router-outlet></router-outlet>
      <ng-container>
        <c4-footer>
        </c4-footer>
      </ng-container>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<ng-template #itemTemplate let-context let-exact="exact">
  <app-sidebar-navigation-item [link]="context.link" [expanded]="!isMinified" [exact]="exact !== false">
    <img class="module-image" [src]="context.image" />
    <span label>{{ context.label | translate }}</span>
  </app-sidebar-navigation-item>
</ng-template>

<ng-template #itemGroupTemplate let-context>
  <app-sidebar-navigation-item-group #group (opened)="closeExcept(group)">
    <app-sidebar-navigation-item [expanded]="!isMinified">
      <img *ngIf="context.header.image" class="module-image" [src]="context.header.image" />
      <span *ngIf="!context.header.image" class="mdi" [class]="context.header.icon"></span>
      <span label>{{ context.header.label | translate }}</span>
    </app-sidebar-navigation-item>
    <app-sidebar-navigation-item *ngFor="let item of context.items" [link]="item.link" [expanded]="!isMinified">
      <span class="mdi" [class]="item.icon"></span>
      <span label>{{ item.label | translate }}</span>
    </app-sidebar-navigation-item>

    <div popup class="group-popup-container">
      <span class="title">{{ context.header.label | translate }}</span>
      <a *ngFor="let item of context.items" matRipple [routerLink]="item.link">
        <span class="mdi" [class]="item.icon"></span>
        <span>{{ item.label | translate }}</span>
      </a>
    </div>
  </app-sidebar-navigation-item-group>
</ng-template>

<ng-template #overviewSidebarTemplate>
  <app-sidebar-navigation-item *ngFor="let tenant of otherTenants" (click)="changeTenant(tenant)"
                               [expanded]="!isMinified">
    <app-label-icon [name]="tenant.name" [sizeRem]="2.4">
    </app-label-icon>
    <span label>{{ tenant.name }}</span>
  </app-sidebar-navigation-item>
</ng-template>

<ng-template #projectSidebarTemplate>
  <ng-container *ngIf="items.dashboard" [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{
    $implicit: items.dashboard
  }"></ng-container>

  <ng-container *ngIf="documentMenuGroup && !isOffline"
                [ngTemplateOutlet]="documentMenuGroup.items.length > 1 ? itemGroupTemplate : itemTemplate"
                [ngTemplateOutletContext]="{
    $implicit: documentMenuGroup.items.length > 1 ? documentMenuGroup : {
      link: documentMenuGroup.items[0].link,
      image: documentMenuGroup.header.image,
      label: documentMenuGroup.header.label
    }
  }"></ng-container>

  <ng-container *ngIf="items.gallery && !isOffline" [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{
    $implicit: items.gallery
  }"></ng-container>

  <ng-container *ngIf="items.planning && !isOffline" [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{
    $implicit: items.planning,
    exact: false
  }"></ng-container>

  <ng-container *ngIf="bimMenuGroup && !isOffline" [ngTemplateOutlet]="itemGroupTemplate" [ngTemplateOutletContext]="{
    $implicit: bimMenuGroup
  }"></ng-container>

  <ng-container *ngIf="roomMenuGroup && !isOffline" [ngTemplateOutlet]="itemGroupTemplate" [ngTemplateOutletContext]="{
    $implicit: roomMenuGroup
  }"></ng-container>

  <ng-container *ngIf="leanMenuGroup && !isOffline" [ngTemplateOutlet]="itemGroupTemplate" [ngTemplateOutletContext]="{
    $implicit: leanMenuGroup
  }"></ng-container>

  <ng-container *ngIf="items.diary && !isOffline" [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{
    $implicit: items.diary
  }"></ng-container>

  <ng-container *ngIf="items.defects" [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{
    $implicit: items.defects
  }"></ng-container>

  <ng-container *ngIf="teamMenuGroup" [ngTemplateOutlet]="itemGroupTemplate" [ngTemplateOutletContext]="{
    $implicit: teamMenuGroup
  }"></ng-container>

  <ng-container *ngIf="items.notifications && !isOffline" [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{
    $implicit: items.notifications,
    exact: false
  }"></ng-container>
</ng-template>

<ng-template #adminSidebarTemplate>
  <app-sidebar-navigation-item [link]="'/admin/' + adminDashboardItem.path" [expanded]="!isMinified">
    <img *ngIf="adminDashboardItem.iconUrl" class="module-image" [src]="adminDashboardItem.iconUrl" />
    <span *ngIf="!adminDashboardItem.iconUrl" class="mdi" [class]="adminDashboardItem.icon"></span>
    <span label>{{ (adminDashboardItem.path + '.title') | translate }}</span>
  </app-sidebar-navigation-item>

  <ng-container *ngIf="adminProjectsMenuGroup" [ngTemplateOutlet]="itemGroupTemplate" [ngTemplateOutletContext]="{
    $implicit: adminProjectsMenuGroup
  }"></ng-container>

  <ng-container *ngIf="adminAccessMenuGroup" [ngTemplateOutlet]="itemGroupTemplate" [ngTemplateOutletContext]="{
    $implicit: adminAccessMenuGroup
  }"></ng-container>

  <ng-container *ngIf="adminBimMenuGroup" [ngTemplateOutlet]="itemGroupTemplate" [ngTemplateOutletContext]="{
    $implicit: adminBimMenuGroup
  }"></ng-container>

  <ng-container *ngIf="adminRoomBookMenuGroup" [ngTemplateOutlet]="itemGroupTemplate" [ngTemplateOutletContext]="{
    $implicit: adminRoomBookMenuGroup
  }"></ng-container>

  <ng-container *ngIf="adminLeanMenuGroup" [ngTemplateOutlet]="itemGroupTemplate" [ngTemplateOutletContext]="{
    $implicit: adminLeanMenuGroup
  }"></ng-container>

  <ng-container *ngIf="adminDefectsMenuGroup" [ngTemplateOutlet]="itemGroupTemplate" [ngTemplateOutletContext]="{
    $implicit: adminDefectsMenuGroup
  }"></ng-container>

  <ng-container *ngIf="adminSettingsMenuGroup" [ngTemplateOutlet]="itemGroupTemplate" [ngTemplateOutletContext]="{
    $implicit: adminSettingsMenuGroup
  }"></ng-container>
</ng-template>