import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { RouterModule } from '@angular/router';
import {
  FileName,
  FileSize,
  FileType,
  FolderPathPipe,
  ImagePreviewPipe,
  LetterRangePipe,
  LocalizedDatePipe,
  NumberFormatPipe,
  SafeHtmlPipe,
  ContrastColorPipe,
  FormCategoryContainsPipe,
  CssUrlPipe,
  HexColorPipe,
  JoinPipe,
} from '@app/shared/pipes';
import {
  ConsumeClickDirective,
  DragFilesDirective,
  NoAutocompleteDirective,
  NumberInputDirective,
  SetFocusDirective,
  TimeInputDirective,
} from '@app/shared/directives';
import { TranslateModule } from '@ngx-translate/core';
import { MaterialModule } from '@app/shared/plugins';
import {
  ATrustSignComponent,
  AcrylicContainerComponent,
  ActiveToggleComponent,
  ActiveToggleGroupComponent,
  AddDependencyDialogComponent,
  AddUserDialogComponent,
  AddressFormComponent,
  AppOpenCameraDialogComponent,
  AsyncSearchAutocompleteComponent,
  AttributeDialogComponent,
  AttributesComponent,
  AutocompleteComponent,
  BaseLayoutComponent,
  BimConfigComponent,
  BimViewpointComponent,
  BreadcrumbComponent,
  BryntumSchedulerComponent,
  BulkChangeDialog,
  CardComponent,
  CardsComponent,
  CarouselComponent,
  CategoriesComponent,
  CategoryDialogComponent,
  ChangeCompanyComponent,
  ChangePasswordComponent,
  CheckboxFormComponent,
  CheckboxRoundComponent,
  ChipsAutocompleteComponent,
  ChipsFilterComponent,
  ColorPickerFormFieldComponent,
  ColorPillComponent,
  CommentBubbleComponent,
  CommentsComponent,
  CompanyUserAssignDialogComponent,
  ConfirmDialogComponent,
  ConfirmWithTogglesDialogComponent,
  ConnectionListComponent,
  ConnectionListItemComponent,
  ContentGroupComponent,
  ContextMenuButtonComponent,
  ContextMenuDirective,
  CopyInfoDialogComponent,
  CropImageDialogComponent,
  CustomSearchbarComponent,
  DataCellComponent,
  DataRowComponent,
  DateRangeComponent,
  DefectConfigComponent,
  DefectsListComponent,
  DiaryConfigComponent,
  DiaryListComponent,
  DividerComponent,
  DocumentListComponent,
  DocumentPathComponent,
  DocumentPositionComponent,
  DragHoverOverlayComponent,
  DrawToSignComponent,
  DriveItemActionDialogComponent,
  DriveItemPrivilegesComponent,
  EditGridDialogComponent,
  EditHoverOverlayComponent,
  EditRoleDialogComponent,
  EditStateDialogComponent,
  EditTabDialogComponent,
  EnterViewNameDialogComponent,
  EntityDetailDialogComponent,
  EstimationProgressComponent,
  ExpandableHeaderComponent,
  FilePreviewOverlayComponent,
  FooterComponent,
  GalleryListComponent,
  GeneralConfigComponent,
  GeoLocationFormComponent,
  GeojsonEditorComponent,
  GeojsonEditorImgLayerComponent,
  GeojsonEditorMapLayerComponent,
  GeojsonPlanSelectionComponent,
  GeojsonPrintDialogComponent,
  GridComponent,
  HeaderBarComponent,
  HeaderComponent,
  HeaderTabsComponent,
  HistoryComponent,
  IFrameDialogComponent,
  IconPlaceholderComponent,
  IconToggleComponent,
  ImageDetailsComponent,
  ImageTagDialogComponent,
  ImageTagEditorComponent,
  ImageTagFromServerPipe,
  InputActionDialogComponent,
  IssueListComponent,
  LabelIconComponent,
  LeanConfigComponent,
  MailNotificationDialogComponent,
  MapsLinkDecodeDialogComponent,
  MetadataConfigComponent,
  MsTeamsConfigComponent,
  MultiImageCaptureDialogComponent,
  MultiKeyValueInputComponent,
  MultiSelectDialogComponent,
  MultiSelectOverlayComponent,
  MultiValueInputComponent,
  NewMarkerDialogComponent,
  NotificationCardComponent,
  NotificationComponent,
  NotificationSettingsComponent,
  NotifyAfterLoginDialogComponent,
  OfflineStateButtonComponent,
  OkDialogComponent,
  OrganizationDialogComponent,
  OrganizationInputComponent,
  OverflowExtendComponent,
  OverlayHeaderBarComponent,
  PageTitleComponent,
  PlainLayoutComponent,
  PlanConfigComponent,
  PlanDeleteDialogComponent,
  PlanListComponent,
  PlanParentComponent,
  PlanSchemaDialogComponent,
  PlanUploadDialogComponent,
  PlanVerifyDialogComponent,
  PopupComponent,
  PowerBiComponent,
  PrivilegeMatrixComponent,
  ProgressBarComponent,
  ProgressDialogComponent,
  ProjectCardComponent,
  ProjectConfigComponent,
  ProjectImageComponent,
  RadioGroupComponent,
  ReturnMenuButtonComponent,
  RolesConfigComponent,
  RoomEditComponent,
  RoomEditDetailComponent,
  RoomEditFilesComponent,
  RoomEditHistoryComponent,
  RoomListComponent,
  RoomTemplateEditDialogComponent,
  RoomTemplatesComponent,
  RoomToTemplateDialogComponent,
  ScanQrDialogComponent,
  SchemaEditDialogComponent,
  SearchSelectComponent,
  SearchbarComponent,
  SecurityConfigComponent,
  SelectDialogComponent,
  SelectionTreeComponent,
  SequenceEditorComponent,
  SidebarComponent,
  SidebarNavigationItemComponent,
  SidebarNavigationItemGroupComponent,
  SignSelectionDialogComponent,
  SimplePaginatorComponent,
  SlimButtonComponent,
  SlimSearchComponent,
  SplitComponent,
  StandardTextInputComponent,
  StandardTextSelectionDialogComponent,
  StateCircleComponent,
  StateMatrixComponent,
  StructureConfigComponent,
  SyncErrorDialogComponent,
  TeamRoleAssignComponent,
  TernaryCheckboxComponent,
  TextInputComponent,
  TextInputDialogComponent,
  ToggleFieldComponent,
  ToolbarComponent,
  TreeSelectCustomComponent,
  TreeSelectDialogComponent,
  TreeSelectionComponent,
  UploadComponent,
  UploadImageComponent,
  UserImageComponent,
  UserProfileComponent,
  UserSettingsDialogComponent,
  ViewLogComponent,
  ViewLogReceiversDialogComponent,
  WorkpackageEditDialogComponent,
  WorkpackageListComponent,
  WorkpackageSequenceComponent,
  WorkpackageSequencesComponent,
  WorkpackageTemplatePaneComponent,
  WorkpackageTemplateTileComponent,
  ZipDownloadUrlDialogComponent,
  ZoneAssignmentComponent,
} from './components';
import { TableModule } from 'primeng/table';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { MultiSelectModule } from 'primeng/multiselect';
import { NgxLoadingModule } from 'ngx-loading';
import { CalendarModule } from 'primeng/calendar';
import { EditorModule } from 'primeng/editor';
import { TreeModule } from 'primeng/tree';
import { TreeTableModule } from 'primeng/treetable';
import { PaginatorModule } from 'primeng/paginator';
import { NameConflictDialogComponent } from './services';
import { NgxMatDatetimePickerModule, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PlanOptions } from './pipes/plan-options.pipe';
import { PlanNameFormat } from './pipes/planname-format.pipe';
import { OverlayModule } from '@angular/cdk/overlay';
import { StartLowerPipe } from './pipes/start-lower.pipe';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { BryntumSchedulerProModule } from '@bryntum/schedulerpro-angular';
import { TreeDragDropService } from 'primeng/api';
import { TranslateFallbackPipe } from './pipes/try-translate.pipe';
import { AngularSignaturePadModule } from '@almothafar/angular-signature-pad';
import { SelectFieldPipe } from './pipes/select-field.pipe';
import { CdkMenuModule } from '@angular/cdk/menu';

const OTHER = [NgxMatDatetimePickerModule, NgxMatTimepickerModule, NgxMatMomentModule];

const PRIMENG = [
  CalendarModule,
  DropdownModule,
  EditorModule,
  InputTextModule,
  MultiSelectModule,
  PaginatorModule,
  TableModule,
  TreeModule,
  TreeTableModule,
];

const PIPES = [
  FileName,
  FileSize,
  FileType,
  FolderPathPipe,
  ImagePreviewPipe,
  LetterRangePipe,
  LocalizedDatePipe,
  NumberFormatPipe,
  PlanNameFormat,
  PlanOptions,
  SafeHtmlPipe,
  StartLowerPipe,
  ContrastColorPipe,
  FormCategoryContainsPipe,
  ImageTagFromServerPipe,
  TranslateFallbackPipe,
  CssUrlPipe,
  HexColorPipe,
  JoinPipe,
];

const UIDEPENDENCY = [
  BryntumSchedulerProModule,
  ClipboardModule,
  CommonModule,
  DragDropModule,
  FormsModule,
  LazyLoadImageModule,
  MaterialModule,
  OverlayModule,
  ReactiveFormsModule,
  RouterModule,
  TranslateModule,
  AngularSignaturePadModule,
  CdkMenuModule,
];

const DIRECTIVES = [
  DragFilesDirective,
  NoAutocompleteDirective,
  NumberInputDirective,
  SetFocusDirective,
  TimeInputDirective,
  ContextMenuDirective,
  ConsumeClickDirective,
];

const COMPONENTS = [
  ATrustSignComponent,
  AcrylicContainerComponent,
  ActiveToggleComponent,
  ActiveToggleGroupComponent,
  AddUserDialogComponent,
  AddressFormComponent,
  AppOpenCameraDialogComponent,
  AsyncSearchAutocompleteComponent,
  AttributeDialogComponent,
  AttributesComponent,
  AutocompleteComponent,
  BaseLayoutComponent,
  BimViewpointComponent,
  BreadcrumbComponent,
  BryntumSchedulerComponent,
  BulkChangeDialog,
  CardComponent,
  CardsComponent,
  CarouselComponent,
  CategoriesComponent,
  CategoryDialogComponent,
  ChangeCompanyComponent,
  ChangePasswordComponent,
  CheckboxFormComponent,
  CheckboxRoundComponent,
  ChipsAutocompleteComponent,
  ChipsFilterComponent,
  ColorPickerFormFieldComponent,
  ColorPillComponent,
  ColorPillComponent,
  CommentsComponent,
  CompanyUserAssignDialogComponent,
  ConfirmDialogComponent,
  ConfirmWithTogglesDialogComponent,
  ConnectionListComponent,
  ConnectionListItemComponent,
  ContentGroupComponent,
  ContextMenuButtonComponent,
  CopyInfoDialogComponent,
  CropImageDialogComponent,
  CustomSearchbarComponent,
  DataCellComponent,
  DataRowComponent,
  DateRangeComponent,
  DefectsListComponent,
  DiaryListComponent,
  DividerComponent,
  DocumentListComponent,
  DocumentPathComponent,
  DocumentPositionComponent,
  DragHoverOverlayComponent,
  DrawToSignComponent,
  DriveItemActionDialogComponent,
  DriveItemPrivilegesComponent,
  DriveItemPrivilegesComponent,
  EditGridDialogComponent,
  EditGridDialogComponent,
  EditHoverOverlayComponent,
  EnterViewNameDialogComponent,
  EntityDetailDialogComponent,
  EstimationProgressComponent,
  ExpandableHeaderComponent,
  FilePreviewOverlayComponent,
  FooterComponent,
  GalleryListComponent,
  GeoLocationFormComponent,
  GeojsonEditorComponent,
  GeojsonEditorImgLayerComponent,
  GeojsonEditorMapLayerComponent,
  GeojsonPlanSelectionComponent,
  GeojsonPrintDialogComponent,
  GridComponent,
  HeaderBarComponent,
  HeaderComponent,
  HeaderTabsComponent,
  HistoryComponent,
  IFrameDialogComponent,
  IconPlaceholderComponent,
  IconToggleComponent,
  ImageDetailsComponent,
  ImageTagDialogComponent,
  ImageTagEditorComponent,
  InputActionDialogComponent,
  IssueListComponent,
  LabelIconComponent,
  MailNotificationDialogComponent,
  MapsLinkDecodeDialogComponent,
  MultiImageCaptureDialogComponent,
  MultiKeyValueInputComponent,
  MultiSelectDialogComponent,
  MultiSelectOverlayComponent,
  MultiValueInputComponent,
  NameConflictDialogComponent,
  NewMarkerDialogComponent,
  NotificationCardComponent,
  NotificationComponent,
  NotificationSettingsComponent,
  NotifyAfterLoginDialogComponent,
  OfflineStateButtonComponent,
  OkDialogComponent,
  OrganizationDialogComponent,
  OrganizationInputComponent,
  OverflowExtendComponent,
  OverlayHeaderBarComponent,
  PageTitleComponent,
  PlainLayoutComponent,
  PlanDeleteDialogComponent,
  PlanListComponent,
  PlanParentComponent,
  PlanSchemaDialogComponent,
  PlanUploadDialogComponent,
  PlanVerifyDialogComponent,
  PopupComponent,
  PowerBiComponent,
  PowerBiComponent,
  PrivilegeMatrixComponent,
  ProgressBarComponent,
  ProgressDialogComponent,
  ProjectCardComponent,
  ProjectConfigComponent,
  ProjectImageComponent,
  RadioGroupComponent,
  ReturnMenuButtonComponent,
  RoomEditComponent,
  RoomListComponent,
  RoomTemplateEditDialogComponent,
  RoomTemplatesComponent,
  RoomToTemplateDialogComponent,
  ScanQrDialogComponent,
  SchemaEditDialogComponent,
  SearchSelectComponent,
  SearchbarComponent,
  SelectDialogComponent,
  SelectionTreeComponent,
  SequenceEditorComponent,
  SidebarComponent,
  SidebarNavigationItemComponent,
  SidebarNavigationItemGroupComponent,
  SignSelectionDialogComponent,
  SimplePaginatorComponent,
  SlimButtonComponent,
  SlimSearchComponent,
  SplitComponent,
  StandardTextInputComponent,
  StandardTextSelectionDialogComponent,
  StateCircleComponent,
  StateMatrixComponent,
  SyncErrorDialogComponent,
  TeamRoleAssignComponent,
  TernaryCheckboxComponent,
  TextInputComponent,
  TextInputDialogComponent,
  ToolbarComponent,
  TreeSelectCustomComponent,
  TreeSelectDialogComponent,
  TreeSelectionComponent,
  UploadComponent,
  UploadComponent,
  UploadImageComponent,
  UserImageComponent,
  UserProfileComponent,
  UserSettingsDialogComponent,
  ViewLogComponent,
  ViewLogComponent,
  ViewLogReceiversDialogComponent,
  WorkpackageEditDialogComponent,
  WorkpackageListComponent,
  WorkpackageSequenceComponent,
  WorkpackageSequencesComponent,
  WorkpackageTemplatePaneComponent,
  WorkpackageTemplateTileComponent,
  ZipDownloadUrlDialogComponent,
  ZoneAssignmentComponent,
];

const INTERNAL_COMPONENTS = [
  RoomEditDetailComponent,
  RoomEditFilesComponent,
  RoomEditHistoryComponent,
  EditRoleDialogComponent,
  AddDependencyDialogComponent,
  BimConfigComponent,
  DefectConfigComponent,
  DiaryConfigComponent,
  EditTabDialogComponent,
  GeneralConfigComponent,
  LeanConfigComponent,
  MetadataConfigComponent,
  MsTeamsConfigComponent,
  PlanConfigComponent,
  RolesConfigComponent,
  SecurityConfigComponent,
  StructureConfigComponent,
  ToggleFieldComponent,
  CommentBubbleComponent,
  EditStateDialogComponent,
];

@NgModule({
  declarations: [PIPES, DIRECTIVES, COMPONENTS, INTERNAL_COMPONENTS],
  imports: [CommonModule, UIDEPENDENCY, PRIMENG, OTHER, NgxLoadingModule.forRoot({})],
  exports: [PIPES, DIRECTIVES, UIDEPENDENCY, COMPONENTS, PRIMENG, OTHER, NgxLoadingModule],
  providers: [
    FileSize,
    DatePipe,
    LocalizedDatePipe,
    PlanOptions,
    PlanNameFormat,
    NumberFormatPipe,
    DecimalPipe,
    StartLowerPipe,
    ContrastColorPipe,
    FormCategoryContainsPipe,
    TreeDragDropService,
    TranslateFallbackPipe,
    HexColorPipe,
    SelectFieldPipe,
  ],
})
export class SharedModule {}
