import { Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ControlValueAccessor, UntypedFormBuilder, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  DriveItemModel,
  GeoJSONFeatureCollection,
  GeoJSONRelationType,
  GeoJSONType,
  LRTaskState,
  MetadataDriveItemModel,
  ModuleType,
  PlanSchemaDefinition,
} from '@app/api';
import { ApiService, LogService, OfflineService, ProjectService } from '@app/core';
import { FormComponent } from '@app/core/utils/form-component';
import { LongRunningTaskService, UserNotificationService } from '@app/shared/services';
import { convertPlan, PlanConversionStatus, PlanSource } from '@app/shared/services/long-running-tasks/convertPlan';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { BehaviorSubject, Subject, firstValueFrom, of } from 'rxjs';
import { catchError, first, map, switchMap, takeWhile } from 'rxjs/operators';
import { MappedPlan } from '../../planschema-list';
import { PlanUtils } from '../../planschema-list/planningUtils';
import { ExtendedPlanFieldModel, _planSeparator } from '../../planschema-list/schema-list.interfaces';
import { GeojsonEditorComponent, IGeoJsonChange } from '../geojson-editor/geojson-editor.component';

interface ListPlan {
  name: string;
  fileId: string;
  metadataId: string;
}

interface PlanGeojson {
  metadataId: string;
  featureCollection: GeoJSONFeatureCollection;
}

type OnChangeCallback = (value: PlanGeojson) => void;
type OnTouchedCallback = () => void;

@Component({
  selector: 'app-geojson-plan-selection',
  templateUrl: './geojson-plan-selection.component.html',
  styleUrls: ['./geojson-plan-selection.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: GeojsonPlanSelectionComponent,
    },
  ],
})
export class GeojsonPlanSelectionComponent extends FormComponent implements Busy, ControlValueAccessor, OnInit {
  @ViewChild('canvasWithMarkers') editor: GeojsonEditorComponent;
  /** Only used for markers */
  @Input() relationType: GeoJSONRelationType;
  /** Only used for markers */
  @Input() relatedEntityId: string;
  /** Currently only supported in Tasklike Dialog */
  @Input() canToggleFullscreen: boolean = true;

  @Input() drawingContext: object | null;
  @Input() snapshotArea: object | null;

  isBusy: boolean;
  isDisabled: boolean;

  plans: ListPlan[] = [];
  selectedPlan$ = new BehaviorSubject<PlanSource | null>(null);
  selectedPlanConversion$ = new Subject<PlanConversionStatus | null>();

  private plansLoaded$ = new BehaviorSubject<boolean>(false);

  private onChange: OnChangeCallback = _ => {};
  private onTouched: OnTouchedCallback = () => {};
  private isTouched: boolean = false;

  constructor(
    private apiService: ApiService,
    private formBuilder: UntypedFormBuilder,
    private log: LogService,
    private lrtService: LongRunningTaskService,
    private projectService: ProjectService,
    private offlineService: OfflineService,
    private userNotification: UserNotificationService
  ) {
    super();

    this.subscribe(
      this.selectedPlan$.pipe(
        switchMap(x =>
          of(x).pipe(
            convertPlan(apiService, lrtService),
            catchError(_ => {
              this.userNotification.notify('dialogs.defects.error.failedToConvertPlan');
              return of(null);
            })
          )
        ),
        map(status => {
          this.selectedPlanConversion$.next(status);
          return status;
        })
      )
    );
  }

  async ngOnInit() {
    this.form = this.formBuilder.group({
      plan: [],
      featureCollection: [],
    });

    this.f.featureCollection.valueChanges.subscribe(_ => this.emitChange());

    await using(new BusyScope(this), async _ => {
      if (this.offlineService.isProjectOffline()) {
        return;
      }

      const resourceIdentifiers = await this.apiService.getResourceIdentifiers();
      const planResource = resourceIdentifiers.find(i => i.moduleType == ModuleType.Plan).key.name;
      const planDef = await this.getPlanschema(planResource);

      if (!planDef) return;

      const result = await firstValueFrom(this.apiService.getMetadataDriveItems(planResource));
      const refinedResult = this.refineResult(
        result.items,
        planDef.planFields,
        planDef.seperator,
        planDef.versionField,
        planDef.versionValues
      );

      refinedResult.forEach((p: DriveItemModel & { Filetype: string }) => {
        if (/pdf/i.test(p.Filetype)) {
          this.plans.push({
            metadataId: p.metadataId,
            fileId: p.id,
            name: p.name,
          });
        }
      });
    }).catch(e => {
      this.userNotification.notifyFailedToLoadDataAndLog('general.errorFailedToLoadDataKeys.plans', e);
      this.isDisabled = true;
    });

    this.plansLoaded$.next(true);
  }

  //Planselect : load & refine
  async selectPlan(isSelectBoxChange: boolean = true) {
    const plan: ListPlan = this.f.plan.value;

    await using(new BusyScope(this), async _ => {
      if (plan && !plan.metadataId) {
        try {
          const model = await this.apiService.resolvePlan(plan.fileId);
          plan.metadataId = model.metadataId;
        } catch (e) {
          this.log.error(e);
          this.userNotification.notify('general.errorMsg.failedPlanResolve');
          return;
        }
      }
    });

    await this.syncPlan(plan, isSelectBoxChange);
  }

  deselectPlan() {
    this.selectedPlan$.next(null);
    this.form.setValue({
      plan: null,
      featureCollection: null,
    });
  }

  togglePlanFullscreen($event, target: GeojsonEditorComponent) {
    document.body.classList.toggle('app-geojson-editor-fullscreen');
    this.rerenderEditor();
  }

  rerenderEditor() {
    this.editor?.resize();
  }

  fireMarkersChange($evt: IGeoJsonChange) {
    const featureCollection = GeoJSONFeatureCollection.fromJS({
      type: GeoJSONType.FeatureCollection,
      features: $evt.features,
    });

    this.form.controls.featureCollection.setValue(featureCollection);
    this.form.markAsDirty();
  }

  async syncPlan(plan: ListPlan, isSelectBoxChange: boolean) {
    if (!plan || !plan.metadataId) {
      this.f.featureCollection.setValue(null, { emitEvent: isSelectBoxChange });
      return;
    }

    await using(new BusyScope(this), async _ => {
      this.selectedPlan$.next({
        projectId: this.projectService.projectId,
        metadataId: plan.metadataId,
      });

      const conversion = await this.selectedPlanConversion$
        .pipe(takeWhile(status => (status?.state ?? false) == false, true))
        .toPromise();

      if (isSelectBoxChange && conversion?.state) {
        if (this.drawingContext) {
          const featureCollection = await this.apiService.getGeoJSON(plan.metadataId, this.relationType, this.relatedEntityId);
          this.f.featureCollection.setValue(featureCollection.toJSON());
        } else {
          this.emitChange();
        }
      }
    });
  }

  private refineResult(
    files: MetadataDriveItemModel[],
    planDef: ExtendedPlanFieldModel[],
    seperator: string,
    versionField: string,
    versionValues: string[]
  ) {
    const response = new Array<MetadataDriveItemModel>();
    files.sort((a, b) => {
      //initial sort
      if (!a.lastModifiedDateTime || !b.lastModifiedDateTime) return 0;
      return a.lastModifiedDateTime > b.lastModifiedDateTime ? -1 : 1;
    });
    let mappedPlans: { [key: string]: MappedPlan } = {}; // cache same/similar files
    files.forEach((f: MetadataDriveItemModel & { selected: boolean; Filetype: string }, index) => {
      // delete : any to have intellisense
      let mapKey = '';
      f.selected = false;
      const { fileNameParts, fileExtension } = PlanUtils.parseFileName(f.name, planDef, seperator);
      planDef.forEach((d, index2) => {
        const fileValue = fileNameParts[index2] ? fileNameParts[index2] : null;
        if (d.isKey && fileValue) mapKey += fileValue.toLowerCase() + seperator;
      });
      // special case for file type (suffix)
      if (fileExtension) {
        f.Filetype = fileExtension;
        mapKey += fileExtension;
      }
      if (!mappedPlans[mapKey]) {
        const newPlanMap: MappedPlan = { files: [], lastIndex: '' };
        mappedPlans[mapKey] = newPlanMap;
      }
      mappedPlans[mapKey].files.push(f);
    });
    //refine latest index files
    for (const arr in mappedPlans) {
      if (mappedPlans.hasOwnProperty(arr)) {
        const datamap = mappedPlans[arr].files;
        // new index sort by index option values
        datamap.sort((a: any, b: any) => {
          return versionValues.indexOf(a[versionField]) - versionValues.indexOf(b[versionField]);
        });
        response.push(datamap[0]);
        mappedPlans[arr].lastIndex = datamap[0][versionField];
      }
    }
    return response;
  }

  private async getPlanschema(resource: string) {
    try {
      const schema: PlanSchemaDefinition = await this.apiService.getPlanSchemaByResource(resource);
      const schemaValues = {
        seperator: _planSeparator,
        versionField: null,
        versionValues: [],
        planFields: schema.planFields as ExtendedPlanFieldModel[],
      };

      if (schema.seperator) schemaValues.seperator = schema.seperator;

      schemaValues.planFields.forEach(item => {
        if (item.isVersion) {
          schemaValues.versionField = item.field;
          schemaValues.versionValues = !item.options || item.options.length <= 0 ? [] : item.options.map(x => x.value);
        }
      });

      return schemaValues;
    } catch (e) {
      this.userNotification.notifyFailedToLoadDataAndLog('planning.error.failedDataKeys.planSchema', e);
      return null;
    }
  }

  private emitChange(value: PlanGeojson = null) {
    this.form.updateValueAndValidity({ emitEvent: false, onlySelf: true });
    const formValue = value ?? this.form.value;
    this.onChange({
      metadataId: formValue?.plan?.metadataId,
      featureCollection: formValue?.featureCollection,
    });
  }

  // ----- Reactive Forms Methods -----

  writeValue(value: PlanGeojson): void {
    const updateSelectedPlan = () => {
      const plan = value?.metadataId ? this.plans.find(p => p.metadataId == value.metadataId) : null;
      const featureCollection = value?.featureCollection ? value.featureCollection : null;
      this.form.setValue(
        {
          plan,
          featureCollection,
        },
        { emitEvent: false }
      );

      if (plan) this.selectPlan(false);
    };

    this.plansLoaded$.pipe(first(areLoaded => areLoaded)).subscribe(updateSelectedPlan.bind(this));
  }

  registerOnChange(fn: OnChangeCallback): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: OnTouchedCallback): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
    if (isDisabled) this.f.plan.disable();
    else this.f.plan.enable();
  }

  private markAsTouched() {
    if (!this.isTouched) {
      this.isTouched = true;
      this.onTouched();
    }
  }
}
