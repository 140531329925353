import { Injectable } from '@angular/core';
import { UserNotificationService } from '../user-notification/user-notification.service';
import { LogService, ApiService, AppConfigService } from '@app/core';
import { DocumentDownloadData } from './document-download-interfaces';
import { ProblemDetails, SwaggerException } from '@app/api';
import { CapacitorUtils } from '@app/core/utils/capacitor-utils';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class DocumentDownloadService {
  constructor(
    private apiService: ApiService,
    private userNotification: UserNotificationService,
    private log: LogService,
    private httpClient: HttpClient
  ) {}

  async downloadDocuments(documents: DocumentDownloadData[]) {
    //https://stackoverflow.com/questions/18451856/how-can-i-let-a-user-download-multiple-files-when-a-button-is-clicked
    //https://stackoverflow.com/questions/9047645/download-multiple-files-without-using-zip-file/9049986#9049986
    if (!documents) {
      return;
    }

    if (documents.length === 1) {
      return await this.downloadDocument(documents[0]);
    }

    let multiDownloadAppSuccess = false;
    for (const doc of documents) {
      try {
        await this.userNotification.notify('documents.downloadStart', {
          file: doc.fileName,
        });

        const url = await this.apiService.generateDriveItemDownloadUrl(doc.id, doc.resource ? doc.resource : null);
        const absoluteUrl = `${AppConfigService.settings.api.url}${url}`;
        //NOTE: keep same if structure like in "downloadDocument"
        if (CapacitorUtils.isApp()) {
          await CapacitorUtils.urlFileDownload(absoluteUrl, this.httpClient, doc.fileName, false);
          multiDownloadAppSuccess = true;
        } else if (this.isIosDevice()) {
          //works on iPad, not on iPhone
          await this.executeDownloadAsLinkClick(absoluteUrl);
        } else if (document.body.append) {
          await this.executeDownloadAsIFrame(absoluteUrl);
        } else {
          //IE bypass
          await this.executeDownloadAsWindowOpen(absoluteUrl);
        }
      } catch (e) {
        this.log.error(`Failed to download document ${doc.id}`, e);

        if (e instanceof ProblemDetails) {
          this.userNotification.notify(e.detail);
        } else {
          this.userNotification.notify('documents.errorDownloadFailed');
        }
      }
    }
    if (CapacitorUtils.isApp() && multiDownloadAppSuccess) {
      await this.userNotification.notify('documents.appMultiDownloadSuccess');
    }
  }

  async downloadDocument(documentData: DocumentDownloadData, preview: boolean = false) {
    try {
      await this.userNotification.notify('documents.downloadStart', {
        file: documentData.fileName,
      });

      const url = await this.apiService.generateDriveItemDownloadUrl(
        documentData.id,
        documentData.resource ? documentData.resource : null,
        preview
      );
      const absoluteUrl = `${AppConfigService.settings.api.url}${url}`;
      //NOTE: keep same if structure like in "downloadDocuments"
      if (CapacitorUtils.isApp()) {
        await CapacitorUtils.urlFileDownload(absoluteUrl, this.httpClient, documentData.fileName, true);
      } else if (this.isIosDevice() || this.isSafari()) {
        //works on iPad and iPhone
        await this.executeDownloadAsLocationChange(absoluteUrl);
      } else if (preview) {
        await this.executeDownloadAsWindowOpen(absoluteUrl);
      } else if (document.body.append) {
        await this.executeDownloadAsIFrame(absoluteUrl);
      } else {
        //IE bypass
        await this.executeDownloadAsLocationChange(absoluteUrl);
      }
    } catch (e) {
      this.log.error(`Failed to download document ${documentData.id}`, e);

      if (e instanceof ProblemDetails) {
        this.userNotification.notify(e.detail);
      } else {
        let uiMsgKey = 'documents.errorDownloadFailed';
        if (SwaggerException.isSwaggerException(e)) {
          e = e as SwaggerException;
          if (e.status === 404) {
            uiMsgKey = 'documents.errorDownloadFailedFileMissing';
          }
        }
        this.userNotification.notify(uiMsgKey);
      }
    }
  }

  private async executeDownloadAsLocationChange(url: string) {
    //works fine on all browser when we just have one link - use it for single file downloads
    window.location.href = url;
  }

  private async executeDownloadAsIFrame(url: string) {
    //works fine on chrome, FF, Edge, fails on IE (there it loads only one of the files when adding multiple)
    //seems to work on  android chrome
    //fails on iOS
    const element = document.createElement('iFrame');
    element.setAttribute('src', url);
    element.setAttribute('style', 'display: none');
    document.body.append(element); //IE would need appendChild
  }

  private async executeDownloadAsWindowOpen(url: string) {
    const newWin = window.open(url);

    if (!newWin || newWin.closed || typeof newWin.closed == 'undefined') {
      this.userNotification.notify('documents.popUpBlocked');
    }
  }

  private async executeDownloadAsLinkClick(url: string) {
    const element = document.createElement('a');
    element.setAttribute('href', url);
    element.setAttribute('target', '_blank');
    element.setAttribute('rel', 'noopener noreferrer');
    document.body.append(element);
    element.click();
  }

  private isIosDevice(): boolean {
    const isSafari = this.isSafari();
    const iOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
    return isSafari && iOS;
  }

  private isSafari(): boolean {
    return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
  }

  async downloadAttachment(attachmentData: DocumentDownloadData) {
    try {
      await this.userNotification.notify('documents.downloadStart', {
        file: attachmentData.fileName,
      });

      const url = await this.apiService.generateAttachmentItemDownloadUrl(attachmentData.id);
      const absoluteUrl = `${AppConfigService.settings.api.url}${url}`;

      //NOTE: keep same if structure like in "downloadAttachment"
      if (CapacitorUtils.isApp()) {
        await CapacitorUtils.urlFileDownload(absoluteUrl, this.httpClient, attachmentData.fileName, false);
      } else if (this.isIosDevice()) {
        //works on iPad and iPhone
        await this.executeDownloadAsLocationChange(absoluteUrl);
      } else if (document.body.append) {
        await this.executeDownloadAsIFrame(absoluteUrl);
      } else {
        //IE bypass
        await this.executeDownloadAsLocationChange(absoluteUrl);
      }
    } catch (e) {
      this.log.error(`Failed to download document ${attachmentData.id}`, e);
      let uiMsgKey = 'documents.errorDownloadFailed';
      if (SwaggerException.isSwaggerException(e)) {
        e = e as SwaggerException;
        if (e.status === 404) {
          uiMsgKey = 'documents.errorDownloadFailedFileMissing';
        }
      }
      this.userNotification.notify(uiMsgKey);
    }
  }
}
