<div class="c4-dialog-wrapper">
	<h1 mat-dialog-title>{{ 'dialogs.zipDownloadUrl.title' | translate }}</h1>
	<mat-dialog-content>
		<div>{{ 'dialogs.zipDownloadUrl.description' | translate }}</div>
		<div class="row">
			<h3>{{ 'dialogs.zipDownloadUrl.url' | translate }}</h3>
			<button mat-button class="copy-button" [disabled]="!url" (click)="notifyUser()" [cdkCopyToClipboard]="url"
							[matTooltip]="'dialogs.zipDownloadUrl.copyToClipboard' | translate">
				<span>{{ 'dialogs.zipDownloadUrl.copy' | translate }}</span>
				<span class="mdi mdi-content-copy"></span>
			</button>
		</div>
		<div class="url">{{ url }}</div>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="close(true)" type="button">{{ 'general.cancel' | translate }}</button>
	</mat-dialog-actions>
</div>
<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop:false }"></ngx-loading>