import { InjectionToken } from '@angular/core';

export const FILE_PREVIEW_OVERLAY_DATA = new InjectionToken<string>('FILE_PREVIEW_OVERLAY_DATA');

export interface FilePreviewEvents {
  onsave?: (evt: IPreviewSaveEvent) => any;
  onnext?: (evt: IFilePreviewConfig) => IFilePreviewConfig;
  onprev?: (evt: IFilePreviewConfig) => IFilePreviewConfig;
}

export interface IFilePreviewParameters {
  fileId: string;
  fileResource: string;
  fileMetadataId: string;
}

export interface IPreviewSaveEvent extends IFilePreviewParameters {
  deleted: boolean;
}

export interface IFilePreviewConfig extends IFilePreviewParameters {
  events?: FilePreviewEvents;
}
