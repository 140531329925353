import { Component, Inject, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {
  CreateOrAssignProjectUserModel,
  GenderType,
  GetUserDataForAssignModel,
  OrganizationModel,
  TeamRole,
  UserDataModel,
  UserFilterModel,
  UserModel,
} from '@app/api';
import { ApiService, CustomValidators, Utils } from '@app/core';
import { FormComponent } from '@app/core/utils/form-component';
import { UserNotificationService } from '@app/shared/services';
import { Busy, BusyScope, using } from '@app/shared/utils/busy';
import { OrganizationDialogComponent } from '../../organization/organization-dialog/organization-dialog.component';

interface ExtendedUserModel extends UserModel {
  fullname: string;
  inviteToTeam?: boolean;
  roles: TeamRole[];
}

@Component({
  selector: 'app-add-user-dialog',
  templateUrl: './add-user-dialog.component.html',
  styleUrls: ['./add-user-dialog.component.scss'],
})
export class AddUserDialogComponent extends FormComponent implements Busy, OnInit {
  isBusy: boolean = false;

  existingUserIds: string[] = [];
  isSelectedUserInProject: boolean = false;

  genders: KeyValue[] = Object.keys(GenderType).map(gender => ({ key: gender, value: `dialogs.teamAssign.gender.${gender}` }));
  organizations: OrganizationModel[] = [];
  roles: TeamRole[] = [];

  addedUsers: ExtendedUserModel[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) data,
    private apiService: ApiService,
    private dialog: MatDialog,
    private dialogRef: MatDialogRef<AddUserDialogComponent>,
    private formBuilder: UntypedFormBuilder,
    private userNotification: UserNotificationService
  ) {
    super();

    this.existingUserIds = data?.existingUserIds ?? [];
  }

  get fetchUser() {
    return this.getAssignableUser.bind(this);
  }

  async ngOnInit() {
    this.initForm();

    const wereOrganizationsLoaded = await this.loadSelections();
    if (!wereOrganizationsLoaded) this.dialogRef.close(false);
  }

  async validateSelectedUser(isCancelRequest: boolean) {
    if (!this.f.selectedUser.value || isCancelRequest) return;

    await using(new BusyScope(this), async _ => {
      const user = this.parseSelectedUser();

      if (!user.externalAuthId && !user.id && this.f.email.valid) {
        try {
          const existingUser = await this.apiService.getUserForAssign(
            new GetUserDataForAssignModel({ mailAddress: user.emailAddress })
          );

          if (existingUser) {
            this.f.selectedUser.setValue(existingUser);
            user.init(existingUser);
          }
        } catch {
          this.userNotification.notify('general.errorMsg.getUserDataForAssignFailed');
        }
      }

      this.isSelectedUserInProject = false;
      this.enableAll();

      if (user.id) {
        if (this.existingUserIds.contains(user.id)) {
          this.disableAll();
          this.isSelectedUserInProject = true;
        } else {
          this.disableUserInformation();
        }
      }

      if (!user?.isOnBehalfLogin) this.f.inviteToTeam.disable();

      const organization = this.organizations.find(o => o.id == user.organization?.id);
      this.form.patchValue({
        firstname: user?.firstname,
        lastname: user?.lastname,
        gender: user?.gender ?? GenderType.None,
        inviteToTeam: !!user?.isOnBehalfLogin,
        organization,
        roles: [],
      });
    });
  }

  addUser() {
    const user = this.parseForm();
    this.addedUsers.push(user);
    this.resetForm();
  }

  removeAddedUser(at: number) {
    this.addedUsers.splice(at, 1);
  }

  async addOrganization() {
    const result = await this.dialog
      .open(OrganizationDialogComponent, {
        disableClose: true,
        data: {
          title: 'dialogs.organization.addTitle',
          isAddOrEdit: true,
        },
      })
      .afterClosed()
      .toPromise();

    const addedOrganizationId = result?.id;
    if (addedOrganizationId != null) {
      const success = await this.loadSelections();

      const addedOrganization = success ? this.organizations.find(o => o.id == addedOrganizationId) : null;
      if (addedOrganization != null) {
        this.f.organization.setValue(addedOrganization);
      }
    }
  }

  async confirm() {
    await using(new BusyScope(this), async _ => {
      const userAssignments = this.addedUsers.map(user => this.getAssignModel(user));

      await this.apiService.assignNewMembers(userAssignments);

      this.dialogRef.close(true);
    }).catch(e => {
      this.userNotification.notify('general.assignError');
    });
  }

  close() {
    this.dialogRef.close(false);
  }

  private initForm() {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, CustomValidators.email]],
      selectedUser: [null],
      firstname: [null],
      lastname: [null, [Validators.required]],
      gender: [null],
      inviteToTeam: [{ value: false, disabled: true }],
      organization: [null],
      roles: [],
    });

    // needed for validators
    this.f.selectedUser.valueChanges.subscribe((value: UserModel | string) => {
      const email = typeof value === 'string' ? value : value?.emailAddress;
      this.f.email.setValue(email);
    });

    this.disableAll();
  }

  private disableUserInformation() {
    this.f.firstname.disable();
    this.f.lastname.disable();
    this.f.gender.disable();
    this.f.organization.disable();
  }

  private disableAll() {
    this.disableUserInformation();
    this.f.inviteToTeam.disable();
  }

  private enableAll() {
    this.form.enable();
  }

  private async loadSelections() {
    return await using(new BusyScope(this), async _ => {
      const [organizations, roles] = await Promise.all([
        Utils.enhanceException(this.apiService.getOrganizations(), 'organizations'),
        Utils.enhanceException(this.apiService.getRoles(false, false), 'roles'),
      ]);

      this.organizations = organizations;
      this.roles = roles;

      return true;
    }).catch(e => {
      this.userNotification.notifyFailedToLoadDataAndLog(
        'general.errorFailedToLoadDataKeys.' + e.translationKey ?? 'organizations',
        e
      );

      return false;
    });
  }

  private async getAssignableUser(textFilter: string) {
    const addedIds = this.addedUsers.map(user => user.id);
    const assignableUser = await this.apiService.getAssignableUser(
      new UserFilterModel({
        text: textFilter,
        top: 10,
      })
    );

    return assignableUser.filter(user => !addedIds.contains(user.id));
  }

  private parseSelectedUser(): UserModel {
    const autocompleteInput: UserModel | string = this.f.selectedUser.value;

    let user: UserModel;
    if (typeof autocompleteInput === 'string') {
      user = new UserModel({
        emailAddress: autocompleteInput,
      });
    } else {
      user = autocompleteInput as UserModel;
    }

    return user;
  }

  private parseForm(): ExtendedUserModel {
    const user = this.parseSelectedUser() as ExtendedUserModel;

    if (!user.id) {
      user.firstname = this.f.firstname.value;
      user.lastname = this.f.lastname.value;
      user.gender = this.f.gender.value;
      user.organization = this.f.organization.value;
    } else if (user.isOnBehalfLogin) {
      user.inviteToTeam = this.f.inviteToTeam.value;
    }

    user.fullname = [user.firstname, user.lastname].filter(x => !!x).join(' ');
    user.roles = this.f.roles.value ?? [];

    return user;
  }

  private resetForm() {
    this.form.setValue(
      {
        email: '',
        selectedUser: null,
        firstname: '',
        lastname: '',
        gender: GenderType.None,
        inviteToTeam: false,
        organization: null,
        roles: [],
      },
      { emitEvent: false }
    );

    this.disableAll();

    this.isSelectedUserInProject = false;
  }

  private getAssignModel(user: ExtendedUserModel): CreateOrAssignProjectUserModel {
    return new CreateOrAssignProjectUserModel({
      userId: user.id,
      inviteToTeam: user.inviteToTeam,
      roles: user.roles.map(role => role.id),
      dataModel: new UserDataModel({
        firstname: user.firstname,
        lastname: user.lastname,
        emailAddress: user.emailAddress,
        gender: user.gender,
        organizationId: user.organization?.id,
        externalAuthId: user.externalAuthId,
      }),
    });
  }
}
