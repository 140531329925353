<ngx-loading [show]="isBusy" [config]="{ fullScreenBackdrop: true }"></ngx-loading>
<div class="c4-dialog-wrapper log-receivers-dialog">
	<h1 mat-dialog-title>{{ 'log.receivers.title' | translate }}</h1>
	<mat-dialog-content>
		<ul *ngIf="receivers?.length; else noReceiverFound">
			<li *ngFor="let receiver of receivers">
				<span [ngClass]="getNotificationIcon(receiver)"
							[matTooltip]="'general.notifications.' + receiver.notificationState | translate"></span>
				<span>{{ receiver.receiverDisplayName }}</span>
			</li>
		</ul>
	</mat-dialog-content>
	<mat-dialog-actions>
		<button mat-flat-button (click)="close()" type="button">{{ 'general.close' | translate }}</button>
	</mat-dialog-actions>
</div>

<ng-template #noReceiverFound>
	<span class="vertical-spacing">{{ 'log.receivers.notFound' | translate }}</span>
</ng-template>